import { cn } from '@/utils/format';
import { forwardRef } from 'react';
import { isMobileOnly } from 'react-device-detect';

type ScrollableLayoutProps = {
  children: React.ReactNode;
  className?: string;
};

export const ScrollableLayout = forwardRef(
  (props: ScrollableLayoutProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    return (
      <section
        ref={ref}
        className={cn(
          'flex-auto',
          isMobileOnly ? '' : 'overflow-y-scroll h-[calc(100vh-64px)]',
          props.className,
        )}
      >
        {props.children}
      </section>
    );
  },
);
