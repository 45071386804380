import { CustomerSearchParams } from '@/api/customers/customers';
import { SidebarLayout, SidebarLinks } from '@/components/Layout';
import { PolicyGate } from '@/features/auth/authorization';
import { InsufficientPermissions } from '@/features/auth/components/InsufficientPermissions';
import { NewCustomerDialog } from '@/features/customers/components/Detail/NewCustomerDialog';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/customers/$filter')({
  component: CustomerFeature,
});

const CustomersSubMenu: SidebarLinks<CustomerSearchParams> = [
  [
    {
      label: 'Investment Bank',
      path: '/customers/all',
      nk: 'investment',
      search_params: {
        bank: 'Investment',
      },
      access_rights: ['customers.view'],
    },
    {
      label: 'Relationship Bank',
      path: '/customers/all',
      nk: 'relationship',
      search_params: {
        bank: 'Relationship',
      },
      access_rights: ['customers.view'],
    },
    {
      label: 'Key Influencer',
      path: '/customers/all',
      nk: 'influencer',
      search_params: {
        bank: 'Key Influencer',
      },
      access_rights: ['customers.view'],
    },
  ],
  [
    {
      label: 'Daily Prospects List',
      path: '/customers/dpl',
      search_params: {
        orderBy: 'followup_at',
      },
      access_rights: ['customers.view'],
    },
    {
      label: 'Recent',
      path: '/customers/recent',
      access_rights: ['customers.view'],
    },
    {
      label: 'Open Leads',
      path: '/customers/open-leads',
      access_rights: ['customers.view'],
    },
    {
      label: 'Upcoming Bookings',
      path: '/customers/upcoming-bookings',
      access_rights: ['customers.view'],
    },
    {
      label: 'Past',
      path: '/customers/past',
      access_rights: ['customers.view'],
    },
    {
      label: 'Frequent',
      path: '/customers/frequent',
      access_rights: ['customers.view'],
    },
    {
      label: 'All',
      path: '/customers/all',
      nk: 'all',
      access_rights: ['customers.view'],
    },
  ],
  [
    {
      label: 'Rewards Queue',
      path: '/customers/rewards',
      access_rights: ['customers.rewards.view'],
    },
    {
      label: 'Lists',
      path: '/customers/lists',
      access_rights: ['customers.lists.view'],
    },
  ],
];

function CustomerFeature() {
  return (
    <PolicyGate policy="customers.view" forbiddenFallback={<InsufficientPermissions />}>
      <SidebarLayout
        title="Customers"
        links={CustomersSubMenu}
        sidebarChildren={
          <div className="my-2 pt-5 text-center border-t border-gray-200">
            <NewCustomerDialog />
          </div>
        }
      >
        <Outlet />
      </SidebarLayout>
    </PolicyGate>
  );
}
