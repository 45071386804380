import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/elements/dropdown-menu';
import { BeakerIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useMapStore } from './MapStateStore';

export const MapStyleControl = () => {
  const [expanded, setExpanded] = useState(false);
  const { mode, setMode } = useMapStore(
    useShallow((state) => ({
      mode: state.mode,
      setMode: state.setMode,
    })),
  );

  return (
    <div className="z-10 absolute top-2.5 left-12">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button
            type="button"
            onClick={() => setExpanded(!expanded)}
            className="bg-white hover:bg-gray-200 hover:cursor-pointer rounded shadow shadow-gray-500 block p-1"
          >
            <BeakerIcon className="w-6 h-6 text-gray-700" />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          <DropdownMenuCheckboxItem
            checked={mode === 'mapbox://styles/mapbox/streets-v12'}
            onCheckedChange={(checked) => setMode('mapbox://styles/mapbox/streets-v12')}
          >
            Streets
          </DropdownMenuCheckboxItem>
          <DropdownMenuCheckboxItem
            checked={mode === 'mapbox://styles/mapbox/satellite-streets-v12'}
            onCheckedChange={(checked) => setMode('mapbox://styles/mapbox/satellite-streets-v12')}
          >
            Satellite
          </DropdownMenuCheckboxItem>
          <DropdownMenuCheckboxItem
            checked={mode === 'mapbox://styles/mapbox/navigation-day-v1'}
            onCheckedChange={(checked) => setMode('mapbox://styles/mapbox/navigation-day-v1')}
          >
            Navigation (Day)
          </DropdownMenuCheckboxItem>
          <DropdownMenuCheckboxItem
            checked={mode === 'mapbox://styles/mapbox/navigation-night-v1'}
            onCheckedChange={(checked) => setMode('mapbox://styles/mapbox/navigation-night-v1')}
          >
            Navigation (Night)
          </DropdownMenuCheckboxItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
