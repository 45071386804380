import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { EventActivity, ModelID } from '@/types';
import { get } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { EventSearchParams, featureKeys } from '.';

type QueryFnType = typeof fetchEventActivities;

type UseEventsOptions = {
  location: ModelID;
  search?: EventSearchParams;
  config?: QueryConfig<QueryFnType>;
};

export const fetchEventActivities = (location: ModelID, search: EventSearchParams = {}) =>
  get<EventActivity[]>(`/v4/events/event-activities?franchise_id=${location}`, search);

export const useEventActivities = ({ location, search = {}, config = {} }: UseEventsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: featureKeys.activities(location, search),
    queryFn: () => fetchEventActivities(location, search),
  });
};
