import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/elements/popover';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  useFormAutosave,
} from '@/components/ui/form/form';
import { calendarDatetoString, cn, stringToCalendarDate } from '@/utils/format';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { CalendarDate, CalendarDateTime, ZonedDateTime } from '@internationalized/date';
import { VariantProps } from 'cva';
import { useState } from 'react';
import { DateInput, DatePicker, DateSegment, Group, Label } from 'react-aria-components';
import { ControllerProps, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';
import { Calendar, DatePickerClearButton, DatePickerTodayButton } from '../ui/elements/calendar';
import { inputVariants } from '../ui/elements/input';
import { formFieldVariants } from './FormInput';

type FormDateProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  VariantProps<typeof inputVariants> & {
    label?: string;
    description?: string;
    placeholder?: string;
    className?: string;
    onFieldFocus?: (value?: string) => void;
    onFieldBlur?: (value?: string) => void;
  };

export const FormDate = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  placeholder,
  label,
  description,
  variant,
  size,
  className,
  disabled,
  onFieldFocus,
  onFieldBlur,
  ...props
}: FormDateProps<TFieldValues, TName>) => {
  const autosave = useFormAutosave();
  const [open, setOpen] = useState(false);

  const setFieldValue = (
    field: ControllerRenderProps<TFieldValues, TName>,
    value: CalendarDate | CalendarDateTime | ZonedDateTime | undefined | null,
  ) => {
    value ? field.onChange(calendarDatetoString(value)) : field.onChange(undefined);
    onFieldBlur
      ? value
        ? onFieldBlur(calendarDatetoString(value))
        : onFieldBlur(undefined)
      : null;
  };

  const handleSetOpen = (isOpen: boolean) => {
    if (disabled) return;
    setOpen(isOpen);
  };

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <DatePicker
          isDisabled={disabled}
          shouldForceLeadingZeros={true}
          defaultValue={stringToCalendarDate(field.value)}
          onChange={(value) => setFieldValue(field, value)}
          onFocus={(event) => {
            if (autosave.enabled) autosave.setPaused(true);
          }}
          onBlur={(event) => {
            if (autosave.enabled) autosave.setPaused(false);
          }}
          isOpen={open}
          onOpenChange={setOpen}
        >
          <Popover open={open} onOpenChange={setOpen}>
            <FormItem className="relative">
              {label && (
                <FormLabel
                  aria-label={`${name}-label`}
                  className={cn(formFieldVariants({ variant: 'inset' }))}
                >
                  <Label>{label}</Label>
                </FormLabel>
              )}

              <FormControl>
                <Group
                  className={cn(inputVariants({ variant, size }), 'flex flex-row items-center')}
                  onClick={() => handleSetOpen(true)}
                >
                  <DateInput slot="start" className={cn('flex flex-row')}>
                    {(segment) => (
                      <DateSegment
                        segment={segment}
                        className={cn(
                          'data-[invalid]:text-red data-[placeholder]:opacity-60 data-[readonly]:opacity-50',
                          'data-[focused]:bg-primary-100 outline-none rounded-sm',
                          'p-0.5',
                          disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
                        )}
                      />
                    )}
                  </DateInput>
                  <PopoverTrigger className="ml-auto">
                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </PopoverTrigger>
                </Group>
              </FormControl>

              <PopoverContent className="md:w-52 p-2" align="end">
                <Calendar
                  defaultValue={stringToCalendarDate(field.value)}
                  onChange={(value) => {
                    if (autosave.enabled) autosave.setPaused(false);
                  }}
                  calendarControls={
                    <>
                      <DatePickerTodayButton />
                      {!props.rules?.required && <DatePickerClearButton />}
                    </>
                  }
                />
              </PopoverContent>
              {description && <FormDescription>{description}</FormDescription>}
              <FormMessage />
            </FormItem>
          </Popover>
        </DatePicker>
      )}
      {...props}
    />
  );
};
