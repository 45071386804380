import {
  CreateDTO,
  useCreateMutationFactory,
  UseCreateMutationProps,
} from '@/lib/react-query/create';
import { PaymentMethod } from '@/types';
import { create } from '@/utils/api';
import { featureKeys, featurePath, PaymentMethodSearchParams } from '.';

type PaymentMethodToken = PaymentMethod & {
  token: string;
};

export type CreatePaymentMethodDTO = CreateDTO<PaymentMethodToken, PaymentMethodSearchParams>;

const createPaymentMethod = ({ data, params = {} }: CreatePaymentMethodDTO) =>
  create<PaymentMethod>(`${featurePath}`, data, params);

export const useCreatePaymentMethod = (options?: UseCreateMutationProps<PaymentMethod>) => {
  return useCreateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: createPaymentMethod,
    ...options,
  });
};
