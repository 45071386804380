import { PolicyGate } from '@/features/auth/authorization';
import { InsufficientPermissions } from '@/features/auth/components/InsufficientPermissions';
import { EventProvider } from '@/features/events/contexts/EventContext';
import { modelID } from '@/utils/format';
import { useParams } from '@tanstack/react-router';
import { Suspense } from 'react';
import { EventDetail } from '../components/Detail/EventDetail';

export const EventDetailPage = () => {
  const { id: eventIdParam } = useParams({
    strict: false,
  });
  const eventId = modelID(eventIdParam);

  return (
    <PolicyGate policy="events.view" forbiddenFallback={<InsufficientPermissions />}>
      <EventProvider eventId={eventId}>
        <Suspense fallback={<div>Loading...</div>}>
          <EventDetail />
        </Suspense>
      </EventProvider>
    </PolicyGate>
  );
};
