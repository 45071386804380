import { Event, ModelID, SearchParams } from '@/types';

export type Filter =
  | 'all'
  | 'upcoming'
  | 'today'
  | 'recent'
  | 'quick'
  | 'payment-needed'
  | 'collections'
  | 'confirmation-needed'
  | 'cancelled'
  | 'pending-transfer'
  | 'attention-required';
export type Sort = 'start_at' | 'booked_at' | 'updated_at' | 'event_id';
export interface EventSearchParams extends SearchParams<Filter, Sort>, Partial<Event> {
  date_begin?: string;
  date_end?: string;
  status_not?: string;
  customer_name?: string;
  phone?: string;
  email?: string;
  address_city?: string;
  booked_begin?: string;
  booked_end?: string;
  event_type_id?: string;
  event_type_ids?: string[];
  organization_type?: string;
  event_id?: string;
  invoice_num?: string;
  booked_online?: boolean;
  how_heard?: string;
  staff_id?: ModelID | Array<ModelID>;
}

export const featurePath = '/v4/events/event';

export const featureKeys = {
  all: [{ scope: 'events' }] as const,

  lists: (location: ModelID) => [{ ...featureKeys.all[0], location, entity: 'list' }] as const,

  list: (location: ModelID, search: EventSearchParams = {}) =>
    [{ ...featureKeys.lists(location)[0], search }] as const,

  activities: (location: ModelID, search: EventSearchParams = {}) =>
    [{ ...featureKeys.lists(location)[0], search, entity: 'activities' }] as const,

  details: () => [{ ...featureKeys.all[0], entity: 'detail' }] as const,

  detail: (id: ModelID) => [{ ...featureKeys.details()[0], id }] as const,
};
