import { Avatar } from '@/components/Elements';
import List from '@/components/Elements/List/List';
import { Button } from '@/components/ui/elements/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/elements/dialog';
import { Input } from '@/components/ui/elements/input';
import { AuthContext } from '@/providers/auth';
import { LocationContext } from '@/providers/location';
import { User } from '@/types';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useContext, useState } from 'react';

type LocationSelectorProps = {
  open: boolean;
  selectedValue: number | undefined;
  listOptions: any[];
  setOpen: (value: boolean) => void;
  onClose: (value: number | undefined) => void;
  badge: React.ReactNode;
};

type UserBadgeProps = {
  user: User;
  activeLocation: any;
};

const UserBadge = ({ user, activeLocation }: UserBadgeProps) => {
  return (
    <div className="flex flex-row">
      <Avatar
        alt={user.full_name}
        src={user.avatar}
        fallback={user.first_name && user.last_name ? user.first_name[0] + user.last_name[0] : '??'}
        size="lg"
      />
      <div className="ml-2 text-left relative w-56">
        <span className="text-xs md:text-md font-semibold absolute">{user.full_name}</span>
        <br />
        <span className="text-xs md:text-sm tracking-tighter absolute top-5">
          {activeLocation?.areaop || 'Pick a Location'}
        </span>
      </div>
    </div>
  );
};

const LocationSelector = ({
  onClose,
  selectedValue,
  open,
  setOpen,
  listOptions,
  badge,
}: LocationSelectorProps) => {
  const { logout } = useContext(AuthContext);
  const [filter, setFilter] = useState<string | null>(null);

  const handleListItemClick = (value: number) => {
    onClose(value);
    setFilter(null);
  };

  const onFilterChange = (value: string) => {
    setFilter(value.toLowerCase());
  };

  const handleOpenChange = (value: boolean) => {
    setFilter(null);
    setOpen(value);
  };

  listOptions = filter
    ? listOptions.filter((option) => option.areaop.toLowerCase().includes(filter))
    : listOptions;

  return (
    <>
      <Dialog open={open} onOpenChange={handleOpenChange}>
        <DialogContent className="h-[500px]">
          <DialogHeader>
            <DialogTitle>
              <div className="flex justify-between pt-3">
                {badge}
                <Button variant="outline" size="sm" className="mt-2" onClick={logout}>
                  Sign Out
                </Button>
              </div>
            </DialogTitle>
          </DialogHeader>
          <Input placeholder="Search..." onChange={(event) => onFilterChange(event.target.value)} />
          <div className="border-t h-80 overflow-y-scroll">
            <List className="">
              {listOptions?.map((access) => (
                <List.ItemButton
                  key={access.franchise_id}
                  onClick={() => handleListItemClick(access.franchise_id)}
                  className="w-full"
                >
                  {selectedValue === access.franchise_id ? (
                    <CheckIcon className="h-5 w-5 mr-2" />
                  ) : (
                    <span className="h-5 w-5 mr-2" />
                  )}
                  {access.areaop}
                </List.ItemButton>
              ))}
            </List>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const FranchisePicker = () => {
  const [open, setOpen] = useState(false);
  const { user, location_access } = useContext(AuthContext);
  const { activeLocation, activeLocationId, selectLocation } = useContext(LocationContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: number | undefined) => {
    setOpen(false);
    if (value !== undefined && value > -1) {
      selectLocation(value);
    }
  };

  if (!user) return <Button>Login</Button>;

  const badge = <UserBadge user={user} activeLocation={activeLocation} />;

  return (
    <div>
      <button
        className="text-gray-800 py-0.5 pl-0.5 md:left-0 border rounded-full cursor-pointer bg-white hover:bg-gray-50 hover:shadow-sm"
        type="button"
        onClick={handleClickOpen}
      >
        {badge}
      </button>
      {location_access && (
        <LocationSelector
          selectedValue={activeLocationId}
          open={open}
          setOpen={setOpen}
          onClose={handleClose}
          listOptions={location_access}
          badge={badge}
        />
      )}
    </div>
  );
};
