import {
  DeleteDTO,
  useDeleteMutationFactory,
  UseDeleteMutationProps,
} from '@/lib/react-query/delete';
import { Pin } from '@/types';
import { remove } from '@/utils/api';
import { featureKeys, featurePath, PinSearchParams } from '.';

export type DeletePinDTO = DeleteDTO<Pin, PinSearchParams>;

const deletePin = ({ id, data, params }: DeletePinDTO) => remove(`${featurePath}/${id}`);

export const useDeletePin = (options?: UseDeleteMutationProps<Pin>) => {
  return useDeleteMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: deletePin,
    ...options,
  });
};
