import { UseUpdateMutationProps } from '@/lib/react-query/update';
import { EventActivity, ModelID } from '@/types';
import { post } from '@/utils/api';
import { CalendarDate } from '@internationalized/date';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { featurePath } from '.';

interface AssignmentMap {
  asset_id: ModelID;
  assigned_user_id: ModelID | null;
}

export interface AssignStaffDTO {
  old_user_id: ModelID | null | 'Unassigned';
  new_user_id: ModelID | 'Unassigned';
  event_activity_id: ModelID;
  action: 'assign' | 'unassign' | 'reassign';
}

export interface BulkAssignStaffDTO {
  franchise_id: ModelID;
  map: AssignmentMap[];
  date: CalendarDate;
}

const assignStaff = ({ old_user_id, new_user_id, event_activity_id, action }: AssignStaffDTO) =>
  post<EventActivity>(`${featurePath}/activity/${event_activity_id}/staff-assignment`, {
    old_user_id,
    new_user_id,
    action,
  });

export const useAssignStaff = (options?: UseUpdateMutationProps<EventActivity>) => {
  return useMutation<EventActivity, unknown, AssignStaffDTO, unknown>({
    mutationFn: assignStaff,
    ...options,
  } as UseMutationOptions<EventActivity, unknown, AssignStaffDTO, unknown>);
};

const bulkAssignStaff = ({ franchise_id, map, date }: BulkAssignStaffDTO) =>
  post<EventActivity[]>(`${featurePath}/bulk-staff-assignment`, {
    franchise_id,
    map,
    date: date.toString(),
  });

export const useBulkAssignStaff = (options?: UseUpdateMutationProps<EventActivity[]>) => {
  return useMutation<EventActivity[], unknown, BulkAssignStaffDTO, unknown>({
    mutationFn: bulkAssignStaff,
    ...options,
  } as UseMutationOptions<EventActivity[], unknown, BulkAssignStaffDTO, unknown>);
};
