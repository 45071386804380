import { CustomerSearchParams, Filter } from '@/api/customers/customers';
import { useAddSearchToList } from '@/api/customers/customers/addSearchToList';
import { usePagedCustomers } from '@/api/customers/customers/getCustomers';
import { CustomerListDropdownMenuGroup } from '@/components/Dropdown/CustomerListDropdownMenuGroup';
import { ErrorResult, ListingPlaceholder, Paginator, Spinner } from '@/components/Elements';
import List from '@/components/Elements/List/List';
import NoResults from '@/components/Elements/NoResults/NoResults';
import { ScrollableLayout } from '@/components/Layout';
import Page from '@/components/Layout/Page';
import { Button } from '@/components/ui/elements/button';
import { Checkbox } from '@/components/ui/elements/checkbox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/elements/dropdown-menu';
import { toast } from '@/components/ui/elements/use-toast';
import { useBatchSelection } from '@/hooks/useBatchSelection';
import { usePaginator } from '@/hooks/usePaginator';
import { useScrollToTop } from '@/hooks/useScrollToTop';
import { useSearchParams } from '@/hooks/useSearchParams';
import { LocationContext } from '@/providers/location';
import { Customer, CustomerList } from '@/types';
import { AnyRoute, useParams } from '@tanstack/react-router';
import { useContext, useEffect, useRef } from 'react';
import { CustomerResult } from './../components/CustomerResult';
import { CustomerSearchForm } from './../components/CustomerSearchForm';

/** @Feature: Indicator badges */

type RouteParams = {
  filter: Filter;
};

const searchDefaults = {
  orderBy: 'updated_at',
  orderDir: 'desc',
  limit: 50,
  page: 1,
} as CustomerSearchParams;

export const CustomerSearchPage = () => {
  const { activeLocationId } = useContext(LocationContext);
  const { filter } = useParams<AnyRoute, RouteParams>({
    strict: false,
  });
  const { search, setSearchParams } = useSearchParams<CustomerSearchParams>();
  const {
    allSelected,
    setAllSelected,
    selected: customersSelected,
    toggle: selectCustomer,
    clear: clearSelectedCustomers,
    batch: selectCustomers,
  } = useBatchSelection();

  const { mutate: addResultsToCustomerList, isPending: listBuildIsPending } = useAddSearchToList();

  const searchState = {
    ...searchDefaults,
    ...search,
  };

  const { isPending, isFetching, isError, data, error } = usePagedCustomers({
    location: activeLocationId,
    search: {
      ...searchState,
      filter: filter || 'upcoming-bookings',
    },
    config: {
      refetchOnWindowFocus: true,
      // Find a way to not have to do this!
      structuralSharing: false,
    },
  });

  const { results: customers, paginator } = data ?? {};
  const { page, total: recordsFound, lastPage, gotoPage } = usePaginator({ paginator });

  const selectAddCustomers = (checked: boolean) => {
    if (checked && customers) {
      selectCustomers(customers.map((customer) => customer.id));
      setAllSelected(true);
    } else {
      clearSelectedCustomers();
      setAllSelected(false);
    }
  };

  const handleAddResultsToCustomerList = (list: CustomerList) => {
    addResultsToCustomerList(
      {
        location: activeLocationId,
        list,
        search: {
          ...searchState,
          filter: filter,
        },
      },
      {
        onSuccess: () => {
          toast({
            title: `All results have been added to the ${list.name} list.`,
          });
        },
      },
    );
  };

  const handleSearch = (values: any) => {
    setSearchParams(values);
  };

  const scrollRef = useRef<HTMLDivElement>(null);
  const { scrollToTop } = useScrollToTop(scrollRef);
  useEffect(() => {
    scrollToTop();
  }, [customers]);

  return (
    <ScrollableLayout ref={scrollRef}>
      <Page className="pt-0">
        <CustomerSearchForm
          onSubmit={handleSearch}
          defaultValues={searchState}
          recordsFound={recordsFound}
        >
          <Checkbox
            checked={allSelected}
            onCheckedChange={selectAddCustomers}
            className="mx-2 hidden @md:inline-block"
          />
          <DropdownMenu>
            <DropdownMenuTrigger asChild disabled={recordsFound === 0}>
              <Button type="button" variant="outline" className="mx-auto hidden @md:inline-block">
                Bulk Actions {customersSelected.length > 0 && `(${customersSelected.length})`}
                {isPending && <Spinner className="ml-2" size="sm" />}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent side="bottom" align="start">
              <DropdownMenuLabel>All ({recordsFound})</DropdownMenuLabel>
              {/* <PolicyGate policy="events.export">
                  <DropdownMenuItem
                    disabled={recordsFound === 0}
                    onClick={handleExportResults}
                    className="cursor-pointer"
                  >
                    <CloudArrowDownIcon className="mr-2 h-4 w-4" />
                    Export Results
                    {exportIsPending && <Spinner className="ml-2" size="sm" />}
                  </DropdownMenuItem>
                </PolicyGate> */}

              <CustomerListDropdownMenuGroup
                disabled={recordsFound === 0}
                actionText="Add Results to List"
                onListClick={handleAddResultsToCustomerList}
              />

              <DropdownMenuSeparator />
              <DropdownMenuLabel>Selected ({customersSelected.length})</DropdownMenuLabel>
              <CustomerListDropdownMenuGroup
                disabled={customersSelected.length === 0}
                customerIds={customersSelected.map((id) => id.toString())}
              />
              <DropdownMenuSeparator />
              <DropdownMenuItem
                disabled={customersSelected.length === 0}
                onClick={clearSelectedCustomers}
                className="cursor-pointer"
              >
                Clear Selection
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </CustomerSearchForm>

        {isPending || isFetching ? (
          <ListingPlaceholder />
        ) : isError ? (
          <ErrorResult error={error} />
        ) : customers && customers.length === 0 ? (
          <NoResults label="customers" />
        ) : (
          customers &&
          customers.length > 0 && (
            <>
              <List>
                {customers.map((customer: Customer) => (
                  <CustomerResult
                    key={customer.id}
                    customer={customer}
                    selected={customersSelected.includes(customer.id)}
                    setSelected={selectCustomer}
                  />
                ))}
              </List>
              {paginator && <Paginator page={page} lastPage={lastPage} onPageChange={gotoPage} />}
              <div className="mt-20 mb-96 flex flex-row items-start justify-center">
                <Button variant="outline" onClick={scrollToTop}>
                  Back to Top
                </Button>
              </div>
            </>
          )
        )}
      </Page>
    </ScrollableLayout>
  );
};
