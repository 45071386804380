import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type TrayMode = 'calendar' | 'map' | 'notifications' | 'pins';

export type TrayDirection = 'horizontal' | 'vertical';

type PanelStore = {
  trayVisible: boolean;
  setTrayVisible: (visible: boolean) => void;
  trayMode: TrayMode;
  setTrayMode: (mode: TrayMode) => void;
  trayDirection: TrayDirection;
  setTrayDirection: (direction: TrayDirection) => void;
  size: number;
  setSize: (size: number) => void;
  toggleDirection: () => void;
};

export const usePanelStore = create<PanelStore>()(
  persist(
    (set) => ({
      trayVisible: false,
      setTrayVisible: (visible: boolean) => set(() => ({ trayVisible: visible })),

      trayMode: 'calendar',
      setTrayMode: (mode: TrayMode) =>
        set((state) => ({
          trayMode: mode,
          trayVisible: true,
          size: state.size + 0.000001,
        })),

      trayDirection: 'horizontal',
      setTrayDirection: (direction: TrayDirection) => set(() => ({ trayDirection: direction })),

      size: 25,
      setSize: (size: number) => set(() => ({ size: size })),

      toggleDirection: () =>
        set((state) => ({
          trayDirection: state.trayDirection === 'horizontal' ? 'vertical' : 'horizontal',
          size: state.size + 0.000001,
        })),
    }),
    {
      name: 'panels-storage',
      partialize: (state) => ({
        trayVisible: state.trayVisible,
        trayMode: state.trayMode,
        trayDirection: state.trayDirection,
        size: state.size,
      }),
    },
  ),
);
