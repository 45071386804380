import { config } from '@/lib/pusher';
import { PusherProvider } from '@harelpls/use-pusher';
import { ReactNode } from 'react';

type RealtimeProviderProps = {
  children: ReactNode;
};

export const RealtimeProvider = ({ children }: RealtimeProviderProps) => {
  return <PusherProvider {...config}>{children}</PusherProvider>;
};
