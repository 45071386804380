import { useEventChangelog } from '@/api/events/events/getEvent';
import { ChangeLog } from '@/components/ChangeLog/ChangeLog';
import { PolicyGate } from '@/features/auth/authorization';
import { InsufficientPermissions } from '@/features/auth/components/InsufficientPermissions';
import { EventContext } from '@/features/events/contexts/EventContext';
import { Event } from '@/types';
import { useContext } from 'react';

interface ChangesProps {
  event: Event;
}
export const ChangesTab = () => {
  const { event } = useContext(EventContext);

  if (!event) return <>Loading...</>;

  return <Changes event={event} />;
};

const Changes = ({ event }: ChangesProps) => {
  const { data: changelog, isSuccess } = useEventChangelog({
    event,
  });

  return (
    <>
      {isSuccess && (
        <PolicyGate policy="events.history" forbiddenFallback={<InsufficientPermissions />}>
          <ChangeLog changes={changelog ?? []} />
        </PolicyGate>
      )}
    </>
  );
};
