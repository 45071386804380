import { Dialog, DialogContent } from '@/components/ui/elements/dialog';

interface MessageMediaPopoverProps {
  mediaUrl: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const MessageMediaPopover = ({ mediaUrl, open, setOpen }: MessageMediaPopoverProps) => {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="w-full">
        <img alt="media message" src={mediaUrl} className="w-full" />
      </DialogContent>
    </Dialog>
  );
};
