import { LocationContext } from '@/providers/location';
import { GamificationStats, ModelID } from '@/types';
import { get } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

interface WaitTimeResponse {
  lead_id: ModelID;
  longest_last_contact: ModelID;
  longest_waiting: ModelID;
  newest_lead: ModelID;
  oldest_lead: ModelID;
  shortest_last_contact: ModelID;
  shortest_waiting: ModelID;
  wait_time: number;
  waiting: number;
  waiting_since: string;
  events_waiting: number;
  unread_messages_count: number;
  ttfc: number | null;
  booking_ratio: number | null;
  gamification: GamificationStats;
}

const FIVE_MINUTES = 1000 * 60 * 5;
const ONE_MINUTE = 1000 * 60 * 1;

export const useHeartbeat = () => {
  const { activeLocationId } = useContext(LocationContext);

  const { data } = useQuery({
    queryKey: [{ scope: 'heartbeat', location: activeLocationId }],
    queryFn: () =>
      get<WaitTimeResponse>('/v4/heartbeat', {
        franchise_id: activeLocationId,
      }),
    refetchInterval: FIVE_MINUTES,
    staleTime: ONE_MINUTE,
    refetchOnWindowFocus: true,
    enabled: activeLocationId > -1 && activeLocationId < 999999999999,
  });

  return {
    nextLeadId: data?.lead_id ?? null,
    waitTime: data?.wait_time ?? 0,
    waiting: data?.waiting ?? 0,
    waitingSince: data?.waiting_since ?? null,
    eventsWaiting: data?.events_waiting ?? 0,
    unreadMessages: data?.unread_messages_count ?? 0,
    ttfc: data?.ttfc ?? 0,
    bookingRatio: data?.booking_ratio ?? 0,
    oldestLead: data?.oldest_lead ?? null,
    newestLead: data?.newest_lead ?? null,
    shortestWaiting: data?.shortest_waiting ?? null,
    longestWaiting: data?.longest_waiting ?? null,
    shortestLastContact: data?.shortest_last_contact ?? null,
    longestLastContact: data?.longest_last_contact ?? null,
    gamification: {
      level: data?.gamification.level ?? 0,
      score: data?.gamification.score ?? 0,
      level_progress: data?.gamification.level_progress ?? 0,
      bookings: data?.gamification.bookings ?? 0,
      calls: data?.gamification.calls ?? 0,
      contacts: data?.gamification.contacts ?? 0,
      emails: data?.gamification.emails ?? 0,
      first_contacts: data?.gamification.first_contacts ?? 0,
      quotes: data?.gamification.quotes ?? 0,
      txts: data?.gamification.txts ?? 0,
    },
  };
};
