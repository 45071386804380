import { User } from '@/types';
import { cva, VariantProps } from 'cva';
import { Avatar } from './Avatar';

export const avatarGroup = cva(['flex overflow-hidden'], {
  variants: {
    size: {
      sm: '-space-x-1',
      md: '-space-x-3',
      lg: '-space-x-5',
      xl: '-space-x-7',
    },
  },
  compoundVariants: [],
  defaultVariants: {
    size: 'sm',
  },
});

export type AvatarGroupProps = VariantProps<typeof avatarGroup> & {
  users: Partial<User>[];
  className?: string;
};

export const AvatarGroup = ({ users, size, className }: AvatarGroupProps) => {
  return (
    <>
      <div className={avatarGroup({ size, className })}>
        {users.map((user) => (
          <Avatar
            key={`avatar-${user.id}`}
            src={user.avatar}
            size={size}
            fallback={`${user.first_name ? user.first_name[0] : '?'}${
              user.last_name ? user.last_name[0] : '?'
            }`}
          />
        ))}
      </div>
    </>
  );
};
