import { featureKeys as calendarFeatureKeys } from '@/api/calendar';
import queryClient from '@/lib/react-query';
import {
  UpdateDTO,
  useUpdateMutationFactory,
  UseUpdateMutationProps,
} from '@/lib/react-query/update';
import { Event, ModelID } from '@/types';
import { post } from '@/utils/api';
import { EventSearchParams, featureKeys, featurePath } from '.';

export type UpdateEventDTO = UpdateDTO<Event, EventSearchParams>;

export type VerifyEventDTO = {
  id: ModelID;
  data: {
    send_email: boolean;
  };
  params?: EventSearchParams;
};

const verifyEvent = ({ data, id, params = {} }: VerifyEventDTO) =>
  post<Event>(`${featurePath}/${id}/local-verify`, data, params);

export const useVerifyEvent = (options?: UseUpdateMutationProps<Event>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: verifyEvent,
    ...options,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: calendarFeatureKeys.all });
      options?.onSuccess?.(data);
    },
  });
};
