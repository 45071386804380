import { Paginator } from '@/utils/api';
import { useNavigate, useSearch } from '@tanstack/react-router';

interface PaginatorProps {
  paginator: Paginator | undefined;
}

export const usePaginator = ({ paginator }: PaginatorProps) => {
  const navigate = useNavigate();
  const { page, ...search } = useSearch({
    strict: false,
  });

  const gotoPage = (newPage: number) =>
    navigate({
      search: { ...search, page: newPage },
    });

  return {
    total: paginator?.total ?? 0,
    hasMore: paginator?.hasMore ?? false,
    lastPage: paginator?.lastPage ?? page,
    page,
    gotoPage,
  };
};
