import { useConfirmEvent } from '@/api/events/events/updateEvent';
import { DropdownMenuTrigger } from '@/components/ui/elements/dropdown-menu';
import { useAuthorization } from '@/features/auth/authorization';
import { Event } from '@/types';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { EventActionDropdownMenu } from '../EventActionDropdownMenu';
import { ConfirmEventDialog } from './ConfirmEventDialog';

interface EventActionsComboButtonProps {
  event: Event;
}

export const EventActionsComboButton = ({ event }: EventActionsComboButtonProps) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const { mutate: confirmEvent } = useConfirmEvent();
  const { checkPolicy } = useAuthorization();

  const canConfirm = checkPolicy({ policy: 'events.confirm' });

  const handleConfirmEvent = (sendEmail: boolean) => {
    confirmEvent({
      id: event.id,
      data: {
        send_email: sendEmail,
      },
    });
  };

  return (
    <>
      <div className="bg-green-500 flex mb-6 shadow-sm border border-green-600 rounded text-white">
        {canConfirm ? (
          <button
            type="button"
            onClick={() => setShowConfirmDialog(true)}
            className="w-full p-3 text-lg hover:bg-green-600 transition-colors"
          >
            <span className="text-shadow">
              {event.confirmed_at ? 'Re-confirm Event' : 'Confirm Event'}
            </span>
          </button>
        ) : (
          <span className="grow"> </span>
        )}

        <EventActionDropdownMenu event={event}>
          <DropdownMenuTrigger className="border-l pl-2 border-green-600 hover:bg-green-600 transition-colors">
            <div className="flex items-center text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 h-8 w-8 focus:ring-offset-gray-100">
              <span className="sr-only">More Actions</span>
              <ChevronDownIcon className="h-6 w-6 pt-0.5 text-white" aria-hidden="true" />
            </div>
          </DropdownMenuTrigger>
        </EventActionDropdownMenu>

        <ConfirmEventDialog
          open={showConfirmDialog}
          onOpenChange={setShowConfirmDialog}
          onSubmit={handleConfirmEvent}
        />
      </div>
    </>
  );
};
