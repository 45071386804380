import { Checkbox } from '@/components/ui/elements/checkbox';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form/form';
import { CheckedState } from '@radix-ui/react-checkbox';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';

type FormCheckboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> & {
  label?: string;
  description?: string;
  placeholder?: string;
  onChange?: (value: CheckedState) => void;
};

export const FormCheckbox = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  placeholder,
  label,
  description,
  onChange,
  ...props
}: FormCheckboxProps<TFieldValues, TName>) => {
  const handleCheckedChange = (value: CheckedState, callback: (checked: CheckedState) => void) => {
    if (onChange) onChange(value);
    callback(value);
  };

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="flex flex-row items-center space-x-3 space-y-0 p-2">
          <FormControl>
            <Checkbox
              checked={field.value}
              disabled={props.disabled}
              onCheckedChange={(value) => handleCheckedChange(value, field.onChange)}
            />
          </FormControl>
          <div className="space-y-1 leading-none">
            {label && <FormLabel>{label}</FormLabel>}
            {description && <FormDescription>{description}</FormDescription>}
          </div>
        </FormItem>
      )}
      {...props}
    />
  );
};
