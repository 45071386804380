import { featureKeys } from '@/api/leads/leads';
import { Toggle } from '@/components/Elements';
import { PolicyGate } from '@/features/auth/authorization';
import { AuthContext } from '@/providers/auth';
import { useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';

export const LeadsModeToggle = () => {
  const { leadsMode, setLeadsMode } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const checked = leadsMode === 'b2b';

  const handleChange = (checked: boolean) => {
    setLeadsMode(checked ? 'b2b' : 'all');
    queryClient.invalidateQueries({ queryKey: featureKeys.all });
  };

  return (
    <PolicyGate policy="leads.view_b2b">
      <div className="w-full border-t pt-3">
        <Toggle
          checked={checked}
          onChange={handleChange}
          label="B2B"
          leftLabel="All"
          className="w-full justify-center"
        />
      </div>
    </PolicyGate>
  );
};
