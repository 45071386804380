import { CopyToClipboard } from '@/components/CopyToClipboard/CopyToClipboard';
import { Badge } from '@/components/ui/elements/badge';
import { Lead } from '@/types';

interface IdBadgeProps {
  lead: Lead;
}

export const IdBadge = ({ lead }: IdBadgeProps) => {
  return (
    <Badge size="sm">
      <CopyToClipboard value={lead.lead_id}>
        <span className="hidden md:inline-block">LID</span> #{lead.lead_id}
      </CopyToClipboard>
    </Badge>
  );
};
