import { PolicyCheck } from '@/features/auth/authorization';
import { AddonPolicies } from './addons';
import { CouponPolicies } from './coupons';
import { PackagePolicies } from './packages';
import { ServicePolicies } from './services';

export const ProductPolicies: Record<string, PolicyCheck> = {
  'products.view': ({ acl, user, meta }) => {
    return acl.products.view;
  },
  ...AddonPolicies,
  ...ServicePolicies,
  ...PackagePolicies,
  ...CouponPolicies,
};
