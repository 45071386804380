import { ModelID, Payment, SearchParams } from '@/types';

export type Filter = 'all' | 'active' | 'inactive';
export type Sort = 'created_at' | 'updated_at' | 'payment_id';
export interface PaymentSearchParams extends SearchParams<Filter, Sort>, Partial<Payment> {}

export const featurePath = '/v4/customers/payment';

export const featureKeys = {
  all: [{ scope: 'payments' }] as const,

  lists: (location: ModelID) => [{ ...featureKeys.all[0], location, entity: 'list' }] as const,

  list: (location: ModelID, search: PaymentSearchParams = {}) =>
    [{ ...featureKeys.lists(location)[0], search }] as const,

  details: () => [{ ...featureKeys.all[0], entity: 'detail' }] as const,

  detail: (id: ModelID) => [{ ...featureKeys.details()[0], id }] as const,
};
