import debounce from 'lodash/debounce';
import { useEffect, useMemo, useRef } from 'react';

export const useDebounce = (callback: CallableFunction, delay: number) => {
  const ref = useRef<CallableFunction>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      return ref.current?.();
    };

    return debounce(func, delay);
  }, []);

  return debouncedCallback;
};
