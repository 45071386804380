import { featureKeys as customerListFeatureKeys } from '@/api/customers/customer-lists';
import queryClient from '@/lib/react-query';
import { UseUpdateMutationProps } from '@/lib/react-query/update';
import { CustomerList, ModelID } from '@/types';
import { post } from '@/utils/api';
import { useMutation } from '@tanstack/react-query';
import { EventSearchParams, featurePath } from '.';

type AddSearchToListDTO = {
  location: ModelID;
  list: CustomerList;
  search?: EventSearchParams;
};

const addSearchToList = ({ location, list, search = {} }: AddSearchToListDTO) =>
  post<CustomerList>(`${featurePath}/results-to-list`, {
    franchise_id: location,
    customer_list_id: list.id,
    ...search,
  });

export const useAddSearchToList = (options?: UseUpdateMutationProps<CustomerList>) => {
  return useMutation({
    mutationFn: addSearchToList,
    onSuccess: (data: CustomerList) => {
      queryClient.invalidateQueries({ queryKey: customerListFeatureKeys.detail(data.id) });
      if (options?.onSuccess) {
        options.onSuccess(data);
      }
    },
  });
};
