import { FormInput } from '@/components/Form/FormInput';
import { Form, FormControl, FormField, FormItem } from '@/components/ui/form/form';
import { useSearchParams } from '@/hooks/useSearchParams';
import { cn, titleCase } from '@/utils/format';
import {
  AdjustmentsHorizontalIcon,
  ArrowPathIcon,
  ArrowUpCircleIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { ClassProp } from 'cva/dist/types';
import { createContext, ReactNode, useContext, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { z } from 'zod';
import { FormSelect } from '../Form';
import { Badge } from '../ui/elements/badge';

interface SearchTermChip {
  key: string;
  label: string;
  value: string | number | boolean | string[];
  resetFn?: CallableFunction;
}

type Formatter = {
  key: string;
  resetFn?: CallableFunction;
  formatter: (value: any) => string;
};

type SearchFormProps = {
  form: UseFormReturn<any>;
  onSubmit: (data: any) => void;
  onReset?: () => void;
  refetch?: () => void;
  hasAdvanced?: boolean;
  children?: React.ReactNode;
  toggleSwitch?: React.ReactNode;
  hiddenKeys?: string[];
  formatters?: Formatter[];
  recordsFound?: number;
  perPageOptions?: PerPageOption[];
  orderByOptions?: OrderByOption[];
} & ClassProp;

export const defaultSearchSchema = z.object({
  keyword: z.string().nullish(),
  orderBy: z.string().optional(),
  orderDir: z.string().nullish(),
  limit: z.coerce.number().nullish(),
});

export type SearchFormContextData = {
  form: UseFormReturn<any>;
  search: Record<string, any>;
  hasAdvanced: boolean;
  hasActiveSearch: boolean;
  showAdvancedSearch: boolean;
  hiddenKeys: string[];
  setShowAdvancedSearch: (show: boolean) => void;
  runSearch: () => void;
  resetSearch: () => void;
  removeSearchTerm: (key: string) => void;
};

type SearchFormProviderProps = {
  form: UseFormReturn<any>;
  hasAdvanced?: boolean;
  hiddenKeys?: string[];
  onSubmit: (data: any) => void;
  onReset?: () => void;
  children: ReactNode;
};

export const SearchFormContext = createContext({} as SearchFormContextData);

export function SearchFormProvider({
  form,
  hasAdvanced = false,
  hiddenKeys = [],
  onSubmit,
  onReset,
  children,
}: SearchFormProviderProps) {
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const { search, resetSearchParams } = useSearchParams<any>();

  const hiddenKeyFields = hiddenKeys.concat([
    'orderBy',
    'orderDir',
    'page',
    'limit',
    'keyword',
    'filter',
    'nk',
  ]);
  const searchTermKeys = Object.keys(search).filter(
    (field) => !(hiddenKeyFields.indexOf(field) > -1),
  );
  const hasActiveSearch = searchTermKeys.length > 0 || search.keyword;

  const runSearch = () => {
    form.handleSubmit(onSubmit)();
  };

  const resetSearch = () => {
    resetSearchParams();
    if (onReset) onReset();
    form.reset(
      {},
      {
        keepDirtyValues: false,
        keepSubmitCount: true,
      },
    );
    setShowAdvancedSearch(false);
  };

  const removeSearchTerm = (key: string) => {
    form.setValue(key, '');
    form.handleSubmit(onSubmit)();
  };

  return (
    <SearchFormContext.Provider
      value={{
        form,
        search,
        hasAdvanced,
        hasActiveSearch,
        showAdvancedSearch,
        hiddenKeys: hiddenKeyFields,
        setShowAdvancedSearch,
        runSearch,
        resetSearch,
        removeSearchTerm,
      }}
    >
      {children}
    </SearchFormContext.Provider>
  );
}

export const useSearchForm = () => useContext(SearchFormContext);

const SearchForm = ({
  form,
  onSubmit,
  onReset,
  children,
  hasAdvanced = false,
  hiddenKeys = [],
  className = '',
}: SearchFormProps) => {
  return (
    <SearchFormProvider
      form={form}
      hasAdvanced={hasAdvanced}
      hiddenKeys={hiddenKeys}
      onSubmit={onSubmit}
      onReset={onReset}
    >
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className={cn(className, '@container grow space-y-2')}
        >
          {children}
        </form>
      </Form>
    </SearchFormProvider>
  );
};

const KeywordInput = () => {
  const {
    form,
    hasAdvanced,
    hasActiveSearch,
    showAdvancedSearch,
    setShowAdvancedSearch,
    resetSearch,
  } = useSearchForm();

  return (
    <div className="relative">
      <div className="z-10 pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <FormInput
        control={form.control}
        name="keyword"
        className="block w-full h-12 border-0 py-3 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 rounded-full"
        placeholder="Search by keyword..."
      />
      {hasActiveSearch && (
        <div className="absolute right-8 top-0">
          <span className="rounded-full block  hover:cursor-pointer p-2 m-1">
            <XCircleIcon
              className="h-6 w-6 text-gray-500 hover:text-gray-800"
              onClick={resetSearch}
            />
          </span>
        </div>
      )}

      {hasAdvanced && (
        <div className="absolute right-0 top-0">
          <span className="rounded-full block hover:bg-gray-100 hover:cursor-pointer p-2 m-1">
            <AdjustmentsHorizontalIcon
              className="h-6 w-6 text-gray-800"
              onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
            />
          </span>
        </div>
      )}
    </div>
  );
};
SearchForm.KeywordInput = KeywordInput;

const AdvancedOptions = ({ children }: { children: ReactNode }) => {
  const { showAdvancedSearch } = useSearchForm();
  return (
    <div
      className={cn(
        'flex flex-col w-full bg-white border-gray-200 border rounded-md px-3 pt-5 pb-2',
        !showAdvancedSearch ? 'hidden' : '',
      )}
    >
      {children}
    </div>
  );
};
SearchForm.AdvancedOptions = AdvancedOptions;

type SearchChipsProps = {
  hiddenKeys?: string[];
  formatters?: Formatter[];
};

const SearchChips = ({ formatters = [] }: SearchChipsProps) => {
  const { runSearch, search, hiddenKeys, removeSearchTerm } = useSearchForm();

  // Derive the search term chips from the current search values
  const searchTermKeys = Object.keys(search).filter((field) => !(hiddenKeys.indexOf(field) > -1));

  const chips =
    searchTermKeys
      .filter((key) => !!search[key])
      .map((key) => {
        const value = search[key];

        if (formatters.length > 0) {
          const formatter = formatters.find((formatter) => formatter.key === key);
          if (formatter) {
            return {
              key,
              label: titleCase(key).replace(' Id', ''),
              value: formatter.formatter(value),
              resetFn: formatter.resetFn,
            } as SearchTermChip;
          }
        }

        return {
          key,
          label: titleCase(key).replace(' Id', ''),
          value: value,
        } as SearchTermChip;
      })
      .filter((chip) => chip.value) ?? [];

  if (!chips || chips.length === 0) return <></>;

  return (
    <div className="flex flex-col md:flex-row w-full">
      <div className="space-x-2 space-y-2">
        {chips.map((chip, i) => (
          <Badge
            key={`search-chip-${chip.key}${i}`}
            size="sm"
            onRemove={() => {
              if (chip.resetFn) {
                chip.resetFn();
                runSearch();
              } else {
                removeSearchTerm(chip.key);
              }
            }}
          >
            {chip.label}: {chip.value}
          </Badge>
        ))}
      </div>
    </div>
  );
};
SearchForm.SearchChips = SearchChips;

type RefetchProps = {
  refetch: () => void;
};

const Refetch = ({ refetch }: RefetchProps) => {
  return (
    <span className="flex w-6">
      <button type="button" onClick={refetch}>
        <ArrowPathIcon className="h-5 w-5 text-gray-400 mx-auto hover:cursor-pointer hover:text-gray-600 transition-all duration-200 ease-in-out transform hover:rotate-180" />
      </button>
    </span>
  );
};
SearchForm.Refetch = Refetch;

interface OrderByOption {
  value: string;
  label: string;
}

type OrderByProps = {
  options?: OrderByOption[];
  className?: string;
  name?: string;
  label?: string;
};

const OrderByDefaults = [
  { value: 'created_at', label: 'Date Added' },
  { value: 'updated_at', label: 'Last Update' },
] as OrderByOption[];

const OrderBy = ({
  options = OrderByDefaults,
  className = '',
  name = 'orderBy',
  label = 'Order By',
}: OrderByProps) => {
  const { form, runSearch } = useSearchForm();
  return (
    <div className={cn('w-40', className)}>
      <FormSelect
        control={form.control}
        onChange={runSearch}
        name={name}
        options={options}
        label={label}
      />
    </div>
  );
};
SearchForm.OrderBy = OrderBy;

interface PerPageOption {
  value: number;
  label: string;
}

type PageLimitProps = {
  options?: PerPageOption[];
  className?: string;
  name?: string;
  label?: string;
};

const PerPageDefaults = [
  { value: 15, label: '15' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 200, label: '200' },
] as PerPageOption[];

const PageLimit = ({
  options = PerPageDefaults,
  className = '',
  name = 'limit',
  label = 'Per Page',
}: PageLimitProps) => {
  const { form, runSearch } = useSearchForm();
  return (
    <div className={cn('w-20', className)}>
      <FormSelect
        control={form.control}
        onChange={runSearch}
        name={name}
        options={options}
        label={label}
      />
    </div>
  );
};
SearchForm.PageLimit = PageLimit;

type OrderDirectionProps = {
  options?: PerPageOption[];
  className?: string;
  name?: string;
  label?: string;
};

const OrderDirection = ({ className = '', name = 'orderDir' }: OrderDirectionProps) => {
  const { form, runSearch } = useSearchForm();
  return (
    <div className={cn('', className)}>
      <FormField
        control={form.control}
        name={name}
        render={({ field }) => (
          <FormItem className="flex flex-row w-8">
            <FormControl>
              <button
                type="button"
                onClick={() => {
                  field.onChange(field.value === 'asc' ? 'desc' : 'asc');
                  runSearch();
                }}
              >
                <ArrowUpCircleIcon
                  className={cn(
                    'h-8 w-8 text-gray-400 mx-auto hover:cursor-pointer hover:text-gray-600 transition-all duration-200 ease-in-out',
                    field.value === 'desc' ? 'transform rotate-180' : '',
                  )}
                />
              </button>
            </FormControl>
          </FormItem>
        )}
      />
    </div>
  );
};
SearchForm.OrderDirection = OrderDirection;

type RecordCountProps = {
  className?: string;
  recordCount: number | undefined;
};

const RecordsFound = ({ className = '', recordCount = 0 }: RecordCountProps) => {
  return (
    <>
      {recordCount ? (
        <span
          className={cn('grow hidden md:inline text-sm text-gray-500 text-right px-4', className)}
        >
          {recordCount} {recordCount === 1 ? 'record' : 'records'} found
        </span>
      ) : (
        <span className="grow" />
      )}
    </>
  );
};
SearchForm.RecordsFound = RecordsFound;

export default SearchForm;
