import { Link } from '@/components/Elements';
import { PolicyGate } from '@/features/auth/authorization';
import { InsufficientPermissions } from '@/features/auth/components/InsufficientPermissions';
import { EventContext } from '@/features/events/contexts/EventContext';
import { Event } from '@/types';
import { formatApiDate } from '@/utils/format';
import { formatNumber } from '@/utils/numbers-utils';
import {
  CalendarDaysIcon,
  CheckBadgeIcon,
  ClipboardDocumentCheckIcon,
  CreditCardIcon,
  TruckIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import { useContext } from 'react';
import { StatusBadge } from '../../Badges/StatusBadge';
import { SurveyBadge } from '../../Badges/SurveyBadge';
import NewNoteForm from '../../NewNoteForm';
import { EventActionsComboButton } from '../EventActionsComboButton';
import { EventNotes } from './components/EventNotes';
import { EventOverviewInvoice } from './components/EventOverviewInvoice';

interface OverviewTabProps {
  event: Event;
}

export const OverviewTab = () => {
  const { event } = useContext(EventContext);

  if (!event) return <>Loading...</>;

  return <EventOverview event={event} />;
};

export const EventOverview = ({ event }: OverviewTabProps) => {
  return (
    <PolicyGate policy="events.view" forbiddenFallback={<InsufficientPermissions />}>
      <div className="w-full py-6">
        <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 md:max-w-none md:grid-cols-2">
          {/* Invoice summary */}
          <div className="md:col-start-3 md:row-end-1">
            <EventActionsComboButton event={event} />
            <h2 className="sr-only">Summary</h2>
            <div className="rounded-lg bg-gray-100 shadow-sm ring-1 ring-gray-900/5">
              <dl>
                <div className="flex justify-between">
                  <div className="flex flex-col pl-6 pt-4">
                    <span className="text-sm font-semibold leading-6 text-gray-900">
                      Balance Due: {formatNumber(event.balance_due, 'currency')}
                    </span>
                    <span className="text-sm font-normal leading-6 text-gray-900">
                      Total: {formatNumber(event.total, 'currency')}
                    </span>
                    <span className="text-sm font-normal leading-6 text-gray-900">
                      Gratuity: {formatNumber(event.gratuity_paid, 'currency')}
                    </span>
                  </div>
                  <div className="px-4 pt-4 flex flex-col items-end ">
                    <StatusBadge event={event} />
                    <SurveyBadge event={event} />
                  </div>
                </div>
                <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                  <dt className="flex-none">
                    <span className="sr-only">Customer</span>
                    <UserCircleIcon className="h-6 w-5 text-gray-600" aria-hidden="true" />
                  </dt>
                  <dd className="text-sm font-medium leading-6 text-gray-900">
                    {event.customer.full_name}
                  </dd>
                </div>
                <div className="mt-4 flex w-full flex-none items-center gap-x-4 px-6">
                  <dt className="flex-none">
                    <span className="sr-only">Event Type</span>
                    <TruckIcon className="h-6 w-5 text-gray-600" aria-hidden="true" />
                  </dt>
                  <dd className="text-sm leading-1 text-gray-900">{event.event_type.name}</dd>
                </div>
                <div className="mt-4 flex w-full flex-none items-center gap-x-4 px-6">
                  <dt className="flex-none">
                    <CalendarDaysIcon className="h-6 w-5 text-gray-600" aria-hidden="true" />
                  </dt>
                  <dd className="text-sm leading-1 text-gray-900">
                    <span className="text-xs uppercase">Verified by Customer at</span>
                    <br />
                    {event.verified_at ? formatApiDate(event.verified_at) : 'Not Verified'}
                  </dd>
                </div>
                <div className="mt-4 flex w-full flex-none items-center gap-x-4 px-6">
                  <dt className="flex-none">
                    <CheckBadgeIcon className="h-6 w-5 text-gray-600" aria-hidden="true" />
                  </dt>
                  <dd className="text-sm leading-1 text-gray-900">
                    <span className="text-xs uppercase">Confirmed at</span>
                    <br />
                    {event.confirmed_at ? formatApiDate(event.confirmed_at) : 'Not Confirmed'}
                  </dd>
                </div>
                {!!event.local_verified_at && (
                  <div className="mt-4 flex w-full flex-none items-center gap-x-4 px-6">
                    <dt className="flex-none">
                      <ClipboardDocumentCheckIcon
                        className="h-6 w-5 text-gray-600"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd className="text-sm leading-1 text-gray-900">
                      <span className="text-xs uppercase">Locally Verified at</span>
                      <br />
                      {event.local_verified_at ? formatApiDate(event.local_verified_at) : 'Never'}
                    </dd>
                  </div>
                )}
                <div className="mt-4 flex w-full flex-none items-center gap-x-4 px-6">
                  <dt className="flex-none">
                    <CreditCardIcon className="h-6 w-5 text-gray-600" aria-hidden="true" />
                  </dt>
                  <dd className="text-sm leading-1 text-gray-900">
                    <span className="text-xs uppercase">
                      Booked {event.booked_online ? 'Online' : 'by'}
                    </span>
                    <br />
                    {!event.booked_online && event.booked_by_name}
                    {!event.booked_online && <br />}
                    on {formatApiDate(event.booked_at)}
                  </dd>
                </div>
              </dl>
              <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                <Link
                  to={`/events/event/${event.id}/invoice`}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  View invoice <span aria-hidden="true">&rarr;</span>
                </Link>
              </div>
            </div>
          </div>

          <div className="-mx-4 px-2 py-6 bg-white/60 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 md:col-span-2 md:row-span-2 md:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">
            <EventOverviewInvoice event={event} />
          </div>

          <div className="md:col-start-3">
            {/* Activity feed */}
            <h2 className="text-sm font-semibold leading-6 text-gray-900">Activity</h2>
            <NewNoteForm className="mt-1 mb-3" event={event} />
            <div className="w-full max-w-xs">
              <EventNotes event={event} />
            </div>
          </div>
        </div>
      </div>
    </PolicyGate>
  );
};
