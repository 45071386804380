import { Button } from '@/components/ui/elements/button';
import { Event } from '@/types';
import { formatDate } from '@/utils/format';
import { formatNumber } from '@/utils/numbers-utils';
import { IdBadge } from '../../../Badges/IdBadge';
import { SurveyBadge } from '../../../Badges/SurveyBadge';

interface OverviewInvoiceProps {
  event: Event;
}

const prepareExternalLinks = (event: Event) => {
  let linkAddress = '';
  linkAddress += `${event.address}+`;
  if (event.address_line2) {
    linkAddress += `${event.address_line2}+`;
  }
  linkAddress += `${event.city}+${event.state}+${event.zipcode}`;
  linkAddress = encodeURI(linkAddress.replace(/\s/g, '+'));

  const openMap = () => {
    window.open(`http://maps.google.com/maps/search/?api=1&query=${linkAddress}`, '_blank');
  };
  const openDirections = () => {
    window.open(`http://maps.google.com/maps?f=d&hmam&l=en&daddr=${linkAddress}`, '_blank');
  };
  const openWeather = () => {
    window.open(
      `https://forecast.weather.gov/MapClick.php?lat=${event.latitude}&lon=${event.longitude}`,
      '_blank',
    );
  };

  return {
    openMap,
    openDirections,
    openWeather,
  };
};

export const EventOverviewInvoice = ({ event }: OverviewInvoiceProps) => {
  const { openMap, openDirections, openWeather } = prepareExternalLinks(event);

  return (
    <>
      <div className="grid grid-cols-2">
        <h2 className="text-xl font-semibold leading-6 text-gray-900">{event.name}</h2>
        <h2 className="text-xl font-semibold leading-6 text-gray-900 text-right">
          <SurveyBadge event={event} />
          <span className="ml-2" />
          <IdBadge event={event} />
        </h2>
      </div>
      <div className="grid grid-cols-1">
        {(event.activities?.length || 0) > 0 && (
          <h3 className="text-sm font-medium leading-6 text-gray-900">
            Staff:{' '}
            {event?.activities?.map(
              (activity) => activity.staff?.map((person) => person.full_name).join(', ') ?? '',
            ) || 'None assigned'}
          </h3>
        )}
      </div>
      <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
        <div className="sm:pr-4">
          <dt className="inline text-gray-500">Event Date: </dt>
          <dd className="inline text-gray-700">
            {formatDate(event.start_at, 'E MMM d, yyyy h:mma')}-{formatDate(event.end_at, 'h:mma')}
          </dd>
        </div>
        <div className="mt-2 sm:mt-0 sm:pl-4">
          <dt className="inline text-gray-500"> </dt>
          <dd className="inline text-gray-700"> </dd>
        </div>
        <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
          <dt className="font-semibold text-gray-900">Event Location</dt>
          <dd className="mt-2 text-gray-700">
            <span className="font-medium text-gray-900">{event.contact_person}</span>
            <br />
            {event.address}
            <br />
            {event.address_line2 && (
              <>
                {event.address_line2}
                <br />
              </>
            )}
            {event.city}, {event.state} {event.zipcode}
            <br />
            {event.contact_person && (
              <>
                Event Contact: {event.contact_person}
                <br />
              </>
            )}
            <a className="underline" href={`tel:${event.phone ?? event.customer.phone}`}>
              {event.phone ?? event.customer.phone}
            </a>
            <br />
            {event.customer.email}
          </dd>
          <div className="flex flex-row space-x-2 my-2">
            <Button variant="secondary" size="xs" onClick={openMap}>
              Map
            </Button>
            <Button variant="secondary" size="xs" onClick={openDirections}>
              Directions
            </Button>
            <Button variant="secondary" size="xs" onClick={openWeather}>
              Weather
            </Button>
          </div>
        </div>
        <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
          <dt className="font-semibold text-gray-900">Event VIPs</dt>
          <dd className="mb-2 text-gray-700">
            {event.vips?.map((vip) => (
              <div key={vip.id}>
                {vip.first_name} {vip.last_name}, {vip.age}
              </div>
            ))}
          </dd>
          <dt className="font-semibold text-gray-900"># of Guests</dt>
          <dd className="mb-2 text-gray-700">{event.guest_count}</dd>
        </div>
      </dl>
      <p className="text-sm pt-2">Notes: {event.special_instructions}</p>
      <table className="mt-16 w-full whitespace-nowrap text-left text-sm leading-6">
        <colgroup>
          <col className="w-full" />
          <col />
          <col />
          <col />
        </colgroup>
        <thead className="border-b border-gray-200 text-gray-900">
          <tr>
            <th scope="col" className="px-0 py-3 font-semibold">
              Description
            </th>
            <th
              scope="col"
              className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
            >
              Qty
            </th>
            <th
              scope="col"
              className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
            >
              Rate
            </th>
            <th scope="col" className="py-3 pl-8 pr-0 text-right font-semibold">
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          {event.activities?.map((activity) => (
            <tr key={activity.id} className="border-b border-gray-100">
              <td className="max-w-0 px-0 py-5 align-top">
                <div className="font-medium text-gray-900">{activity.name}</div>
                <div className="text-gray-600">
                  {activity.asset?.name ?? (
                    <span className="text-red-600">ERROR: No Equipment Assignment!</span>
                  )}
                  : {formatDate(activity.start_at)} - {formatDate(activity.end_at, 'h:mma')}
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  Arrive at {formatDate(activity.arrive_at, 'h:mma')}
                  <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                    <title>Dot</title>
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  Depart by {formatDate(activity.depart_at, 'h:mma')}
                </div>
              </td>
              <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                {activity.duration / 60} Hrs
              </td>
              <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                {formatNumber(activity.base_price, 'currency')}
              </td>
              <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                {formatNumber(activity.total, 'currency')}
              </td>
            </tr>
          ))}
          {event.line_items?.map((lineItem) => (
            <tr key={lineItem.id} className="border-b border-gray-100">
              <td className="max-w-0 px-0 py-5 align-top">
                <div className="font-medium text-gray-900">{lineItem.name}</div>
              </td>
              <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                {lineItem.quantity}
              </td>
              <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                {' '}
              </td>
              <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                {formatNumber(lineItem.total, 'currency')}
              </td>
            </tr>
          ))}
          {event.coupons?.map((couponLineItem) => (
            <tr key={couponLineItem.id} className="border-b border-gray-100">
              <td className="max-w-0 px-0 py-5 align-top" colSpan={3}>
                <div className="font-medium text-gray-900">
                  {couponLineItem.coupon.type === 'referral' ? 'Referral' : 'Coupon'}:{' '}
                  {couponLineItem.coupon.code}
                </div>
              </td>
              <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                -{formatNumber(couponLineItem.coupon.value, 'currency')}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th scope="row" className="px-0 pb-0 pt-6 font-normal text-gray-700 sm:hidden">
              Subtotal
            </th>
            <th
              scope="row"
              colSpan={3}
              className="hidden px-0 pb-0 pt-6 text-right font-normal text-gray-700 sm:table-cell"
            >
              Subtotal
            </th>
            <td className="pb-0 pl-8 pr-0 pt-6 text-right tabular-nums text-gray-900">
              {formatNumber(event.subtotal, 'currency')}
            </td>
          </tr>
          <tr>
            <th scope="row" className="pt-4 font-normal text-gray-700 sm:hidden">
              Tax
            </th>
            <th
              scope="row"
              colSpan={3}
              className="hidden pt-4 text-right font-normal text-gray-700 sm:table-cell"
            >
              Tax
            </th>
            <td className="pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-900">
              {formatNumber(event.tax, 'currency')}
            </td>
          </tr>
          <tr>
            <th scope="row" className="pt-4 font-semibold text-gray-900 sm:hidden">
              Total
            </th>
            <th
              scope="row"
              colSpan={3}
              className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
            >
              Total
            </th>
            <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900">
              {formatNumber(event.total, 'currency')}
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};
