import { useCreateMutationFactory, UseCreateMutationProps } from '@/lib/react-query/create';
import { Event, ModelID } from '@/types';
import { post } from '@/utils/api';
import { EventSearchParams, featureKeys, featurePath } from '.';

interface CopyInstructions {
  new_date: string;
  copy_mode: 'event' | 'lead';
  send_email: boolean;
  keep_basic_info: boolean;
  keep_address: boolean;
  keep_services: boolean;
  keep_addons: boolean;
  keep_notes: boolean;
  keep_vips: boolean;
  customer_id?: ModelID | null;
  first_name?: string | null;
  last_name?: string | null;
  phone?: string | null;
  email?: string | null;
}

export type CopyEventDTO = {
  id: ModelID;
  data: CopyInstructions;
  params?: EventSearchParams;
};

const copy = ({ data, id, params = {} }: CopyEventDTO) =>
  post<Event>(`${featurePath}/${id}/copy`, data, params);

export const useCopyEvent = (options?: UseCreateMutationProps<Event>) => {
  return useCreateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: copy,
    ...options,
  });
};
