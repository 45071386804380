import { StarRating } from '@/components/StarRating/StarRating';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/elements/accordion';
import { IdBadge } from '@/features/events/components/Badges/IdBadge';
import { SurveyBadge } from '@/features/events/components/Badges/SurveyBadge';
import { Customer, EventSurvey } from '@/types';
import { cn, formatApiDate } from '@/utils/format';

type CustomerSurveyListProps = {
  customer: Customer;
};

type surveyFields =
  | 'answer_1'
  | 'answer_2'
  | 'answer_3'
  | 'answer_4'
  | 'answer_5'
  | 'answer_6'
  | 'answer_7';

type surveyQuestions = {
  field: surveyFields;
  label: string;
};

const surveyQuestions = [
  {
    field: 'answer_1',
    label:
      'My GameCoach was enthusiastic, courteous, and professional when engaging myself and my party guests',
  },
  {
    field: 'answer_2',
    label: 'My GameCoach was knowledgeable about the games and equipment',
  },
  {
    field: 'answer_3',
    label: 'The products and activities at my event met or exceeded my expectations',
  },
  {
    field: 'answer_4',
    label: 'All equipment at my event was clean and in proper working order',
  },
  {
    field: 'answer_5',
    label: 'The GameTruck representative that booked my event was friendly and knowledgeable',
  },
  {
    field: 'answer_6',
    label: 'GameTruck was easy to contact and easy to book',
  },
  {
    field: 'answer_7',
    label: 'The value I received for my experience is consistent with the price I paid',
  },
] as surveyQuestions[];

export const CustomerSurveyList = ({ customer }: CustomerSurveyListProps) => {
  const surveys = (customer.surveys.map((survey) => {
    return {
      ...survey,
      event: customer.events.find((event) => event.id === survey?.event_id),
    };
  }) ?? []) as EventSurvey[];

  return (
    <Accordion type="multiple" className="my-3">
      {surveys.map((survey) => (
        <AccordionItem value={survey.id.toString()} key={`survey-${survey.id}`}>
          <AccordionTrigger
            className={cn(
              'bg-white border border-gray-100 px-4 py-2 rounded text-sm space-x-2',
              'data-[state=open]:border-b-0 rounded-s-none',
            )}
          >
            <IdBadge copyable={false} event={survey.event} />
            <span>{survey.event?.name}</span>
            {(survey.event?.activities?.length || 0) > 0 && (
              <p>
                Staff:{' '}
                {survey.event?.activities?.map(
                  (activity) => activity.staff?.map((person) => person.full_name).join(', ') ?? '',
                ) || 'None assigned'}
              </p>
            )}
            <span className="grow" />
            <SurveyBadge hoverable={false} event={survey.event} />
          </AccordionTrigger>
          <AccordionContent className="bg-white border border-t-0 rounded-s border-gray-100 py-2 px-4">
            <div className="flex flex-row justify-between py-2">
              <p className="text-xs text-gray-700">Comments:</p>
              {survey.completed_at && (
                <p className="text-xs text-gray-600">
                  Completed at {formatApiDate(survey.completed_at)}
                </p>
              )}
            </div>
            <p className="pb-4">"{survey.comments || 'None provided'}"</p>
            <dd>
              <dl className="divide-y">
                {surveyQuestions.map((question, index) => (
                  <div
                    key={`answer-${index}`}
                    className="flex flex-row space-x-2 py-2 text-xs text-gray-800 justify-between items-center"
                  >
                    <span>{question.label}?</span>
                    <span>
                      <StarRating rating={survey[question.field] || 5} />
                    </span>
                  </div>
                ))}
              </dl>
            </dd>
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  );
};
