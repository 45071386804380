import { PolicyGate } from '@/features/auth/authorization';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { FranchisePicker } from './TopBar/FranchisePicker';
import { NewLeadDialog } from './TopBar/NewLeadDialog';
import { WaitTime } from './TopBar/WaitTime';

interface HeaderProps {
  setMobileMenuOpen: (open: boolean) => void;
  children?: React.ReactNode;
}

export const Header = ({ setMobileMenuOpen, children }: HeaderProps) => {
  return (
    <>
      <PolicyGate policy="leads.view">
        <div className="grow flex md:hidden">
          <WaitTime />
        </div>
      </PolicyGate>

      <header className="w-full z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white">
        <button
          type="button"
          className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500 md:hidden"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex flex-1 justify-between items-center px-4 sm:px-6">
          <PolicyGate policy="leads.view">
            <div className="grow pr-2 hidden md:flex">
              <WaitTime />
            </div>
          </PolicyGate>
          <div className="flex items-center">
            <PolicyGate policy="leads.create">
              <span>
                <NewLeadDialog />
              </span>
            </PolicyGate>
            {children}
          </div>
          <div className="ml-4">
            <FranchisePicker />
          </div>
        </div>
      </header>
    </>
  );
};
