import logo from '@/assets/gametruck-logo.svg';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/_public')({
  component: AuthLayout,
});

function AuthLayout() {
  return (
    <div className="flex min-h-full h-[100vh] flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-100">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-10 w-auto" src={logo} alt="GameTruck" />
        <h2 className="mt-2 text-center text-2xl font-bold tracking-tight text-gray-900">
          Portal<span className="sups">3</span>
        </h2>
      </div>

      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
