import { useDeletePin } from '@/api/system/pins/deletePin';
import { usePins } from '@/api/system/pins/getPins';
import { Button } from '@/components/ui/elements/button';
import { StatusBadge } from '@/features/events/components/Badges/StatusBadge';
import { ConditionBadge } from '@/features/leads/components/Badges/ConditionBadge';
import { AuthContext } from '@/providers/auth';
import { LocationContext } from '@/providers/location';
import { ModelID, Pin } from '@/types';
import { cn } from '@/utils/format';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useNavigate } from '@tanstack/react-router';
import { useContext } from 'react';

export const PinStack = () => {
  const { activeLocationId } = useContext(LocationContext);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { data: pins, refetch } = usePins({
    location: activeLocationId,
    search: {
      user_id: user?.user_id ?? undefined,
    },
  });

  const { mutate: deletePin } = useDeletePin();

  const handleDeletePin = (id: ModelID) => {
    deletePin({ id });
  };

  const handleRouteToPin = (pin: Pin) => {
    if (pin.pinned_type === 'lead') {
      navigate({ to: `/leads/lead/${pin.pinned_id}/quote` });
    } else if (pin.pinned_type === 'event') {
      navigate({ to: `/events/event/${pin.pinned_id}/overview` });
    } else if (pin.pinned_type === 'customer') {
      navigate({ to: `/customers/customer/${pin.pinned_id}/overview` });
    }
  };

  return (
    <div className="flex flex-col divide-y divide-gray-100 h-full overflow-y-auto">
      <div className="flex flex-row my-3 justify-around">
        <Button
          variant="secondary"
          size="sm"
          className={cn(!pins?.length ? 'text-gray-500' : 'text-gray-900', 'text-xs')}
          onClick={() => refetch()}
          disabled={!pins?.length}
        >
          Refresh Statuses
        </Button>
      </div>

      <ul className="divide-y divide-gray-100">
        {pins?.map((pin) => (
          <li key={pin.id} className="flex items-center justify-between gap-x-6 py-2 px-4">
            <div className="min-w-0">
              <div className="flex flex-col items-start text-xs">
                <div className="flex flex-row space-x-2 items-center">
                  <button
                    type="button"
                    onClick={() => handleRouteToPin(pin)}
                    className={cn(
                      'text-sm leading-6 font-semibold text-gray-900 hover:text-primary-600 hover:underline',
                    )}
                  >
                    {pin.pinned_type.toUpperCase()}: {pin.pinned_id}
                  </button>
                  {pin.pinned_type === 'lead' && pin?.pinned && (
                    <span>
                      <ConditionBadge size="xs" lead={pin.pinned} />
                    </span>
                  )}
                  {pin.pinned_type === 'event' && pin?.pinned && (
                    <span>
                      <StatusBadge size="xs" event={pin.pinned} />
                    </span>
                  )}
                </div>

                {pin.pinned_type !== 'customer' && (
                  <p className="text-gray-600">{pin?.pinned?.franchise?.areaop}</p>
                )}
                {pin.note && <p className="text-gray-500">{pin.note}</p>}
              </div>
            </div>
            <div className="flex flex-none items-center gap-x-4">
              <Button variant="icon" onClick={() => handleDeletePin(pin.id)}>
                <TrashIcon className="w-6 h-6 p-1 text-gray-400" />
              </Button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
