import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { EventType, ModelID } from '@/types';
import { get } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { EventTypeSearchParams, featureKeys, featurePath } from '.';

type QueryFnType = typeof fetchEventTypes;

type UseEventTypesOptions = {
  location: ModelID;
  search?: EventTypeSearchParams;
  config?: QueryConfig<QueryFnType>;
};

const defaultSearchParams: EventTypeSearchParams = {
  orderBy: 'event_type_id',
  orderDir: 'asc',
};

const ONE_HOUR = 1000 * 60 * 60;

export const fetchEventTypes = (search: EventTypeSearchParams) =>
  get<EventType[]>(`${featurePath}`, search);

export const useEventTypes = ({
  location,
  search = defaultSearchParams,
  config = {},
}: UseEventTypesOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    staleTime: ONE_HOUR,
    queryKey: featureKeys.list(location, search),
    queryFn: () => fetchEventTypes(search),
  });
};
