import { Login } from '@/features/auth/routes/Login';
import { AuthContext } from '@/providers/auth';
import { useContext } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { DesktopShell } from './Application/Desktop/DesktopShell';
import { MobileShell } from './Application/Mobile/MobileShell';
import { OverlayControlProvider } from './OverlayControls';

export const Application = () => {
  const { authenticated } = useContext(AuthContext);

  if (!authenticated) {
    return <Login />;
  }

  return (
    <OverlayControlProvider>
      {isMobileOnly ? <MobileShell /> : <DesktopShell />}
    </OverlayControlProvider>
  );
};
