import { ConfirmationDialog } from '@/components/Elements';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/elements/accordion';
import { Button } from '@/components/ui/elements/button';
import { cn } from '@/utils/format';
import { useContext } from 'react';
import { LeadContext } from '../../../../contexts/LeadContext';
import { AccordionItemButtonStyle, AccordionItemPanelStyle } from '../../styles';

export const SimilarCustomerAccordionItem = () => {
  const { similarCustomers, lead, handleLinkCustomer } = useContext(LeadContext);

  if (!lead) return null;

  return (
    <>
      {similarCustomers &&
        (similarCustomers.length > 1 ||
          (similarCustomers[0] && similarCustomers[0].customer_id !== lead.customer_id)) && (
          <AccordionItem value="similar-customers">
            <AccordionTrigger className={cn(AccordionItemButtonStyle, 'mt-3')}>
              Similar Customers Found ({similarCustomers?.length ?? 0})
            </AccordionTrigger>
            <AccordionContent className={AccordionItemPanelStyle}>
              <div className="max-h-96 my-2 p-1 border overflow-y-scroll">
                {similarCustomers?.map((similar) => (
                  <div
                    className="text-xs leading-5 flex flex-row p-2 border-b items-center"
                    key={`simcust-${similar.id}`}
                  >
                    <div className="flex flex-col grow">
                      <div className="font-semibold">
                        {similar.full_name}
                        {similar.customer_id === lead?.customer?.customer_id ? ' (Selected)' : ''}
                      </div>
                      <div>
                        {similar.city || '???'}, {similar.state || '??'}
                      </div>
                      <div>{similar.organization_name}</div>
                      <div>{similar.email}</div>
                      <div>{similar.phone}</div>
                    </div>
                    <div>
                      <ConfirmationDialog
                        title="Link Similar Customer?"
                        body="Are you sure you want to link this customer to this lead? Message history nay no longer be accessible after the link is changed."
                        confirmAction={() => handleLinkCustomer(similar.customer_id)}
                        triggerButton={<Button size="xs">Link</Button>}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </AccordionContent>
          </AccordionItem>
        )}
    </>
  );
};
