import { cn } from '@/utils/format';
import { CalendarEventChipRenderProps } from '../types';

type CalendarChipProps = CalendarEventChipRenderProps & {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export const CalendarChip = ({ className, style, eventInfo, children }: CalendarChipProps) => {
  return (
    <div
      className={cn(
        'text-[11px] border-2 leading-snug w-full h-full overflow-hidden text-left',
        eventInfo.view.type === 'resourceTimeline'
          ? 'py-1 flex flex-row space-x-1 items-start'
          : 'px-0.5 flex flex-col items-start',
        className,
      )}
      style={style}
    >
      {children}
    </div>
  );
};
