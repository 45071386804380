import { useExportCalendarEvents } from '@/api/calendar/exportCalendarEvents';
import { useCalendarEvents } from '@/api/calendar/getCalendarEvents';
import { useAssets } from '@/api/equipment/assets/getAssets';
import { useUpdateBlackout } from '@/api/equipment/blackouts/updateBlackout';
import { useAssignStaff } from '@/api/events/events/assignStaff';
import { useLocationUserAccesses } from '@/api/locations/access/getAccesses';
import { DayPicker } from '@/components/DatePicker/DayPicker';
import ButtonGroup, {
  inlineButtonDefaultStyles,
} from '@/components/Elements/ButtonGroup/ButtonGroup';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/elements/dropdown-menu';
import { FULLCALENDAR_LICENSE_KEY } from '@/config';
import { PolicyGate } from '@/features/auth/authorization';
import { useOrientation } from '@/hooks/useOrientation';
import { useCalendarStore } from '@/stores/calendar';
import { Blackout, CalendarNote, ModelID } from '@/types';
import { cn, formatDate } from '@/utils/format';
import {
  ArrowPathRoundedSquareIcon,
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DocumentArrowDownIcon,
  PencilSquareIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { CalendarDate, DateFormatter, getLocalTimeZone } from '@internationalized/date';
import { useNavigate } from '@tanstack/react-router';
import React, { createRef, useEffect, useState } from 'react';
import { isIOS, isTablet } from 'react-device-detect';
import { useShallow } from 'zustand/react/shallow';
import { Spinner } from '../Elements';
import NoResults from '../Elements/NoResults/NoResults';
import { CalendarViewControls } from './CalendarViewControls';
import { CreateBlackoutsModal } from './CreateBlackoutsModal';
import { EditBlackoutModal } from './EditBlackoutModal';
import { NoteModal } from './NoteModal';
import { RenderEventContent } from './RenderEventContent';

import FullCalendar from '@fullcalendar/react';

import { DatesSetArg, EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';

const views = {
  day: {
    type: 'resourceTimeGrid',
    duration: { days: 1 },
    buttonText: 'Day',
  },
  threeDay: {
    type: 'resourceTimeGrid',
    duration: { days: 3 },
    buttonText: '3 Day',
  },
  week: {
    type: 'resourceTimeGrid',
    duration: { days: 7 },
    dateAlignment: 'week',
    buttonText: 'Week',
  },
  month: {
    type: 'dayGridMonth',
    buttonText: 'Month',
  },
};

interface CalendarViewerProps {
  fallbackLocationId: number;
  heightOverride?: string | null;
}

const CalendarViewer = ({ fallbackLocationId, heightOverride = null }: CalendarViewerProps) => {
  const { isLandscape } = useOrientation();
  const navigate = useNavigate();
  const [blackoutOpen, setBlackoutOpen] = useState(false);
  const [blackoutEditOpen, setBlackoutEditOpen] = useState(false);
  const [selectedBlackout, setSelectedBlackout] = useState<Blackout>();
  const [noteOpen, setNoteOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState<CalendarNote>();

  const { activeLocationId, dates, mode, temporaryEvents, setCalendarDates } = useCalendarStore(
    useShallow((state) => ({
      activeLocationId: state.activeCalendarLocationId,
      dates: { start: state.startDate, end: state.endDate },
      temporaryEvents: state.temporaryEvents,
      mode: state.mode,
      setCalendarDates: state.setDates,
    })),
  );

  const location = (activeLocationId as number) ?? fallbackLocationId;
  const calendarRef = createRef<FullCalendar>();
  const { isPending, data: assets } = useAssets({
    location,
    search: { active: true, orderBy: 'asset_type_id', orderDir: 'asc' },
  });

  const {
    data: calendarData,
    isFetching: calendarIsPending,
    refetch,
  } = useCalendarEvents({
    location,
    search: { start: dates.start, end: dates.end },
  });

  const { mutate: updateBlackout } = useUpdateBlackout();

  const { data: userAccessResults } = useLocationUserAccesses({
    location,
    search: {
      active: true,
      orderBy: 'type_code',
      orderDir: 'desc',
    },
  });

  const { mutate: assignStaff } = useAssignStaff({
    onSuccess: () => {
      refetch();
    },
    onError: () => {
      refetch();
    },
  });
  const handleAddStaff = (user_id: ModelID, activity_id: ModelID) => {
    assignStaff({
      old_user_id: 'Unassigned',
      new_user_id: user_id,
      event_activity_id: activity_id,
      action: 'assign',
    });
  };

  const handleDateChange = (dates: DatesSetArg) => {
    const start = new CalendarDate(
      dates.start.getFullYear(),
      dates.start.getMonth() + 1,
      dates.start.getDate(),
    );
    const end = new CalendarDate(
      dates.end.getFullYear(),
      dates.end.getMonth() + 1,
      dates.end.getDate(),
    );
    setCalendarDates(start, end);
  };

  const gotoDate = (date: Date) => {
    calendarRef.current?.getApi().gotoDate(date);
  };

  const gotoToday = () => {
    gotoDate(new Date());
  };

  const { mutate: exportEvents } = useExportCalendarEvents({
    location,
    search: dates,
  });
  const exportCalendar = () => {
    exportEvents();
  };

  const calendarPrev = () => {
    calendarRef.current?.getApi().prev();
  };

  const calendarNext = () => {
    calendarRef.current?.getApi().next();
  };

  const handleDateClick = (dateClick: DateClickArg) => {
    dateClick.jsEvent.preventDefault();
  };

  const toggleNote = (note: CalendarNote | undefined) => {
    if (note) {
      setSelectedNote(note);
    } else {
      setSelectedNote(undefined);
    }
    setNoteOpen(!noteOpen);
  };

  const handleEventClick = (eventClick: EventClickArg) => {
    eventClick.jsEvent.preventDefault();
    console.log(eventClick);
    if (eventClick?.event?.extendedProps) {
      switch (eventClick.event.extendedProps.cal_event_type) {
        case 'event':
          navigate({ to: `/events/event/${eventClick.event.extendedProps.event_id}/edit` });
          break;
        case 'note':
          toggleNote({
            title: eventClick.event.title,
            ...eventClick.event.extendedProps,
          } as CalendarNote);
          break;
        case 'blackout':
          setSelectedBlackout(eventClick.event.extendedProps as Blackout);
          setBlackoutEditOpen(true);
          break;
        case 'travel':
          window.open(eventClick.event.url, '_blank');
          break;
      }
    }
  };

  const handleResize = (eventResize: any) => {
    if (eventResize.event.extendedProps.blackout_id) {
      updateBlackout({
        id: eventResize.event.extendedProps.blackout_id,
        data: {
          end_at: formatDate(eventResize.event.end, 'yyyy-MM-dd HH:mm:ss'),
        },
      });
    }
  };

  useEffect(() => {
    calendarRef.current?.getApi().changeView(mode);
  }, [mode]);

  let calendarEvents: any[] = [];
  let resources: any[] = [];

  calendarEvents = [...temporaryEvents, ...(calendarData?.events ?? [])];

  resources = assets
    ? assets.map((asset, i): any => {
        return {
          id: asset.id,
          asset_type_id: asset.asset_type_id,
          sort_order: asset.sort_order,
          title: asset.abbr,
          resource: asset,
        };
      })
    : [];

  const startString = new DateFormatter('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }).format(dates.start.toDate(getLocalTimeZone()));

  const endString = new DateFormatter('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }).format(dates.end.toDate(getLocalTimeZone()));

  // Normal Desktop, iPhones
  let calendarHeight = 'calc(100vh - 133px)';

  // iPads
  if (isIOS && isTablet) {
    calendarHeight = 'calc(100vh - 163px)';
  }

  // Android Phones
  // if (!isTablet && isAndroid) {
  //   calendarHeight = 'calc(100vh - 230px)';
  // }

  // Android Tablets
  // if (isTablet && isAndroid) {
  //   calendarHeight = 'calc(100vh - 265px)';
  // }

  if (isPending) {
    return <>Please wait...</>;
  }

  if (activeLocationId === 0) {
    return <NoResults label="location" />;
  }

  return (
    <>
      <div className="@container px-3 w-full">
        <div className="flex justify-between items-center py-4">
          <CalendarViewControls />

          <span className="px-4 text-center text-sm whitespace-nowrap overflow-hidden">
            {mode !== 'day' ? `${startString} - ${endString}` : startString}
          </span>

          <ButtonGroup>
            <ButtonGroup.Button title="Go to Today" isFirst onClick={gotoToday} className="p-2">
              {calendarIsPending ? (
                <Spinner size="sm" className="mx-0.5" />
              ) : (
                <>
                  <span className="sr-only">Go to Today</span>
                  <ArrowPathRoundedSquareIcon className="h-5 w-5" />
                </>
              )}
            </ButtonGroup.Button>

            <ButtonGroup.Button title="Choose Day" asChild className="p-2">
              <DayPicker
                setDate={gotoDate}
                date={dates.start.toDate(getLocalTimeZone())}
                mode="icon"
                inlineButtonClassName={cn(inlineButtonDefaultStyles, 'hidden @md:inline-flex')}
              />
            </ButtonGroup.Button>

            <PolicyGate policy="equipment.blackouts.create">
              <ButtonGroup.Button
                title="Add Blackout"
                className="p-2 hidden @md:inline-flex"
                onClick={() => setBlackoutOpen(true)}
              >
                <span className="sr-only">Add Blackout</span>
                <span className="relative">
                  <CalendarIcon className="h-5 w-5" aria-hidden="true" />
                  <XMarkIcon className="absolute top-0.5 h-5 w-5" aria-hidden="true" />
                </span>
              </ButtonGroup.Button>
            </PolicyGate>

            <PolicyGate policy="calendar.notes.create">
              <ButtonGroup.Button
                title="Add Note"
                className="p-2 hidden @md:inline-flex"
                onClick={() => setNoteOpen(true)}
              >
                <span className="sr-only">Add Note</span>
                <PencilSquareIcon className="h-5 w-5" />
              </ButtonGroup.Button>
            </PolicyGate>

            <PolicyGate policy="events.export">
              <ButtonGroup.Button
                title="Export"
                className="p-2 hidden @md:inline-flex"
                onClick={exportCalendar}
              >
                <span className="sr-only">Export</span>
                <DocumentArrowDownIcon className="h-5 w-5" />
              </ButtonGroup.Button>
            </PolicyGate>

            <ButtonGroup.Button title="Back" className="p-2" onClick={calendarPrev}>
              <span className="sr-only">Back</span>
              <ChevronLeftIcon className="h-5 w-5" />
            </ButtonGroup.Button>

            <ButtonGroup.Button
              title="Next"
              className="p-2 @md:rounded-r-md"
              onClick={calendarNext}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" />
            </ButtonGroup.Button>

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <ButtonGroup.Button className="p-2 @md:hidden rounded-r-md">
                  <span className="sr-only">Options</span>
                  <EllipsisVerticalIcon className="h-5 w-5" />
                </ButtonGroup.Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem asChild className="cursor-pointer">
                  <DayPicker
                    setDate={gotoDate}
                    date={dates.start.toDate(getLocalTimeZone())}
                    inlineButtonClassName={cn(inlineButtonDefaultStyles, 'hidden @md:inline-flex')}
                  />
                </DropdownMenuItem>
                <PolicyGate policy="equipment.blackouts.create">
                  <DropdownMenuItem
                    onClick={() => setBlackoutOpen(true)}
                    className="cursor-pointer"
                  >
                    <span className="relative">
                      <CalendarIcon className="h-5 w-5" aria-hidden="true" />
                      <XMarkIcon className="absolute top-0.5 h-5 w-5" aria-hidden="true" />
                    </span>
                    Add a Blackout
                  </DropdownMenuItem>
                </PolicyGate>
                <PolicyGate policy="calendar.notes.create">
                  <DropdownMenuItem onClick={() => setNoteOpen(true)} className="cursor-pointer">
                    <PencilSquareIcon className="h-5 w-5 mr-2" aria-hidden="true" /> Add a Note
                  </DropdownMenuItem>
                </PolicyGate>
                <PolicyGate policy="events.export">
                  <DropdownMenuItem onClick={exportCalendar} className="cursor-pointer">
                    <DocumentArrowDownIcon className="h-5 w-5 mr-2" aria-hidden="true" /> Export
                  </DropdownMenuItem>
                </PolicyGate>
              </DropdownMenuContent>
            </DropdownMenu>
          </ButtonGroup>
        </div>
      </div>
      <FullCalendar
        ref={calendarRef}
        key={dates.start.toString()}
        plugins={[dayGridPlugin, resourceTimeGridPlugin, interactionPlugin]}
        schedulerLicenseKey={FULLCALENDAR_LICENSE_KEY}
        views={views}
        initialView={mode}
        initialDate={dates.start.toString()}
        allDaySlot={true}
        datesAboveResources={true}
        firstDay={1}
        slotDuration={'00:30:00'}
        scrollTime={'09:00:00'}
        eventMinHeight={1}
        slotMinTime={'00:00:00'}
        slotMaxTime={'24:00:00'}
        expandRows={true}
        headerToolbar={false}
        resourceOrder={'asset_type_id,sort_order'}
        eventOrder={'start,-duration,resourceId,allDay,title'}
        eventResize={handleResize}
        datesSet={handleDateChange}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        resources={resources}
        events={calendarEvents}
        eventContent={(eventInfo) =>
          RenderEventContent({
            eventInfo,
            staff: userAccessResults ?? [],
            handleAddStaff,
          })
        }
        height={heightOverride ?? calendarHeight}
      />

      <CreateBlackoutsModal
        open={blackoutOpen}
        setOpen={setBlackoutOpen}
        assets={assets ?? []}
        location={location}
      />

      {selectedBlackout && assets && (
        <EditBlackoutModal
          open={blackoutEditOpen}
          setOpen={setBlackoutEditOpen}
          blackout={selectedBlackout}
          assets={assets}
          location={location}
        />
      )}
      <NoteModal
        open={noteOpen}
        setOpen={() => toggleNote(undefined)}
        location={location}
        note={selectedNote}
      />
    </>
  );
};

export default React.memo(CalendarViewer);
