import { Head } from '@/components/Head';
import { useAuthorization } from '@/features/auth/authorization';
import { SearchParams } from '@/types';
import { cn } from '@/utils/format';
import { ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import { Link, useMatchRoute, useSearch } from '@tanstack/react-router';
import { Fragment, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';

export type SidebarLinks<T extends SearchParams = SearchParams> = SidebarNode<T>[];

type SidebarNode<T> = SidebarItem<T>[];

type SidebarItemOptions<T> = {
  nk: string;
  search_params: T;
  access_rights: string[];
  extra: React.ReactNode;
  active: boolean;
};

type SidebarItem<T> = {
  label: string;
  path: string;
} & Partial<SidebarItemOptions<T>>;

type SidebarLayoutProps = {
  title: string;
  links: SidebarLinks;
  children: React.ReactNode;
  sidebarChildren?: React.ReactNode;
  perserveSearch?: boolean;
};

export const SidebarLayout = ({
  title,
  links,
  children,
  perserveSearch = false,
  sidebarChildren,
}: SidebarLayoutProps) => {
  const [open, setOpen] = useState(false);
  const { checkPolicy } = useAuthorization();
  const search = useSearch({
    strict: false,
  });
  const matchRoute = useMatchRoute();

  links = links.map((section) => {
    return section
      .filter((item) => {
        return (
          item.access_rights?.every((right: string) => {
            return checkPolicy({ policy: right });
          }) ?? true
        );
      })
      .map((item) => {
        return {
          ...item,
          path: item.path.replace(/[?|&]page=\d+/, ''),
          active: item.nk
            ? item.nk === search.nk
            : matchRoute({ to: item.path, fuzzy: true }) !== false,
        };
      });
  });

  return (
    <>
      <span
        className={cn(
          isMobileOnly ? 'sticky block top-16 z-50 h-12' : 'block relative z-50',
          ' md:hidden',
        )}
      >
        <button
          type="button"
          onClick={() => setOpen(!open)}
          className={cn(
            'block absolute -top-0.5 w-14 bg-gray-100 border border-gray-300 p-2 rounded-br-full',
          )}
        >
          <ChevronDoubleRightIcon className={cn('w-6 h-6', open ? 'rotate-180' : '')} />
        </button>
      </span>
      <main className="flex flex-row w-full">
        <aside
          className={cn(
            isMobileOnly ? 'h-[calc(100vh-64px)] sticky top-16 pt-8' : 'h-full pt-8 md:pt-0',
            // isAndroid && isTablet ? 'h-[calc(100vh-95px-65px)]' : 'h-full',
            // isIOS ? 'h-[calc(100vh-65px)]' : 'h-full',
            'flex-none overflow-y-auto overflow-x-hidden',
            'bg-gray-50 pb-8 border-r',
            'md:block transition-all',
            open ? 'mr-0 md:mr-0 w-44 lg:w-48 xl:w-52' : 'w-0 md:w-32 lg:w-48 xl:w-52 pr-0',
            'whitespace-nowrap',
          )}
        >
          <Head title={title} />
          <h1
            id="primary-heading"
            className="hidden md:inline-block pt-5 pb-1.5 pl-5 mr-4 text-md lg:text-lg xl:text-xl font-bold text-gt-green-900"
          >
            {title}
          </h1>
          <div className="mb-14">
            {links.map((section, i) => (
              <Fragment key={`submenu-${i}`}>
                {section.map((item, j) => (
                  <Link
                    key={`submenu-${i}-${j}`}
                    to={item.path}
                    params={{}}
                    search={
                      perserveSearch
                        ? { ...search, ...item.search_params, nk: item.nk, page: 1 }
                        : { ...item.search_params, nk: item.nk }
                    }
                    className={cn(
                      'py-2 lg:py-2.5 xl:py-3 pl-5 my-0.5 lg:mr-4 transition-colors',
                      'text-xs tracking-tight lg:tracking-normal lg:text-sm flex justify-between items-center',
                      'hover:bg-gt-green-400/20 rounded-r-full',
                      '[&.active]:font-bold [&.active]:bg-gt-green-400/50 [&.active]:text-gt-green-900',
                      item.active ? 'font-bold bg-gt-green-400/50 text-gt-green-900' : '',
                    )}
                  >
                    <span>{item.label}</span>
                    <span>{item.extra}</span>
                  </Link>
                ))}

                {links.length > 1 && i !== links.length - 1 && (
                  <div key={`divider-${i}`} className="my-2 border-b border-gray-200" />
                )}
              </Fragment>
            ))}
            {sidebarChildren}
          </div>
        </aside>
        <div className="flex-auto" aria-labelledby="primary-heading">
          {children}
        </div>
      </main>
    </>
  );
};
