import { Lead, ModelID, SearchParams } from '@/types';

export type Filter =
  | 'inbox'
  | 'new'
  | 'daily-call-list'
  | 'needs-followup'
  | 'open'
  | 'pending-transfer'
  | 'archived'
  | 'booked'
  | 'all';
export type Sort = 'created_at' | 'updated_at' | 'followup_at' | 'date_wanted' | 'waiting_since';
export interface LeadSearchParams extends SearchParams<Filter, Sort>, Partial<Lead> {
  owned?: boolean;
  keyword?: string;
  name?: string;
  customer_name?: string;
  phone?: string;
  email?: string;
  address_city?: string;
  created_at_begin?: string;
  created_at_end?: string;
  date_wanted_begin?: string;
  date_wanted_end?: string;
  organization_type?: string;
  event_type_ids?: string[];
}

export const featurePath = '/v4/leads/lead';

export const featureKeys = {
  all: [{ scope: 'leads' }] as const,

  lists: (location: ModelID) => [{ ...featureKeys.all[0], location, entity: 'list' }] as const,

  list: (location: ModelID, search?: LeadSearchParams) =>
    [{ ...featureKeys.lists(location)[0], search }] as const,

  paged: (location: ModelID, search?: LeadSearchParams) =>
    [{ ...featureKeys.lists(location)[0], entity: 'paged', search }] as const,

  details: () => [{ ...featureKeys.all[0], entity: 'detail' }] as const,

  detail: (id: ModelID, search?: LeadSearchParams) =>
    [
      { ...featureKeys.details()[0], id, search },
      { ...featureKeys.details()[0], id },
    ] as const,

  validation: (id: ModelID) => [{ scope: 'leads', entity: 'booking-validation', id }] as const,
};
