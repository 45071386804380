import { Button } from '@/components/ui/elements/button';
import { Input } from '@/components/ui/elements/input';
import { ModelID } from '@/types';
import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';

export const LeadLookup = () => {
  const navigate = useNavigate();
  const [id, setID] = useState<ModelID>();

  const goToLead = () => {
    if (!id) return;
    navigate({ to: `/leads/lead/${id}/quote` });
  };

  return (
    <div className="rounded border px-4 pb-4 bg-white">
      <h2 className="text-sm font-medium pt-2 pb-1">Open Quote</h2>
      <div className="flex space-x-1">
        <Input
          type="number"
          name="lead_id"
          placeholder="Enter Quote #..."
          defaultValue={id}
          onChange={(event) => setID(event.target.value)}
        />
        <Button onClick={goToLead} disabled={!id} id="load-quote-submit">
          Go
        </Button>
      </div>
    </div>
  );
};
