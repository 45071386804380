import { EventNote, ModelID, SearchParams } from '@/types';

export type Filter = 'all' | 'active' | 'inactive';
export type Sort = 'created_at' | 'updated_at' | 'note_id';
export interface EventNoteSearchParams extends SearchParams<Filter, Sort>, Partial<EventNote> {}

export const featurePath = '/v4/events/note';

export const featureKeys = {
  all: [{ scope: 'event-notes' }] as const,

  lists: () => [{ ...featureKeys.all[0], entity: 'list' }] as const,

  list: (location: ModelID, search: EventNoteSearchParams = {}) =>
    [{ search, location, entity: 'list' }] as const,

  details: () => [{ ...featureKeys.all[0], entity: 'detail' }] as const,

  detail: (id: ModelID, search: EventNoteSearchParams = {}) =>
    [
      { ...featureKeys.details()[0], id, search },
      { ...featureKeys.details()[0], id },
    ] as const,
};
