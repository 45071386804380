import { Input } from '@/components/ui/elements/input';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  useFormAutosave,
} from '@/components/ui/form/form';
import { cn } from '@/utils/format';
import { cva, VariantProps } from 'cva';
import { ClassProp } from 'cva/dist/types';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';

export const formFieldVariants = cva([], {
  variants: {
    variant: {
      default: '',
      inset:
        'absolute -top-2 left-2 inline-block bg-gradient-to-b from-transparent from-0% via-gray-50 via-50% to-50% to-white px-1 text-xs font-medium ',
    },
  },
  defaultVariants: {
    variant: 'inset',
  },
});

type FormInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  VariantProps<typeof formFieldVariants> &
  ClassProp & {
    label?: string;
    description?: string;
    placeholder?: string;
    prefix?: string;
    suffix?: string;
    type?: React.HTMLInputTypeAttribute;
    step?: string | number;
    autoComplete?: string;
    onFieldFocus?: (value?: string) => void;
    onFieldBlur?: (value: string) => void;
  };

export const FormInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  placeholder,
  label,
  description,
  variant,
  className,
  prefix,
  suffix,
  type = 'text',
  step,
  autoComplete = 'false',
  onFieldFocus,
  onFieldBlur,
  ...props
}: FormInputProps<TFieldValues, TName>) => {
  const autosave = useFormAutosave();

  return (
    <FormField
      control={control}
      name={name}
      render={({ field: { onBlur, ...field } }) => (
        <FormItem className="relative">
          {label && <FormLabel className={cn(formFieldVariants({ variant }))}>{label}</FormLabel>}
          <FormControl>
            <div>
              {prefix && (
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">{prefix}</span>
                </div>
              )}
              <Input
                id={name}
                placeholder={placeholder}
                type={type}
                step={step}
                inputMode={type === 'number' ? 'decimal' : undefined}
                autoComplete={autoComplete}
                aria-invalid={control?._formState.errors[name] ? 'true' : 'false'}
                className={cn(className, prefix ? 'pl-7' : '', suffix ? 'pr-12' : '')}
                onFocus={(event) => {
                  if (onFieldFocus) onFieldFocus(event.target.value);
                  if (autosave.enabled) autosave.setPaused(true);
                }}
                onBlur={(event) => {
                  if (onFieldBlur) onFieldBlur(event.target.value);
                  if (autosave.enabled) autosave.setPaused(false);
                  onBlur();
                }}
                {...field}
              />
              {suffix && (
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <span className="text-gray-500 sm:text-sm" id="price-currency">
                    {suffix}
                  </span>
                </div>
              )}
            </div>
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
      {...props}
    />
  );
};
