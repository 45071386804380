import queryClient, { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { CustomerMessage, ModelID } from '@/types';
import { get } from '@/utils/api';
import { useChannel, useEvent } from '@harelpls/use-pusher';
import { useQuery } from '@tanstack/react-query';
import { CustomerMessageSearchParams, featureKeys, featurePath } from '.';

type QueryFnType = typeof fetchCustomerMessages;

type UseCustomerMessagesOptions = {
  location: ModelID;
  search?: CustomerMessageSearchParams;
  config?: QueryConfig<QueryFnType>;
};

type UseRealtimeCustomerMessagesOptions = {
  location: ModelID;
  search: { customer_id: ModelID } & CustomerMessageSearchParams;
};

type RealtimeUpdate = {
  message: CustomerMessage;
  queue: 'string';
};

export const fetchCustomerMessages = (
  location: ModelID,
  search: CustomerMessageSearchParams = {},
) => get<CustomerMessage[]>(`${featurePath}?franchise_id=${location}`, search);

export const useCustomerMessages = ({
  location,
  search = {},
  config = {},
}: UseCustomerMessagesOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: featureKeys.list(location, search),
    queryFn: () => fetchCustomerMessages(location, search),
  });
};

export const useRealtimeCustomerMessages = ({
  location,
  search,
}: UseRealtimeCustomerMessagesOptions) => {
  const channel = useChannel(`private-customer.${search.customer_id.toString()}`);

  useEvent<RealtimeUpdate>(channel, 'new-customer-message', (data) => {
    queryClient.setQueryData(
      featureKeys.list(location, search),
      (previous: CustomerMessage[] | undefined) => {
        if (!data || !data.message) return previous;
        if (data && previous) {
          const existing = previous.find((item) => item.id === data.message.id);
          if (existing) {
            return [
              { ...existing, ...data.message },
              ...previous
                .filter((item) => item.status !== 'sending' || item.customer_message_id)
                .filter((item) => item.id !== data.message.id),
            ];
          } else {
            return [
              data.message,
              ...previous.filter((item) => item.status !== 'sending' || item.customer_message_id),
            ];
          }
        }
        return [data.message];
      },
    );
  });

  return {
    channel: channel,
  };
};
