import { featureKeys as calendarFeatureKeys } from '@/api/calendar';
import queryClient from '@/lib/react-query';
import {
  DeleteDTO,
  useDeleteMutationFactory,
  UseDeleteMutationProps,
} from '@/lib/react-query/delete';
import { Blackout } from '@/types';
import { remove } from '@/utils/api';
import { BlackoutSearchParams, featureKeys, featurePath } from '.';

export type DeleteBlackoutDTO = DeleteDTO<Blackout, BlackoutSearchParams>;

const deleteBlackout = ({ id, data, params }: DeleteBlackoutDTO) => remove(`${featurePath}/${id}`);

export const useDeleteBlackout = (options?: UseDeleteMutationProps<Blackout>) => {
  return useDeleteMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: deleteBlackout,
    ...options,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: calendarFeatureKeys.all });
      options?.onSuccess?.(data);
    },
  });
};
