import { useCustomers } from '@/api/customers/customers/getCustomers';
import { useLead } from '@/api/leads/leads/getLead';
import {
  LeadManageQuoteDTO,
  UpdateLeadDTO,
  useLeadManageQuote,
  useUpdateLead,
} from '@/api/leads/leads/updateLead';
import { Customer, Lead, ModelID } from '@/types';
import { UseMutateFunction } from '@tanstack/react-query';
import { createContext, ReactNode, useEffect, useState } from 'react';

type LeadContextType = {
  leadId: ModelID;
  lead: Lead | undefined;
  isPending: boolean;
  isError: boolean;
  error: unknown;
  isUpdatePending: boolean;
  updateLead: UseMutateFunction<Lead, unknown, UpdateLeadDTO, unknown>;
  manageQuote: UseMutateFunction<Lead, unknown, LeadManageQuoteDTO, unknown>;
  temporaryTextMessage: string;
  setTemporaryTextMessage: (message: string) => void;
  similarLookupValues: Partial<Customer>;
  setSimilarLookupValues: (values: Partial<Customer>) => void;
  similarCustomers: Customer[] | undefined;
  refetchSimilar: () => void;
  handleLinkCustomer: (customer_id: ModelID) => void;
};

type LeadProviderProps = {
  leadId: ModelID;
  children: ReactNode;
};

const isSearchable = (value: any): boolean => {
  return Object.values(value).filter((v) => !!v).length > 0;
};

export const LeadContext = createContext<LeadContextType>({} as LeadContextType);

export const LeadProvider = ({ leadId, children }: LeadProviderProps) => {
  const [temporaryTextMessage, setTemporaryTextMessage] = useState('');
  const [similarLookupValues, setSimilarLookupValues] = useState<Partial<Customer>>({});

  const {
    isPending,
    isError,
    data: lead,
    error,
  } = useLead({
    id: leadId,
    config: {
      // Find a way to not have to do this!
      structuralSharing: false,
    },
  });

  const { data: similarCustomers, refetch: refetchSimilar } = useCustomers({
    location: lead?.franchise_id || 9999999999,
    search: {
      similar: JSON.stringify(similarLookupValues),
      limit: 50,
    },
    config: {
      enabled: !!lead?.franchise_id && isSearchable(similarLookupValues),
    },
  });

  const { mutate: updateLead, isPending: isUpdatePending } = useUpdateLead();
  const { mutate: manageQuote } = useLeadManageQuote();

  const handleLinkCustomer = (customer_id: ModelID) => {
    updateLead({
      id: leadId,
      data: {
        customer_id,
      },
    });
  };

  useEffect(() => {
    if (lead) {
      setSimilarLookupValues({
        phone: lead.customer?.phone || lead.phone || undefined,
        email: lead.customer?.email || lead.email || undefined,
        first_name: lead.customer?.first_name || lead.first_name || undefined,
        last_name: lead.customer?.last_name || lead.last_name || undefined,
      });
    }
  }, [lead]);

  return (
    <LeadContext.Provider
      value={{
        leadId,
        lead,
        isPending,
        isError,
        error,
        isUpdatePending,
        updateLead,
        manageQuote,
        temporaryTextMessage,
        setTemporaryTextMessage,
        similarLookupValues,
        setSimilarLookupValues,
        similarCustomers,
        refetchSimilar,
        handleLinkCustomer,
      }}
    >
      {children}
    </LeadContext.Provider>
  );
};
