import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/elements/alert-dialog';
import { ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import * as React from 'react';

export type ConfirmationDialogProps = {
  triggerButton: React.ReactElement;
  confirmAction: () => void;
  title: string;
  body?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  icon?: 'danger' | 'info';
  isDone?: boolean;
};

export const ConfirmationDialog = ({
  triggerButton,
  confirmAction,
  title,
  body = '',
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  icon = 'danger',
}: ConfirmationDialogProps) => {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>{triggerButton}</AlertDialogTrigger>
      <AlertDialogContent>
        <>
          <AlertDialogHeader className="flex flex-row space-x-2">
            {icon === 'danger' && (
              <div className="flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
              </div>
            )}
            {icon === 'info' && (
              <div className="flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <InformationCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
              </div>
            )}
            <div>
              <AlertDialogTitle>{title}</AlertDialogTitle>
              <AlertDialogDescription>{body}</AlertDialogDescription>
            </div>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{cancelButtonText}</AlertDialogCancel>
            <AlertDialogAction onClick={confirmAction}>{confirmButtonText}</AlertDialogAction>
          </AlertDialogFooter>
        </>
      </AlertDialogContent>
    </AlertDialog>
  );
};
