import { useBatchAddCustomersToList } from '@/api/customers/customer-lists/batchAddCustomers';
import { useCustomerLists as useCustomerListsQuery } from '@/api/customers/customer-lists/getCustomerLists';
import { useAddCustomerToList } from '@/api/customers/customer-lists/updateCustomerList';
import { toast } from '@/components/ui/elements/use-toast';
import { Customer, CustomerList, ModelID } from '@/types';

type UseCustomerListsProps = {
  location: ModelID;
};

export const useCustomerLists = ({ location }: UseCustomerListsProps) => {
  const { data: lists } = useCustomerListsQuery({
    location,
    search: {
      orderBy: 'name',
      orderDir: 'asc',
    },
  });

  const { mutate: add } = useAddCustomerToList({
    onSuccess: (list) => {
      toast({
        title: `Customer added to ${list.name} list.`,
      });
    },
  });
  const { mutate: batch } = useBatchAddCustomersToList({
    onSuccess: (list) => {
      toast({
        title: `Customers added to ${list.name} list.`,
      });
    },
  });

  const addCustomerToList = (customer: Customer, list: CustomerList) => {
    add({
      id: list.id,
      data: {
        customer_id: customer.id,
      },
    });
  };

  const addCustomersToList = (customerIds: ModelID[], list: CustomerList) => {
    batch({
      id: list.id,
      ids: customerIds,
    });
  };

  return {
    lists,
    addCustomerToList,
    addCustomersToList,
  };
};
