import { useMapStore } from '@/components/MapViewer/MapStateStore';
import { useCalendarStore } from '@/stores/calendar';
import { LocationAccess } from '@/types';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import useSessionStorageState from 'use-session-storage-state';
import { useShallow } from 'zustand/react/shallow';
import { AuthContext } from './auth';

export type LocationContextData = {
  selectLocation: (location: any) => void;
  activeLocation: any;
  activeLocationId: number;
};

type LocationProviderProps = {
  bootedLocationId: number | null;
  children: ReactNode;
};

export const LocationContext = createContext({} as LocationContextData);

export function LocationProvider({ bootedLocationId, children }: LocationProviderProps) {
  const { user, location_access } = useContext(AuthContext);

  const defaultValue = 99999999;
  const [sessionLocationId, setSessionLocationId] = useSessionStorageState<number>(
    'activeLocationId',
    {
      defaultValue,
    },
  );
  const [activeLocationId, setActiveLocationId] = useState<number>(
    bootedLocationId || sessionLocationId,
  );
  const handleSetLocationId = (id: number) => {
    setSessionLocationId(id);
    setActiveLocationId(id);
  };

  const { setMapLocationId, setCenter } = useMapStore(
    useShallow((state) => ({
      setMapLocationId: state.setDefaultLocationId,
      setCenter: state.setCenter,
    })),
  );
  const { setCalendarLocationId } = useCalendarStore(
    useShallow((state) => ({
      setCalendarLocationId: state.setDefaultLocationId,
    })),
  );

  let activeLocation = {} as LocationAccess;
  const match =
    location_access?.filter(
      (a) =>
        a.franchise_id === (activeLocationId > -1 ? activeLocationId : user?.primary_franchise_id),
    ) ?? [];
  if (match.length) {
    activeLocation = match[0];
  }

  useEffect(() => {
    if (activeLocation) {
      if (activeLocation.longitude && activeLocation.latitude) {
        setCenter(activeLocation.longitude, activeLocation.latitude);
      }
      setMapLocationId(activeLocation.franchise_id);
      setCalendarLocationId(activeLocation.franchise_id);
    }
  }, [activeLocation]);

  useEffect(() => {
    if (user && (!activeLocationId || activeLocationId === defaultValue)) {
      handleSetLocationId(user.primary_franchise_id);
    }
  }, [user]);

  return (
    <LocationContext.Provider
      value={{
        selectLocation: handleSetLocationId,
        activeLocation,
        activeLocationId,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
}
