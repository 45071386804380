import { featureKeys as leadFeatureKeys } from '@/api/leads/leads';
import { useRestoreQuote } from '@/api/leads/leads/restoreQuote';
import { useStartNewQuote } from '@/api/leads/leads/startNewQuote';
import { useLeadSendQuote } from '@/api/leads/leads/updateLead';
import { useUpdateQuote } from '@/api/leads/quotes/updateQuote';
import { ConfirmationDialog } from '@/components/Elements';
import List from '@/components/Elements/List/List';
import { Badge } from '@/components/ui/elements/badge';
import { Button } from '@/components/ui/elements/button';
import { toast } from '@/components/ui/elements/use-toast';
import { queryClient } from '@/lib/react-query';
import { Quote } from '@/types';
import { cn, formatDate, formatMoney } from '@/utils/format';
import { useContext, useState } from 'react';
import { LeadContext } from '../../contexts/LeadContext';
import { MultiQuoteEditForm } from './Forms/MultiQuoteEditForm';

export const MultiQuote = () => {
  const { lead } = useContext(LeadContext);

  if (!lead) throw new Error('No lead has been loaded...');

  const [showAllQuotes, setShowAllQuotes] = useState(false);
  const [quotesBeingEdited, setQuotesBeingEdited] = useState<Quote[]>([]);

  const { mutate: startNewQuote } = useStartNewQuote();
  const { mutate: restoreQuote } = useRestoreQuote();
  const { mutate: updateQuote } = useUpdateQuote();
  const { mutate: sendQuote } = useLeadSendQuote();

  const handleNewQuote = () => {
    startNewQuote(
      {
        data: {
          lead_id: lead.lead_id,
          is_bookable_online: true,
        },
      },
      {
        onSuccess: () =>
          toast({
            title: 'New Quote Started',
          }),
      },
    );
  };

  const handleRestoreQuote = (quote: Quote) => () => {
    restoreQuote({
      data: {
        lead_id: lead.lead_id,
        quote_id: quote.id,
      },
    });
  };

  const handleVoidQuote = (quote: Quote) => () => {
    updateQuote(
      {
        id: quote.id,
        data: {
          expires_at: new Date().toLocaleDateString(),
        },
      },
      {
        onSuccess: () => queryClient.invalidateQueries({ queryKey: leadFeatureKeys.details() }),
      },
    );
  };

  const handleSendQuote = () => {
    sendQuote(
      { id: lead.id, data: {} },
      {
        onSuccess: () =>
          toast({
            title: 'Quote Sent',
            description: 'The quote has been queued for delivery.',
          }),
      },
    );
  };

  const handleEditQuote = (quote: Quote) => {
    const isBeingEdited = quotesBeingEdited.some((q) => q.id === quote.id);
    setQuotesBeingEdited(
      isBeingEdited
        ? quotesBeingEdited.filter((q) => q.id !== quote.id)
        : [...quotesBeingEdited, quote],
    );
  };

  return (
    <>
      <div className="flex flex-row justify-between border p-2 rounded bg-gray-100">
        {(lead.quotes?.length || 0) > 0 ? (
          <Button
            variant="outline"
            size="sm"
            className="text-xs"
            onClick={() => setShowAllQuotes(!showAllQuotes)}
          >
            View All Quotes ({lead.quotes?.length})
          </Button>
        ) : (
          <span />
        )}
        <div className="flex flex-row space-x-4">
          {lead.customer && (
            <ConfirmationDialog
              title="Send Quote"
              body={`Send this quote to ${lead.customer.email}? The email will contain the currently active quote plus a link to any other unexpired quotes you have created for this lead.`}
              confirmAction={handleSendQuote}
              confirmButtonText="Confirm"
              triggerButton={
                <Button size="sm" className="text-xs">
                  Send Quote(s)
                </Button>
              }
            />
          )}

          <Button size="sm" className="text-xs" onClick={handleNewQuote}>
            Add Quote
          </Button>
        </div>
      </div>

      {showAllQuotes && (
        <>
          <List className="space-y-2 mt-2">
            {lead.quotes
              ?.sort(
                (a, b) =>
                  parseInt(b.lead_quote_id.toString()) - parseInt(a.lead_quote_id.toString()),
              )
              .map((quote) => (
                <List.Item
                  key={`quote-${quote.publishable_key}`}
                  className={cn(
                    'flex flex-col font-normal border shadow even:bg-gray-100 even:hover:bg-gray-100',
                  )}
                >
                  <div className="flex flex-row justify-between">
                    <span className="font-semibold">
                      #{lead.id}-{quote.id} | Total: {formatMoney(quote.data?.total || 0)}{' '}
                      <button
                        type="button"
                        className="text-xs hover:underline"
                        onClick={() => handleEditQuote(quote)}
                      >
                        edit...
                      </button>
                    </span>
                    <span className="space-x-2">
                      {!quote.createdby_user_id && (
                        <Badge variant="gray" size="sm">
                          OLB
                        </Badge>
                      )}
                      {quote.is_expired ? (
                        <Badge variant="red" size="sm">
                          Expired
                        </Badge>
                      ) : (
                        <Button variant="outline" size="xs" onClick={handleVoidQuote(quote)}>
                          Void
                        </Button>
                      )}
                      {quote.id === lead.primary_quote_id ? (
                        <Badge variant="green" size="sm">
                          Current
                        </Badge>
                      ) : (
                        <Button variant="outline" size="xs" onClick={handleRestoreQuote(quote)}>
                          Make Current
                        </Button>
                      )}
                    </span>
                  </div>
                  <div>
                    <p className="text-xs text-gray-600">
                      Created by {quote.created_by?.full_name || 'Online Booking'} on{' '}
                      {formatDate(quote.created_at)}
                    </p>
                  </div>
                  <div className="py-2">
                    <div className="flex flex-col divide-y">
                      {quote.data?.activities?.map((activity) => (
                        <div
                          key={`qt-act-${activity.id}-${quote.id}`}
                          className="flex flex-col text-xs py-2"
                        >
                          <div className="font-semibold">
                            {activity.name} on {formatDate(activity.start_at)}
                          </div>
                          <div className="flex flex-row justify-between align-baseline">
                            <span>Duration {activity.duration / 60} hrs</span>
                            <span>{formatMoney(activity.total)}</span>
                          </div>
                        </div>
                      ))}
                      {quote.data?.line_items?.map((lineItem) => (
                        <div
                          key={`qt-li-${lineItem.id}-${quote.id}`}
                          className="flex flex-row justify-between text-xs py-2 align-baseline"
                        >
                          <span className="font-semibold">
                            {lineItem.name} {lineItem.quantity > 1 && <>x{lineItem.quantity}</>}
                          </span>
                          <span>{formatMoney(lineItem.total)}</span>
                        </div>
                      ))}
                      {quote.data?.packages?.map((lineItem) => (
                        <div
                          key={`qt-li-${lineItem.id}-${quote.id}`}
                          className="flex flex-row justify-between text-xs py-2 align-baseline"
                        >
                          <span className="font-semibold">
                            {lineItem.name} {lineItem.quantity > 1 && <>x{lineItem.quantity}</>}
                          </span>
                          <span>{formatMoney(lineItem.total)}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  {quotesBeingEdited.indexOf(quote) > -1 ? (
                    <div>
                      <MultiQuoteEditForm quote={quote} />
                    </div>
                  ) : (
                    <div
                      className={cn(
                        'flex flex-col justify-between text-xs',
                        !!quote.customer_message || !!quote.internal_notes ? 'pt-1' : '',
                      )}
                    >
                      {!!quote.customer_message && (
                        <p>
                          <span className="font-semibold">Customer Message:</span>{' '}
                          {quote.customer_message}
                        </p>
                      )}
                      {!!quote.internal_notes && (
                        <p>
                          <span className="font-semibold">Internal Notes:</span>{' '}
                          {quote.internal_notes}
                        </p>
                      )}
                    </div>
                  )}
                </List.Item>
              ))}
          </List>
          <div className="border-t mb-3" />
        </>
      )}
    </>
  );
};
