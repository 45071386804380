import { MapProvider } from '@/components/MapViewer/MapContext';
import AppErrorBoundaryFallback from '@/error-handling/fallbacks/App';
import { GlobalLoadingIndicator } from '@/lib/react-query/GlobalLoadingIndicator';
import { LocationContext } from '@/providers/location';
import { cn } from '@/utils/format';
import * as Sentry from '@sentry/react';
import { Outlet } from '@tanstack/react-router';
import { useContext, useState } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import CalendarViewer from '../../../CalendarViewer/CalendarViewer';
import MapViewer from '../../../MapViewer/MapViewer';
import { useOverlayContext } from '../../OverlayControls';
import { Header } from '../Header';
import { MainNavigation } from '../MainNavigation';
import { NotificationManager } from '../NotificationManager';
import { NotificationsStack } from '../NotificationsStack';
import { PinStack } from '../PinStack';
import { DesktopOverlayControls } from './DesktopOverlayControls';

export const DesktopShell = () => {
  const { activeLocationId: fallbackLocationId } = useContext(LocationContext);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { panelRef, trayVisible, trayMode, trayDirection, size, handleOnTrayHandleDrag } =
    useOverlayContext();

  const minSize = trayDirection === 'horizontal' ? 10 : 25;

  return (
    <div className="flex h-[100vh]">
      <MainNavigation mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
      <div className="flex flex-1 flex-col overflow-hidden">
        <GlobalLoadingIndicator />
        <NotificationManager />
        <Header setMobileMenuOpen={setMobileMenuOpen}>
          <DesktopOverlayControls />
        </Header>
        <PanelGroup direction={trayDirection} autoSaveId="tray" onLayout={handleOnTrayHandleDrag}>
          <Panel className="flex flex-1 items-stretch">
            <Sentry.ErrorBoundary
              fallback={AppErrorBoundaryFallback}
              beforeCapture={(scope) => {
                scope.setTag('boundary', 'page-level');
              }}
              showDialog
            >
              <Outlet />
            </Sentry.ErrorBoundary>
          </Panel>
          <PanelResizeHandle onDragging={handleOnTrayHandleDrag}>
            <div
              className={cn(
                'hover:border-gray-600 transition-all from-black/10 to-transparent',
                'absolute z-50',
                trayDirection === 'horizontal'
                  ? 'hover:cursor-col-resize h-[calc(100vh-64px)] hover:border-l-4 hover:bg-gradient-to-r border-l-2 border-gray-300'
                  : 'hover:cursor-row-resize w-[calc(100vw-110px)] hover:border-t-4 hover:bg-gradient-to-b border-t-2',
                trayVisible && trayDirection === 'horizontal' ? 'w-5' : 'h-0 ',
                trayVisible && trayDirection === 'vertical' ? 'h-5' : 'w-0 ',
              )}
            />
          </PanelResizeHandle>
          {trayVisible && (
            <Panel
              ref={panelRef}
              defaultSize={minSize > size ? minSize : size}
              minSize={trayDirection === 'horizontal' ? 10 : 25}
              className={cn('w-full overflow-y-scroll bg-white')}
            >
              <Sentry.ErrorBoundary
                fallback={AppErrorBoundaryFallback}
                beforeCapture={(scope) => {
                  scope.setTag('boundary', 'sidebar-level');
                }}
              >
                {trayMode === 'map' && (
                  <MapProvider>
                    <MapViewer size={size} />
                  </MapProvider>
                )}
                {trayMode === 'calendar' && (
                  <CalendarViewer fallbackLocationId={fallbackLocationId} />
                )}
                {trayMode === 'notifications' && <NotificationsStack />}
                {trayMode === 'pins' && <PinStack />}
              </Sentry.ErrorBoundary>
            </Panel>
          )}
        </PanelGroup>
      </div>
    </div>
  );
};
