import { SidebarLayout, SidebarLinks } from '@/components/Layout';
import { PolicyGate } from '@/features/auth/authorization';
import { InsufficientPermissions } from '@/features/auth/components/InsufficientPermissions';
import { WaitingBadge } from '@/features/events/components/WaitingBadge';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/events/$filter')({
  component: EventFeature,
});

const EventsSubMenu: SidebarLinks = [
  [
    {
      label: 'Inbox',
      path: '/events/inbox',
      access_rights: ['events.view'],
      extra: <WaitingBadge />,
    },
    {
      label: 'Event Packs',
      path: '/events/tools/event-packs',
      access_rights: ['events.view'],
    },
  ],
  [
    {
      label: 'Upcoming',
      path: '/events/upcoming',
      access_rights: ['events.view'],
    },
    {
      label: 'Today',
      path: '/events/today',
      access_rights: ['events.view'],
    },
    {
      label: 'Confirmation Needed',
      path: '/events/confirmation-needed',
      access_rights: ['events.view'],
    },
    {
      label: 'Payment Needed',
      path: '/events/payment-needed',
      access_rights: ['events.view'],
    },
    {
      label: 'Recent',
      path: '/events/recent',
      access_rights: ['events.view'],
    },
    {
      label: 'Cancelled',
      path: '/events/cancelled',
      access_rights: ['events.view'],
    },
    {
      label: 'Quick List',
      path: '/events/quick',
      access_rights: ['events.view'],
    },
    {
      label: 'Pending Transfers',
      path: '/events/pending-transfer',
      access_rights: ['events.view'],
    },
    {
      label: 'Attention Required',
      path: '/events/attention-required',
      access_rights: ['events.view'],
    },
    {
      label: 'Collections',
      path: '/events/collections',
      access_rights: ['events.view'],
    },
    {
      label: 'All',
      path: '/events/all',
      access_rights: ['events.view'],
    },
  ],
];

function EventFeature() {
  return (
    <PolicyGate policy="events.view" forbiddenFallback={<InsufficientPermissions />}>
      <SidebarLayout title="Events" links={EventsSubMenu} perserveSearch>
        <Outlet />
      </SidebarLayout>
    </PolicyGate>
  );
}
