import _ from 'lodash';
import { ACL } from '.';
import { NewbieACL } from './newbie';
export const ACL_ROLE_GAMECOACH = 'Game Coach';

export const GameCoachACL: ACL = _.merge({}, NewbieACL, {
  role: ACL_ROLE_GAMECOACH,
  dashboard: {
    view: true,
  },
  calendar: {
    view: true,
    notes: {
      view: true,
    },
  },
  scheduler: {
    view: true,
  },
  events: {
    view: true,
  },
  customers: {
    view: true,
  },
  payments: {
    view: true,
    create: true,
  },
  payment_methods: {
    view: true,
    create: true,
    edit: true,
  },
  help: {
    view: true,
  },
});
