import { useDeletePin } from '@/api/system/pins/deletePin';
import { usePins } from '@/api/system/pins/getPins';
import { usePinItem } from '@/api/system/pins/pinItem';
import { useUpdatePin } from '@/api/system/pins/updatePin';
import { AuthContext } from '@/providers/auth';
import { LocationContext } from '@/providers/location';
import { ModelID } from '@/types';
import { cn } from '@/utils/format';
import { BookmarkIcon as BookmarkIconInactive } from '@heroicons/react/24/outline';
import { BookmarkIcon as BookmarkIconActive } from '@heroicons/react/24/solid';
import { useContext } from 'react';
import { buttonVariants } from '../ui/elements/button';

type PinButtonProps = {
  mode: 'lead' | 'event' | 'customer';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  id: ModelID;
};

export const PinButton = ({ mode, id, size = 'md' }: PinButtonProps) => {
  const { activeLocationId } = useContext(LocationContext);
  const { user } = useContext(AuthContext);
  const { data: pins } = usePins({
    location: activeLocationId,
    search: {
      user_id: user?.user_id ?? undefined,
    },
  });
  const { mutate: pinItem } = usePinItem();
  const { mutate: unpinItem } = useDeletePin();
  const { mutate: updatePin } = useUpdatePin();

  const pinned = pins?.find((pin) => pin.pinned_id === id && pin.pinned_type === mode);

  const handlePinItem = () => {
    pinned ? unpinItem({ id: pinned.id }) : pinItem({ data: { pinned_type: mode, pinned_id: id } });
  };

  const handleAddNote = (e: React.FocusEvent<HTMLInputElement>) => {
    if (pinned) {
      updatePin({ id: pinned.id, data: { note: e.target.value } });
    }
  };

  return (
    <div className={cn(buttonVariants({ variant: 'action', size }), 'transition-all')}>
      <button type="button" onClick={handlePinItem}>
        {!pinned && (
          <BookmarkIconInactive
            className={cn(size === 'xs' ? 'h-4 w-4' : size === 'sm' ? 'h-5 w-5' : 'h-6 w-6')}
          />
        )}
        {pinned && (
          <BookmarkIconActive
            className={cn(
              'text-primary',
              size === 'xs' ? 'h-4 w-4' : size === 'sm' ? 'h-5 w-5' : 'h-6 w-6',
            )}
          />
        )}
      </button>
      {pinned && (
        <input
          type="text"
          name="pin_note"
          defaultValue={pinned.note ?? ''}
          onBlur={handleAddNote}
          placeholder="Add a note..."
          className="h-5 w-24 ml-1 py-0 px-1 border-gray-300 text-xs focus-visible:outline-none focus-visible:border-1"
        />
      )}
    </div>
  );
};
