import { useNavigate, useRouterState } from '@tanstack/react-router';

export const useRouterTab = () => {
  const routerState = useRouterState();
  const navigate = useNavigate();

  const activeTab = routerState.location.pathname.split('/').pop();
  const selectTab = (path: string) => {
    const routeParts = routerState.location.pathname.split('/');
    const to =
      routeParts && routeParts.length < 5
        ? routerState.location.pathname.concat(`/${path}`)
        : routerState.location.pathname.replace(routeParts?.pop() ?? '', path);

    navigate({ to, search: {}, params: {} });
  };

  return { activeTab, selectTab };
};
