import { PolicyCheck } from '@/features/auth/authorization';

export const CouponPolicies: Record<string, PolicyCheck> = {
  'products.coupons.view': ({ acl, user, meta }) => {
    return acl.products.coupons.view;
  },
  'products.coupons.create': ({ acl, user, meta }) => {
    return acl.products.coupons.create;
  },
  'products.coupons.update': ({ acl, user, meta }) => {
    return acl.products.coupons.edit;
  },
  'products.coupons.delete': ({ acl, user, meta }) => {
    return false;
  },
};
