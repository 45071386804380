import queryClient from '@/lib/react-query';
import { CreateDTO, UseCreateMutationProps } from '@/lib/react-query/create';
import { AuthContext } from '@/providers/auth';
import { CustomerMessage, ModelID } from '@/types';
import { create } from '@/utils/api';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useContext } from 'react';
import { CustomerMessageSearchParams, featureKeys, featurePath } from '.';

type NewMessageCommand = {
  customer_id: ModelID;
  message: string;
  context: null | 'lead' | 'event';
  context_id: ModelID;
};

export type CreateCustomerMessageDTO = CreateDTO<NewMessageCommand, CustomerMessageSearchParams>;

type UseCreateCustomerMessageProps = {
  location: ModelID;
  options: UseCreateMutationProps<CustomerMessage>;
};

const createCustomerMessage = ({ data, params = {} }: CreateCustomerMessageDTO) =>
  create<CustomerMessage>(`${featurePath}`, data, params);

export const useCreateCustomerMessage = ({ location, options }: UseCreateCustomerMessageProps) => {
  const { onMutate, onError, onSuccess, config } = options;
  const { user } = useContext(AuthContext);

  const mutationConfig = Object.assign(
    {
      onMutate: async (mutation: CreateCustomerMessageDTO) => {
        const queryKey = featureKeys.list(location, { customer_id: mutation.data.customer_id });

        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({ queryKey: featureKeys.all, exact: false });

        // Snapshot the previous value
        const previousMessages = queryClient.getQueryData(queryKey);

        // Optimistically update to the new value
        const placeholderKey = Math.random().toString() as ModelID;
        const newMessage = {
          ...mutation.data,
          type: 'sms',
          direction: 'outbound',
          status: 'sending',
          created_at: new Date().toISOString(),
          sentby_user_id: user?.user_id,
          sent_by: user,
          sender_first_name: user?.first_name,
          sender_last_name: user?.last_name,
        } as CustomerMessage;

        queryClient.setQueryData(queryKey, (previous: CustomerMessage[] | undefined) => {
          if (!previous) return [newMessage];
          return [newMessage, ...previous];
        });

        // Return a context object with the snapshotted value
        return { previousMessages };
      },

      onError: (err: unknown, newData: any, context: any) => {
        const queryKey = featureKeys.list(location, { customer_id: newData.customer_id });
        queryClient.setQueryData(queryKey, context.previousMessages);
      },

      onSuccess: (data: CustomerMessage) => {
        // const queryKey = [
        //   {
        //     entity: 'list',
        //     scope: 'customer-messages',
        //     location,
        //     search: { customer_id: data.customer_id },
        //   },
        // ];
        // queryClient.invalidateQueries({ queryKey });
        if (onSuccess) {
          onSuccess(data);
        }
      },

      mutationFn: createCustomerMessage,
    },
    config,
  );

  return useMutation(
    mutationConfig as UseMutationOptions<
      CustomerMessage,
      unknown,
      CreateCustomerMessageDTO,
      unknown
    >,
  );

  // return useCreateMutationFactory({
  //   invalidateKeys: featureKeys,
  //   mutationFn: createCustomerMessage,
  //   ...options,
  //   onSuccess: () => null,
  // });
};
