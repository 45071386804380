import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItemIndicator,
  DropdownMenuTrigger,
} from '@/components/ui/elements/dropdown-menu';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form/form';
import { cn } from '@/utils/format';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { VariantProps } from 'cva';
import { ControllerProps, FieldPath, FieldValues, UseFieldArrayReturn } from 'react-hook-form';
import { inputVariants } from '../ui/elements/input';
import { formFieldVariants } from './FormInput';

export type SelectOption = {
  label: string;
  value: string | number;
  meta?: any;
};

export type FormMultiSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  VariantProps<typeof inputVariants> & {
    fieldArray: UseFieldArrayReturn<TFieldValues>;
    label?: string;
    labelVariant?: 'default' | 'inset';
    description?: string;
    placeholder?: string;
    onChange?: (value: string[]) => void;
  };

export const FormMultiSelect = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  fieldArray,
  name,
  placeholder,
  label,
  description,
  variant,
  labelVariant,
  onChange,
  ...props
}: FormMultiSelectProps<TFieldValues, TName>) => {
  const { fields, update } = fieldArray;
  const selected = fields.filter((field: any) => field.checked).map((field: any) => field.label);

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem key={field.value} className="relative">
          {label && (
            <FormLabel className={cn(formFieldVariants({ variant: labelVariant }))}>
              {label}
            </FormLabel>
          )}
          <DropdownMenu>
            <FormControl>
              <DropdownMenuTrigger
                className={cn(inputVariants({ variant }), 'items-center justify-between')}
              >
                <div className="flex flex-nowrap overflow-hidden -ml-2">
                  {selected.length > 0 ? (
                    <>
                      <span
                        key={`multiselect-selected-${selected[0]}`}
                        className="px-1 py-1 mx-0.5 mt-0.5 rounded bg-primary-100 text-xs text-nowrap"
                      >
                        {selected[0]}
                      </span>
                      {selected.length > 1 ? (
                        <span className="px-1 py-1 mx-0.5 mt-0.5 rounded bg-primary-100 text-xs text-nowrap">
                          +{selected.length - 1}
                        </span>
                      ) : null}
                    </>
                  ) : (
                    <span className="px-1 py-1 ml-1.5 ">
                      {placeholder ?? 'Select an option...'}
                    </span>
                  )}
                </div>
                <div>
                  <ChevronDownIcon className="h-4 w-4 opacity-50" />
                </div>
              </DropdownMenuTrigger>
            </FormControl>
            <DropdownMenuContent
              style={{
                width: 'var(--radix-dropdown-menu-trigger-width)',
                maxHeight: 'var(--radix-dropdown-menu-content-available-height)',
              }}
            >
              {fields.map((option: any, index) => (
                <DropdownMenuCheckboxItem
                  key={`multiselect-${option.label}` + name}
                  checked={option.checked}
                  onSelect={(e) => e.preventDefault()}
                  onCheckedChange={(checked) => update(index, { ...option, checked })}
                >
                  <DropdownMenuItemIndicator>
                    <CheckIcon />
                  </DropdownMenuItemIndicator>
                  {option.label}
                </DropdownMenuCheckboxItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
      {...props}
    />
  );
};
