import { useCreateVip } from '@/api/customers/vips/createVip';
import { useVips } from '@/api/customers/vips/getVips';
import { Button } from '@/components/ui/elements/button';
import queryClient from '@/lib/react-query';
import { LocationContext } from '@/providers/location';
import { Customer, Event, Lead, Vip } from '@/types';
import { PlusIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { Fragment, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormDate, FormInput } from '../Form';
import { Form } from '../ui/form/form';
import VipRow from './VipRow';

type VipManagerProps = {
  customer: Customer;
  readonly?: boolean;
} & (
  | {
      mode: 'lead';
      record: Lead;
    }
  | {
      mode: 'event';
      record: Event;
    }
  | {
      mode?: null;
      record?: undefined;
    }
);

const schema = z.object({
  first_name: z.string().min(1, 'Required'),
  last_name: z.string().nullish(),
  age: z.coerce.string().nullish(),
  gender: z.string().nullish(),
  date_of_birth: z.string().nullish(),
});

const VipManager = ({ customer, mode, record, readonly = false }: VipManagerProps) => {
  const { activeLocationId } = useContext(LocationContext);
  const [createMode, setCreateMode] = useState(false);
  const { data: vips } = useVips({
    location: record?.franchise_id ?? activeLocationId,
    search: { customer_id: customer.customer_id, orderBy: 'created_at', orderDir: 'desc' },
  });
  const { mutate: createVip } = useCreateVip();

  const handleCreateVip = (data: Partial<Vip>) => {
    createVip(
      {
        data: {
          ...data,
          customer_id: customer.customer_id,
          targetKey: record?.id,
          mode: mode ?? undefined,
        },
      },
      {
        onSuccess: () => {
          if (record) {
            queryClient.invalidateQueries({
              queryKey: [{ entity: 'detail', id: record.id, scope: `${mode}s` }],
            });
          }
          form.reset();
          setCreateMode(false);
        },
      },
    );
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      first_name: '',
      last_name: '',
      age: '',
      gender: '',
      date_of_birth: null,
    },
  });

  return (
    <div className="px-2">
      <div className="flex flex-row justify-between py-3">
        <h3 className="text-lg font-semibold">VIP</h3>
        {!readonly && (
          <Button variant="icon" className="w-7" onClick={() => setCreateMode(!createMode)}>
            {createMode ? (
              <PlusIcon className="w-4 h-4 rotate-45" />
            ) : (
              <PlusIcon className="w-4 h-4" />
            )}
          </Button>
        )}
      </div>

      {createMode && (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleCreateVip)} className="mb-3">
            <h4 className="text-sm">Add new VIP...</h4>
            <div className="py-4 px-1 grid gap-3 grid-cols-2">
              <FormInput control={form.control} name="first_name" label="First Name" />
              <FormInput control={form.control} name="last_name" label="Last Name" />
              <FormInput control={form.control} name="gender" label="Gender" />
              <FormInput control={form.control} name="age" label="Age" />
              <FormDate control={form.control} name="date_of_birth" label="Birth Date" />
              <Button type="submit">Create</Button>
            </div>
          </form>
        </Form>
      )}

      <div className="divide-y">
        {vips?.map((vip) => (
          <Fragment key={`vip${vip.id}-${record?.vips.length}`}>
            {mode === 'lead' && (
              <VipRow mode={mode} record={record} vip={vip} readonly={readonly} />
            )}
            {mode === 'event' && (
              <VipRow mode={mode} record={record} vip={vip} readonly={readonly} />
            )}
            {!mode && <VipRow vip={vip} readonly={readonly} />}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default VipManager;
