import { Event, EventSurvey, Lead, Payment } from '@/types';
import { cn, formatApiDate, formatDate } from '@/utils/format';
import { formatNumber } from '@/utils/numbers-utils';
import { CalendarIcon, CurrencyDollarIcon, PhoneIcon, StarIcon } from '@heroicons/react/24/solid';

export type TypedLead = Lead & { timeline_type: 'Lead' };
export type TypedEvent = Event & { timeline_type: 'Event' };
export type TypedPayment = Payment & { timeline_type: 'Payment' };
export type TypedSurvey = EventSurvey & { timeline_type: 'Survey' };

export type CustomerTimelineProps = {
  items: (TypedEvent | TypedLead | TypedPayment | TypedSurvey)[];
  className?: string;
};

export const CustomerTimeline = ({ items, className }: CustomerTimelineProps) => {
  return (
    <div className={cn('flow-root', className)}>
      <ul role="list" className="-mb-8">
        {items.map((item, index) => (
          <li key={item.id}>
            <div className="relative pb-8">
              {index !== items.length - 1 ? (
                <span
                  className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex items-start space-x-3">
                {item.timeline_type === 'Lead' && <LeadTimelineItem lead={item} />}
                {item.timeline_type === 'Event' && <EventTimelineItem event={item} />}
                {item.timeline_type === 'Payment' && <PaymentTimelineItem payment={item} />}
                {item.timeline_type === 'Survey' && <SurveyTimelineItem survey={item} />}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const LeadTimelineItem = ({ lead }: { lead: TypedLead }) => {
  return (
    <>
      <div>
        <div className="relative px-1">
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-3 ring-white">
            <PhoneIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1">
        <div>
          <div className="text-sm">
            <span className="text-gray-800">
              New inquiry for <span className="font-medium text-gray-900">{lead.name}</span> event
            </span>
          </div>
          <p className="mt-0.5 text-sm text-gray-500">{formatApiDate(lead.created_at)}</p>
        </div>
        {(lead.how_heard || lead.source_campaign) && (
          <div className="mt-2 text-sm text-gray-700">
            <p>
              Found via: {lead.how_heard} {lead.source_campaign}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

const EventTimelineItem = ({ event }: { event: TypedEvent }) => {
  return (
    <>
      <div>
        <div className="relative px-1">
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-3 ring-white">
            <CalendarIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1">
        <div>
          <div className="text-sm">
            <span className="text-gray-800">
              Booked the <span className="font-medium text-gray-900">{event.name}</span> event for{' '}
              {formatDate(event.start_at, 'MMM  dd, Y')}
            </span>
          </div>
          <p className="mt-0.5 text-sm text-gray-500">Booked: {formatApiDate(event.booked_at)}</p>
        </div>
      </div>
    </>
  );
};

const PaymentTimelineItem = ({ payment }: { payment: TypedPayment }) => {
  return (
    <>
      <div>
        <div className="relative px-1">
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-3 ring-white">
            <CurrencyDollarIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1">
        <div>
          <div className="text-sm">
            <span className="text-gray-800">
              Processed{' '}
              <span className="font-medium text-gray-900">
                {payment.method} {payment.type}
              </span>{' '}
              payment
            </span>
          </div>
          <p className="mt-0.5 text-sm text-gray-500">{formatApiDate(payment.created_at)}</p>
        </div>
        <div className="mt-2 text-sm text-gray-700">
          <p>{formatNumber(payment.amount, 'currency')}</p>
        </div>
      </div>
    </>
  );
};

const SurveyTimelineItem = ({ survey }: { survey: TypedSurvey }) => {
  if (survey.completed_at === null) return <></>;
  return (
    <>
      <div>
        <div className="relative px-1">
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-3 ring-white">
            <StarIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1">
        <div>
          <div className="text-sm">
            <span className="text-gray-800">
              NPS <span className="font-medium text-gray-900">{survey.recommend}</span> survey
              completed
            </span>
          </div>
          <p className="mt-0.5 text-sm text-gray-500">
            Completed {formatApiDate(survey.completed_at)}
          </p>
        </div>
        <div className="mt-2 text-sm text-gray-700">
          <p>{survey.comments}</p>
        </div>
      </div>
    </>
  );
};
