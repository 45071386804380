import { CustomerProvider } from '@/features/customers/contexts/CustomerContext';
import { modelID } from '@/utils/format';
import { useParams } from '@tanstack/react-router';
import { CustomerDetail } from '../components/CustomerDetail';

export const CustomerDetailPage = () => {
  const { id: customerIdParam } = useParams({
    strict: false,
  });
  const customerId = modelID(customerIdParam);

  return (
    <CustomerProvider customerId={customerId}>
      <CustomerDetail />
    </CustomerProvider>
  );
};
