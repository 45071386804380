import { toast } from '@/components/ui/elements/use-toast';
import { useServiceWorkerMessages } from '@/hooks/useServiceWorkerMessages';
import { useUserNotificationsChannel } from '@/hooks/useUserNotificationsChannel';
import { onMessageListener } from '@/lib/firebase';
import queryClient from '@/lib/react-query';
import { LocationContext } from '@/providers/location';
import useNotificationStore from '@/stores/notifications';
import { MessagePayload } from 'firebase/messaging';
import { useContext, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

export const NotificationManager = () => {
  const { activeLocationId: fallbackLocationId } = useContext(LocationContext);

  const { addNotification } = useNotificationStore(
    useShallow((state) => ({
      addNotification: state.add,
    })),
  );

  useUserNotificationsChannel();
  useServiceWorkerMessages();

  const handleForegroundMessage = (payload: MessagePayload) => {
    console.log('Message received. ', payload);
    toast({
      title: payload.data?.message ?? 'New Notification',
      description: payload.data?.message ?? undefined,
    });

    let url = undefined as string | undefined;
    if (payload.data?.contextable_type === 'lead') {
      url = `/leads/lead/${payload.data?.contextable_id}/messages`;
    } else if (payload.data?.contextable_type === 'event') {
      url = `/events/event/${payload.data?.contextable_id}/messages`;
    } else if (payload.data?.customer_id) {
      url = `/customers/customer/${payload.data?.customer_id}/messages`;
    }

    addNotification({
      title: payload.data?.title ?? 'New Notification',
      message: payload.data?.message ?? undefined,
      link: url,
    });

    queryClient.invalidateQueries({ queryKey: [{ scope: 'heartbeat' }] });
    queryClient.invalidateQueries({
      queryKey: [
        {
          entity: 'list',
          location: fallbackLocationId,
          scope: 'leads',
          search: { filter: 'inbox' },
        },
      ],
      exact: false,
    });
  };

  useEffect(() => {
    const unsubscribe = onMessageListener().then((payload) => {
      if (payload) {
        handleForegroundMessage(payload);
      }
    });

    return () => {
      unsubscribe.catch((err) => console.log('failed: ', err));
    };
  }, []);

  return <></>;
};
