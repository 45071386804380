import { useRefundPayment } from '@/api/events/events/updateEvent';
import { Button } from '@/components/ui/elements/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/elements/dialog';
import { Form } from '@/components/ui/form/form';
import queryClient from '@/lib/react-query';
import { Event, Payment } from '@/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { DialogProps } from '@radix-ui/react-dialog';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

type RefundPaymentDialogProps = DialogProps & {
  event: Event;
  payment: Payment;
  onClose?: () => void;
};

const schema = z.object({
  amount: z.coerce.number(),
  reason: z.string().nullish(),
});

export const RefundPaymentDialog = ({
  event,
  payment,
  onClose,
  ...props
}: RefundPaymentDialogProps) => {
  const { mutate: refundPayment } = useRefundPayment();

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      amount: payment.amount,
      reason: '',
    },
  });

  const handleSubmit = (values: z.infer<typeof schema>) => {
    refundPayment(
      {
        id: event.id,
        data: {
          payment_id: payment.id,
          amount: values.amount,
          // reason: values.reason,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['events', event.id] });
          handleClose();
        },
      },
    );
  };

  const handleClose = () => {
    form.reset();
    if (onClose && props.open === true) onClose();
    if (props.onOpenChange) props.onOpenChange(!props.open);
  };

  return (
    <Dialog {...props} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you sure you want to refund this payment?</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
            {/* <FormInput control={form.control} name="amount" type="number" label="Amount" /> */}

            <DialogFooter>
              <Button variant="destructive" type="submit">
                Refund Payment
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
