import { useEvents } from '@/api/events/events/getEvents';
import { ScrollableLayout } from '@/components/Layout';
import { useMapStore } from '@/components/MapViewer/MapStateStore';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/elements/tabs';
import { PolicyGate } from '@/features/auth/authorization';
import { CustomerProvider } from '@/features/customers/contexts/CustomerContext';
import { EventContext } from '@/features/events/contexts/EventContext';
import { useActivityCalendarLink } from '@/hooks/useActivityCalendarLink';
import { useRouterTab } from '@/hooks/useRouterTab';
import { LocationContext } from '@/providers/location';
import { useCalendarStore } from '@/stores/calendar';
import { apiDateToCalendarDate, formatDate } from '@/utils/format';
import { calculateActivityDateBounds } from '@/utils/logic';
import { Outlet } from '@tanstack/react-router';
import { useContext, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { EventTransfer } from '../EventTransfer';
import { HeaderBar } from './HeaderBar';

const tabs = [
  { title: 'Overview', path: 'overview', policy: 'events.view' },
  { title: 'Edit', path: 'edit', policy: 'events.update' },
  { title: 'Messages', path: 'messages', policy: 'messages.view' },
  { title: 'Customer', path: 'customer', policy: 'customers.update' },
  { title: 'Payments', path: 'payments', policy: 'payments.view' },
  { title: 'Invoice', path: 'invoice', policy: 'events.view' },
  { title: 'CCI', path: 'cci', policy: 'events.view' },
  { title: 'Changes', path: 'changes', policy: 'events.history' },
];

export const EventDetail = () => {
  const { activeLocationId } = useContext(LocationContext);
  const { event, isPending, isError, error } = useContext(EventContext);
  const { activeTab, selectTab } = useRouterTab();

  const { addPin, clearPins, setCenter, setActiveMapLocationId, resetActiveMapLocationId } =
    useMapStore(
      useShallow((state) => ({
        addPin: state.addPin,
        clearPins: state.clearPins,
        setCenter: state.setCenter,
        setActiveMapLocationId: state.setActiveMapLocationId,
        resetActiveMapLocationId: state.resetActiveMapLocationId,
      })),
    );

  const { setActiveCalendarLocationId, resetActiveCalendarLocationId } = useCalendarStore(
    useShallow((state) => ({
      setActiveCalendarLocationId: state.setActiveCalendarLocationId,
      resetActiveCalendarLocationId: state.resetActiveCalendarLocationId,
    })),
  );

  useActivityCalendarLink({ quoteable: event });

  useEffect(() => {
    if (event) {
      // Handle Map Setup
      setCenter(event.longitude, event.latitude);

      // Dont switch the calendar and map if no location is set
      if (event.franchise_id > 0) {
        setActiveCalendarLocationId(event.franchise_id);
        setActiveMapLocationId(event.franchise_id);
      }
    }
    return () => {
      resetActiveCalendarLocationId();
      resetActiveMapLocationId();
    };
  }, [event]);

  // Determine the date we will use to pull in events for the day
  // which will then be displayed on the calendar and map view
  const { activityDates, searchDateBegin, searchDateEnd } = calculateActivityDateBounds(event);

  // Get events for map pins
  const { data: sameDayEvents } = useEvents({
    location: event?.franchise_id ?? activeLocationId,
    search: {
      date_begin: searchDateBegin.toString(),
      date_end: searchDateEnd.toString(),
      status_not: 'Cancelled',
    },
  });

  // Pull in the events also taking place on the same day
  // and add to the sidebar map
  useEffect(() => {
    if (sameDayEvents) {
      sameDayEvents.map((event) =>
        event.activities
          .filter(
            (activity) =>
              activityDates
                .map((date) => date.toString())
                .includes(apiDateToCalendarDate(activity.start_at).toString()) || false,
          )
          .map((activity) => {
            addPin({
              id: activity.id,
              itemId: activity.event_id,
              assetType: activity.asset?.abbr ?? '',
              type: 'event',
              title: event.name,
              lat: event.latitude,
              lng: event.longitude,
              message: `${formatDate(activity.start_at, 'M/d h:mm a')} ${activity.asset?.abbr}`,
            });
          }),
      );
    }

    return () => clearPins('event');
  }, [sameDayEvents]);

  if (isPending) return <div>Loading...</div>;
  if (!event || isError) return <div>{JSON.stringify(error)}</div>;

  if (event?.transfer_pending) return <EventTransfer />;

  return (
    <ScrollableLayout className="sm:px-0 lg:px-0 py-0 bg-gray-50 w-full max-w-[100vw]">
      <HeaderBar event={event} />

      <Tabs value={activeTab} onValueChange={selectTab}>
        <div className="@container border-gray-200">
          <TabsList className="flex space-x-2 @2xl:space-x-8 px-0 md:px-4 my-0 bg-white max-w-[100vw] overflow-x-auto overflow-y-hidden">
            {tabs.map((tab, i) => (
              <PolicyGate key={`tabtrigger-${tab.path}-${i}`} policy={tab.policy}>
                <TabsTrigger
                  value={tab.path}
                  className="text-xs tracking-tighter md:tracking-normal md:text-sm px-3"
                >
                  {tab.title}
                </TabsTrigger>
              </PolicyGate>
            ))}
          </TabsList>
        </div>
      </Tabs>

      <div className="px-5 pt-3 pb-[300px]">
        <CustomerProvider customerId={event.customer.id}>
          <Outlet />
        </CustomerProvider>
      </div>
    </ScrollableLayout>
  );
};
