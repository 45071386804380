import { FormCheckbox, FormInput } from '@/components/Form';
import { Form, FormAutosave } from '@/components/ui/form/form';
import { EventLineItem, LeadLineItem, LineItem } from '@/types';
import { formatMoney } from '@/utils/format';
import { TrashIcon } from '@heroicons/react/24/outline';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

// interface LineItem extends LeadLineItem, EventLineItem {}

interface LineItemRowProps {
  lineItem: LeadLineItem | EventLineItem;
  readonly?: boolean;
  onSave: (data: any) => void;
  onDelete: () => void;
}

const schema = z.object({
  quantity: z.coerce.number().min(1, 'Required'),
  unit_cost: z.coerce.number(),
  taxable: z.coerce.boolean(),
});

export const LineItemRow = ({ lineItem, readonly = false, onSave, onDelete }: LineItemRowProps) => {
  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: lineItem,
  });

  const handleSave = (data: Partial<LineItem>) => {
    onSave(data);
  };

  // Save changes when leaving this page!
  useEffect(() => {
    return () => {
      if (form.formState.isDirty) {
        form.handleSubmit(handleSave)();
      }
    };
  }, []);

  return (
    <div className="pl-3">
      <Form {...form}>
        <FormAutosave interval={1000} onAutosave={form.handleSubmit(handleSave)}>
          <form onSubmit={form.handleSubmit(handleSave)}>
            <fieldset
              disabled={readonly}
              className="flex flex-row items-center space-x-2 space-y-3"
            >
              {!readonly && (
                <TrashIcon
                  className="h-6 w-6 p-1 mr-3 hover:bg-red-300 hover:cursor-pointer rounded"
                  onClick={onDelete}
                />
              )}
              <div className="grow flex-col">
                <span className="text-sm leading-tight">
                  {lineItem.addon?.name ?? lineItem.name}
                </span>
                {lineItem.sub_items && (
                  <span className="text-sm text-gray-700">
                    {lineItem.sub_items.map((subItem) => (
                      <SubItem key={`subitem${lineItem.id}${subItem.id}`} subItem={subItem} />
                    ))}
                  </span>
                )}
              </div>
              {!lineItem.package_id && (
                <span className="w-20">
                  <FormInput control={form.control} name="quantity" label="Qty" />
                </span>
              )}
              <span className="w-20">
                <FormInput control={form.control} name="unit_cost" label="Price" />
              </span>
              <span className="w-24">
                <FormCheckbox control={form.control} name="taxable" label="Tax" />
              </span>
              <div className="w-12 pr-2 text-sm font-semibold text-right pt-1.5">
                {formatMoney(lineItem.total)}
              </div>
            </fieldset>
          </form>
        </FormAutosave>
      </Form>
    </div>
  );
};

interface SubItemProps {
  subItem: Omit<LineItem, 'event_id' | 'lead_id'>;
}

const SubItem = ({ subItem }: SubItemProps) => {
  return (
    <div>
      <ChevronLeftIcon className="h-4 w-4 inline-block -rotate-45" />
      <span>{subItem.name}</span>
    </div>
  );
};
