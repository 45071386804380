import { API_URL } from '@/config';
import { axios } from '@/lib/axios';
import { LocationAccess, User } from '@/types';
import { get } from '@/utils/api';
import { createContext, ReactNode, useState } from 'react';
import { Sanctum, useSanctum } from 'react-sanctum';

type LeadsMode = 'all' | 'b2b';

export type AuthContextData = {
  authenticated: boolean;
  user: User | null | undefined;
  location_access: LocationAccess[];
  login: (username: string, password: string, remember?: boolean | undefined) => void;
  logout: () => void;
  leadsMode: LeadsMode;
  setLeadsMode: (mode: LeadsMode) => void;
};

type AuthenticationWrapperProps = {
  children: ReactNode;
};

type AuthProviderProps = {
  token?: string;
  children: ReactNode;
};

export const sanctumConfig = {
  apiUrl: API_URL,
  csrfCookieRoute: 'sanctum/csrf-cookie',
  signInRoute: 'v4/login',
  signOutRoute: 'v4/logout',
  userObjectRoute: 'v4/me',
  axiosInstance: axios,
};

export const AuthContext = createContext({} as AuthContextData);

export const AuthenticationWrapper = ({ children }: AuthenticationWrapperProps) => {
  return (
    <Sanctum config={sanctumConfig} checkOnInit={true}>
      {children}
    </Sanctum>
  );
};

export const AuthProvider = ({ token, children }: AuthProviderProps) => {
  const sanctum = useSanctum();
  // const { stop: stopPushNotifications } = usePushNotifications();

  const tokenMode = !!token;
  const [tokenUser, setTokenUser] = useState<any>();
  const [leadsMode, setLeadsMode] = useState<LeadsMode>('all');

  if (tokenMode && token && !tokenUser) {
    // Use token to sign requests
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;

    // Set header to identify the origin of the request when embedded in brightpattern
    axios.defaults.headers.common.FrameOrigin = 'brightpattern';

    get('/v4/me').then((response: any) => {
      setTokenUser(response);
    });
  }

  const doLogout = () => {
    if (tokenMode) {
      setTokenUser(undefined);
      window.location.assign('/login');
    } else {
      sanctum.signOut().then(() => {
        window.location.assign('/login');
      });
    }
    // stopPushNotifications();
  };

  const doSignin = (username: string, password: string, remember?: boolean) => {
    sanctum.signIn(username, password, remember).then(() => {
      window.location.assign('/');
    });
  };

  const authenticated = tokenMode ? !!tokenUser : !!sanctum.authenticated;
  const user = tokenMode ? tokenUser : sanctum.user;
  const location_access = tokenMode
    ? tokenUser?.franchise_access ?? []
    : sanctum.user?.franchise_access ?? [];

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        user,
        location_access,
        login: doSignin,
        logout: doLogout,
        leadsMode,
        setLeadsMode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
