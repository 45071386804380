import { Lead } from '@/types';
import { cn } from '@/utils/format';
import { FireIcon } from '@heroicons/react/24/solid';
import { useFlagPriority } from '../hooks/useFlagPriority';

interface PriorityFlagProps {
  lead: Lead;
}
export const PriorityFlag = ({ lead }: PriorityFlagProps) => {
  const { flag } = useFlagPriority();

  return (
    <FireIcon
      onClick={() => flag(lead.id, !lead.is_priority)}
      className={cn(
        'w-5 h-5 inline-block text-gray-400 hover:text-red-400 hover:cursor-pointer',
        lead.is_priority ? 'text-red-500' : '',
      )}
    />
  );
};
