import { AddressLookup, AddressSelection } from '@/components/AddressLookup/AddressLookup';
import { Placeholder } from '@/components/Elements';
import { FormCheckbox, FormDate, FormInput, FormSelect, FormTextarea } from '@/components/Form';
import { FormStateSelect } from '@/components/Form/FormStateSelect';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/elements/accordion';
import { Form, FormAutosave } from '@/components/ui/form/form';
import {
  CUSTOMER_ORGANIZATION_TYPES,
  HOW_HEARD_OPTIONS,
  LEAD_STATUS_OPTIONS,
  TIME_TO_CALL_OPTIONS,
} from '@/config';
import { LeadContext } from '@/features/leads/contexts/LeadContext';
import queryClient from '@/lib/react-query';
import { AssetType, Lead } from '@/types';
import { cn } from '@/utils/format';
import { PlusIcon } from '@heroicons/react/24/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';
import { AccordionItemButtonStyle, AccordionItemPanelStyle } from '../styles';

interface LeadFormProps {
  lead: Lead;
  eventTypes: any[];
  assetTypes: AssetType[];
}

const leadSchema = z.object({
  status: z.string(),
  event_type_id: z.coerce.string(),
  name: z.coerce.string(),
  address: z.string(),
  address_line2: z.string().nullish(),
  city: z.string(),
  state: z.string(),
  zipcode: z.string().min(5, 'Required'),
  latitude: z.coerce.number(),
  longitude: z.coerce.number(),
  date_wanted: z.coerce.string(),
  time_wanted: z.coerce.string().nullish(),
  guest_count: z.coerce.number().or(z.string()).nullish(),
  time_to_call: z.string().nullish(),
  best_method: z.string().nullish(),
  followup_at: z.coerce.string().nullish(),
  special_instructions: z.string().nullish(),
  event_day_contact: z.string().nullish(),
  event_day_phone: z.string().nullish(),
  how_heard: z.string().nullish(),
  how_heard_other: z.string().nullish(),
  promo_code: z.string().nullish(),
  activity_interest: z.array(z.any()).default([]),
  organization_name: z.string().nullish(),
  organization_type: z.string().nullish(),
});

const contactMethodOptions: any[] = [
  {
    label: 'Phone or Email',
    value: 'Phone or Email',
  },
  {
    label: 'Phone',
    value: 'Phone',
  },
  {
    label: 'Email',
    value: 'Email',
  },
];

const populateDefaultValues = (lead: Lead, assetTypes: AssetType[]) => {
  const activity_interest = lead.activity_interest ?? [];
  const selectedActivityInterest = assetTypes?.map((assetType) => {
    const selected = !!activity_interest.find((a) => a === assetType.name);

    return { id: assetType.id, selected, name: assetType.name };
  });

  return {
    status: lead.status ?? 'New',
    event_type_id: lead.event_type_id?.toString() ?? '',
    name: lead.name ?? '',
    address: lead.address ?? '',
    address_line2: lead.address_line2 ?? '',
    city: lead.city ?? '',
    state: lead.state ?? '',
    zipcode: lead.zipcode ?? '',
    latitude: lead.latitude ?? 0.0,
    longitude: lead.longitude ?? 0.0,
    date_wanted: lead.date_wanted ?? '',
    time_wanted: lead.time_wanted ?? '',
    guest_count: lead.guest_count ?? '',
    time_to_call: lead.time_to_call ?? '',
    best_method: lead.best_method ?? '',
    followup_at: lead.followup_at ?? undefined,
    special_instructions: lead.special_instructions ?? '',
    event_day_contact: lead.event_day_contact ?? '',
    event_day_phone: lead.event_day_phone ?? '',
    how_heard: lead.how_heard ?? '',
    how_heard_other: lead.how_heard_other ?? '',
    promo_code: lead.promo_code ?? '',
    activity_interest: selectedActivityInterest ?? [],
    organization_name: lead.customer?.organization_name ?? '',
    organization_type: lead.customer?.organization_type ?? '',
  };
};

const OrganizationTypeOptions = [
  { value: '', label: 'Select a customer type...' },
  ...CUSTOMER_ORGANIZATION_TYPES,
];

export const LeadForm = ({ lead, eventTypes, assetTypes }: LeadFormProps) => {
  const { leadId, updateLead } = useContext(LeadContext);
  const [addressDirty, setAddressDirty] = useState(false);
  const [showAddressLine2, setShowAddressLine2] = useState(lead.address_line2 || false);

  const handleLeadSubmit = (data: z.infer<typeof leadSchema>) => {
    updateLead(
      {
        id: leadId,
        data: {
          ...data,
          activity_interest:
            data?.activity_interest
              ?.filter((item: string | any) => item?.selected ?? false)
              .map((item: any) => item.name) ?? [],
        },
      },
      {
        onSuccess: (values) => {
          if (addressDirty) {
            queryClient.invalidateQueries({ queryKey: ['availability', { id: leadId }] });
          }
          setAddressDirty(false);
        },
      },
    );
  };

  const form = useForm<z.infer<typeof leadSchema>>({
    resolver: zodResolver(leadSchema),
    defaultValues: populateDefaultValues(lead, assetTypes ?? []),
  });

  const { fields: assetTypeFields } = useFieldArray({
    control: form.control,
    name: 'activity_interest',
  });

  // Prepare display data
  const eventTypeOptions = [
    { value: null, label: 'Select an event type' },
    ...(eventTypes?.map((eventType) => {
      return { value: eventType.event_type_id, label: eventType.name };
    }) ?? []),
  ];

  const applyAddressLookup = (location: AddressSelection) => {
    form.setValue('address', location.address);
    form.setValue('address_line2', null);
    if (location.city) form.setValue('city', location.city);
    if (location.state) form.setValue('state', location.state);
    if (location.zipcode) form.setValue('zipcode', location.zipcode);
    if (location.latitude) form.setValue('latitude', location.latitude);
    if (location.longitude) form.setValue('longitude', location.longitude);

    setAddressDirty(true);

    form.handleSubmit(handleLeadSubmit)();
  };

  // Save changes when leaving this page!
  useEffect(() => {
    return () => {
      if (form.formState.isDirty) {
        form.handleSubmit(handleLeadSubmit)();
      }
    };
  }, []);

  return (
    <Form {...form}>
      <FormAutosave interval={50} onAutosave={form.handleSubmit(handleLeadSubmit)}>
        <form onSubmit={form.handleSubmit(handleLeadSubmit)} className="space-y-3">
          <AccordionItem value="location" className="mt-2">
            <AccordionTrigger className={AccordionItemButtonStyle}>
              About the Event
            </AccordionTrigger>
            <AccordionContent className={AccordionItemPanelStyle}>
              <div className="flex flex-col space-y-3 pt-4">
                {!eventTypes && <Placeholder size="full" className="h-10" />}
                {eventTypeOptions && (
                  <FormSelect
                    control={form.control}
                    name="event_type_id"
                    options={eventTypeOptions}
                    label="Event Type"
                  />
                )}
                {(lead.customer?.is_organization || lead.event_type_id > 2) && (
                  <div className="flex flex-col space-y-3 py-2">
                    <FormInput
                      control={form.control}
                      name="organization_name"
                      label="Organization Name"
                    />
                    <FormSelect
                      control={form.control}
                      name="organization_type"
                      label="Organization Type"
                      options={OrganizationTypeOptions}
                    />
                  </div>
                )}
                <div className="flex flex-row">
                  <div className="grow">
                    <div className="relative">
                      <AddressLookup
                        onSelect={applyAddressLookup}
                        address={lead.address}
                        searchArea={{
                          lon: lead.franchise.longitude,
                          lat: lead.franchise.latitude,
                        }}
                        className="absolute top-0 right-0 z-10"
                      />
                      <FormInput control={form.control} name="address" label="Event Address" />
                    </div>
                    <FormInput type="hidden" control={form.control} name="latitude" />
                    <FormInput type="hidden" control={form.control} name="longitude" />
                  </div>
                  <PlusIcon
                    className={cn(
                      'h-4 w-4 mt-3 mx-1 text-black transition-all',
                      showAddressLine2 ? 'rotate-45' : '',
                    )}
                    onClick={() => setShowAddressLine2(!showAddressLine2)}
                  />
                </div>
                {showAddressLine2 && (
                  <FormInput
                    control={form.control}
                    name="address_line2"
                    label="Event Address Line 2"
                  />
                )}
                <div className="grid grid-rows-2 grid-cols-2 @lg:grid-cols-4 @lg:grid-rows-1 space-y-1.5 @lg:space-y-0 @lg:space-x-2">
                  <div className="col-span-2 @lg:col-span-2">
                    <FormInput control={form.control} name="city" label="City" />
                  </div>
                  <div className="mr-3 @lg:mr-0">
                    <FormStateSelect
                      control={form.control}
                      name="state"
                      label="State"
                      style="abbr"
                    />
                  </div>
                  <div>
                    <FormInput control={form.control} name="zipcode" label="Zip Code" />
                  </div>
                </div>
                <FormInput control={form.control} name="name" label="Event Name" />
                <div className="flex flex-col @sm:flex-row space-y-3 @sm:space-y-0 @sm:space-x-2">
                  <span className="w-full @sm:max-w-[150px]">
                    <FormInput
                      control={form.control}
                      type="number"
                      name="guest_count"
                      label="# of Guests"
                    />
                  </span>
                  <span className="w-full">
                    <FormDate control={form.control} name="date_wanted" label="Date Wanted" />
                  </span>
                  <span className="w-full @sm:max-w-[150px]">
                    <FormSelect
                      control={form.control}
                      name="time_wanted"
                      options={TIME_TO_CALL_OPTIONS}
                      label="Time Wanted"
                    />
                  </span>
                </div>
                <div className="flex flex-col @sm:flex-row space-y-3 @sm:space-y-0 @sm:space-x-2">
                  <FormInput
                    control={form.control}
                    name="event_day_contact"
                    label="Event Contact Person"
                  />
                  <FormInput
                    control={form.control}
                    name="event_day_phone"
                    label="Event Contact Phone"
                  />
                </div>
                <FormTextarea
                  control={form.control}
                  name="special_instructions"
                  label="Special Instructions"
                  className="resize-y overflow-y-scroll"
                />
              </div>
              {!form.formState.isValid && 'Invalid'}
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="followup" className="mt-2">
            <AccordionTrigger className={AccordionItemButtonStyle}>Follow Up</AccordionTrigger>
            <AccordionContent className={AccordionItemPanelStyle}>
              <div className="pt-4 px-1 grid gap-3 grid-cols-1 @sm:grid-cols-2">
                <FormSelect
                  control={form.control}
                  name="time_to_call"
                  options={TIME_TO_CALL_OPTIONS}
                  label="Best time to call"
                />
                <FormSelect
                  control={form.control}
                  name="best_method"
                  options={contactMethodOptions}
                  label="Contact method"
                />
                <FormInput
                  control={form.control}
                  type="date"
                  name="followup_at"
                  label="Follow up on..."
                />
                <FormSelect
                  control={form.control}
                  name="status"
                  options={LEAD_STATUS_OPTIONS}
                  label="Status"
                />
              </div>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="marketing" className="mt-2">
            <AccordionTrigger className={AccordionItemButtonStyle}>Marketing</AccordionTrigger>
            <AccordionContent className={AccordionItemPanelStyle}>
              <div className="py-4 px-1 grid gap-3 grid-cols-1 @sm:grid-cols-2">
                <FormSelect
                  control={form.control}
                  name="how_heard"
                  options={HOW_HEARD_OPTIONS}
                  label="How heard"
                />
                <FormInput
                  control={form.control}
                  name="how_heard_other"
                  label="How heard (other)"
                />
                <FormInput control={form.control} name="promo_code" label="Promo/Ad code" />
              </div>
              <div className="px-2 grid grid-cols-3">
                {assetTypeFields?.map((field, index) => (
                  <FormCheckbox
                    key={`ai-${field.id}-${index}`}
                    control={form.control}
                    name={`activity_interest.${index}.selected`}
                    label={field.name}
                  />
                ))}
              </div>
            </AccordionContent>
          </AccordionItem>
        </form>
      </FormAutosave>
    </Form>
  );
};
