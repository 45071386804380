import { PolicyCheck } from '@/features/auth/authorization';

export const AssetPolicies: Record<string, PolicyCheck> = {
  'equipment.assets.view': ({ acl, user, meta }) => {
    return acl.equipment.assets.view;
  },
  'equipment.assets.create': ({ acl, user, meta }) => {
    return acl.equipment.assets.create;
  },
  'equipment.assets.update': ({ acl, user, meta }) => {
    return acl.equipment.assets.edit;
  },
  'equipment.assets.delete': ({ acl, user, meta }) => {
    return false;
  },
};
