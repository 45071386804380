import { PolicyCheck } from '@/features/auth/authorization';

export const ResourcesPolicies: Record<string, PolicyCheck> = {
  'resources.view': ({ acl, user, meta }) => {
    return acl.resources.view;
  },
  'resources.directory.view': ({ acl, user, meta }) => {
    return acl.resources.directory.view;
  },
  'resources.directory.internal.view': ({ acl, user, meta }) => {
    return acl.resources.directory.internal.view;
  },
  'resources.store.view': ({ acl, user, meta }) => {
    return acl.resources.store.view;
  },
};
