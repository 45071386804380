import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Event, ModelID } from '@/types';
import { get, getPaginated, PaginatedResource } from '@/utils/api';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { EventSearchParams, featureKeys, featurePath } from '.';

type QueryFnType = typeof fetchEvents;
type PagedQueryFnType = typeof fetchPagedEvents;

type UseEventsOptions = {
  location: ModelID;
  search?: EventSearchParams;
  config?: QueryConfig<QueryFnType>;
};

type UsePagedEventsOptions = {
  location: ModelID;
  search?: EventSearchParams;
  config?: QueryConfig<PagedQueryFnType>;
};

export const fetchEvents = (location: ModelID, search: EventSearchParams = {}) =>
  get<Event[]>(`${featurePath}?franchise_id=${location}`, search);

export const fetchPagedEvents = (location: ModelID, search?: EventSearchParams) =>
  getPaginated<Event>(`${featurePath}?franchise_id=${location}`, search);

export const useEvents = ({ location, search = {}, config = {} }: UseEventsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: featureKeys.list(location, search),
    queryFn: () => fetchEvents(location, search),
  });
};

export const usePagedEvents = ({ location, search, config = {} }: UsePagedEventsOptions) => {
  return useQuery<ExtractFnReturnType<PagedQueryFnType>, Error, PaginatedResource<Event>, any>({
    ...config,
    queryKey: featureKeys.list(location, search),
    queryFn: () => fetchPagedEvents(location, search),
    placeholderData: keepPreviousData,
    staleTime: 1000 * 5,
  });
};
