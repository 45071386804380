import { CustomerAvatar } from '@/components/Customer/CustomerAvatar';
import { ListsBadge } from '@/components/Customer/ListsBadge';
import { EmailButton, PhoneButton } from '@/components/Elements/ContactButtons';
import { ScrollableLayout } from '@/components/Layout';
import { PinButton } from '@/components/PinButton/PinButton';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/elements/tabs';
import { PolicyGate } from '@/features/auth/authorization';
import { useRouterTab } from '@/hooks/useRouterTab';
import { Outlet } from '@tanstack/react-router';
import { useCustomerContext } from '../contexts/CustomerContext';
import { CustomerActionDropdownMenu } from './CustomerActionDropdownMenu';

const tabs = [
  { title: 'Overview', path: 'overview', policy: 'customers.view' },
  { title: 'Edit', path: 'edit', policy: 'customers.update' },
  { title: 'Messages', path: 'messages', policy: 'customers.view' },
  { title: 'Leads', path: 'leads', policy: 'leads.view' },
  { title: 'Events', path: 'events', policy: 'events.view' },
  { title: 'Payments', path: 'payments', policy: 'payments.view' },
  { title: 'Changes', path: 'changes', policy: 'customers.history' },
];

export const CustomerDetail = () => {
  const { customer, isPending, isError, error } = useCustomerContext();
  const { activeTab, selectTab } = useRouterTab();

  if (isPending) return <div>Loading...</div>;
  if (!customer || isError) return <div>{JSON.stringify(error)}</div>;

  return (
    <ScrollableLayout className="sm:px-0 lg:px-0 py-0 bg-gray-50">
      <div className="@container bg-white pt-4">
        <div className="flex flex-col @3xl:flex-row @3xl:items-end px-5 pb-4">
          <div className="grow">
            <div className="flex flex-row">
              <CustomerAvatar customer={customer} />
              <span className="flex flex-col">
                <div className="flex flex-col @md:flex-row items-center">
                  <span className="text-xl font-bold pr-1.5">{customer.full_name}</span>
                  <ListsBadge customer={customer} />
                </div>
                <div className="flex flex-col space-y-2 @md:flex-row @md:space-x-2 @md:space-y-0">
                  <PhoneButton
                    phone={customer.phone}
                    isInvalid={!customer.phone_valid}
                    phoneType={customer.phone_type}
                  />
                  <EmailButton
                    email={customer.email}
                    isInvalid={customer.has_bad_email}
                    customerId={customer.id}
                  />
                </div>
              </span>
            </div>
          </div>
          <div className="flex flex-row pt-2 space-x-2 justify-end items-baseline">
            <PinButton mode="customer" id={customer.id} />
            <CustomerActionDropdownMenu customer={customer} />
          </div>
        </div>
      </div>

      <Tabs value={activeTab} onValueChange={selectTab}>
        <div className="@container border-gray-200 bg-white">
          <TabsList className="flex space-x-2 @2xl:space-x-8 px-0 md:px-4 my-0 bg-white max-w-[100vw] overflow-x-auto overflow-y-hidden">
            {tabs.map((tab, i) => (
              <PolicyGate key={`tabtrigger-${tab.title}-${i}`} policy={tab.policy}>
                <TabsTrigger
                  value={tab.path}
                  className="text-xs tracking-tighter md:tracking-normal md:text-sm px-3"
                >
                  {tab.title}
                </TabsTrigger>
              </PolicyGate>
            ))}
          </TabsList>
        </div>
      </Tabs>

      <div className="px-5 pt-3">
        <Outlet />
      </div>
    </ScrollableLayout>
  );
};
