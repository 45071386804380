import _ from 'lodash';
import { ACL } from '.';
import { NewbieACL } from './newbie';
export const ACL_ROLE_REPORTING = 'Reporting';

export const ReportingACL: ACL = _.merge({}, NewbieACL, {
  role: ACL_ROLE_REPORTING,
  dashboard: {
    view: true,
  },
  reports: {
    view: true,
  },
});
