import { useCustomerClearEmailBlock } from '@/api/customers/customers/clearEmailBlock';
import { CopyToClipboard } from '@/components/CopyToClipboard/CopyToClipboard';
import { ModelID } from '@/types';
import { cn } from '@/utils/format';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { EnvelopeIcon } from '@heroicons/react/24/solid';
import { ConfirmationDialog } from '../ConfirmationDialog';

interface EmailButtonProps {
  email: string | null | undefined;
  variant?: 'sm' | 'lg';
  isInvalid?: boolean;
  customerId?: ModelID;
}

export const EmailButton = ({
  email,
  variant = 'sm',
  isInvalid = false,
  customerId = undefined,
}: EmailButtonProps) => {
  if (!email) isInvalid = true;
  const { mutate: clearBlocks } = useCustomerClearEmailBlock();

  const handleResubscribe = () => {
    if (!email) return;
    if (customerId) clearBlocks({ id: customerId, data: { email } });
  };

  return (
    <span
      className={cn(
        'inline-flex flex-row align-middle items-center justify-center border rounded shadow-sm',
        isInvalid ? 'bg-red-100 border-red-300 ' : 'bg-white border-gray-300 hover:bg-gt-green-50',
      )}
    >
      <span
        className={cn(
          'mr-1 border-r border-1 rounded-l',
          isInvalid ? 'bg-red-200 border-red-400' : 'bg-gray-100 border-gray-300',
        )}
      >
        <EnvelopeIcon className={cn('h-4 w-4 m-1', isInvalid ? 'text-red-400' : 'text-gray-400')} />
      </span>
      {email ? (
        <>
          <a
            href={`mailto:${email}`}
            className={cn(
              'grow font-semibold mr-1.5 pl-1',
              'text-nowrap whitespace-nowrap text-ellipsis overflow-hidden',
              variant === 'sm' ? 'text-xs' : 'tracking-tighter text-xs lg:text-base',
              isInvalid ? 'text-red-500' : 'text-gray-700',
            )}
          >
            {email}
          </a>
          {isInvalid ? (
            customerId ? (
              <ConfirmationDialog
                title="Resubscribe"
                body="Are you sure you want to resubscribe this email?"
                triggerButton={
                  <ExclamationTriangleIcon className="h-4 w-4 m-1 text-red-500 hover:text-red-900 hover:cursor-pointer" />
                }
                confirmAction={handleResubscribe}
                confirmButtonText="Resubscribe"
              />
            ) : (
              <ExclamationTriangleIcon className="h-4 w-4 m-1 text-red-500" />
            )
          ) : (
            <CopyToClipboard value={email} />
          )}
        </>
      ) : (
        <span
          className={cn(
            'grow font-semibold mr-1.5 pl-1',
            'text-nowrap whitespace-nowrap text-ellipsis overflow-hidden',
            variant === 'sm' ? 'text-xs' : 'tracking-tighter text-xs lg:text-base',
            isInvalid ? 'text-red-500' : 'text-gray-700',
          )}
        >
          No email
        </span>
      )}
    </span>
  );
};
