import { Location, ModelID, SearchParams } from '@/types';

export type Filter = 'all' | 'active' | 'inactive';
export type Sort = 'created_at' | 'updated_at' | 'location_id' | 'areaop';
export interface LocationSearchParams extends SearchParams<Filter, Sort>, Partial<Location> {}

export const featurePath = '/v4/locations/location';

export const featureKeys = {
  all: [{ scope: 'locations' }] as const,

  lists: (location: ModelID) => [{ ...featureKeys.all[0], location, entity: 'list' }] as const,

  list: (location: ModelID, search: LocationSearchParams = {}) =>
    [{ ...featureKeys.lists(location)[0], search }] as const,

  details: () => [{ ...featureKeys.all[0], entity: 'detail' }] as const,

  detail: (id: ModelID) => [{ ...featureKeys.details()[0], id }] as const,

  simple: (location: ModelID, search: LocationSearchParams = {}) =>
    [{ ...featureKeys.lists(location)[0], search }] as const,
};
