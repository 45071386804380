import CCI from '@/components/Location/CCI';
import { LeadContext } from '@/features/leads/contexts/LeadContext';
import { useContext } from 'react';

export const CciTab = () => {
  const { lead } = useContext(LeadContext);

  if (!lead) return <>Loading...</>;

  if (!lead.franchise.cci_sheet_path) return <>No CCI sheet configured.</>;

  return (
    <>
      <div className="flex flex-row space-x-2 text-xs">
        {!!lead.franchise.facebook_url && <span>{lead.franchise.facebook_url} </span>}
        {!!lead.franchise.instagram_url && <span>{lead.franchise.instagram_url} </span>}
      </div>
      <CCI srcPath={lead.franchise.cci_sheet_path} />
    </>
  );
};
