import { ModelID } from '@/types';
import { CalendarDate } from '@internationalized/date';

export interface CalendarEventSearchParams {
  start: CalendarDate;
  end: CalendarDate;
}

export const featurePath = '/v4/calendar/events';

export const featureKeys = {
  all: [{ scope: 'calendar-events' }] as const,

  lists: (location: ModelID) => [{ ...featureKeys.all[0], location, entity: 'list' }] as const,

  list: (location: ModelID, search: { start: string; end: string }) =>
    [{ ...featureKeys.lists(location)[0], search }] as const,
};
