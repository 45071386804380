import { CustomerMessage, ModelID, SearchParams } from '@/types';

export type Filter = 'all' | 'unread';
export type Sort = 'created_at' | 'updated_at' | 'customer-message_id';
export type CustomerMessageSearchParams = SearchParams<Filter, Sort> & Partial<CustomerMessage>;

export const featurePath = '/v4/customers/customer-message';

export const featureKeys = {
  all: [{ scope: 'customer-messages' }] as const,

  lists: (location: ModelID) => [{ ...featureKeys.all[0], location, entity: 'list' }] as const,

  list: (location: ModelID, search: CustomerMessageSearchParams = {}) =>
    [{ ...featureKeys.lists(location)[0], search }] as const,

  conversations: (location: ModelID, search: CustomerMessageSearchParams = {}) =>
    [{ ...featureKeys.lists(location)[0], entity: 'conversations', search }] as const,

  details: () => [{ ...featureKeys.all[0], entity: 'detail' }] as const,

  detail: (id: ModelID) => [{ ...featureKeys.details()[0], id }] as const,
};
