import { featureKeys as leadFeatureKeys } from '@/api/leads/leads';
import { CustomerEditForm } from '@/components/Customer/CustomerEditForm';
import { Link } from '@/components/Elements';
import NoResults from '@/components/Elements/NoResults/NoResults';
import PaymentMethodManager from '@/components/PaymentMethodManager/PaymentMethodManager';
import { PolicyGate } from '@/features/auth/authorization';
import { LeadContext } from '@/features/leads/contexts/LeadContext';
import { queryClient } from '@/lib/react-query';
import { useContext } from 'react';

export const CustomerTab = () => {
  const { lead } = useContext(LeadContext);
  const onUpdate = () => {
    queryClient.invalidateQueries({ queryKey: leadFeatureKeys.details() });
  };

  const copyFrom = () => {
    return {
      address: lead?.address,
      address_line2: lead?.address_line2,
      city: lead?.city,
      state: lead?.state,
      zipcode: lead?.zipcode,
    };
  };

  if (!lead) return <>Loading...</>;

  if (!lead?.customer) return <NoResults label="customer" />;

  return (
    <div className="@container">
      <div className="flex flex-col @xl:flex-row md:space-x-4">
        <PolicyGate policy="customers.update">
          <div className="basis-1/2">
            <h2 className="text-lg">
              Customer{' '}
              <Link className="text-lg" to={`/customers/customer/${lead.customer.id}/overview`}>
                #{lead.customer.id}
              </Link>
            </h2>
            <CustomerEditForm
              key={`custedit-${lead.customer_id}`}
              customer={lead.customer}
              onUpdate={onUpdate}
              copyFrom={copyFrom}
            />
          </div>
        </PolicyGate>
        <PolicyGate policy="payments.create">
          <div className="basis-1/2">
            <h2>Payment Methods</h2>
            <PaymentMethodManager customer={lead.customer} />
          </div>
        </PolicyGate>
      </div>
      <div className="mb-96"> </div>
    </div>
  );
};
