import { Badge, BadgeProps } from '@/components/ui/elements/badge';
import { Customer } from '@/types';

interface StatusBadgeProps {
  customer: Customer | null | undefined;
}

export const ListsBadge = ({ customer }: StatusBadgeProps) => {
  if (!customer || !customer.lists || customer.lists?.length === 0) return <></>;

  const lists = customer.lists.map((list) => list.name).join(', ') ?? [];
  let variant = 'gray' as BadgeProps['variant'];

  if (lists.toLowerCase().match(/blacklist|do.not.book/)) {
    variant = 'red';
  }

  return (
    <Badge size="sm" variant={variant}>
      {lists}
    </Badge>
  );
};
