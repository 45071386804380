import { CalendarEventChipRenderProps } from '../types';
import { CalendarChip } from './CalendarChip';

export const LeadChip = ({ eventInfo }: CalendarEventChipRenderProps) => {
  const { timeText } = eventInfo.event.extendedProps;

  return (
    <CalendarChip eventInfo={eventInfo} className="bg-gray-300 border-gray-300 text-gray-800">
      <span className="font-bold">{timeText}</span>
      <span>{eventInfo.event.title}</span>
    </CalendarChip>
  );
};
