import { axios } from '@/lib/axios';
import { ApiResponse, ModelID } from '@/types';

export type ApiDeleteResponse = {
  data: {
    id: ModelID;
    success: boolean;
  };
  meta: any;
};

export type Paginator = {
  hasMore: number;
  lastPage: number;
  page: number;
  total: number;
};

export type PaginatedResponse<T> = {
  data: T[];
  paginator: Paginator;
};

export type PaginatedResource<T> = {
  results: T[];
  paginator: Paginator;
};

export const get = async <T, S = any>(url: string, search?: S): Promise<T> => {
  const response = await axios.get<T>(url, {
    params: search ?? {},
  });
  return response.data ? response.data : (response as T);
};

export const getRaw = async <T, S = any>(url: string, search?: S): Promise<T> => {
  const response = await axios.get<T>(url, {
    params: search ?? {},
    responseType: 'blob',
  });
  return response as T;
};

export const postRaw = async <T, S = any, D = any>(
  url: string,
  formData: Partial<T | D>,
  search?: S,
): Promise<T> => {
  const response = await axios.post<T>(url, formData, {
    params: search ?? {},
    responseType: 'blob',
  });
  return response as T;
};

export const getPaginated = async <T, S = any>(
  url: string,
  search?: S,
): Promise<PaginatedResource<T>> => {
  const response = await axios.get<PaginatedResource<T>, PaginatedResponse<T>>(url, {
    params: search ?? {},
  });
  return {
    results: response.data,
    paginator: response.paginator,
  };
};

export const post = async <T, S = any, D = any>(
  url: string,
  formData: Partial<T | D>,
  search?: S,
  config?: any,
): Promise<T> => {
  const response = await axios.post<T>(url, formData, {
    params: search ?? {},
    ...config,
  });
  return response.data;
};

export const create = post;

export const update = async <T, S = any>(
  url: string,
  formData: Partial<T>,
  search?: S,
): Promise<T> => {
  const response = await axios.put<T>(url, formData, {
    params: search ?? {},
  });
  return response.data;
};

export const remove = async (url: string) => {
  const response = await axios.delete<ApiResponse<ApiDeleteResponse>>(url);
  return response.data;
};
