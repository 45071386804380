import { useInvoice } from '@/api/events/events/getEvent';
import { Event } from '@/types';
import { isString } from 'lodash';
import { useEffect, useState } from 'react';
import { useFileDownload } from './useFileDownload';

interface UseEventInvoiceProps {
  event: Event;
}

export const useEventInvoice = ({ event }: UseEventInvoiceProps) => {
  const [pdfData, setPdfData] = useState<string>('');
  const { download } = useFileDownload();

  const { data: invoiceData, isFetching } = useInvoice({ event });

  useEffect(() => {
    const reader = new FileReader();
    if (invoiceData) {
      reader.readAsDataURL(invoiceData);
      reader.onloadend = () => {
        if (reader.result && isString(reader.result))
          setPdfData(reader.result.substring(reader.result.indexOf(',') + 1));
      };
    }
    return () => reader.abort();
  }, [invoiceData, FileReader, setPdfData]);

  const downloadFile = (mode: 'download' | 'open') => {
    if (!(invoiceData instanceof Blob)) return;
    const blob = new Blob([invoiceData], { type: 'application/pdf' });

    download(blob, `gametruck-invoice-EID#${event.id}.pdf`, mode);
  };

  return { pdfData, isFetching, downloadFile };
};
