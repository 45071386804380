import { useFileDownload } from '@/hooks/useFileDownload';
import { ModelID } from '@/types';
import { postRaw } from '@/utils/api';
import { useMutation } from '@tanstack/react-query';
import { CalendarEventSearchParams, featurePath } from '.';

type ExportCalendarEventsDTO = {
  location: ModelID;
  search: CalendarEventSearchParams;
};

const exportCalendarEvents = ({ location, search }: ExportCalendarEventsDTO) =>
  postRaw<Blob>(`${featurePath}/export`, {
    franchise_id: location,
    start: search.start.toString(),
    end: search.end.toString(),
  });

export const useExportCalendarEvents = ({ location, search }: ExportCalendarEventsDTO) => {
  const { download } = useFileDownload();

  return useMutation({
    mutationFn: () => exportCalendarEvents({ location, search }),
    onSuccess: (data) => {
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
      download(blob, `calendar-schedule-${search.start.toString()}.csv`, 'download');
    },
  });
};
