import { useLogLead as useLogLeadMutator } from '@/api/leads/leads/updateLead';
import { ModelID } from '@/types';

export const useLogLead = () => {
  const { mutate: log } = useLogLeadMutator();

  const logLead = (
    leadId: ModelID,
    contactType: string,
    result?: string,
    onSuccess?: () => void,
  ) => {
    log(
      { id: leadId, data: { contactType, result } },
      {
        onSuccess,
      },
    );
  };

  return {
    logLead,
  };
};
