import { NotesTimeline } from '@/components/NotesTimeline';
import { BaseNote, Event } from '@/types';

interface EventNotesProps {
  event: Event;
}

export const EventNotes = ({ event }: EventNotesProps) => {
  let combinedNotes = [] as BaseNote[];
  if (event) {
    combinedNotes = combinedNotes.concat(event.notes ?? []).concat(event.lead?.notes ?? []);
  }

  return <NotesTimeline notes={combinedNotes} />;
};
