import { useEventTypes } from '@/api/settings/event-types/getEventTypes';
import { AddressLookup, AddressSelection } from '@/components/AddressLookup/AddressLookup';
import { Placeholder } from '@/components/Elements';
import { FormInput, FormSelect, FormTextarea } from '@/components/Form';
import { FormStateSelect } from '@/components/Form/FormStateSelect';
import { Form, FormAutosave } from '@/components/ui/form/form';
import VipManager from '@/components/VipManager/VipManager';
import { EventContext } from '@/features/events/contexts/EventContext';
import queryClient from '@/lib/react-query';
import { Event } from '@/types';
import { cn } from '@/utils/format';
import { PlusIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

type EventFormProps = {
  event: Event;
  onUpdate?: (data: Event) => void;
  readonly?: boolean;
};

const schema = z.object({
  event_type_id: z.coerce.string(),
  name: z.string(),
  contact_person: z.string().nullish(),
  phone: z.string().nullish(),
  address: z.string(),
  address_line2: z.string().nullish(),
  city: z.string(),
  state: z.string(),
  zipcode: z.string(),
  longitude: z.number(),
  latitude: z.number(),
  special_instructions: z.string().nullish(),
  guest_count: z.coerce.number().nullish(),
});

const populateDefaultValues = (event: Event): z.infer<typeof schema> => {
  return {
    event_type_id: event.event_type_id.toString() ?? '1',
    name: event.name ?? '',
    contact_person: event.contact_person ?? '',
    phone: event.phone ?? '',
    address: event.address ?? '',
    address_line2: event.address_line2 ?? '',
    city: event.city ?? '',
    state: event.state ?? '',
    zipcode: event.zipcode ?? '',
    latitude: event.latitude ?? 0.0,
    longitude: event.longitude ?? 0.0,
    special_instructions: event.special_instructions ?? '',
    guest_count: event.guest_count ?? null,
  };
};

export const EventEditForm = ({ event, onUpdate, readonly = false }: EventFormProps) => {
  const { updateEvent } = useContext(EventContext);
  const [addressDirty, setAddressDirty] = useState(false);
  const [showAddressLine2, setShowAddressLine2] = useState(event.address_line2 ?? false);

  const { data: eventTypes, isPending: eventTypesIsLoading } = useEventTypes({
    location: event.franchise_id,
  });

  const eventTypeOptions = eventTypes?.map((type) => {
    return { value: type.event_type_id, label: type.name };
  });

  const handleSubmit = (values: z.infer<typeof schema>) => {
    if (true === readonly) return;
    commitUpdate(values);
  };

  const commitUpdate = (values: z.infer<typeof schema>) => {
    updateEvent(
      {
        data: values,
        id: event.id,
      },
      {
        onSuccess: (values) => {
          if (onUpdate) onUpdate(values);
          if (addressDirty) {
            queryClient.invalidateQueries({ queryKey: ['availability', { id: event.id }] });
          }
          setAddressDirty(false);
        },
      },
    );
  };

  const applyAddressLookup = (location: AddressSelection) => {
    form.setValue('address', location.address);
    form.setValue('address_line2', null);
    if (location.city) form.setValue('city', location.city);
    if (location.state) form.setValue('state', location.state);
    if (location.zipcode) form.setValue('zipcode', location.zipcode);
    if (location.latitude) form.setValue('latitude', location.latitude);
    if (location.longitude) form.setValue('longitude', location.longitude);

    setAddressDirty(true);

    form.handleSubmit(handleSubmit)();
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: populateDefaultValues(event),
  });

  // Save changes when leaving this page!
  useEffect(() => {
    return () => {
      if (form.formState.isDirty) {
        form.handleSubmit(commitUpdate)();
      }
    };
  }, []);

  return (
    <>
      <Form {...form}>
        <FormAutosave interval={50} onAutosave={form.handleSubmit(handleSubmit)}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <fieldset disabled={readonly} className="@container space-y-8">
              <section className="space-y-3">
                <FormInput control={form.control} name="name" label="Event Name" />
                <div className="flex flex-col w-full space-y-3 @sm:flex-row @sm:space-x-2 @sm:space-y-0">
                  <FormInput
                    control={form.control}
                    name="contact_person"
                    label="Event Contact Person"
                  />
                  <FormInput control={form.control} name="phone" label="Event Contact Phone" />
                </div>
              </section>

              <section className="space-y-3">
                <div className="flex flex-row">
                  <div className="grow">
                    <div className="relative">
                      <AddressLookup
                        onSelect={applyAddressLookup}
                        address={event.address}
                        searchArea={{
                          lon: event.franchise.longitude,
                          lat: event.franchise.latitude,
                        }}
                        className="absolute top-0 right-0 z-10"
                      />
                      <FormInput control={form.control} name="address" label="Event Address" />
                    </div>
                    <FormInput type="hidden" control={form.control} name="latitude" />
                    <FormInput type="hidden" control={form.control} name="longitude" />
                  </div>
                  <PlusIcon
                    className={cn(
                      'h-4 w-4 mt-3 mx-1 text-black transition-all',
                      showAddressLine2 ? 'rotate-45' : '',
                    )}
                    onClick={() => setShowAddressLine2(!showAddressLine2)}
                  />
                </div>
                {showAddressLine2 && (
                  <FormInput
                    control={form.control}
                    name="address_line2"
                    label="Event Address Line 2"
                  />
                )}
                <div className="flex flex-col md:flex-row md:space-x-2">
                  <FormInput control={form.control} name="city" label="City" />
                  <div className="w-48">
                    <FormStateSelect
                      control={form.control}
                      name="state"
                      label="State"
                      style="abbr"
                    />
                  </div>
                  <div className="w-64">
                    <FormInput control={form.control} name="zipcode" label="Zip Code" />
                  </div>
                </div>
              </section>

              <section className="space-y-3">
                <div className="flex flex-col w-full space-y-3 @sm:flex-row @sm:space-x-2 @sm:space-y-0">
                  {eventTypesIsLoading && <Placeholder size="full" className="h-10" />}
                  {eventTypeOptions && (
                    <FormSelect
                      control={form.control}
                      name="event_type_id"
                      options={eventTypeOptions}
                      label="Event Type"
                    />
                  )}
                  <FormInput
                    control={form.control}
                    type="number"
                    name="guest_count"
                    label="# of Guests"
                  />
                </div>
                <FormTextarea
                  control={form.control}
                  name="special_instructions"
                  label="Special Instructions"
                  className="resize-y overflow-y-scroll"
                />
              </section>
            </fieldset>
          </form>
        </FormAutosave>
      </Form>
      <section className="py-4">
        {event.customer && (
          <VipManager customer={event.customer} record={event} mode="event" readonly={readonly} />
        )}
      </section>
    </>
  );
};
