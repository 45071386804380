import { useUpdateLead } from '@/api/leads/leads/updateLead';
import { Button } from '@/components/ui/elements/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/elements/dropdown-menu';
import { Lead } from '@/types';
import { cn, todayAsCalendarDate } from '@/utils/format';
import { isMobile } from 'react-device-detect';

interface FollowUpContextMenuProps {
  lead: Lead;
  onSuccess?: () => void;
}

const calculateFollowUp = (days = 1) => {
  return {
    followup_at: todayAsCalendarDate().add({ days }).toString(),
    status: 'NeedsCallBack',
  };
};

export const FollowUpContextMenu = ({ lead, onSuccess }: FollowUpContextMenuProps) => {
  const { mutate: updateLead } = useUpdateLead();

  const FollowUpActions = [
    {
      label: 'Follow Up - Tomorrow',
      onClick: () => updateLead({ id: lead.id, data: calculateFollowUp(1) }, { onSuccess }),
    },
    {
      label: 'Follow Up - Two days',
      onClick: () => updateLead({ id: lead.id, data: calculateFollowUp(2) }, { onSuccess }),
    },
    {
      label: 'Follow Up - Next Week',
      onClick: () => updateLead({ id: lead.id, data: calculateFollowUp(7) }, { onSuccess }),
    },
    {
      label: 'Follow Up - Two Weeks',
      onClick: () => updateLead({ id: lead.id, data: calculateFollowUp(14) }, { onSuccess }),
    },
    {
      label: 'Follow Up - Next Month',
      onClick: () => updateLead({ id: lead.id, data: calculateFollowUp(28) }, { onSuccess }),
    },
    {
      label: 'Follow Up - 3 Months',
      onClick: () => updateLead({ id: lead.id, data: calculateFollowUp(90) }, { onSuccess }),
    },
  ];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size={isMobile ? 'xs' : 'sm'}>
          <span className="flex flex-col ">
            <span className={cn('whitespace-nowrap', lead.followup_at ? 'text-xs leading-3' : '')}>
              Follow Up
            </span>
            {lead.followup_at && (
              <span className="hidden md:inline whitespace-nowrap text-xs pl-1">
                {lead.followup_at}
              </span>
            )}
          </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {FollowUpActions.map((action, i) => {
          return (
            <DropdownMenuItem
              key={`ddm-archive-${i}`}
              onClick={action.onClick}
              className="cursor-pointer"
            >
              {action.label}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
