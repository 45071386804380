import { PolicyCheck } from '@/features/auth/authorization';

export const MaintenancePolicies: Record<string, PolicyCheck> = {
  'equipment.maintenance.view': ({ acl, user, meta }) => {
    return acl.equipment.maintenance.view;
  },
  'equipment.maintenance.create': ({ acl, user, meta }) => {
    return acl.equipment.maintenance.create;
  },
  'equipment.maintenance.update': ({ acl, user, meta }) => {
    return acl.equipment.maintenance.edit;
  },
  'equipment.maintenance.delete': ({ acl, user, meta }) => {
    return acl.equipment.maintenance.delete;
  },
};
