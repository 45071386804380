import { useLocationTravelCharges } from '@/api/locations/locations/getLocationTravelCharges';
import { LocationContext } from '@/providers/location';
import { FeatureCollection, GeoJsonProperties } from 'geojson';
import { createContext, ReactNode, useContext, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useMapStore } from './MapStateStore';

type MapContextData = {
  travelChargeLayers: FeatureCollection | undefined;
  heatmap: FeatureCollection | undefined;
  setHeatmap: <T>(heatmap: FeatureCollection | undefined) => void;
};

type MapProviderProps = {
  children: ReactNode;
};

export const MapContext = createContext({} as MapContextData);

export function MapProvider({ children }: MapProviderProps) {
  const { activeLocationId } = useContext(LocationContext);
  const { activeMapLocationId } = useMapStore(
    useShallow((state) => ({
      activeMapLocationId: state.activeMapLocationId,
    })),
  );

  // Heat Maps
  const [heatmap, setHeatmap] = useState<FeatureCollection | undefined>(undefined);

  // Layers
  const { data: travelChargeLayers } = useLocationTravelCharges({
    id: activeMapLocationId ?? activeLocationId,
    config: {
      select: (data) => {
        data.features =
          data.features.map((feature) => {
            feature.properties = applyTravelZoneColors(feature.properties);
            return feature;
          }) ?? [];

        return data;
      },
      staleTime: 1000 * 60 * 60,
      refetchOnWindowFocus: false,
    },
  });

  return (
    <MapContext.Provider
      value={{
        travelChargeLayers,
        heatmap,
        setHeatmap,
      }}
    >
      {children}
    </MapContext.Provider>
  );
}

const applyTravelZoneColors = (properties: GeoJsonProperties): GeoJsonProperties => {
  let color = '#7aa52b';

  switch (properties?.type) {
    case 'refer':
      color = '#ff7200';
      break;
    case 'exclusion':
      color = '#FF0000';
      break;
    default:
      switch (properties?.asset_type_id) {
        case 8:
          color = '#665cbe';
          break;
        case 6:
          color = '#e4000f';
          break;
        case 4:
          color = '#eac4f8';
          break;
        case 3:
          color = '#b9def6';
          break;
        case 2:
          color = '#f6e7b7';
          break;
        default:
          color = '#7aa52b';
          break;
      }
  }

  return {
    ...properties,
    color,
  };
};
