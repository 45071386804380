import { useSetPriority } from '@/api/leads/leads/updateLead';
import { ModelID } from '@/types';

export const useFlagPriority = () => {
  const { mutate: setPriority } = useSetPriority();

  const flag = (leadId: ModelID, is_priority: boolean) => {
    setPriority({ id: leadId, is_priority });
  };

  return {
    flag,
  };
};
