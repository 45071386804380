import { cn } from '@/utils/format';
import * as React from 'react';
import { ErrorResult } from '../ErrorResult';
import NoResults from '../NoResults/NoResults';
import { TablePlaceholder } from '../Placeholder/Placeholder';

type TableColumn<Entry> = {
  title: string;
  field: keyof Entry;
  className?: string;
  Cell?({ entry }: { entry: Entry }): React.ReactElement;
};

export type TableProps<Entry> = {
  data: Entry[];
  columns: TableColumn<Entry>[];
  label?: string;
  isPending?: boolean;
  error?: any;
  className?: string;
  tableClassName?: string;
  thClassName?: string;
  trClassName?: string;
  tdClassName?: string;
  hasSummary?: boolean;
};

export const Table = <Entry,>({
  data,
  columns,
  label,
  isPending,
  error,
  className,
  tableClassName,
  thClassName,
  trClassName,
  tdClassName,
  hasSummary = false,
}: TableProps<Entry>) => {
  if (isPending) return <TablePlaceholder />;
  if (error) return <ErrorResult error={error} />;
  if (!data?.length) return <NoResults label={label} />;

  return (
    <div className={cn('mt-8 flow-root @container', className)}>
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full py-2 align-middle">
          <table className={cn('min-w-full divide-y divide-gray-300', tableClassName)}>
            <thead>
              <tr>
                {columns.map((column, index) => (
                  <th
                    key={column.title + index}
                    scope="col"
                    className={cn(
                      'px-3 py-3.5 md:px-6 text-left text-sm font-semibold text-gray-900',
                      thClassName,
                      column.className ?? '',
                    )}
                  >
                    {column.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {data.map((entry, entryIndex) => (
                <tr
                  key={`tblrow-${label}-${entryIndex}`}
                  className={cn(
                    'even:bg-gray-50/50',
                    trClassName,
                    entryIndex === 0 && hasSummary && 'border-b-4',
                  )}
                >
                  {columns.map(({ Cell, field, title, className }, columnIndex) => (
                    <td
                      key={title + columnIndex}
                      className={cn(
                        'whitespace-nowrap py-2 px-3 md:py-4 md:px-6 text-sm',
                        columnIndex === 0 ? 'font-medium text-gray-900' : 'text-sm text-gray-500',
                        tdClassName,
                        className,
                      )}
                    >
                      {Cell ? <Cell entry={entry} /> : (entry[field] as any)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
