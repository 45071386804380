import { LocationUserAccess } from '@/types';
import { EventContentArg } from '@fullcalendar/core';
import { BackgroundChip } from './Chips/BackgroundChip';
import { BlackoutChip } from './Chips/BlackoutChip';
import { DefaultChip } from './Chips/DefaultChip';
import { EventChip } from './Chips/EventChip';
import { InactiveChip } from './Chips/InactiveChip';
import { LeadChip } from './Chips/LeadChip';
import { NoteChip } from './Chips/NoteChip';
import { TravelChip } from './Chips/TravelChip';

type RenderEventContentProps = {
  eventInfo: EventContentArg;
  staff: LocationUserAccess[];
  handleAddStaff: CallableFunction;
};

export const RenderEventContent = ({
  eventInfo,
  staff,
  handleAddStaff,
}: RenderEventContentProps) => {
  const { cal_event_type, activity_id } = eventInfo.event.extendedProps;

  switch (cal_event_type) {
    case 'background':
      return <BackgroundChip eventInfo={eventInfo} />;
    case 'inactive':
      return <InactiveChip eventInfo={eventInfo} />;
    case 'travel':
      return <TravelChip eventInfo={eventInfo} />;
    case 'blackout':
      return <BlackoutChip eventInfo={eventInfo} />;
    case 'note':
      return <NoteChip eventInfo={eventInfo} />;
    case 'lead':
      return <LeadChip eventInfo={eventInfo} />;
    case 'event':
      return (
        <EventChip eventInfo={eventInfo} staffOptions={staff} handleAddStaff={handleAddStaff} />
      );
    default:
      return <DefaultChip eventInfo={eventInfo} />;
  }
};
