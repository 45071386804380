import { PolicyGate } from '@/features/auth/authorization';
import { CustomerListForm } from '@/features/customers/components/CustomerListForm';
import { useCustomerLists } from '@/hooks/useCustomerLists';
import { LocationContext } from '@/providers/location';
import { Customer, CustomerList, ModelID } from '@/types';
import { UserPlusIcon } from '@heroicons/react/24/outline';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { useContext, useState } from 'react';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/elements/dialog';
import {
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '../ui/elements/dropdown-menu';

type CustomerListDropdownMenuGroupProps = DropdownMenuPrimitive.DropdownMenuGroupProps &
  React.RefAttributes<HTMLDivElement> & {
    disabled?: boolean;
    actionText?: string;
  } & (
    | {
        customer: Customer;
        customerIds?: never;
        onListClick?: never;
      }
    | {
        customer?: never;
        customerIds: ModelID[];
        onListClick?: never;
      }
    | {
        customer?: never;
        customerIds?: never;
        onListClick: (list: CustomerList) => void;
      }
  );

export const CustomerListDropdownMenuGroup = ({
  customer,
  customerIds,
  onListClick,
  disabled = false,
  actionText,
  ...props
}: CustomerListDropdownMenuGroupProps) => {
  const { activeLocationId } = useContext(LocationContext);
  const [showNewListDialog, setShowNewListDialog] = useState(false);
  const {
    lists: customerlists,
    addCustomerToList,
    addCustomersToList,
  } = useCustomerLists({
    location: activeLocationId,
  });

  const handleAddToList = (list: CustomerList) => {
    if (onListClick) return onListClick(list);

    if (customer) {
      addCustomerToList(customer, list);
    }

    if (customerIds) {
      addCustomersToList(customerIds, list);
    }
  };

  const onCreate = (list: CustomerList) => {
    handleAddToList(list);
    setShowNewListDialog(false);
  };

  return (
    <PolicyGate policy="customers.lists.view">
      <DropdownMenuGroup {...props}>
        <DropdownMenuSub>
          <DropdownMenuSubTrigger disabled={disabled}>
            <UserPlusIcon className="mr-2 h-4 w-4" />
            <span>{actionText || 'Add Customer to List'}</span>
          </DropdownMenuSubTrigger>
          <DropdownMenuPortal>
            <DropdownMenuSubContent>
              {customerlists?.map((list, i) => (
                <DropdownMenuItem
                  key={`drop-cust-list${list.id}${customer?.id}${i}`}
                  onClick={() => handleAddToList(list)}
                  className="cursor-pointer"
                >
                  <span>{list.name}</span>
                </DropdownMenuItem>
              ))}
              <DropdownMenuSeparator />
              <Dialog open={showNewListDialog} onOpenChange={setShowNewListDialog}>
                <DialogTrigger className="w-full">
                  <DropdownMenuItem onSelect={(e) => e.preventDefault()} className="cursor-pointer">
                    <span>Create New List...</span>
                  </DropdownMenuItem>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Create New Customer List</DialogTitle>
                  </DialogHeader>
                  <CustomerListForm onCreate={onCreate} />
                </DialogContent>
              </Dialog>
            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>
      </DropdownMenuGroup>
    </PolicyGate>
  );
};
