'use client';

import { useHeartbeat } from '@/api/locations/useHeartbeat';
import { ScrollArea } from '@/components/ui/elements/scroll-area';
import { Sheet, SheetContent } from '@/components/ui/elements/sheet';
import { MainMenuItems } from '@/config/mainmenu';
import { PolicyGate, useAuthorization } from '@/features/auth/authorization';
import { LocationContext } from '@/providers/location';
import { cn } from '@/utils/format';
import { Link, LinkOptions } from '@tanstack/react-router';
import * as React from 'react';
import { useContext } from 'react';

interface MobileNavProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function MobileNav({ open, setOpen }: MobileNavProps) {
  const { waiting, eventsWaiting, unreadMessages } = useHeartbeat();
  const { activeLocation } = useContext(LocationContext);
  const { checkPolicy } = useAuthorization();

  const menuItems = MainMenuItems.filter((item) => {
    return item.access_rights.every((right: string) => {
      return checkPolicy({ policy: right, meta: { location: activeLocation } });
    });
  });

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetContent side="left" className="pr-0">
        <MobileLink to="/" className="flex items-center" setOpen={setOpen}>
          <span className="font-bold">GameTruck Portal</span>
        </MobileLink>
        <ScrollArea className="my-4 h-[calc(100vh-8rem)] pb-10 pl-6">
          <div className="flex flex-col space-y-3">
            {menuItems?.map(
              (item) =>
                item.path && (
                  <MobileLink
                    key={item.path}
                    to={item.path}
                    search={item.search}
                    setOpen={setOpen}
                    className="flex items-center"
                  >
                    <span>{item.label}</span>
                    <PolicyGate policy="messages.view">
                      {item.label === 'Messages' && !!unreadMessages && (
                        <div className="bg-red-600 text-white rounded-3xl p-0.5 w-full max-w-8 mx-2 text-center text-xs font-bold">
                          {unreadMessages}
                        </div>
                      )}
                      {item.label === 'Leads' && !!waiting && (
                        <div className="bg-red-600 text-white rounded-3xl p-0.5 w-full max-w-8 mx-2 text-center text-xs font-bold">
                          {waiting}
                        </div>
                      )}
                      {item.label === 'Events' && !!eventsWaiting && (
                        <div className="bg-red-600 text-white rounded-3xl p-0.5 w-full max-w-8 mx-2 text-center text-xs font-bold">
                          {eventsWaiting}
                        </div>
                      )}
                    </PolicyGate>
                  </MobileLink>
                ),
            )}
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
}

interface MobileLinkProps extends LinkOptions {
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
  className?: string;
}

function MobileLink({ to, setOpen, className, children, ...props }: MobileLinkProps) {
  return (
    <Link to={to} onClick={() => setOpen(false)} className={cn(className)} {...props}>
      {children}
    </Link>
  );
}
