'use client';

import { RadioGroup, RadioGroupItem } from '@/components/ui/elements/radio-group';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form/form';
import { VariantProps } from 'cva';
import { ClassProp } from 'cva/dist/types';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { formFieldVariants } from './FormInput';

export type RadioGroupOption = {
  label: string;
  value: string | number;
};

type FormRadioGroupProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  VariantProps<typeof formFieldVariants> &
  ClassProp & {
    options: RadioGroupOption[];
    label?: string;
    description?: string;
  };

export const FormRadioGroup = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  label,
  description,
  options,
  ...props
}: FormRadioGroupProps<TFieldValues, TName>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="space-y-3">
          {label && <FormLabel>{label}</FormLabel>}
          <FormControl>
            <RadioGroup
              onValueChange={field.onChange}
              defaultValue={field.value?.toString()}
              className="flex flex-col space-y-4 md:space-y-2"
            >
              {options.map((option) => (
                <FormItem
                  key={`rg-${label}-${option.value}}`}
                  className="flex items-center space-x-3 space-y-0"
                >
                  <FormControl>
                    <RadioGroupItem value={option.value?.toString()} />
                  </FormControl>
                  <FormLabel className="font-normal">{option.label}</FormLabel>
                </FormItem>
              ))}
            </RadioGroup>
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
      {...props}
    />
  );
};
