import { useCreateCustomerNote } from '@/api/customers/notes/createCustomerNote';
import { FormTextarea } from '@/components/Form';
import { Button } from '@/components/ui/elements/button';
import { Form } from '@/components/ui/form/form';
import { Customer } from '@/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const schema = z.object({
  message: z.string().min(3, 'Required'),
});

type NewNoteFormProps = {
  customer: Customer;
  className?: string;
};

const NewNoteForm = ({ customer, className = '' }: NewNoteFormProps) => {
  const { mutate: addNote } = useCreateCustomerNote();

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: { message: '' },
  });

  const handleNewNote = (data: z.infer<typeof schema>) => {
    addNote(
      {
        data: { customer_id: customer.id, message: data.message },
      },
      {
        onSuccess: () => form.reset(),
      },
    );
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleNewNote)} className={className}>
        <div className="flex flex-col bg-white border-gray-100 border rounded-md">
          <div className="relative">
            <FormTextarea
              control={form.control}
              name="message"
              className="h-24 border-0 shadow-none"
              placeholder="Enter notes here..."
            />
            <div className="absolute bottom-2 right-2">
              <Button type="submit" size="sm" variant="outline">
                Add Note
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
};

export default NewNoteForm;
