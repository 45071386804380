import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Customer, ModelID } from '@/types';
import { get, getPaginated, PaginatedResource } from '@/utils/api';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { CustomerSearchParams, featureKeys, featurePath } from '.';

type QueryFnType = typeof fetchCustomers;
type PagedQueryFnType = typeof fetchPagedCustomers;

type SearchParams = CustomerSearchParams & {
  query_all_locations?: boolean;
};

type UseCustomersOptions = {
  location: ModelID;
  search?: SearchParams;
  config?: QueryConfig<QueryFnType>;
};

type UsePagedCustomersOptions = {
  location: ModelID;
  search?: SearchParams;
  config?: QueryConfig<PagedQueryFnType>;
};

export const fetchCustomers = (location: ModelID, search: SearchParams = {}) =>
  get<Customer[]>(`${featurePath}?franchise_id=${location}`, search);

export const fetchPagedCustomers = (location: ModelID, search?: SearchParams) =>
  getPaginated<Customer>(`${featurePath}?franchise_id=${location}`, search);

export const useCustomers = ({ location, search = {}, config = {} }: UseCustomersOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: featureKeys.list(location, search),
    queryFn: () => fetchCustomers(location, search),
  });
};

export const usePagedCustomers = ({ location, search, config = {} }: UsePagedCustomersOptions) => {
  return useQuery<ExtractFnReturnType<PagedQueryFnType>, Error, PaginatedResource<Customer>, any>({
    ...config,
    queryKey: featureKeys.list(location, search),
    queryFn: () => fetchPagedCustomers(location, search),
    placeholderData: keepPreviousData,
    staleTime: 1000 * 5,
  });
};
