import { useSearchSettingStore } from '@/stores/search';
import { useParams } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { LeadResults } from '../components/LeadResults';

export const LeadSearchPage = () => {
  const { lastFilter, setLastFilter } = useSearchSettingStore(
    useShallow((state) => ({
      lastFilter: state.filter,
      setLastFilter: state.setFilter,
    })),
  );

  const { filter: activeFilter } = useParams({
    strict: false,
  });

  useEffect(() => {
    if (activeFilter && activeFilter !== lastFilter) {
      setLastFilter(activeFilter);
    }
  }, [lastFilter, activeFilter, setLastFilter]);

  return <LeadResults />;
};
