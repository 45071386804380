import { LeadSearchParams } from '@/api/leads/leads';
import { FormDate, FormInput, FormSelect } from '@/components/Form';
import { FormMultiSelect } from '@/components/Form/FormMultiSelect';
import { FormSwitch } from '@/components/Form/FormSwitch';
import SearchForm, { defaultSearchSchema } from '@/components/SearchForm/SearchForm';
import { Button } from '@/components/ui/elements/button';
import {
  CUSTOMER_ORGANIZATION_TYPES,
  HOW_HEARD_OPTIONS,
  LEAD_CLOSE_REASONS,
  LEAD_STATUS_OPTIONS,
} from '@/config';
import { EventType } from '@/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';

const schema = defaultSearchSchema.merge(
  z.object({
    owned: z.coerce.boolean().nullish(),
    name: z.string().nullish(),
    customer_name: z.string().nullish(),
    phone: z.string().nullish(),
    email: z.string().nullish(),
    address_city: z.string().nullish(),
    date_wanted_begin: z.string().nullish(),
    date_wanted_end: z.string().nullish(),
    organization_type: z.string().nullish(),
    event_type_ids: z
      .array(
        z.object({
          value: z.string(),
          label: z.string(),
          checked: z.boolean().default(false),
        }),
      )
      .nullish(),
    lead_id: z.string().nullish(),
    status: z.string().nullish(),
    close_reason: z.string().nullish(),
    how_heard: z.string().nullish(),
  }),
);

type LeadSearchFormProps = {
  onSubmit: (data: any) => void;
  onReset: () => void;
  refetch: () => void;
  eventTypes: EventType[];
  defaultValues: LeadSearchParams;
  recordsFound?: number;
  children?: React.ReactNode;
};

export const LeadSearchForm = ({
  onSubmit,
  onReset,
  refetch,
  eventTypes,
  defaultValues,
  recordsFound,
  children,
}: LeadSearchFormProps) => {
  const eventTypeArrayDefaults =
    eventTypes?.map((type) => {
      return {
        value: type.event_type_id.toString(),
        label: type.name,
        checked: false,
      };
    }) ?? [];

  const defaultEventTypes =
    eventTypeArrayDefaults?.map((type) => {
      return {
        ...type,
        checked: defaultValues.event_type_ids?.includes(type.value) ?? false,
      };
    }) ?? [];

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      keyword: '',
      ...defaultValues,
      owned: defaultValues.owned ?? false,
      name: defaultValues.name ?? '',
      customer_name: defaultValues.customer_name ?? '',
      phone: defaultValues.phone ?? '',
      email: defaultValues.email ?? '',
      address_city: defaultValues.address_city ?? '',
      date_wanted_begin: defaultValues.date_wanted_begin ?? null,
      date_wanted_end: defaultValues.date_wanted_end ?? null,
      organization_type: defaultValues.organization_type ?? '',
      lead_id: defaultValues.lead_id?.toString() ?? '',
      event_type_ids: defaultEventTypes,
      status: defaultValues.status ?? '',
      close_reason: defaultValues.close_reason ?? '',
      how_heard: defaultValues.how_heard ?? '',
    },
  });
  const eventTypeFieldArray = useFieldArray({
    control: form.control,
    name: 'event_type_ids',
  });

  const setMyLeads = (value: boolean) => {
    onSubmit({ ...form.getValues(), owned: value });
  };

  const OrganizationTypeOptions = CUSTOMER_ORGANIZATION_TYPES;
  const StatusOptions = LEAD_STATUS_OPTIONS;
  const CloseReasonOptions = LEAD_CLOSE_REASONS.map((reason) => {
    return { value: reason, label: reason };
  });

  return (
    <SearchForm
      form={form}
      onReset={() => {
        onReset();
        eventTypeFieldArray.replace(eventTypeArrayDefaults);
      }}
      onSubmit={onSubmit}
      hasAdvanced
      hiddenKeys={['owned']}
      className="m-4"
    >
      <SearchForm.KeywordInput />
      <SearchForm.SearchChips
        formatters={[
          {
            key: 'event_type_ids',
            resetFn: () => eventTypeFieldArray.replace(eventTypeArrayDefaults),
            formatter: (value: string[]): string => {
              const label = eventTypes
                ?.filter((type) => value.includes(type.event_type_id.toString()))
                .map((type) => type.name);
              return label?.join(', ') ?? '';
            },
          },
        ]}
      />
      <SearchForm.AdvancedOptions>
        <div className="flex flex-col space-y-3">
          <FormInput control={form.control} name="name" label="Event Name" />
          <div className="grid lg:grid-cols-3 gap-2">
            <FormInput control={form.control} name="customer_name" label="Customer Name" />
            <FormInput control={form.control} name="phone" label="Phone" />
            <FormInput control={form.control} name="email" label="Email" />
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-2">
            <FormInput control={form.control} name="address_city" label="Address / City" />
            <FormInput control={form.control} name="lead_id" label="Lead/Quote #" />
            <FormDate control={form.control} name="date_wanted_begin" label="Date Wanted (From)" />
            <FormDate control={form.control} name="date_wanted_end" label="Date Wanted (Until)" />
          </div>
          <div className="grid md:grid-cols-2 gap-3">
            <FormMultiSelect<z.infer<typeof schema>, 'event_type_ids'>
              control={form.control}
              fieldArray={eventTypeFieldArray}
              name="event_type_ids"
              label="Event Types"
              placeholder="All"
            />
            <FormSelect
              control={form.control}
              name="organization_type"
              options={OrganizationTypeOptions}
              label="Organization Type"
            />
            <FormSelect
              control={form.control}
              name="status"
              options={StatusOptions}
              label="Status"
            />
            <FormSelect
              control={form.control}
              name="close_reason"
              options={CloseReasonOptions}
              label="Lost Reason"
            />
            <FormSelect
              control={form.control}
              name="how_heard"
              options={HOW_HEARD_OPTIONS}
              label="How Heard"
            />
          </div>
          <div className="text-right space-x-2">
            <Button type="submit">Search</Button>
          </div>
        </div>
      </SearchForm.AdvancedOptions>

      <div className="flex flex-col md:flex-row space-y-3 md:space-x-2 md:space-y-0 items-start md:items-center justify-between pt-3">
        <span>
          <FormSwitch
            control={form.control}
            name="owned"
            label="My Leads Only"
            onChange={setMyLeads}
          />
        </span>
        {children}
        <div className="flex flex-row space-x-2 items-center">
          <SearchForm.RecordsFound recordCount={recordsFound} />
          {refetch && <SearchForm.Refetch refetch={refetch} />}
          <SearchForm.OrderBy
            options={[
              { value: 'waiting_since', label: 'Time Waiting' },
              { value: 'created_at', label: 'Date Submitted' },
              { value: 'updated_at', label: 'Last Update' },
              { value: 'date_wanted', label: 'Date Requested' },
              { value: 'followup_at', label: 'Follow Up Date' },
            ]}
          />
          <SearchForm.PageLimit />
          <SearchForm.OrderDirection />
        </div>
      </div>
    </SearchForm>
  );
};
