import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Location, ModelID } from '@/types';
import { get } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { featureKeys, featurePath, LocationSearchParams } from '.';

type QueryFnType = typeof fetchLocations;
type SimpleQueryFnType = typeof fetchSimpleLocations;

type UseLocationsOptions = {
  location: ModelID;
  search?: LocationSearchParams;
  config?: QueryConfig<QueryFnType>;
};

type UseSimpleLocationsOptions = {
  location: ModelID;
  search?: LocationSearchParams;
  config?: QueryConfig<SimpleQueryFnType>;
};

export type SimpleLocation = {
  id: ModelID;
  franchise_id: ModelID;
  name: string;
  active: boolean;
};

export const fetchLocations = (location: ModelID, search: LocationSearchParams = {}) =>
  get<Location[]>(`${featurePath}?franchise_id=${location}`, search);

export const fetchSimpleLocations = (location: ModelID, search: LocationSearchParams = {}) =>
  get<SimpleLocation[]>(`${featurePath}/simple-list?franchise_id=${location}`, search);

export const useLocations = ({ location, search = {}, config = {} }: UseLocationsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: featureKeys.list(location, search),
    queryFn: () => fetchLocations(location, search),
  });
};

export const useSimpleLocations = ({
  location,
  search = {},
  config = {},
}: UseSimpleLocationsOptions) => {
  return useQuery<ExtractFnReturnType<SimpleQueryFnType>>({
    ...config,
    queryKey: featureKeys.simple(location, search),
    queryFn: () => fetchSimpleLocations(location, search),
  });
};
