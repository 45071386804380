import { ModelID, SearchParams } from '@/types';
import { PaginatedResource } from '@/utils/api';
import { MutateOptions, useMutation, UseMutationOptions } from '@tanstack/react-query';
import { queryClient } from '.';

export type DeleteDTO<T extends { id: ModelID; franchise_id?: ModelID }, S = SearchParams> = {
  id: ModelID;
  data?: Partial<T>;
  params?: S;
};

export type UseDeleteMutationProps<TModel extends { id: ModelID; franchise_id?: ModelID }> = {
  onMutate?: (value: DeleteDTO<TModel>) => void;
  onError?: (error: unknown, value: Partial<TModel>, context?: unknown) => void;
  onSuccess?: (response: TModel) => void;
  config?: UseMutationOptions<TModel>;
};

type DeleteMutationFactoryProps<
  TDataDTO,
  TModel extends { id: ModelID; franchise_id?: ModelID },
> = UseDeleteMutationProps<TModel> & {
  mutationFn: (variables: TDataDTO, options: MutateOptions) => unknown;
  invalidateKeys?: any;
};

export const genericDeleteOnMutateStrategy = async <
  T extends { id: ModelID; franchise_id?: ModelID },
  S,
>(
  invalidationKeys: any,
  deletedData: DeleteDTO<T, S>,
) => {
  await queryClient.cancelQueries(invalidationKeys.all);

  const previousData = queryClient.getQueryData<T>(
    invalidationKeys.lists(deletedData.data?.franchise_id),
  );

  if (previousData) {
    queryClient.setQueryData(
      invalidationKeys.lists(deletedData.data?.franchise_id),
      (previousData: T[] | undefined) => previousData?.filter((item) => item.id !== deletedData.id),
    );
  }

  return { previousData };
};

export const genericDeleteErrorOnMutateStrategy = (
  _: any,
  __: any,
  context: any,
  invalidationKeys: any,
) => {
  if (context?.previousData) {
    queryClient.setQueryData(
      invalidationKeys.detail(context.previousData.id),
      context.previousData,
    );
  }
};

export const defaultDeleteMergeStrategy = <T extends { id: ModelID; franchise_id?: ModelID }>(
  invalidationKeys: any,
  data: T,
) => {
  queryClient.setQueryData(invalidationKeys.detail(+data.id), data);

  queryClient.setQueriesData(
    { queryKey: invalidationKeys.lists(data.franchise_id) },
    (previous: T[] | undefined) => {
      if (!previous) return previous;

      return previous.filter((item) => item.id !== data.id);
    },
  );

  if (invalidationKeys.paged) {
    queryClient.setQueriesData(
      { queryKey: invalidationKeys.paged(data.franchise_id) },
      (previous: PaginatedResource<T> | undefined) => {
        if (!previous) return previous;

        previous.results = previous.results.filter((item) => item.id !== data.id);

        return previous;
      },
    );
  }
};

export const useDeleteMutationFactory = <
  TDataDTO,
  TModel extends { id: ModelID; franchise_id?: ModelID },
>(
  options: DeleteMutationFactoryProps<TDataDTO, TModel>,
) => {
  const { mutationFn, onMutate, onError, onSuccess, invalidateKeys = {}, config } = options;

  const mutationConfig = Object.assign(
    {
      onMutate: (deleteData: DeleteDTO<TModel>) => {
        if (onMutate) {
          onMutate(deleteData);
        } else {
          genericDeleteOnMutateStrategy(invalidateKeys, deleteData);
        }
      },

      onError: (_: unknown, __: any, context: any) => {
        if (onError) {
          onError(_, __, context);
        } else {
          genericDeleteErrorOnMutateStrategy(_, __, context, invalidateKeys);
        }
      },

      onSuccess: (data: TModel) => {
        queryClient.invalidateQueries({ queryKey: invalidateKeys.all });
        defaultDeleteMergeStrategy(invalidateKeys, data);
        if (onSuccess) {
          onSuccess(data);
        }
      },

      mutationFn,
    },
    config,
  );

  return useMutation<TModel, unknown, TDataDTO, unknown>(
    mutationConfig as UseMutationOptions<TModel, unknown, TDataDTO, unknown>,
  );
};
