import { cn } from '@/utils/format';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

interface CopyToClipboardProps {
  value: string | number;
  children?: React.ReactNode;
}

export const CopyToClipboard = ({ value, children }: CopyToClipboardProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleOnClick = () => {
    navigator.clipboard.writeText(value.toString());
    setShowConfirmation(true);
  };

  return (
    <span className="relative">
      <button type="button" className="flex" onClick={handleOnClick}>
        {children ? (
          children
        ) : (
          <span className="px-1">
            <ClipboardDocumentCheckIcon className="h-4 w-4 text-gray-400 hover:text-gt-green-500" />
          </span>
        )}
      </button>
      <span
        className={cn(
          'absolute block -left-3 z-30',
          'bg-gray-200 text-xs p-1 rounded',
          'transition-opacity duration-1000 ease-in-out opacity-0',
          showConfirmation ? 'animate-pulse-once' : '',
        )}
      >
        Copied!
      </span>
    </span>
  );
};
