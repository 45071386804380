import { CalendarEventChipRenderProps } from '../types';
import { CalendarChip } from './CalendarChip';

export const DefaultChip = ({ eventInfo }: CalendarEventChipRenderProps) => {
  const { timeText } = eventInfo.event.extendedProps;

  return (
    <CalendarChip eventInfo={eventInfo} className="bg-gray-500 border-gray-500 text-gray-50">
      <span className="font-bold">{timeText}</span>
      <span>{eventInfo.event.title}</span>
    </CalendarChip>
  );
};
