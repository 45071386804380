import { Button } from '@/components/ui/elements/button';
import { email, messages } from '@/config';
import resetApp from '@/utils/reset-app';
import { EnvelopeIcon } from '@heroicons/react/24/outline';

function AppErrorBoundaryFallback() {
  return (
    <div className="flex min-h-full h-[100vh] flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-100">
      <div className="max-w-md mx-auto my-auto">
        <h5 className="max-w-sm mx-auto py-3 text-lg text-center">{messages.app.crash.title}</h5>
        <div className="flex flex-col md:flex-row space-x-2 text-center items-center">
          <Button size="md" onClick={resetApp}>
            {messages.app.crash.options.reset}
          </Button>
          <h6 className="text-gray-500">or</h6>
          <Button variant="outline" size="md" asChild>
            <a href={`mailto: ${email}`} className="flex flex-row space-x-2">
              <span>
                <EnvelopeIcon className="h-4 w-4" />
              </span>
              <span>{messages.app.crash.options.email}</span>
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AppErrorBoundaryFallback;
