import {
  CreateDTO,
  useCreateMutationFactory,
  UseCreateMutationProps,
} from '@/lib/react-query/create';
import { ModelID, Pin } from '@/types';
import { post } from '@/utils/api';
import { featureKeys, featurePath, PinSearchParams } from '.';

interface PinLink {
  pinned_id: ModelID;
  pinned_type: 'lead' | 'event' | 'customer';
  note?: string | null;
}

export type CreatePinDTO = CreateDTO<PinLink, PinSearchParams>;

const pinItem = ({ data, params = {} }: CreatePinDTO) =>
  post<Pin>(`${featurePath}/pin-item`, data, params);

export const usePinItem = (options?: UseCreateMutationProps<Pin>) => {
  return useCreateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: pinItem,
    ...options,
  });
};
