import { Customer, ModelID, SearchParams } from '@/types';

export type Filter = 'all' | 'open-leads' | 'recent' | 'upcoming-bookings' | 'past' | 'frequent';
export type Sort = 'created_at' | 'updated_at' | 'followup_at' | 'customer_id';
export interface CustomerSearchParams extends SearchParams<Filter, Sort>, Partial<Customer> {
  similar?: string;
  organization_types?: string[];
  name?: string;
  customer_list_id?: ModelID;
  followup_at_begin?: string;
  followup_at_end?: string;
  lastcontact_at_begin?: string;
  lastcontact_at_end?: string;
}

export const featurePath = '/v4/customers/customer';

export const featureKeys = {
  all: [{ scope: 'customers' }] as const,

  lists: (location: ModelID) => [{ ...featureKeys.all[0], location, entity: 'list' }] as const,

  list: (location: ModelID, search: CustomerSearchParams = {}) =>
    [{ ...featureKeys.lists(location)[0], search }] as const,

  details: () => [{ ...featureKeys.all[0], entity: 'detail' }] as const,

  detail: (id: ModelID) => [{ ...featureKeys.details()[0], id }] as const,
};
