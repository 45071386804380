import { buttonVariants } from '@/components/ui/elements/button';
import { cn } from '@/utils/format';
import { Link as RouterLink, LinkOptions } from '@tanstack/react-router';
import { VariantProps } from 'cva';

export interface LinkProps extends LinkOptions, VariantProps<typeof buttonVariants> {
  className?: string;
  children?: React.ReactNode;
}

export const Link = ({ to, className, variant = 'link', size, ...props }: LinkProps) => {
  return (
    <RouterLink to={to} className={cn(buttonVariants({ variant, size }), className)} {...props} />
  );
};
