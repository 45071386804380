import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ModelID, ReferralCampaign } from '@/types';
import { get } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { featureKeys, featurePath, ReferralCampaignSearchParams } from '.';

type QueryFnType = typeof fetchReferralCampaigns;

type UseReferralCampaignsOptions = {
  location: ModelID;
  search?: ReferralCampaignSearchParams;
  config?: QueryConfig<QueryFnType>;
};

export const fetchReferralCampaigns = (
  location: ModelID,
  search: ReferralCampaignSearchParams = {},
) => get<ReferralCampaign[]>(`${featurePath}?franchise_id=${location}`, search);

export const useReferralCampaigns = ({
  location,
  search = {},
  config = {},
}: UseReferralCampaignsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: featureKeys.list(location, search),
    queryFn: () => fetchReferralCampaigns(location, search),
  });
};
