import { featureKeys as leadFeatureKeys } from '@/api/leads/leads';
import { queryClient } from '@/lib/react-query';
import {
  CreateDTO,
  useCreateMutationFactory,
  UseCreateMutationProps,
} from '@/lib/react-query/create';
import { Lead, LeadNote } from '@/types';
import { create } from '@/utils/api';
import { featureKeys, featurePath, LeadNoteSearchParams } from '.';

export type CreateLeadNoteDTO = CreateDTO<LeadNote, LeadNoteSearchParams>;

const createLeadNote = ({ data, params = {} }: CreateLeadNoteDTO) =>
  create<LeadNote>(`${featurePath}`, data, params);

export const useCreateLeadNote = (options?: UseCreateMutationProps<LeadNote>) => {
  return useCreateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: createLeadNote,
    ...options,
    onSuccess: (newData) => {
      queryClient.setQueriesData({ queryKey: featureKeys.lists() }, (previous: any) => [
        newData,
        ...previous,
      ]);
      queryClient.setQueriesData({ queryKey: leadFeatureKeys.all }, (previous: any) => {
        if (Array.isArray(previous)) {
          const remapped = previous.map((item) => {
            if (item.id === newData.lead_id) {
              item.notes = [newData, ...item.notes];
              return item;
            }
            return item;
          });

          return [...remapped];
        } else if (previous.paginator && previous.results) {
          const results = previous.results.map((item: Lead) => {
            if (item.id === newData.lead_id) {
              item.notes = [newData, ...item.notes];
              return { ...item };
            }
            return { ...item };
          });

          return {
            ...previous,
            results: [...results],
          };
        } else {
          const recordNotes = previous.notes ? previous.notes : [];
          previous.notes = [newData, ...recordNotes];

          return { ...previous };
        }
      });
    },
  });
};
