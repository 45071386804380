import { AuthContext } from '@/providers/auth';
import {
  RealtimeChannel,
  RealtimePresenceJoinPayload,
  RealtimePresenceLeavePayload,
  RealtimePresenceState,
} from '@supabase/supabase-js';
import { useContext, useEffect, useReducer, useState } from 'react';
import useSupabase from './useSupabase';

interface UsePresenceOptions {
  channelName: string;
}

type Action =
  | { event: 'clear' }
  | { event: 'sync'; state: RealtimePresenceState<ClientState> }
  | RealtimePresenceJoinPayload<ClientState>
  | RealtimePresenceLeavePayload<ClientState>;

type ClientState = {
  id: string;
  full_name: string;
  first_name: string;
  last_name: string;
  online_at: string;
} & Record<string, any>;

const membersReducer = (state: RealtimePresenceState<ClientState>, action: Action) => {
  if (action.event === 'join') {
    if (state[action.key]) {
      state[action.key] = [...state[action.key], ...action.newPresences];
    } else {
      state[action.key] = action.newPresences;
    }

    return { ...state };
  }

  if (action.event === 'leave') {
    const clientsLeft = action.leftPresences.map((presence) => presence.presence_ref);

    if (state[action.key]) {
      state[action.key] = state[action.key].filter(
        (client) => !clientsLeft.includes(client.presence_ref),
      );
    }

    return { ...state };
  }

  if (action.event === 'sync') {
    return { ...action.state };
  }

  if (action.event === 'clear') {
    return {};
  }

  throw Error('Unknown action.');
};

export const usePresence = ({ channelName }: UsePresenceOptions) => {
  const client = useSupabase();
  const { user } = useContext(AuthContext);
  const [channel, setChannel] = useState<RealtimeChannel>();
  const [members, dispatchMembers] = useReducer(membersReducer, {});

  const setClientStatus = (updates = {}): ClientState => {
    return {
      ...updates,
      id: user?.id.toString() || crypto.randomUUID(),
      full_name: user?.full_name || 'Guest',
      first_name: user?.first_name || 'Guest',
      last_name: user?.last_name || '',
      online_at: new Date().toISOString(),
    };
  };

  const userStatus = setClientStatus();

  useEffect(() => {
    const channel = client
      .channel(channelName, {
        config: {
          presence: {
            key: userStatus.id,
          },
        },
      })
      .on('presence', { event: 'sync' }, () => {
        const presenceState = channel.presenceState<ClientState>();
        dispatchMembers({ event: 'sync', state: presenceState });
      })
      .on(
        'presence',
        { event: 'join' },
        ({ key, currentPresences, newPresences }: RealtimePresenceJoinPayload<ClientState>) => {
          dispatchMembers({
            event: 'join',
            key,
            currentPresences,
            newPresences,
          });
        },
      )
      .on(
        'presence',
        { event: 'leave' },
        ({ key, currentPresences, leftPresences }: RealtimePresenceLeavePayload<ClientState>) => {
          dispatchMembers({ event: 'leave', key, currentPresences, leftPresences });
        },
      );

    channel.subscribe(async (status) => {
      if (status !== 'SUBSCRIBED') {
        return;
      }

      const presenceTrackStatus = await channel.track(userStatus);
    });

    setChannel(channel);

    return () => {
      channel.untrack();
      channel.unsubscribe();
      setChannel(undefined);
    };
  }, []);

  const updateStatus = (newState: any) => {
    if (!channel) {
      return;
    }

    channel.track(setClientStatus(newState));
  };

  // Simple members list, ignoring multiple clients
  const membersList = Object.values(members).reduce((acc, val) => acc.concat(val), []);

  return {
    channel,
    members,
    here: membersList,
    updateStatus,
  };
};
