import {
  ContextMenu,
  ContextMenuCheckboxItem,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
  ContextMenuTrigger,
} from '@/components/ui/elements/context-menu';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/elements/tooltip';
import { LocationUserAccess } from '@/types';
import { cn } from '@/utils/format';
import { HandThumbUpIcon } from '@heroicons/react/24/solid';
import { TooltipPortal } from '@radix-ui/react-tooltip';
import { Link } from '@tanstack/react-router';
import { CalendarEventChipRenderProps } from '../types';
import { CalendarChip } from './CalendarChip';

type EventChipProps = CalendarEventChipRenderProps & {
  staffOptions: LocationUserAccess[];
  handleAddStaff: CallableFunction;
};

export const EventChip = ({ eventInfo, staffOptions, handleAddStaff }: EventChipProps) => {
  const { event_type, status, assetAbbr, timeText, tooltip, staff } = eventInfo.event.extendedProps;

  let colorClasses;
  switch (event_type) {
    case 'vg':
      colorClasses = 'bg-[green] border-[green]';
      break;
    case 'lt':
      colorClasses = 'bg-[orange] border-[orange]';
      break;
    case 'wt':
      colorClasses = 'bg-blue-400 border-blue-400';
      break;
    case 'gel':
      colorClasses = 'bg-[#6dbdd1] border-[#6dbdd1]';
      break;
    case 'ztag':
    case 'zt':
      colorClasses = 'bg-pink-400 border-pink-400';
      break;
    case 'gx':
    case 'gxs':
    case 'gxp':
    case 'gxm':
      colorClasses = 'bg-purple-400 border-purple-400';
      break;
    case 'fp':
      colorClasses = 'bg-teal-400 border-teal-400';
      break;
    default:
      colorClasses = 'bg-gray-400 border-gray-400';
  }

  const staffInitials = staff.map((name: string) => name.split(' ').map((n) => n[0])) as string[];
  const isHorizontal = eventInfo.view.type === 'resourceTimeline';
  const isLocallyVerified = !!eventInfo.event.extendedProps.local_verified_at;

  return (
    <ContextMenu>
      <ContextMenuTrigger>
        <CalendarChip
          eventInfo={eventInfo}
          className={cn(
            colorClasses,
            status === 'Confirmed' ? 'border-yellow-500' : '',
            status === 'Confirmed' && event_type === 'lt' ? 'border-yellow-600' : '',
          )}
        >
          <TooltipProvider delayDuration={100}>
            <Tooltip>
              <TooltipTrigger
                className={cn(
                  'w-full h-full flex flex-col items-start',
                  isHorizontal ? 'pl-1' : 'text-left',
                )}
              >
                <span className="whitespace-nowrap">{timeText}</span>
                {isHorizontal && <span>{assetAbbr}</span>}
                {!isHorizontal && staffInitials.length > 0 && (
                  <span className="flex space-x-1">
                    {staffInitials.map((initial, index) => (
                      <span
                        key={`initial-${index}`}
                        className="bg-white/75 text-black rounded text-[9px] font-semibold px-0.5 py-0.5 leading-none"
                      >
                        {initial}
                      </span>
                    ))}
                  </span>
                )}
                <span>{eventInfo.event.title}</span>
                {isLocallyVerified && (
                  <span className="absolute bottom-0 -left-[0px] pr-0.5 pt-0.5 rounded-tr-lg bg-yellow-500">
                    <HandThumbUpIcon className="w-3 h-3 text-yellow-900" />
                  </span>
                )}
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent className="text-xs" side={isHorizontal ? 'bottom' : 'left'}>
                  EID #{tooltip}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          </TooltipProvider>
        </CalendarChip>
      </ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuItem className="w-32 hover:cursor-pointer hover:bg-slate-100" asChild>
          <Link to={`/events/event/${eventInfo.event.extendedProps.event_id}/edit`}>
            Edit Event
          </Link>
        </ContextMenuItem>
        <ContextMenuSub>
          <ContextMenuSubTrigger>Staff</ContextMenuSubTrigger>
          <ContextMenuSubContent>
            {staffOptions.map((userAccess, index) => (
              <ContextMenuCheckboxItem
                key={`assign-${eventInfo.event.id}-${userAccess.user_id}-${index}`}
                checked={eventInfo.event.extendedProps.assignmentIds.includes(userAccess.user_id)}
                onClick={() =>
                  handleAddStaff(userAccess.user_id, eventInfo.event.extendedProps.activity_id)
                }
                className="w-36 hover:cursor-pointer hover:bg-slate-100"
              >
                {userAccess.user?.full_name || 'Error: Unknown User'}
              </ContextMenuCheckboxItem>
            ))}
          </ContextMenuSubContent>
        </ContextMenuSub>
      </ContextMenuContent>
    </ContextMenu>
  );
};
