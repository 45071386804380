import { Filter, LeadSearchParams } from '@/api/leads/leads';
import { useExportLeads } from '@/api/leads/leads/exportLeads';
import { usePagedLeads } from '@/api/leads/leads/getLeads';
import { useEventTypes } from '@/api/settings/event-types/getEventTypes';
import { ErrorResult, ListingPlaceholder, Paginator, Spinner } from '@/components/Elements';
import NoResults from '@/components/Elements/NoResults/NoResults';
import { ScrollableLayout } from '@/components/Layout';
import Page from '@/components/Layout/Page';
import { useMapStore } from '@/components/MapViewer/MapStateStore';
import { Button } from '@/components/ui/elements/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/elements/dropdown-menu';
import { PolicyGate } from '@/features/auth/authorization';
import { usePaginator } from '@/hooks/usePaginator';
import { useScrollToTop } from '@/hooks/useScrollToTop';
import { useSearchParams } from '@/hooks/useSearchParams';
import queryClient from '@/lib/react-query';
import { AuthContext } from '@/providers/auth';
import { LocationContext } from '@/providers/location';
import { useSearchSettingStore } from '@/stores/search';
import { Lead } from '@/types';
import { CloudArrowDownIcon } from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/react';
import { keepPreviousData } from '@tanstack/react-query';
import { AnyRoute, useParams } from '@tanstack/react-router';
import { useContext, useEffect, useRef } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { LeadResult } from './Results/LeadResult';
import { LeadSearchForm } from './Results/LeadSearchForm';
import { LeadStatsBar } from './Results/LeadStatsBar';

type RouteParams = {
  filter: Filter;
};

const searchDefaults = {
  orderBy: 'created_at',
  orderDir: 'desc',
  limit: 50,
  owned: false,
  page: 1,
} as LeadSearchParams;

export const LeadResults = () => {
  const { leadsMode } = useContext(AuthContext);
  const { activeLocationId } = useContext(LocationContext);
  const { data: eventTypes } = useEventTypes({ location: activeLocationId });
  const { setLastSearch } = useSearchSettingStore(
    useShallow((state) => ({
      setLastSearch: state.setSearch,
    })),
  );
  const { filter } = useParams<AnyRoute, RouteParams>({
    strict: false,
  });
  const { search, setSearchParams } = useSearchParams<LeadSearchParams>();

  const searchState = {
    ...searchDefaults,
    orderBy: filter && filter === 'inbox' ? 'waiting_since' : searchDefaults.orderBy,
    ...search,
    leadsMode,
  };

  const { addPin, clearPins, setCenter } = useMapStore(
    useShallow((state) => ({
      setCenter: state.setCenter,
      addPin: state.addPin,
      clearPins: state.clearPins,
    })),
  );

  const { isPending, isFetching, isError, data, error, refetch } = usePagedLeads({
    location: activeLocationId,
    search: {
      ...searchState,
      page: search.page ?? 1,
      filter: filter || 'inbox',
    },
    config: {
      placeholderData: keepPreviousData,
      staleTime: 1000 * 60,
      refetchOnWindowFocus: true,
      // Find a way to not have to do this!
      structuralSharing: false,
    },
  });
  const { results: leads, paginator } = data ?? {};
  const { page, total: recordsFound, lastPage, gotoPage } = usePaginator({ paginator });

  const triggerRefetch = () => {
    queryClient.invalidateQueries({
      queryKey: [{ scope: 'heartbeat', location: activeLocationId }],
    });
    refetch();
  };

  const handleSearch = (values: any) => {
    const _values = {
      ...values,
      event_type_ids:
        values.event_type_ids?.filter((type: any) => type.checked).map((type: any) => type.value) ??
        undefined,
    };
    setSearchParams(_values);
    setLastSearch('leads', _values);
  };

  const handleReset = () => {
    setSearchParams({});
    setLastSearch('leads', {});
  };

  const { mutate: exportResults, isPending: exportIsPending } = useExportLeads();
  const handleExportResults = () => {
    exportResults({
      location: activeLocationId,
      search: {
        ...searchState,
        filter: filter,
      },
    });
  };

  useEffect(() => {
    if (leads) {
      clearPins();
      const firstResult = leads[0];
      if (firstResult) setCenter(firstResult.longitude, firstResult.latitude);

      leads.map((lead: Lead) => {
        addPin({
          id: lead.id,
          itemId: lead.id,
          title: 'Lead',
          assetType: 'lead',
          lat: lead.latitude,
          lng: lead.longitude,
          type: 'lead',
          color: 'gray-500',
        });
      });
    }

    return () => clearPins();
  }, [leads]);

  const scrollRef = useRef<HTMLDivElement>(null);
  const { scrollToTop } = useScrollToTop(scrollRef);
  useEffect(() => {
    scrollToTop();
  }, [page]);

  return (
    <ScrollableLayout ref={scrollRef}>
      <Page className="pt-0">
        <LeadStatsBar />
        {eventTypes && (
          <LeadSearchForm
            onSubmit={handleSearch}
            onReset={handleReset}
            refetch={triggerRefetch}
            eventTypes={eventTypes}
            defaultValues={searchState}
            recordsFound={recordsFound}
          >
            <DropdownMenu>
              <DropdownMenuTrigger asChild disabled={recordsFound === 0}>
                <Button type="button" variant="outline" className="hidden md:inline-block">
                  Bulk Actions
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent side="bottom" align="start">
                <DropdownMenuLabel>All ({recordsFound})</DropdownMenuLabel>
                <PolicyGate policy="leads.export">
                  <DropdownMenuItem
                    disabled={recordsFound === 0}
                    onClick={handleExportResults}
                    className="cursor-pointer"
                  >
                    <CloudArrowDownIcon className="mr-2 h-4 w-4" />
                    Export Results
                    {exportIsPending && <Spinner className="ml-2" size="sm" />}
                  </DropdownMenuItem>
                </PolicyGate>

                {/* <CustomerListDropdownMenuGroup
                  disabled={recordsFound === 0}
                  actionText="Add Results to List"
                  onListClick={handleAddResultsToCustomerList}
                /> */}
              </DropdownMenuContent>
            </DropdownMenu>
          </LeadSearchForm>
        )}

        {isPending || isFetching ? (
          <ListingPlaceholder />
        ) : isError ? (
          <ErrorResult error={error} />
        ) : leads && leads.length === 0 ? (
          <NoResults label="leads" />
        ) : (
          leads && (
            <>
              {leads.map((lead: Lead) => (
                <Sentry.ErrorBoundary
                  key={`lead-${lead.id}-${lead.updated_at}`}
                  fallback={() => LeadResultFallback({ lead })}
                  beforeCapture={(scope) => {
                    scope.setTag('boundary', 'lead-result');
                  }}
                >
                  <LeadResult lead={lead} />
                </Sentry.ErrorBoundary>
              ))}
              {paginator && <Paginator page={page} lastPage={lastPage} onPageChange={gotoPage} />}
              <div className="mt-20 mb-96 flex flex-row items-start justify-center">
                <Button variant="outline" onClick={scrollToTop}>
                  Back to Top
                </Button>
              </div>
            </>
          )
        )}
      </Page>
    </ScrollableLayout>
  );
};

interface LeadResultFallbackProps {
  lead: Lead;
}

const LeadResultFallback = ({ lead }: LeadResultFallbackProps) => {
  return (
    <div className="border-y bg-white text-sm my-0 py-8 text-center">
      ERROR: Could not load lead data for LID #{lead.lead_id}
    </div>
  );
};
