import ButtonGroup from '@/components/Elements/ButtonGroup/ButtonGroup';
import { PolicyGate } from '@/features/auth/authorization';
import useNotificationStore from '@/stores/notifications';
import { cn } from '@/utils/format';
import {
  BellIcon,
  BookmarkIcon,
  CalendarIcon,
  MapPinIcon,
  ViewColumnsIcon,
} from '@heroicons/react/24/outline';
import { BellIcon as BellIconSolid } from '@heroicons/react/24/solid';
import { useShallow } from 'zustand/react/shallow';
import { useOverlayContext } from '../../OverlayControls';

export const DesktopOverlayControls = () => {
  const { trayDirection, trayVisible, trayMode, togglePanels, setTrayMode, toggleDirection } =
    useOverlayContext();

  const { notifications } = useNotificationStore(
    useShallow((state) => ({
      notifications: state.notifications,
    })),
  );

  const unread = notifications.filter((notification) => !notification.read).length || 0;

  const handleCalendarIconClick = () => {
    if (trayMode === 'calendar' && trayVisible) {
      togglePanels();
    } else {
      if (!trayVisible) togglePanels();
      setTrayMode('calendar');
    }
  };

  const handleMapIconClick = () => {
    if (trayMode === 'map' && trayVisible) {
      togglePanels();
    } else {
      if (!trayVisible) togglePanels();
      setTrayMode('map');
    }
  };

  const handleNotificationIconClick = () => {
    if (trayMode === 'notifications' && trayVisible) {
      togglePanels();
    } else {
      if (!trayVisible) togglePanels();
      setTrayMode('notifications');
    }
  };

  const handlePinsIconClick = () => {
    if (trayMode === 'pins' && trayVisible) {
      togglePanels();
    } else {
      if (!trayVisible) togglePanels();
      setTrayMode('pins');
    }
  };

  return (
    <ButtonGroup className="my-3 mx-auto">
      <PolicyGate policy="calendar.view">
        <ButtonGroup.Button
          onClick={handleCalendarIconClick}
          isActive={trayVisible && trayMode === 'calendar'}
          isFirst
        >
          <span className="sr-only">Show Calendar</span>
          <CalendarIcon className="h-5 w-5" aria-hidden="true" />
        </ButtonGroup.Button>
      </PolicyGate>
      <ButtonGroup.Button onClick={handleMapIconClick} isActive={trayVisible && trayMode === 'map'}>
        <span className="sr-only">Show Map</span>
        <MapPinIcon className="h-5 w-5" aria-hidden="true" />
      </ButtonGroup.Button>
      <ButtonGroup.Button
        onClick={handleNotificationIconClick}
        isActive={trayVisible && trayMode === 'notifications'}
        className={cn(
          !!unread && trayMode === 'notifications' && trayVisible
            ? 'bg-red-500 hover:bg-red-600'
            : '',
          !!unread && (trayMode !== 'notifications' || !trayVisible)
            ? 'text-red-500 hover:bg-red-600'
            : '',
        )}
      >
        <span className="sr-only">Show Notifications</span>
        {unread > 0 ? (
          <BellIconSolid className={cn('animate-bounce h-5 w-5')} aria-hidden="true" />
        ) : (
          <BellIcon className={cn('h-5 w-5')} aria-hidden="true" />
        )}
      </ButtonGroup.Button>
      <ButtonGroup.Button
        onClick={handlePinsIconClick}
        isActive={trayVisible && trayMode === 'pins'}
      >
        <span className="sr-only">Show Pins</span>
        <BookmarkIcon className="h-5 w-5" aria-hidden="true" />
      </ButtonGroup.Button>
      <ButtonGroup.Button onClick={toggleDirection} isLast disabled={!trayVisible}>
        <span className="sr-only">Switch View Mode</span>
        <ViewColumnsIcon
          className={cn('h-5 w-5', trayDirection === 'horizontal' ? '' : 'rotate-90')}
          aria-hidden="true"
        />
      </ButtonGroup.Button>
    </ButtonGroup>
  );
};
