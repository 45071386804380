import { PolicyCheck } from '@/features/auth/authorization';

export const PackagePolicies: Record<string, PolicyCheck> = {
  'products.packages.view': ({ acl, user, meta }) => {
    return acl.products.packages.view;
  },
  'products.packages.create': ({ acl, user, meta }) => {
    return acl.products.packages.create;
  },
  'products.packages.update': ({ acl, user, meta }) => {
    return acl.products.packages.edit;
  },
  'products.packages.delete': ({ acl, user, meta }) => {
    return acl.products.packages.delete;
  },
};
