import { useCreateLead } from '@/api/leads/leads/createLead';
import { findByZipcode } from '@/api/locations/locations/getLocation';
import { FormInput } from '@/components/Form';
import { FormEmailInput } from '@/components/Form/FormEmailInput';
import { Button } from '@/components/ui/elements/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/elements/dialog';
import { Form } from '@/components/ui/form/form';
import { Location } from '@/types';
import { cn } from '@/utils/format';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from '@tanstack/react-router';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const schema = z.object({
  zipcode: z.string().min(1),
  phone: z.string().nullish(),
  email: z.string().nullish(),
});

export const NewLeadDialog = () => {
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState<Location | null>(null);
  const { mutate: createLead } = useCreateLead();
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      zipcode: '',
      phone: '',
      email: '',
    },
  });

  const debouncedZipcodeLookup = useCallback(
    debounce((zip) => {
      if (zip && zip.length === 5) findByZipcode(zip).then((data) => setLocation(data));
    }, 1000),
    [],
  );

  useEffect(() => {
    const subscription = form.watch((value) => {
      if (value.zipcode && value.zipcode.length === 5) debouncedZipcodeLookup(value.zipcode);
    });

    return () => subscription.unsubscribe();
  }, [form.watch]);

  const resetForm = () => {
    setLocation(null);
    form.reset();
    setOpen(!open);
  };

  const handleSubmit = (data: z.infer<typeof schema>) => {
    createLead(
      {
        data,
      },
      {
        onSuccess: (data) => {
          navigate({ to: `/leads/lead/${data.id}/quote` });
          resetForm();
          setOpen(false);
        },
      },
    );
  };

  return (
    <Dialog open={open} onOpenChange={resetForm}>
      <span className="isolate inline-flex ">
        <DialogTrigger asChild>
          <button
            type="button"
            className={cn(
              'relative inline-flex items-center mr-3 px-2 py-2 rounded-md ring-1 ring-inset ring-gray-300  focus:z-10',
              'bg-white hover:bg-primary-600 hover:text-white text-gray-400',
            )}
            onClick={() => setOpen(!open)}
          >
            <span className="sr-only">New Lead</span>
            <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </DialogTrigger>
      </span>
      <DialogContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
            <DialogHeader>
              <DialogTitle>New Lead</DialogTitle>
            </DialogHeader>
            <div className="grid sm:grid-cols-2 gap-3">
              <div className="space-y-3">
                <FormInput control={form.control} name="zipcode" type="number" label="Zip Code" />
                <FormInput control={form.control} name="phone" label="Phone" />
                <FormEmailInput control={form.control} name="email" label="Email" />
              </div>
              <div className="flex flex-col text-sm">
                {location && (
                  <>
                    <span className="font-medium">{location.areaop}</span>
                    <span className="pt-2">Booking Contact:</span>
                    <span>{location.booking_name}</span>
                    <span>{location.booking_phone}</span>
                    <span>{location.booking_email}</span>
                    <span className="pt-2">Offers: </span>
                  </>
                )}
              </div>
            </div>
            <DialogFooter>
              <Button type="submit">Next</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
