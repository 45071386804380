import { useCreateLead } from '@/api/leads/leads/createLead';
import { Customer } from '@/types';
import { useNavigate } from '@tanstack/react-router';

interface UseCreateBlankLeadProps {
  customer: Customer | undefined;
}

export const useCreateBlankLead = ({ customer }: UseCreateBlankLeadProps) => {
  const navigate = useNavigate();
  const { mutate: createLead } = useCreateLead();

  const createBlankLead = () => {
    if (customer) {
      createLead(
        {
          data: {
            customer_id: customer.id,
            zipcode: customer.zipcode ?? undefined,
            phone: customer.phone,
            email: customer.email,
            address: customer.address ?? undefined,
            city: customer.city ?? undefined,
            state: customer.state ?? undefined,
          },
          params: {
            force_new: true,
          },
        },
        {
          onSuccess: (lead) => {
            navigate({ to: `/leads/lead/${lead.id}/quote` });
          },
        },
      );
    }
  };

  return {
    createBlankLead,
  };
};
