import { Filter } from '@/api/leads/leads';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type SearchSettingStore = {
  filter: Filter;
  setFilter: (filter: Filter) => void;
  search: any;
  setSearch: (feature: string, search: any) => void;
};

type PersistedSearchSettingStore = {
  filter: string;
  search: string;
};

export const useSearchSettingStore = create<SearchSettingStore>()(
  persist(
    (set) => ({
      filter: 'inbox',
      setFilter: (filter: Filter) => set(() => ({ filter })),
      search: {},
      setSearch: (feature, search: any) =>
        set((state) => ({ search: { ...state.search, [feature]: search } })),
    }),
    {
      name: 'search-setting-storage',
      partialize: (state) => ({
        filter: state.filter,
        search: JSON.stringify(state.search),
      }),
      merge: (persistedState, currentState) => {
        const hydrator = persistedState as PersistedSearchSettingStore;
        return {
          ...currentState,
          filter: (hydrator.filter as Filter) ?? ('inbox' as Filter),
          search: JSON.parse(hydrator.search),
        };
      },
    },
  ),
);
