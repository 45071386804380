import { CustomerNote, ModelID, SearchParams } from '@/types';

export type Filter = 'all' | 'active' | 'inactive';
export type Sort = 'created_at' | 'updated_at' | 'note_id';
export interface CustomerNoteSearchParams
  extends SearchParams<Filter, Sort>,
    Partial<CustomerNote> {}

export const featurePath = '/v4/customers/note';

export const featureKeys = {
  all: [{ scope: 'customer-notes' }] as const,

  lists: () => [{ ...featureKeys.all[0], entity: 'list' }] as const,

  list: (location: ModelID, search: CustomerNoteSearchParams = {}) =>
    [{ search, location, entity: 'list' }] as const,

  details: () => [{ ...featureKeys.all[0], entity: 'detail' }] as const,

  detail: (id: ModelID, search: CustomerNoteSearchParams = {}) =>
    [
      { ...featureKeys.details()[0], id, search },
      { ...featureKeys.details()[0], id },
    ] as const,
};
