import { Link } from '@/components/Elements';
import ButtonGroup from '@/components/Elements/ButtonGroup/ButtonGroup';
import NoResults from '@/components/Elements/NoResults/NoResults';
import { Button } from '@/components/ui/elements/button';
import useNotificationStore from '@/stores/notifications';
import { cn } from '@/utils/format';
import { CheckIcon, TrashIcon } from '@heroicons/react/24/outline';
import { formatDistanceToNow } from 'date-fns';
import { useShallow } from 'zustand/react/shallow';

export const NotificationsStack = () => {
  const { notifications, markAsRead, markAllAsRead, deleteNotification, clearAll } =
    useNotificationStore(
      useShallow((state) => ({
        markAllAsRead: state.markAllAsRead,
        markAsRead: state.markAsRead,
        notifications: state.notifications,
        deleteNotification: state.delete,
        clearAll: state.clear,
      })),
    );

  return (
    <ul role="list" className="flex flex-col divide-y divide-gray-100 h-full overflow-y-auto">
      <ButtonGroup className="my-3 mx-auto">
        <ButtonGroup.Button
          className={cn(!notifications.length ? 'text-gray-500' : 'text-gray-900')}
          onClick={markAllAsRead}
          isFirst
          disabled={!notifications.length}
        >
          Mark All As Read
        </ButtonGroup.Button>
        <ButtonGroup.Button
          className={cn(!notifications.length ? 'text-gray-500' : 'text-gray-900')}
          onClick={clearAll}
          isLast
          disabled={!notifications.length}
        >
          Delete All
        </ButtonGroup.Button>
      </ButtonGroup>

      {!notifications.length && <NoResults label="notifications" />}

      {notifications.map((notification) => (
        <li key={notification.id} className="flex items-center justify-between gap-x-6 py-4 px-4">
          <div className="min-w-0">
            <div className="flex items-start gap-x-3">
              {!notification.link ? (
                <p
                  className={cn(
                    'text-sm leading-6 ',
                    notification.read
                      ? 'font-normal text-gray-500 italic'
                      : 'font-semibold text-gray-900',
                  )}
                >
                  {notification.title}
                </p>
              ) : (
                <Link
                  to={notification.link}
                  className={cn(
                    'text-sm leading-6 ',
                    notification.read
                      ? 'font-normal text-gray-500 italic'
                      : 'font-semibold text-gray-900',
                  )}
                >
                  {notification.title}
                </Link>
              )}
            </div>
            <div className="mt-1 flex flex-col gap-y-1 text-xs leading-5 text-gray-500">
              <p>Received {formatDistanceToNow(notification.id)} ago</p>
              <p>{notification.message}</p>
            </div>
          </div>
          <div className="flex flex-none items-center gap-x-4">
            {!notification.read && (
              <Button variant="icon" onClick={() => markAsRead(notification.id)}>
                <CheckIcon className="w-6 h-6 p-1 text-gray-400" />
              </Button>
            )}
            <Button variant="icon" onClick={() => deleteNotification(notification.id)}>
              <TrashIcon className="w-6 h-6 p-1 text-gray-400" />
            </Button>
          </div>
        </li>
      ))}
    </ul>
  );
};
