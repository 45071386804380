import { CustomerList, ModelID, SearchParams } from '@/types';

export type Filter = 'all' | 'active' | 'inactive';
export type Sort = 'created_at' | 'updated_at' | 'customer_list_id' | 'name';
export interface CustomerListSearchParams
  extends SearchParams<Filter, Sort>,
    Partial<CustomerList> {}

export const featurePath = '/v4/customers/customer-list';

export const featureKeys = {
  all: [{ scope: 'customer-lists' }] as const,

  lists: (location: ModelID) => [{ ...featureKeys.all[0], location, entity: 'list' }] as const,

  list: (location: ModelID, search: CustomerListSearchParams = {}) =>
    [{ ...featureKeys.lists(location)[0], search }] as const,

  details: () => [{ ...featureKeys.all[0], entity: 'detail' }] as const,

  detail: (id: ModelID) => [{ ...featureKeys.details()[0], id }] as const,
};
