import queryClient from '@/lib/react-query';
import { useUpdateMutationFactory, UseUpdateMutationProps } from '@/lib/react-query/update';
import { CustomerList, ModelID } from '@/types';
import { post } from '@/utils/api';
import { featureKeys, featurePath } from '.';

type BatchAddCustomersDTO = {
  id: ModelID;
  ids: ModelID[];
};

const batchAddCustomers = ({ ids, id }: BatchAddCustomersDTO) =>
  post<CustomerList>(`${featurePath}/${id}/bulk`, { customer_ids: ids });

export const useBatchAddCustomersToList = (options?: UseUpdateMutationProps<CustomerList>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: batchAddCustomers,
    ...options,
    onSuccess: (data: CustomerList) => {
      queryClient.invalidateQueries({ queryKey: featureKeys.detail(data.id) });
      if (options?.onSuccess) {
        options.onSuccess(data);
      }
    },
  });
};
