import {
  CornerDotType,
  CornerSquareType,
  DotType,
  DrawType,
  ErrorCorrectionLevel,
  GradientType,
  Mode,
  TypeNumber,
} from "qr-code-styling";

export const qrDefaults = {
  width: 400,
  height: 400,
  type: "svg" as DrawType,
  data: "https://www.gametruckparty.com",
  margin: 10,
  qrOptions: {
    typeNumber: 0 as TypeNumber,
    mode: "Byte" as Mode,
    errorCorrectionLevel: "Q" as ErrorCorrectionLevel,
  },
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.4,
    margin: 5,
    crossOrigin: "anonymous",
  },
  dotsOptions: {
    color: "#7aa52b",
    gradient: {
      type: "radial" as GradientType, // 'linear', 'radial
      rotation: 0,
      colorStops: [
        { offset: 0, color: "#e76829" },
        { offset: 1, color: "#7aa52b" },
      ],
    },
    type: "dots" as DotType,
  },
  backgroundOptions: {
    color: "#000000",
    // gradient: {
    //   type: 'linear', // 'radial'
    //   rotation: 0,
    //   colorStops: [{ offset: 0, color: '#ededff' }, { offset: 1, color: '#e6e7ff' }]
    // },
  },
  cornersSquareOptions: {
    color: "#7aa52b",
    type: "extra-rounded" as CornerSquareType,
    // gradient: {
    //   type: 'linear', // 'radial'
    //   rotation: 180,
    //   colorStops: [{ offset: 0, color: '#25456e' }, { offset: 1, color: '#4267b2' }]
    // },
  },
  cornersDotOptions: {
    color: "#7aa52b",
    type: "dot" as CornerDotType,
    // gradient: {
    //   type: 'linear', // 'radial'
    //   rotation: 180,
    //   colorStops: [{ offset: 0, color: '#00266e' }, { offset: 1, color: '#4060b3' }]
    // },
  },
};
