import { Blackout } from '@/types';
import { z } from 'zod';

export const blackoutTypeOptions = [
  { label: 'Select a Type', value: '' },
  { label: 'Repairs', value: 'Repairs' },
  { label: 'Service/Maintenance', value: 'Service/Maintenance' },
  { label: 'No Staff', value: 'No Staff' },
  { label: 'Schedule Closed', value: 'Schedule Closed' },
  { label: 'Holiday', value: 'Holiday' },
  { label: 'Note', value: 'Note' },
  { label: 'Vehicle Reserved', value: 'Vehicle Reserved' },
];

export const blackoutSchema = z.object({
  asset_id: z.coerce.string().min(1),
  start_date: z.coerce.string().min(1),
  start_time: z.coerce.string().nullish(),
  end_date: z.coerce.string().nullish(),
  end_time: z.coerce.string().nullish(),
  reason: z.string().optional(),
  type: z
    .enum([
      '',
      'Repairs',
      'Service/Maintenance',
      'No Staff',
      'Schedule Closed',
      'Holiday',
      'Note',
      'Vehicle Reserved',
    ])
    .nullish(),
});

export const prepareBlackoutData = (
  blackout: z.infer<typeof blackoutSchema>,
): Partial<Blackout> => {
  if (!blackout.end_time) {
    blackout.end_time = '23:59:59';
  }

  if (!blackout.start_time) {
    blackout.start_time = '00:00:00';
    blackout.end_time = '23:59:59';
  }

  if (!blackout.end_date) {
    blackout.end_date = blackout.start_date;
  }

  const startAtString = blackout.start_date.concat(' ', blackout.start_time);
  const endAtString = blackout.end_date.concat(' ', blackout.end_time);

  return {
    asset_id: +blackout.asset_id,
    start_at: startAtString ?? '',
    end_at: endAtString ?? '',
    reason: blackout.reason,
    type: blackout.type ?? '',
  };
};
