import ButtonGroup from '@/components/Elements/ButtonGroup/ButtonGroup';
import { PolicyGate } from '@/features/auth/authorization';
import useNotificationStore from '@/stores/notifications';
import { cn } from '@/utils/format';
import { BellIcon, BookmarkIcon, CalendarIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { BellIcon as BellIconSolid } from '@heroicons/react/24/solid';
import { useShallow } from 'zustand/react/shallow';
import { useOverlayContext } from '../../OverlayControls';

interface MobileOverlayControlsProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const MobileOverlayControls = ({ open, setOpen }: MobileOverlayControlsProps) => {
  const { trayMode, setTrayMode } = useOverlayContext();

  const trayVisible = open;

  const { notifications } = useNotificationStore(
    useShallow((state) => ({
      notifications: state.notifications,
    })),
  );

  const unread = notifications.filter((notification) => !notification.read).length || 0;

  const handleCalendarIconClick = () => {
    if (trayMode === 'calendar' && trayVisible) {
      setOpen(!open);
    } else {
      if (!trayVisible) {
        setOpen(!open);
      }
      setTrayMode('calendar');
    }
  };

  const handleMapIconClick = () => {
    if (trayMode === 'map' && trayVisible) {
      setOpen(!open);
    } else {
      if (!trayVisible) {
        setOpen(!open);
      }
      setTrayMode('map');
    }
  };

  const handleNotificationIconClick = () => {
    if (trayMode === 'notifications' && trayVisible) {
      setOpen(!open);
    } else {
      if (!trayVisible) {
        setOpen(!open);
      }
      setTrayMode('notifications');
    }
  };

  const handlePinsIconClick = () => {
    if (trayMode === 'pins' && trayVisible) {
      setOpen(!open);
    } else {
      if (!trayVisible) {
        setOpen(!open);
      }
      setTrayMode('pins');
    }
  };

  return (
    <ButtonGroup className="my-3 mx-auto">
      <PolicyGate policy="calendar.view">
        <ButtonGroup.Button
          onClick={handleCalendarIconClick}
          isActive={trayVisible && trayMode === 'calendar'}
          isFirst
        >
          <span className="sr-only">Show Calendar</span>
          <CalendarIcon className="h-5 w-5" aria-hidden="true" />
        </ButtonGroup.Button>
      </PolicyGate>
      <ButtonGroup.Button onClick={handleMapIconClick} isActive={trayVisible && trayMode === 'map'}>
        <span className="sr-only">Show Map</span>
        <MapPinIcon className="h-5 w-5" aria-hidden="true" />
      </ButtonGroup.Button>
      <ButtonGroup.Button
        onClick={handleNotificationIconClick}
        isActive={trayVisible && trayMode === 'notifications'}
        className={cn(
          !!unread && trayMode === 'notifications' && trayVisible
            ? 'bg-red-500 hover:bg-red-600'
            : '',
          !!unread && (trayMode !== 'notifications' || !trayVisible)
            ? 'text-red-500 hover:bg-red-600'
            : '',
        )}
      >
        <span className="sr-only">Show Notifications</span>
        {unread > 0 ? (
          <BellIconSolid className={cn('animate-bounce h-5 w-5')} aria-hidden="true" />
        ) : (
          <BellIcon className={cn('h-5 w-5')} aria-hidden="true" />
        )}
      </ButtonGroup.Button>
      <ButtonGroup.Button
        onClick={handlePinsIconClick}
        isActive={trayVisible && trayMode === 'pins'}
        isLast
      >
        <span className="sr-only">Show Pins</span>
        <BookmarkIcon className="h-5 w-5" aria-hidden="true" />
      </ButtonGroup.Button>
    </ButtonGroup>
  );
};
