import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Asset, ModelID } from '@/types';
import { get } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { AssetSearchParams, featureKeys, featurePath } from '.';

type QueryFnType = typeof fetchAssets;

type UseAssetsOptions = {
  location: ModelID;
  search?: AssetSearchParams;
  config?: QueryConfig<QueryFnType>;
};

const ONE_HOUR = 1000 * 60 * 60;

export const fetchAssets = (location: ModelID, search: AssetSearchParams = {}) =>
  get<Asset[]>(`${featurePath}?franchise_id=${location}`, search);

export const useAssets = ({ location, search = {}, config = {} }: UseAssetsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    staleTime: ONE_HOUR,
    queryKey: featureKeys.list(location, search),
    queryFn: () => fetchAssets(location, search),
  });
};
