import {
  useCustomerMessages,
  useRealtimeCustomerMessages,
} from '@/api/customers/customer-messages/getCustomerMessages';
import { PolicyGate } from '@/features/auth/authorization';
import { LocationContext } from '@/providers/location';
import { CustomerMessage, ModelID } from '@/types';
import { cn, formatDate } from '@/utils/format';
import _ from 'lodash';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { isAndroid } from 'react-device-detect';
import { Placeholder, Spinner } from '../Elements';
import NoResults from '../Elements/NoResults/NoResults';
import { Badge } from '../ui/elements/badge';
import { MessageMediaPopover } from './MessageMediaPopover';

interface ConversationThreadProps {
  customerId: ModelID;
}

export const ConversationThread = ({ customerId }: ConversationThreadProps) => {
  const { activeLocationId } = useContext(LocationContext);
  const CustomerMessageParams = {
    location: activeLocationId,
    search: {
      customer_id: customerId,
    },
  };

  const { data: messages, isPending } = useCustomerMessages(CustomerMessageParams);
  const channel = useRealtimeCustomerMessages(CustomerMessageParams);

  const messageBox = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messageBox.current && messages && messages?.length > 0) {
      messageBox.current.scrollTop = messageBox.current.scrollHeight;
    }
  }, [messages?.length]);

  if (isPending) {
    return (
      <div className="flex flex-col grow space-y-7 m-4">
        <div className="flex flex-row space-x-2">
          <Placeholder variant="circle" size="md" className="" />
          <Placeholder size="md" className="w-full" />
        </div>
        <div className="flex flex-row space-x-2">
          <Placeholder variant="circle" size="md" className="" />
          <Placeholder size="xl" className="w-full" />
        </div>
        <div className="flex flex-row space-x-2">
          <Placeholder size="md" className="w-full" />
          <Placeholder variant="circle" size="md" />
        </div>
        <div className="flex flex-row space-x-2">
          <Placeholder variant="circle" size="md" />
          <Placeholder size="lg" className="w-full" />
        </div>
        <div className="flex flex-row space-x-2">
          <Placeholder size="md" className="w-full" />
          <Placeholder variant="circle" size="md" />
        </div>
        <Placeholder size="xl" className="w-full" />
      </div>
    );
  }

  const messagesByDate = _.groupBy(messages ?? [], (message) =>
    formatDate(message.created_at, 'MM/dd/yyyy'),
  );

  if (!messages?.length) {
    return <NoResults label="messages" />;
  }

  return (
    <PolicyGate policy="messages.view">
      <div
        ref={messageBox}
        className={cn(
          'w-full overflow-y-auto',
          isAndroid ? 'md:h-[calc(87vh-560px)]' : 'md:h-[calc(100vh-560px)]',
        )}
      >
        <div className="flex flex-col-reverse space-y-2 bg-gray-50 m-1">
          {messagesByDate &&
            Object.entries(messagesByDate).map(([date, messages], i) => (
              <Fragment key={`msg-date-node${date}`}>
                {messages?.map((message, i) => (
                  <Fragment key={`msg-note-node${message.id ?? i}`}>
                    {message.type === 'sms' ? (
                      <div className="flex flex-row content-end items-end my-2">
                        {message.direction === 'outbound' ? (
                          <OutboundMessage message={message} />
                        ) : (
                          <InboundMessage message={message} />
                        )}
                      </div>
                    ) : (
                      <div className="flex flex-row">
                        <div className="text-sm text-center text-gray-600 w-full py-3">
                          <span
                            className={cn('mx-1', message.status === 'error' ? 'text-red-800' : '')}
                          >
                            {message.status === 'error' && (
                              <Badge variant="red" size="xs" className="mx-1">
                                {message.error_message}
                              </Badge>
                            )}
                            Email {message.status}:
                          </span>
                          {message.subject}
                        </div>
                      </div>
                    )}
                  </Fragment>
                ))}
                <span className="inline text-center text-sm relative -top-7 z-10 bg-gray-50 text-gray-500 p-2 mx-auto">
                  {date}
                </span>
                <div className="border-b pt-6 mb-6 relative" />
              </Fragment>
            ))}
        </div>
      </div>
    </PolicyGate>
  );
};

interface MessageDisplayProps {
  message: CustomerMessage;
}

const InboundMessage = ({ message }: MessageDisplayProps) => {
  const [mediaOpen, setMediaOpen] = useState(false);
  const [expandedMediaUrl, setExpandedMediaUrl] = useState<string>();
  const handleExpandMedia = (url: string) => {
    setExpandedMediaUrl(url);
    setMediaOpen(true);
  };
  return (
    <div className="flex-col mr-auto">
      <div className="flex flex-row items-end">
        <div className="mr-2">
          <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-purple-700">
            <span className="text-sm font-semibold leading-none text-white">
              {message.sender_first_name ? message.sender_first_name[0] : '?'}
              {message.sender_last_name ? message.sender_last_name[0] : '?'}
            </span>
          </span>
        </div>
        <div className="flex-col">
          {message.media?.map((media, i) => (
            <Fragment key={`media-${media.url}`}>
              <button type="button" onClick={() => handleExpandMedia(media.url)} className="m-0.5">
                <img alt="media message" src={media.url} className="max-h-48 max-w-48" />
              </button>
              {expandedMediaUrl && (
                <MessageMediaPopover
                  open={mediaOpen}
                  setOpen={setMediaOpen}
                  mediaUrl={expandedMediaUrl}
                />
              )}
            </Fragment>
          ))}
          {message.message && (
            <div
              className={cn(
                'rounded-xl px-4 py-2.5 text-sm leading-tight',
                'bg-gray-200 md:mr-16 rounded-bl-none',
              )}
            >
              {message.message}
            </div>
          )}
        </div>
        <div className="w-44" />
      </div>
      {message.created_at && (
        <div className="text-xs text-left text-gray-600 pt-1 ml-10">
          {formatDate(message.created_at, 'h:mm a')}
        </div>
      )}
    </div>
  );
};

const OutboundMessage = ({ message }: MessageDisplayProps) => {
  if (message.marketing_message_id === 13) {
    message.message = message.message.replace(
      /(https:\/\/.*\.com\/survey\/)[^\s]+/g,
      '$1**********',
    );
  }

  return (
    <div className="flex-col ml-auto">
      <div className="flex flex-row items-end">
        <div className="order-last ml-2">
          <span
            className={cn(
              'inline-flex h-8 w-8 items-center justify-center rounded-full',
              !message.sentby_user_id ? 'bg-green-700' : 'bg-blue-700',
            )}
          >
            <span className="text-sm font-semibold leading-none text-white">
              {message.sentby_user_id ? (
                <>
                  {message.sender_first_name ? message.sender_first_name[0] : '?'}
                  {message.sender_last_name ? message.sender_last_name[0] : '?'}
                </>
              ) : (
                <>GT</>
              )}
            </span>
          </span>
        </div>
        <div className="flex-col">
          <div
            className={cn(
              'rounded-xl px-4 py-2.5 text-sm leading-tight',
              'break-all',
              message.direction === 'outbound' && message.sentby_user_id
                ? 'bg-blue-200 md:ml-16 rounded-br-none'
                : message.type === 'sms'
                ? 'bg-blue-100 md:ml-20 rounded-br-none'
                : '',
              message.status === 'error' ? 'bg-red-200 text-right' : '',
            )}
          >
            {message.message}
            {message.status === 'error' && (
              <span className="text-xs italic">
                <br />
                {message.error_message}
              </span>
            )}
          </div>
        </div>
      </div>
      {message.created_at && (
        <div className="text-xs text-right text-gray-600 pt-1 mr-10">
          {message.status === 'sending' && <Spinner size="sm" className="inline mx-2" />}
          {message.customer_message_id
            ? formatDate(message.created_at, 'h:mm a')
            : formatDate(new Date(), 'h:mm a')}
          {message.status === 'error' && (
            <span className="text-xs italic">
              <br />
              {message.error_message}
            </span>
          )}
        </div>
      )}
    </div>
  );
};
