import { PolicyCheck } from '@/features/auth/authorization';

export const LeadPolicies: Record<string, PolicyCheck> = {
  'leads.view': ({ acl, user, meta }) => {
    return acl.leads.view;
  },
  'leads.view_b2b': ({ acl, user, meta }) => {
    return acl.leads.view && user.is_b2b;
  },
  'leads.create': ({ acl, user, meta }) => {
    return acl.leads.create;
  },
  'leads.update': ({ acl, user, meta }) => {
    return acl.leads.edit;
  },
  'leads.transfer': ({ acl, user, meta }) => {
    return acl.leads.transfer;
  },
  'leads.history': ({ acl, user, meta }) => {
    return acl.leads.history;
  },
  'leads.export': ({ acl, user, meta }) => {
    return acl.leads.export;
  },
};
