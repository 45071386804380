import { useMotd } from '@/api/system/motds/getMotd';
import { ModelID } from '@/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useShallow } from 'zustand/react/shallow';

type MotdStore = {
  dismissed: ModelID[];
  dismiss: (id: ModelID) => void;
};

const useMotdStore = create<MotdStore>()(
  persist(
    (set) => ({
      dismissed: [],
      dismiss: (id: ModelID) => set((state) => ({ dismissed: [...state.dismissed, id] })),
    }),
    {
      name: 'motd-dismissed',
      partialize: (state) => ({
        dismissed: state.dismissed,
      }),
    },
  ),
);

export const useMessageOfTheDay = () => {
  const { data: message } = useMotd({});
  const { dismissed, dismiss } = useMotdStore(
    useShallow((state) => {
      return {
        dismissed: state.dismissed,
        dismiss: state.dismiss,
      };
    }),
  );

  const isUnseen = message && !dismissed.includes(message.id);

  return {
    message,
    isUnseen,
    dismiss,
  };
};
