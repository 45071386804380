import { CalendarNote, ModelID, SearchParams } from '@/types';

export type Filter = 'all' | 'active' | 'inactive';
export type Sort = 'created_at' | 'updated_at' | 'start_at' | 'end_at' | 'note_id';
export interface NoteSearchParams extends SearchParams<Filter, Sort>, Partial<CalendarNote> {
  dates?: {
    start: Date;
    end: Date;
  };
}

export const featurePath = '/v4/calendar/note';

export const featureKeys = {
  all: [{ scope: 'notes' }] as const,

  lists: (location: ModelID) => [{ ...featureKeys.all[0], location, entity: 'list' }] as const,

  list: (location: ModelID, search: NoteSearchParams = {}) =>
    [{ ...featureKeys.lists(location)[0], search }] as const,

  details: () => [{ ...featureKeys.all[0], entity: 'detail' }] as const,

  detail: (id: ModelID) => [{ ...featureKeys.details()[0], id }] as const,
};
