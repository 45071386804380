import { useCustomer } from '@/api/customers/customers/getCustomer';
import { UpdateCustomerDTO, useUpdateCustomer } from '@/api/customers/customers/updateCustomer';
import { Customer, ModelID } from '@/types';
import { UseMutateFunction } from '@tanstack/react-query';
import { createContext, ReactNode, useContext } from 'react';
import { useCreateBlankLead } from '../hooks/useCreateBlankLead';

type CustomerContextType = {
  customerId: ModelID;
  customer: Customer | undefined;
  isPending: boolean;
  isError: boolean;
  error: unknown;
  isUpdatePending: boolean;
  updateCustomer: UseMutateFunction<Customer, unknown, UpdateCustomerDTO, unknown>;
  createBlankLead: () => void;
};

type CustomerProviderProps = {
  customerId: ModelID;
  children: ReactNode;
};

export const CustomerContext = createContext<CustomerContextType>({} as CustomerContextType);

export const CustomerProvider = ({ customerId, children }: CustomerProviderProps) => {
  const {
    isPending,
    isError,
    data: customer,
    error,
  } = useCustomer({
    id: customerId,
    config: {
      // Find a way to not have to do this!
      structuralSharing: false,
    },
  });
  const { mutate: updateCustomer, isPending: isUpdatePending } = useUpdateCustomer();
  const { createBlankLead } = useCreateBlankLead({ customer });

  return (
    <CustomerContext.Provider
      value={{
        customerId,
        customer,
        isPending,
        isError,
        error,
        isUpdatePending,
        updateCustomer,
        createBlankLead,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomerContext = () => {
  const context = useContext(CustomerContext);
  if (context === undefined) {
    throw new Error('useCustomerContext must be used within a CustomerProvider');
  }
  return context;
};
