import { toast } from '@/components/ui/elements/use-toast';
import { ModelID } from '@/types';
import { post } from '@/utils/api';
import { useMutation } from '@tanstack/react-query';
import { featurePath, LeadSearchParams } from '.';

type ExportLeadsDTO = {
  location: ModelID;
  search?: LeadSearchParams;
};

type ExportLeadsResponse = {
  message: string;
};

const exportLeads = ({ location, search = {} }: ExportLeadsDTO) =>
  post<ExportLeadsResponse>(`${featurePath}/export`, {
    franchise_id: location,
    ...search,
  });

export const useExportLeads = () => {
  return useMutation({
    mutationFn: exportLeads,
    onSuccess: () => {
      toast({
        title: 'Export Started',
        description: "You'll receive a notification when your download is ready.",
        duration: 3000,
      });
    },
  });
};
