import { Label } from '@/components/ui/elements/label';
import { Switch } from '@/components/ui/elements/switch';
import { cn } from '@/utils/format';
import { ClassProp } from 'cva/dist/types';

export type ToggleProps = ClassProp & {
  checked: boolean;
  onChange: (checked: boolean) => any;
  label?: string;
  leftLabel?: string;
};

export const Toggle = ({ checked, onChange, label, leftLabel, className }: ToggleProps) => {
  return (
    <div className={cn('flex items-center space-x-2', className)}>
      {leftLabel && <Label htmlFor={`switch-${label}`}>{leftLabel}</Label>}
      <Switch id={`switch-${label}`} checked={checked} onCheckedChange={onChange} />
      <Label htmlFor={`switch-${label}`}>{label}</Label>
    </div>
  );
};
