import { Asset, ModelID } from '@/types';
import { CalendarDate, getLocalTimeZone, now, parseDate } from '@internationalized/date';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type CalendarMode = 'day' | 'threeDay' | 'week' | 'month';

export type CalendarEvent = {
  id: string;
  cal_event_type: string;
  resourceId: ModelID;
  title: string;
  className?: string;
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
  start: Date;
  end: Date;
};

export type CalendarResource = {
  id: ModelID;
  asset_type_id: ModelID;
  sort_order: number;
  title: string;
  resource: Asset;
};

type PersistedCalendarStore = {
  startDate: string;
  endDate: string;
  mode: CalendarMode;
};

type CalendarStore = {
  defaultLocationId: ModelID | null;
  setDefaultLocationId: (id: ModelID) => void;

  activeCalendarLocationId: ModelID | null;
  setActiveCalendarLocationId: (id: ModelID) => void;
  resetActiveCalendarLocationId: () => void;

  startDate: CalendarDate;
  setStartDate: (date: CalendarDate) => void;

  endDate: CalendarDate;
  setEndDate: (date: CalendarDate) => void;

  setDates: (start: CalendarDate, end: CalendarDate) => void;

  mode: CalendarMode;
  setMode: (mode: CalendarMode) => void;

  temporaryEvents: CalendarEvent[];
  addTemporaryEvent: (temporaryEvent: CalendarEvent) => void;
  removeTemporaryEvent: (id: string) => void;
  clearTemporaryEvents: () => void;
};

const nowDT = now(getLocalTimeZone());
const today = new CalendarDate(nowDT.year, nowDT.month, nowDT.day);

export const useCalendarStore = create<CalendarStore>()(
  persist(
    (set) => ({
      defaultLocationId: null,
      setDefaultLocationId: (id: ModelID) =>
        set(() => ({ defaultLocationId: id, activeCalendarLocationId: id })),

      activeCalendarLocationId: null,
      setActiveCalendarLocationId: (id: ModelID) => set(() => ({ activeCalendarLocationId: id })),
      resetActiveCalendarLocationId: () =>
        set((state) => ({ activeCalendarLocationId: state.defaultLocationId })),

      startDate: today,
      setStartDate: (date: CalendarDate) => set(() => ({ startDate: date })),

      endDate: today,
      setEndDate: (date: CalendarDate) => set(() => ({ endDate: date })),

      setDates: (start: CalendarDate, end: CalendarDate) =>
        set(() => ({ startDate: start, endDate: end })),

      mode: 'threeDay',
      setMode: (mode: CalendarMode) => set(() => ({ mode })),

      temporaryEvents: [],
      addTemporaryEvent: (temporaryEvent: CalendarEvent) =>
        set((state) => {
          if (
            state.temporaryEvents.some(
              (existing: CalendarEvent) => existing.id === temporaryEvent.id,
            )
          ) {
            const events = state.temporaryEvents.map((existing: CalendarEvent) => {
              if (existing.id === temporaryEvent.id) {
                return temporaryEvent;
              }

              return existing;
            });

            return { temporaryEvents: [...events] };
          } else {
            return { temporaryEvents: [...state.temporaryEvents, temporaryEvent] };
          }
        }),
      removeTemporaryEvent: (id) =>
        set((state) => ({
          temporaryEvents: state.temporaryEvents.filter(
            (temporaryEvent) => temporaryEvent.id !== id,
          ),
        })),
      clearTemporaryEvents: () => set(() => ({ temporaryEvents: [] })),
    }),
    {
      version: 1,
      name: 'calendar-storage', // name of the item in the storage (must be unique)
      partialize: (state) => ({
        startDate: state.startDate.toString(),
        endDate: state.endDate.toString(),
        mode: state.mode,
      }),
      migrate: (persistedState: any, version) => {
        if (!version || version === 0) {
          // if the stored value is in version 0, we rename the field to the new name
          const startDate = persistedState.startDate.split('T')[0];
          const endDate = persistedState.endDate.split('T')[0];

          return { ...persistedState, startDate, endDate };
        }

        return { ...persistedState };
      },
      merge: (persistedState, currentState) => {
        const hydrator = persistedState as PersistedCalendarStore;
        return {
          ...currentState,
          mode: hydrator.mode,
          startDate: parseDate(hydrator.startDate),
          endDate: parseDate(hydrator.endDate),
        };
      },
    },
  ),
);
