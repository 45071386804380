import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ChangeLogNode, ChangeLogResponse, Customer, ModelID } from '@/types';
import { get } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { CustomerSearchParams, featureKeys, featurePath } from '.';

type QueryFnType = typeof findCustomer;
type QueryChangelogFnType = typeof getChangelog;

type UseCustomerOptions = {
  id: ModelID;
  search?: CustomerSearchParams;
  config?: QueryConfig<QueryFnType>;
};

type UseCustomerChangelogOptions = {
  customer: Customer;
  search?: CustomerSearchParams;
  config?: QueryConfig<QueryChangelogFnType>;
};

const findCustomer = (id: ModelID, search: any = {}) =>
  get<Customer>(`${featurePath}/${id}`, search);

const getChangelog = async (
  customer: Customer,
  search?: CustomerSearchParams,
): Promise<ChangeLogNode[]> => {
  const response = await get<ChangeLogResponse>(`${featurePath}/${customer.id}/changelog`, search);
  return response.changelog;
};

export const useCustomer = ({ id, search = {}, config = {} }: UseCustomerOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: featureKeys.detail(+id),
    queryFn: () => findCustomer(id, search),
  });
};

export const useCustomerChangelog = ({
  customer,
  search,
  config = {},
}: UseCustomerChangelogOptions) => {
  return useQuery<ChangeLogNode[]>({
    ...config,
    queryKey: [{ scope: 'customers', entity: 'changelog', id: customer.id, search }],
    queryFn: () => getChangelog(customer, search),
  });
};
