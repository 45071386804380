import { Service } from '@/types';
import { formatMoney, mapsAreEqual } from '@/utils/format';
import { CheckIcon, XCircleIcon } from '@heroicons/react/24/outline';

type ServicePricingProps = {
  service: Service;
};

type AdvancedServicePricingProps = {
  service: Service;
  duration: any;
};

export const ServicePricing = ({ service }: ServicePricingProps) => {
  const hasTimeSlotAdjustments = Object.keys(service.timeslots).length > 0;

  return hasTimeSlotAdjustments ? (
    <>
      {service.durations.map((duration: any) => (
        <AdvancedServicePricing
          key={`service-pricing-${service.id}-${duration.duration}`}
          service={service}
          duration={duration}
        />
      ))}
    </>
  ) : (
    <BasicServicePricing service={service} />
  );
};

export const BasicServicePricing = ({ service }: ServicePricingProps) => {
  return (
    <table className="table-auto w-full divide-y divide-gray-300 bg-white border">
      <thead>
        <tr>
          <th className="text-left p-2">Duration</th>
          <th className="capitalize text-center p-2">Price</th>
          <th className="capitalize text-center p-2">OLB?</th>
        </tr>
      </thead>
      <tbody>
        {service.durations.map((duration: any) => (
          <tr key={`price-${service.id}-${duration.duration}`} className="even:bg-gray-100">
            <td className="text-left px-2">{duration.duration / 60} Hour Party</td>
            <td className="text-center px-2">{formatMoney(duration.price)}</td>
            <td className="text-center px-2">
              <span className="block w-10 mx-auto">
                {duration.olb ? (
                  <CheckIcon className="w-4 h-4" />
                ) : (
                  <XCircleIcon className="w-4 h-4" />
                )}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const AdvancedServicePricing = ({ service, duration }: AdvancedServicePricingProps) => {
  const basePrice =
    service.durations.find((d: any) => d.duration === duration.duration)?.price ?? null;

  const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].filter((day) =>
    service.days_allowed?.length > 0 ? service.days_allowed.includes(day) : true,
  );

  const startTimes = [] as string[];
  const pricingMap = new Map<string, Map<string, number>>();
  daysOfWeek.map((day) => {
    const priceAdjustments = service.timeslots[day] ?? null;
    const dayMap = new Map();

    if (!priceAdjustments && service.days_allowed && service.days_allowed?.length > 0) {
      return;
    }

    if (!priceAdjustments && (!service.days_allowed || service.days_allowed?.length === 0)) {
      ['10:00', '13:00', '16:00', '19:00'].map((time) => {
        startTimes.push(time);
        dayMap.set(time, parseFloat(basePrice));
      });
    } else {
      priceAdjustments.map((adjustment: any) => {
        startTimes.push(adjustment.start);

        dayMap.set(adjustment.start, parseFloat(basePrice) + parseFloat(adjustment.value ?? 0));
      });
    }

    pricingMap.set(day, dayMap);
  });

  const condensedMap = new Map<string, Map<string, number>>();
  let combinerKey = `${daysOfWeek[0]}-${daysOfWeek[0]}`;
  daysOfWeek.forEach((day) => {
    if (
      mapsAreEqual(condensedMap.get(combinerKey) ?? new Map(), pricingMap.get(day) ?? new Map())
    ) {
      condensedMap.delete(combinerKey);
      combinerKey = `${combinerKey.split('-')[0]}-${day}`;
      condensedMap.set(combinerKey, pricingMap.get(day) ?? new Map());
    } else {
      combinerKey = `${day}-${day}`;
      condensedMap.set(combinerKey, pricingMap.get(day) ?? new Map());
    }
  });

  const uniqueStartTimes = [...new Set(startTimes)].sort();

  return (
    <>
      <div className="flex flex-row justify-between">
        <h2 className="text-base font-semibold">{duration.duration / 60} Hour Party</h2>
        <div className="flex align-baseline">
          <span>OLB:</span>
          <span className="mt-1">
            {duration.olb ? <CheckIcon className="w-4 h-4" /> : <XCircleIcon className="w-4 h-4" />}
          </span>
        </div>
      </div>
      <div>
        <table className="table-auto w-full divide-y divide-gray-300 bg-white border">
          <thead>
            <tr>
              <th className="text-left p-2">Time</th>
              {Array.from(condensedMap.entries()).map(([day, pricing]) => {
                const displayDay =
                  day.split('-')[0] === day.split('-')[1]
                    ? day.split('-')[0]
                    : day.split('-').join(' - ');
                return (
                  <th
                    key={`adv-price-${service.id}-${displayDay}`}
                    className="capitalize text-center p-2"
                  >
                    {displayDay}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {uniqueStartTimes.map((startTime: any) => {
              const time = startTime.split(':');

              const hour = parseInt(time[0]) > 12 ? parseInt(time[0]) - 12 : parseInt(time[0]);
              const ampm = parseInt(time[0]) >= 12 ? 'pm' : 'am';
              const displayTime = `${hour}:${time[1]}${ampm}`;

              return (
                <tr key={`adv-price-${service.id}-${startTime}`} className="even:bg-gray-100">
                  <td className="text-left px-2">{displayTime}</td>
                  {Array.from(condensedMap.entries()).map(([day, pricing]) => {
                    const price = pricing.get(startTime);

                    return (
                      <td
                        key={`adv-price-${service.id}-${day}-${startTime}`}
                        className="text-center px-2"
                      >
                        {price ? formatMoney(price) : '--'}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
