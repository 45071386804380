import { PolicyCheck } from '@/features/auth/authorization';

export const BlackoutPolicies: Record<string, PolicyCheck> = {
  'equipment.blackouts.view': ({ acl, user, meta }) => {
    return acl.equipment.blackouts.view;
  },
  'equipment.blackouts.create': ({ acl, user, meta }) => {
    return acl.equipment.blackouts.create;
  },
  'equipment.blackouts.update': ({ acl, user, meta }) => {
    return acl.equipment.blackouts.edit;
  },
  'equipment.blackouts.delete': ({ acl, user, meta }) => {
    return acl.equipment.blackouts.delete;
  },
};
