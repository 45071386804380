import { ACL } from '.';
export const ACL_ROLE_NEWBIE = 'Newbie';

export const NewbieACL: ACL = {
  role: ACL_ROLE_NEWBIE,
  is_superadmin: false,
  is_admin: false,
  dashboard: {
    view: false,
    news: false,
    motd: false,
  },
  calendar: {
    view: false,
    notes: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
  },
  scheduler: {
    view: false,
    edit: false,
  },
  map: {
    view: false,
  },
  leads: {
    view: false,
    create: false,
    edit: false,
    transfer: false,
    history: false,
    export: false,
  },
  events: {
    view: false,
    create: false,
    edit: false,
    cancel: false,
    confirm: false,
    transfer: false,
    history: false,
    export: false,
  },
  customers: {
    view: false,
    edit: false,
    history: false,
    lists: {
      view: false,
      create: false,
      edit: false,
      delete: false,
      export: false,
    },
    rewards: {
      view: false,
      approve: false,
    },
  },
  messages: {
    view: false,
    send: false,
  },
  payments: {
    view: false,
    create: false,
    edit: false,
    refund: false,
  },
  payment_methods: {
    view: false,
    create: false,
    edit: false,
    delete: false,
  },
  products: {
    view: false,
    services: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    addons: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    packages: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    coupons: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
  },
  equipment: {
    view: false,
    assets: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    depots: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    vehicles: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    inspections: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    maintenance: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    gamescans: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    blackouts: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
  },
  settings: {
    view: false,
    edit: false,
  },
  franchisor: {
    view: false,
  },
  users: {
    view: false,
    create: false,
    edit: false,
    delete: false,
    grant: false,
  },
  reports: {
    view: false,
    beta: false,
  },
  help: {
    view: false,
  },
  ordering: {
    view: false,
  },
  resources: {
    view: false,
    directory: {
      view: false,
      internal: {
        view: false,
      },
    },
    store: {
      view: false,
    },
  },
};
