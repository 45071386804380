import { Badge, BadgeProps } from '@/components/ui/elements/badge';
import { Event } from '@/types';
import { HandThumbUpIcon } from '@heroicons/react/24/solid';

interface StatusBadgeProps {
  event: Event;
  size?: 'xs' | 'sm' | 'lg';
}

export const StatusBadge = ({ event, size = 'sm' }: StatusBadgeProps) => {
  let variant = 'blue' as BadgeProps['variant'];
  const condition = event.is_waiting ? 'orange' : event.is_snoozed ? 'yellow' : event.status;
  switch (condition) {
    case 'Collections':
      variant = 'orange';
      break;
    case 'Cancelled':
    case 'Canceled':
      variant = 'red';
      break;
    case 'Requested':
    case 'Verified':
      variant = 'blue';
      break;
    case 'Confirmed':
      variant = 'green';
      break;
    default:
      variant = 'blue';
      break;
  }

  let statusText = event.status;

  if (event.is_snoozed) {
    statusText = `Snoozed / ${event.status}`;
  }

  if (event.is_waiting) {
    statusText = `Waiting / ${event.status}`;
  }

  if (event.is_snoozed && event.is_waiting) {
    statusText = `Waiting (Snoozed) / ${event.status}`;
  }

  return (
    <Badge size={size} variant={variant}>
      {statusText}
      {!!event.local_verified_at && <HandThumbUpIcon className="w-3 h-3 ml-1" />}
    </Badge>
  );
};
