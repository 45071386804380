import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { LocationUserAccess, ModelID } from '@/types';
import { get } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { featureKeys, featurePath, LocationUserAccessSearchParams } from '.';

type QueryFnType = typeof fetchLocationUserAccesses;

type UseLocationUserAccesssOptions = {
  location: ModelID;
  search?: LocationUserAccessSearchParams;
  config?: QueryConfig<QueryFnType>;
};

export const fetchLocationUserAccesses = (
  location: ModelID,
  search: LocationUserAccessSearchParams = {},
) => get<LocationUserAccess[]>(`${featurePath}?franchise_id=${location}`, search);

export const useLocationUserAccesses = ({
  location,
  search = {},
  config = {},
}: UseLocationUserAccesssOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: featureKeys.list(location, search),
    queryFn: () => fetchLocationUserAccesses(location, search),
  });
};
