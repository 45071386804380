import {
  UpdateDTO,
  useUpdateMutationFactory,
  UseUpdateMutationProps,
} from '@/lib/react-query/update';
import { MessageTemplate } from '@/types';
import { update } from '@/utils/api';
import { featureKeys, featurePath, MessageTemplateSearchParams } from '.';

export type UpdateMessageTemplateDTO = UpdateDTO<MessageTemplate, MessageTemplateSearchParams>;

const updateMessageTemplate = ({ data, id, params = {} }: UpdateMessageTemplateDTO) =>
  update<MessageTemplate>(`${featurePath}/${id}`, data, params);

export const useUpdateMessageTemplate = (options?: UseUpdateMutationProps<MessageTemplate>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: updateMessageTemplate,
    ...options,
  });
};
