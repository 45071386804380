import { BellSlashIcon } from '@heroicons/react/24/outline';
import { Button, ButtonProps } from '../ui/elements/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/elements/tooltip';

type ClearWaitButtonProps = ButtonProps & {
  onClick: () => void;
};

export const ClearWaitButton = ({ onClick, variant, size, ...props }: ClearWaitButtonProps) => {
  return (
    <TooltipProvider delayDuration={50}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button variant={variant || 'outline'} size={size || 'sm'} onClick={onClick} {...props}>
            <BellSlashIcon className="w-5 h-5 text-gray-700" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Clear Wait</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
