import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type Notification = {
  title: string;
  message?: string;
  link?: string;
};

type NotificationRecord = {
  id: number;
  read: boolean;
} & Notification;

type NotificationStore = {
  notifications: NotificationRecord[];
  add: (notification: Notification) => void;
  delete: (id: number) => void;
  markAsRead: (id: number) => void;
  markAllAsRead: () => void;
  clear: () => void;
};

const useNotificationStore = create<NotificationStore>()(
  persist(
    (set) => ({
      notifications: [],
      add: (notification) =>
        set((state) => ({
          // add the new notification to the top of the list
          // But only keep a maximum of 200 notifications
          notifications: [
            { id: Date.now(), ...notification, read: false },
            ...state.notifications.slice(0, 200),
          ],
        })),
      markAsRead: (id) =>
        set((state) => ({
          notifications: state.notifications.map((notification) =>
            notification.id === id ? { ...notification, read: true } : notification,
          ),
        })),
      markAllAsRead: () =>
        set((state) => ({
          notifications: state.notifications.map((notification) => ({
            ...notification,
            read: true,
          })),
        })),
      delete: (id: number) =>
        set((state) => ({
          notifications: state.notifications.filter((notification) => notification.id !== id),
        })),
      clear: () => set(() => ({ notifications: [] })),
    }),
    {
      name: 'notifications-storage', // name of the item in the storage (must be unique)
      // partialize: (state) => ({
      //   startDate: state.startDate,
      //   endDate: state.endDate,
      //   mode: state.mode,
      // }),
      // merge: (persistedState, currentState) => {
      //   const hydrator = persistedState as PersistedCalendarStore;
      //   return {
      //     ...currentState,
      //     mode: hydrator.mode,
      //     startDate: new Date(hydrator.startDate),
      //     endDate: new Date(hydrator.endDate),
      //   };
      // },
    },
  ),
);

export default useNotificationStore;
