import { usePaymentMethods } from '@/api/customers/payment-methods/getPaymentMethods';
import { useUpdatePayment } from '@/api/customers/payments/updatePayment';
import { ChargeMode, PaymentType, useProcessPayment } from '@/api/events/events/updateEvent';
import { Table } from '@/components/Elements';
import ButtonGroup from '@/components/Elements/ButtonGroup/ButtonGroup';
import { FormInput } from '@/components/Form';
import { FormRadioGroup, RadioGroupOption } from '@/components/Form/FormRadioGroup';
import { Button } from '@/components/ui/elements/button';
import {
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/elements/select';
import { Form } from '@/components/ui/form/form';
import { PolicyGate, useAuthorization } from '@/features/auth/authorization';
import { EventContext } from '@/features/events/contexts/EventContext';
import { Event, Payment } from '@/types';
import { cn, formatApiDate, formatMoney } from '@/utils/format';
import { zodResolver } from '@hookform/resolvers/zod';
import { Select } from '@radix-ui/react-select';
import { formatDistanceToNow } from 'date-fns';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { BalanceDueBadge } from '../../Badges/BalanceDueBadge';
import { RefundPaymentDialog } from '../RefundPaymentDialog';

interface PaymentsTabProps {
  event: Event;
}

const appPaymentMethodOptions = [
  { value: 'Venmo', label: 'Venmo' },
  { value: 'Zelle', label: 'Zelle' },
  { value: 'Cash App', label: 'Cash App' },
  { value: 'PayPal', label: 'PayPal' },
  { value: 'FedNow', label: 'FedNow' },
  { value: 'OtherP2P', label: 'Other' },
];

const offlinePaymentOptions = [
  { value: 'Offline', label: 'Offline' },
  { value: 'Offline - Card Reader', label: 'Offline - Card Reader' },
];

const schema = z.object({
  amount: z.coerce.number().gte(0),
  gratuity: z.coerce.number().gte(0),
  method: z.string(),
  type: z.string(),
  check_number: z.string().nullish(),
  payment_method_id: z.coerce.string().nullish(),
});

const ChargeModeOptions = [
  { value: 'Deposit', label: 'Deposit' },
  { value: 'Partial', label: 'Partial' },
  { value: 'Final', label: 'Final' },
];

export const PaymentsTab = () => {
  const { event } = useContext(EventContext);

  if (!event) return <>Loading...</>;

  return <Payments event={event} />;
};

const Payments = ({ event }: PaymentsTabProps) => {
  const [chargeMode, setChargeMode] = useState<ChargeMode>('Partial');
  const [paymentType, setPaymentType] = useState<PaymentType>('CreditCard');
  const [showRefundDialog, setShowRefundDialog] = useState(false);
  const [pendingRefund, setPendingRefund] = useState<Payment>();
  const { checkPolicy } = useAuthorization();

  const canRefund = checkPolicy({ policy: 'payments.refund' });
  const canEditPayments = checkPolicy({ policy: 'payments.update' });

  const cardsDisabled = chargeMode === 'Refund';

  const { mutate: processPayment, isPending: isProcessingPayment } = useProcessPayment();
  const { mutate: updatePayment } = useUpdatePayment();

  const { data: paymentMethods } = usePaymentMethods({
    location: event.franchise_id as number,
    search: { customer_id: event.customer_id, orderBy: 'created_at', orderDir: 'desc' },
  });

  let paymentMethodOptions = [] as RadioGroupOption[];
  switch (paymentType) {
    case 'CreditCard':
      paymentMethodOptions =
        paymentMethods?.map((paymentMethod) => ({
          value: paymentMethod.id,
          label: `${paymentMethod.brand} ${paymentMethod.last4} - ${paymentMethod.exp_month}/${
            paymentMethod.exp_year
          } | added ${formatDistanceToNow(new Date(paymentMethod.created_at.replace(' ', 'T')), {
            includeSeconds: true,
            addSuffix: true,
          })}`,
        })) ?? [];
      break;
    case 'Cash':
    case 'Check':
      paymentMethodOptions = [];
      break;
    case 'App':
      paymentMethodOptions = appPaymentMethodOptions;
      break;
    case 'Offline':
      paymentMethodOptions = offlinePaymentOptions;
      break;
  }

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      amount: event.balance_due ?? 0.0,
      gratuity: 0.0,
      method: paymentType,
      type: chargeMode,
      check_number: '',
      payment_method_id: null,
    },
  });

  const [watchedAmount, watchedGratuity] = form.watch(['amount', 'gratuity']);
  let amountToCharge = 0.0;
  if (watchedAmount && watchedAmount > 0) {
    amountToCharge += parseFloat(watchedAmount.toString());
  }
  if (watchedGratuity && watchedGratuity > 0) {
    amountToCharge += parseFloat(watchedGratuity.toString());
  }

  const selectChargeMode = (mode: ChargeMode) => {
    setChargeMode(mode);
    form.setValue('type', mode);
    switch (mode) {
      // case 'Deposit':
      //   break;
      // case 'Partial':
      //   break;
      // case 'Final':
      //   break;
      case 'Refund':
        if (paymentType === 'CreditCard') setPaymentType('Offline');
        form.setValue('payment_method_id', null);
        break;
    }
  };

  const selectPaymentType = (type: PaymentType) => {
    setPaymentType(type);
    form.setValue('payment_method_id', null);
    switch (type) {
      case 'CreditCard':
        form.setValue('method', 'CreditCard');
        break;
      case 'Cash':
        form.setValue('method', 'Cash');
        break;
      case 'Check':
        form.setValue('method', 'Check');
        break;
      case 'App':
        form.setValue('method', 'Venmo');
        break;
      case 'Offline':
        form.setValue('method', 'Offline');
        break;
    }
  };

  const selectGratuity = (gratuity: number) => {
    form.setValue('gratuity', parseFloat((event.total * gratuity).toFixed(2)));
  };

  const handleProcessPayment = (data: z.infer<typeof schema>) => {
    processPayment(
      {
        id: event.id,
        data: {
          amount: data.amount,
          gratuity: data.gratuity,
          method: data.method,
          type: data.type as ChargeMode,
          check_number: data.check_number,
          payment_method_id: data.payment_method_id ? parseInt(data.payment_method_id) : null,
        },
      },
      {
        onSuccess: () => {
          form.reset({
            amount: 0.0,
            gratuity: 0.0,
            method: 'Cash',
          });
          setChargeMode('Partial');
          setPaymentType('Cash');
        },
      },
    );
  };

  const handlePaymentTypeChange = (value: string, payment: Payment) => {
    updatePayment({
      id: payment.id,
      data: {
        type: value,
        method: payment.method,
      },
    });
  };
  const handleRefundClick = (payment: Payment) => {
    setPendingRefund(payment);
    setShowRefundDialog(true);
  };

  return (
    <div className="@container">
      <div className="flex flex-col @2xl:flex-row space-x-2">
        <PolicyGate policy="payments.create">
          <div className="w-full pt-4 mr-4 @2xl:max-w-sm">
            <div className="flex flex-row justify-between">
              <h2>Payment Details</h2>
              <BalanceDueBadge event={event} />
            </div>
            <Form key={`payment-eid-${event.id}`} {...form}>
              <form onSubmit={form.handleSubmit(handleProcessPayment)} className="space-y-3">
                <div className="py-4 px-1 flex flex-col space-y-3">
                  <FormInput
                    control={form.control}
                    name="amount"
                    type="number"
                    step={0.01}
                    label="Amount to Charge"
                  />

                  <FormInput
                    control={form.control}
                    name="gratuity"
                    type="number"
                    step={0.01}
                    label="Gratuity"
                  />
                  <div className="flex flex-row">
                    <ButtonGroup>
                      <ButtonGroup.Button
                        className="text-base font-normal"
                        onClick={() => selectGratuity(0.1)}
                        isActive={form.getValues('gratuity') === event.total * 0.1}
                        isFirst
                      >
                        10% {formatMoney(event.total * 0.1)}
                      </ButtonGroup.Button>
                      <ButtonGroup.Button
                        className="text-base font-normal"
                        onClick={() => selectGratuity(0.15)}
                        isActive={form.getValues('gratuity') === event.total * 0.15}
                      >
                        15% {formatMoney(event.total * 0.15)}
                      </ButtonGroup.Button>
                      <ButtonGroup.Button
                        className="text-base font-normal"
                        onClick={() => selectGratuity(0.18)}
                        isActive={form.getValues('gratuity') === event.total * 0.18}
                      >
                        18% {formatMoney(event.total * 0.18)}
                      </ButtonGroup.Button>
                      <ButtonGroup.Button
                        className="text-base font-normal"
                        onClick={() => selectGratuity(0.2)}
                        isActive={form.getValues('gratuity') === event.total * 0.2}
                        isLast
                      >
                        20% {formatMoney(event.total * 0.2)}
                      </ButtonGroup.Button>
                    </ButtonGroup>
                  </div>

                  <FormInput type="hidden" control={form.control} name="method" />
                  <div className="flex flex-row">
                    <ButtonGroup>
                      <ButtonGroup.Button
                        className={'text-base font-normal'}
                        onClick={() => selectChargeMode('Deposit')}
                        isActive={chargeMode === 'Deposit'}
                        isFirst
                      >
                        Deposit
                      </ButtonGroup.Button>
                      <ButtonGroup.Button
                        className="text-base font-normal"
                        onClick={() => selectChargeMode('Partial')}
                        isActive={chargeMode === 'Partial'}
                      >
                        Partial
                      </ButtonGroup.Button>
                      <ButtonGroup.Button
                        className="text-base font-normal"
                        onClick={() => selectChargeMode('Final')}
                        isActive={chargeMode === 'Final'}
                      >
                        Final
                      </ButtonGroup.Button>
                      <ButtonGroup.Button
                        className="text-base font-normal"
                        onClick={() => selectChargeMode('Refund')}
                        isActive={chargeMode === 'Refund'}
                        isLast
                      >
                        Refund
                      </ButtonGroup.Button>
                    </ButtonGroup>
                  </div>

                  <FormInput type="hidden" control={form.control} name="type" />
                  <div className="flex flex-row">
                    <ButtonGroup>
                      <ButtonGroup.Button
                        className={'text-base font-normal'}
                        onClick={() => selectPaymentType('Cash')}
                        isActive={paymentType === 'Cash'}
                        isFirst
                      >
                        Cash
                      </ButtonGroup.Button>
                      <ButtonGroup.Button
                        className={'text-base font-normal'}
                        onClick={() => selectPaymentType('Check')}
                        isActive={paymentType === 'Check'}
                      >
                        Check
                      </ButtonGroup.Button>
                      <ButtonGroup.Button
                        className={cn(
                          'text-base font-normal',
                          chargeMode === 'Refund' ? 'line-through' : '',
                        )}
                        onClick={() => selectPaymentType('CreditCard')}
                        isActive={paymentType === 'CreditCard'}
                        disabled={cardsDisabled}
                      >
                        Card
                      </ButtonGroup.Button>
                      <ButtonGroup.Button
                        className={'text-base font-normal'}
                        onClick={() => selectPaymentType('App')}
                        isActive={paymentType === 'App'}
                      >
                        App
                      </ButtonGroup.Button>
                      <ButtonGroup.Button
                        className={'text-base font-normal'}
                        onClick={() => selectPaymentType('Offline')}
                        isActive={paymentType === 'Offline'}
                        isLast
                      >
                        Offline
                      </ButtonGroup.Button>
                    </ButtonGroup>
                  </div>

                  {paymentMethodOptions.length > 0 && paymentType !== 'CreditCard' && (
                    <FormRadioGroup
                      control={form.control}
                      name="method"
                      label="Payment Method"
                      options={paymentMethodOptions}
                    />
                  )}

                  {paymentMethodOptions.length > 0 && paymentType === 'CreditCard' && (
                    <FormRadioGroup
                      control={form.control}
                      name="payment_method_id"
                      label="Payment Method"
                      options={paymentMethodOptions}
                    />
                  )}

                  {paymentType === 'Check' && (
                    <FormInput
                      control={form.control}
                      name="check_number"
                      type="number"
                      label="Check Number"
                    />
                  )}
                </div>

                <Button
                  className="w-full"
                  size="xl"
                  type="submit"
                  disabled={isProcessingPayment || !form.formState.isValid || amountToCharge <= 0}
                >
                  Charge: {formatMoney(amountToCharge)}
                </Button>
              </form>
            </Form>
          </div>
        </PolicyGate>
        <PolicyGate policy="payments.view">
          <div className="grow max-w-sm md:max-w-full">
            <Table<Payment>
              data={event.payments}
              columns={[
                {
                  title: 'Date',
                  field: 'created_at',
                  Cell({ entry: { created_at } }) {
                    return <>{formatApiDate(created_at)}</>;
                  },
                },
                {
                  title: 'Amount',
                  field: 'amount',
                  Cell({ entry: { amount } }) {
                    return <>{formatMoney(amount)}</>;
                  },
                },
                {
                  title: 'Gratuity',
                  field: 'gratuity',
                  Cell({ entry: { gratuity } }) {
                    return <>{formatMoney(gratuity)}</>;
                  },
                },
                {
                  title: 'Method',
                  field: 'method',
                  Cell({ entry: { method, check_number } }) {
                    return (
                      <>
                        {method} {check_number}
                      </>
                    );
                  },
                },
                {
                  title: 'Type',
                  field: 'type',
                  Cell({ entry }) {
                    if (!canEditPayments) return <span>{entry.type}</span>;
                    return (
                      <>
                        {entry.type === 'Refund' ? (
                          <span className="">{entry.type}</span>
                        ) : (
                          <Select
                            onValueChange={(value) => handlePaymentTypeChange(value, entry)}
                            defaultValue={entry.type}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Payment Type" />
                            </SelectTrigger>
                            <SelectContent className="max-h-96">
                              {ChargeModeOptions.map((option) => (
                                <SelectItem
                                  key={`select-${option.label}` + entry.id}
                                  value={option.value}
                                >
                                  {option.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )}
                      </>
                    );
                  },
                },
                {
                  title: 'Refund',
                  field: 'id',
                  Cell({ entry }) {
                    if (!canRefund) return <></>;
                    return (
                      <>
                        {entry.type !== 'Refund' && entry.voided !== true && (
                          <Button variant="destructive" onClick={() => handleRefundClick(entry)}>
                            Refund
                          </Button>
                        )}
                        {entry.voided === true && <span>Refunded</span>}
                      </>
                    );
                  },
                },
              ]}
            />
            {pendingRefund && (
              <RefundPaymentDialog
                event={event}
                payment={pendingRefund}
                open={showRefundDialog}
                onOpenChange={setShowRefundDialog}
                onClose={() => setPendingRefund(undefined)}
              />
            )}
          </div>
        </PolicyGate>
      </div>
    </div>
  );
};
