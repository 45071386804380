import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/')({
  beforeLoad: ({ context, location }) => {
    if (!context.auth.authenticated) {
      throw redirect({
        to: '/login',
        search: {
          redirect: location.href,
        },
      });
    } else {
      throw redirect({
        to: '/dashboard',
        search: {
          redirect: location.href,
        },
      });
    }
  },
});
