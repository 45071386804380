import { Link } from '@/components/Elements';
import { FormInput } from '@/components/Form';
import { Button } from '@/components/ui/elements/button';
import { Form } from '@/components/ui/form/form';
import { AuthContext } from '@/providers/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { Navigate, useSearch } from '@tanstack/react-router';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const schema = z.object({
  email: z.string().min(3, 'Required'),
  password: z.string().min(3, 'Required'),
});

export const Login = () => {
  const { authenticated, login } = useContext(AuthContext);

  const { email, redirect } = useSearch({
    strict: false,
  });
  const handleSubmit = (values: z.infer<typeof schema>) => login(values.email, values.password);

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: email ?? '',
      password: '',
    },
  });

  const intended = redirect ?? '/dashboard';

  if (authenticated) {
    return <Navigate to={intended} />;
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
        <div>
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
            Email address
          </label>
          <div className="mt-2">
            <FormInput control={form.control} name="email" />
          </div>
        </div>

        <div>
          <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
            Password
          </label>
          <div className="mt-2">
            <FormInput
              control={form.control}
              name="password"
              type="password"
              autoComplete="current-password"
            />
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600"
            />
            <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">
              Remember me
            </label>
          </div>

          <div className="text-sm leading-6">
            <Link
              to="/password/forgot"
              className="font-semibold text-primary-600 hover:text-primary-500"
            >
              Forgot password?
            </Link>
          </div>
        </div>

        <div>
          <Button type="submit">Sign in</Button>
        </div>
      </form>
    </Form>
  );
};
