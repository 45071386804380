import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Motd } from '@/types';
import { get } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { featureKeys, featurePath } from '.';

type QueryFnType = typeof getLatestMotd;

type UseMotdOptions = {
  config?: QueryConfig<QueryFnType>;
};

const getLatestMotd = () => get<Motd>(`${featurePath}/latest`);

export const useMotd = ({ config = {} }: UseMotdOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: featureKeys.latest,
    queryFn: getLatestMotd,
    staleTime: 1000 * 60 * 60 * 8, // 8 hours
  });
};
