import {
  UpdateDTO,
  useUpdateMutationFactory,
  UseUpdateMutationProps,
} from '@/lib/react-query/update';
import { PaymentMethod } from '@/types';
import { update } from '@/utils/api';
import { featureKeys, featurePath, PaymentMethodSearchParams } from '.';

type PaymentMethodToken = PaymentMethod & {
  token: string;
};

export type UpdatePaymentMethodDTO = UpdateDTO<PaymentMethodToken, PaymentMethodSearchParams>;

const updatePaymentMethod = ({ data, id, params = {} }: UpdatePaymentMethodDTO) =>
  update<PaymentMethod>(`${featurePath}/${id}`, data, params);

export const useUpdatePaymentMethod = (options?: UseUpdateMutationProps<PaymentMethod>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: updatePaymentMethod,
    ...options,
  });
};
