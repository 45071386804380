export const NewsFeed = () => {
  return (
    <iframe
      title="News Feed"
      src="https://gametruck847.workplace.com/plugins/news_feed/1469428397183238/?workplace_plugin_mobile=1"
      width="100%"
      height="500"
      style={{ border: 'none', overflow: 'auto' }}
      allow="encrypted-media"
      sandbox="allow-same-origin allow-scripts allow-popups-to-escape-sandbox allow-popups allow-storage-access-by-user-activation"
    />
  );
};
