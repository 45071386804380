import {
  UpdateDTO,
  useUpdateMutationFactory,
  UseUpdateMutationProps,
} from '@/lib/react-query/update';
import { Pin } from '@/types';
import { update } from '@/utils/api';
import { featureKeys, featurePath, PinSearchParams } from '.';

export type UpdatePinDTO = UpdateDTO<Pin, PinSearchParams>;

const updatePin = ({ data, id, params = {} }: UpdatePinDTO) =>
  update<Pin>(`${featurePath}/${id}`, data, params);

export const useUpdatePin = (options?: UseUpdateMutationProps<Pin>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: updatePin,
    ...options,
  });
};
