import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Lead, ModelID } from '@/types';
import { get, getPaginated, PaginatedResource } from '@/utils/api';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { featureKeys, featurePath, LeadSearchParams } from '.';

type QueryFnType = typeof fetchLeads;
type PagedQueryFnType = typeof fetchPagedLeads;

type UseLeadsOptions = {
  location: ModelID;
  search?: LeadSearchParams;
  config?: QueryConfig<QueryFnType>;
};

type UsePagedLeadsOptions = {
  location: ModelID;
  search?: LeadSearchParams;
  config?: QueryConfig<PagedQueryFnType>;
};

export const fetchLeads = (location: ModelID, search?: LeadSearchParams) =>
  get<Lead[]>(`${featurePath}?franchise_id=${location}`, search);

export const fetchPagedLeads = (location: ModelID, search?: LeadSearchParams) =>
  getPaginated<Lead>(`${featurePath}?franchise_id=${location}`, search);

export const useLeads = ({ location, search, config = {} }: UseLeadsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: featureKeys.list(location, search),
    queryFn: () => fetchLeads(location, search),
  });
};

export const usePagedLeads = ({ location, search, config = {} }: UsePagedLeadsOptions) => {
  return useQuery<ExtractFnReturnType<PagedQueryFnType>, Error, PaginatedResource<Lead>, any>({
    ...config,
    queryKey: featureKeys.paged(location, search),
    queryFn: () => fetchPagedLeads(location, search),
    placeholderData: keepPreviousData,
    staleTime: 1000 * 5,
  });
};
