import { Head } from '@/components/Head';
import Page from '@/components/Layout/Page';
import { LocationCalendar } from '@/features/calendar/components/LocationCalendar';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/calendar')({
  component: Calendar,
});

function Calendar() {
  return (
    <Page className="py-0">
      <Head title="Calendar" />
      <Page.Body policy="calendar.view" className="py-0">
        <LocationCalendar />
      </Page.Body>
    </Page>
  );
}
