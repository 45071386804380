import { cn } from '@/utils/format';
import { StarIcon } from '@heroicons/react/24/solid';

interface StarRatingProps {
  rating: number;
}

export const StarRating = ({ rating }: StarRatingProps) => {
  return (
    <div className="flex items-center">
      {
        [...Array(5)]
          .reverse()
          .map((_, index) => (
            <StarIcon
              key={`star-${index}`}
              className={cn('h-4 w-4', index < rating ? 'text-yellow-500' : 'text-gray-300')}
            />
          )) as any[]
      }
    </div>
  );
};
