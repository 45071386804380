import _ from 'lodash';
import { ACL } from '.';
import { GTLicensingACL } from './gtl_staff';
export const ACL_ROLE_ADMINISTRATOR = 'Administrator';

export const AdminACL: ACL = _.merge({}, GTLicensingACL, {
  role: ACL_ROLE_ADMINISTRATOR,
  payments: {
    refund: true,
  },
  ordering: {
    view: false,
  },
  customers: {
    rewards: {
      approve: true,
    },
  },
});
