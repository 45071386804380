import { SUPABASE_ANON_KEY, SUPABASE_URL } from '@/config/globals';
import { createClient } from '@supabase/supabase-js';

let client: ReturnType<typeof createClient<any>> | undefined;

export const getSupabaseClient = () => {
  if (client) {
    return client;
  }

  client = createClient<any>(SUPABASE_URL, SUPABASE_ANON_KEY, {
    realtime: {
      params: {
        eventsPerSecond: 2,
      },
    },
  });

  return client;
};
