import { FormCheckbox, FormDate, FormInput, FormSelect, FormTextarea } from '@/components/Form';
import { FormStateSelect } from '@/components/Form/FormStateSelect';
import { toast } from '@/components/ui/elements/use-toast';
import { Form, FormAutosave } from '@/components/ui/form/form';
import {
  CUSTOMER_BANK_TYPE_OPTIONS,
  CUSTOMER_BANK_TYPE_VALUES,
  CUSTOMER_ORGANIZATION_TYPES,
} from '@/config';
import { CustomerContext } from '@/features/customers/contexts/CustomerContext';
import { Customer } from '@/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormEmailInput } from '../Form/FormEmailInput';
import { FormPhoneInput } from '../Form/FormPhoneInput';
import { Button } from '../ui/elements/button';

interface CustomerEditFormProps {
  customer: Customer;
  onUpdate?: () => void;
  copyFrom?: () => Partial<Customer>;
  expandedMode?: boolean;
}

const schema = z.object({
  first_name: z.string().min(1, 'Required'),
  last_name: z.string(),
  phone: z.string().min(1, 'Required'),
  email: z.string(),
  is_organization: z.coerce.boolean(),
  organization_name: z.string().nullish(),
  organization_type: z.string().nullish(),
  phone_alt: z.string().nullish(),
  address: z.string().nullish(),
  address_line2: z.string().nullish(),
  city: z.string().nullish(),
  state: z.string().nullish(),
  zipcode: z.string().nullish(),
  billing_name: z.string().nullish(),
  billing_phone: z.string().nullish(),
  billing_email: z.string().nullish(),
  billing_address: z.string().nullish(),
  billing_address_line2: z.string().nullish(),
  billing_city: z.string().nullish(),
  billing_state: z.string().nullish(),
  billing_zipcode: z.string().nullish(),
  sms_event_updates: z.coerce.boolean(),
  sms_lead_updates: z.coerce.boolean(),
  do_not_email: z.coerce.boolean(),
  is_married: z.coerce.boolean(),
  spouse_name: z.string().nullish(),
  important_details: z.string().nullish(),
  where_met: z.string().nullish(),
  bank: z.enum(CUSTOMER_BANK_TYPE_VALUES),
  relationship_strength: z.coerce.number().nullish(),
  preferred_staff: z.string().nullish(),
  medical_notes: z.string().nullish(),
  school_name: z.string().nullish(),
  company_name: z.string().nullish(),
  linkedin_profile: z.string().nullish(),
  lastcontact_at: z.string().nullish(),
  followup_at: z.string().nullish(),
});

const populateDefaultValues = (customer: Customer) => {
  return {
    first_name: customer?.first_name ?? '',
    last_name: customer?.last_name ?? '',
    phone: customer?.phone ?? '',
    email: customer?.email ?? '',
    is_organization: customer?.is_organization ?? false,
    organization_name: customer?.organization_name ?? '',
    organization_type: customer?.organization_type ?? '',
    phone_alt: customer?.phone_alt ?? '',
    address: customer?.address ?? '',
    address_line2: customer?.address_line2 ?? '',
    city: customer?.city ?? '',
    state: customer?.state ?? '',
    zipcode: customer?.zipcode ?? '',
    billing_name: customer?.billing_name ?? '',
    billing_phone: customer?.billing_phone ?? '',
    billing_email: customer?.billing_email ?? '',
    billing_address: customer?.billing_address ?? '',
    billing_address_line2: customer?.billing_address_line2 ?? '',
    billing_city: customer?.billing_city ?? '',
    billing_state: customer?.billing_state ?? '',
    billing_zipcode: customer?.billing_zipcode ?? '',
    sms_event_updates: customer?.sms_event_updates ?? true,
    sms_lead_updates: customer?.sms_lead_updates ?? true,
    do_not_email: customer?.do_not_email ?? false,
    is_married: customer?.is_married ?? false,
    spouse_name: customer?.spouse_name ?? '',
    important_details: customer?.important_details ?? '',
    where_met: customer?.where_met ?? '',
    bank: customer?.bank ?? 'Seasonal',
    relationship_strength: customer?.relationship_strength ?? 1,
    preferred_staff: customer?.preferred_staff ?? '',
    medical_notes: customer?.medical_notes ?? '',
    school_name: customer?.school_name ?? '',
    company_name: customer?.company_name ?? '',
    linkedin_profile: customer?.linkedin_profile ?? '',
    lastcontact_at: customer?.lastcontact_at ?? '',
    followup_at: customer?.followup_at ?? '',
  };
};

export const CustomerEditForm = ({
  customer,
  onUpdate,
  copyFrom,
  expandedMode = false,
}: CustomerEditFormProps) => {
  const { updateCustomer } = useContext(CustomerContext);
  const [expanded, setExpanded] = useState(expandedMode);

  const handleCustomerSubmit = (data: Partial<Customer>) => {
    updateCustomer(
      { id: customer.id, data },
      {
        onSuccess: (values) => {
          if (onUpdate) onUpdate();
          toast({
            title: 'Customer updated',
          });
        },
      },
    );
  };

  const handleCopyFromEvent = () => {
    if (!copyFrom) return;

    const values = copyFrom();
    form.setValue('address', values.address ?? '');
    form.setValue('address_line2', values.address_line2 ?? '');
    form.setValue('city', values.city ?? '');
    form.setValue('state', values.state ?? '');
    form.setValue('zipcode', values.zipcode ?? '');

    form.handleSubmit(handleCustomerSubmit)();
  };

  const handleBillingIsSame = () => {
    form.setValue('billing_name', customer.full_name ?? '');
    form.setValue('billing_phone', customer.phone ?? '');
    form.setValue('billing_email', customer.email ?? '');
    form.setValue('billing_address', customer.address ?? '');
    form.setValue('billing_address_line2', customer.address_line2 ?? '');
    form.setValue('billing_city', customer.city ?? '');
    form.setValue('billing_state', customer.state ?? '');
    form.setValue('billing_zipcode', customer.zipcode ?? '');

    form.handleSubmit(handleCustomerSubmit)();
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: populateDefaultValues(customer),
  });

  const watchedOrg = form.watch('is_organization');

  const OrganizationTypeOptions = CUSTOMER_ORGANIZATION_TYPES;

  // Save changes when leaving this page!
  useEffect(() => {
    return () => {
      if (form.formState.isDirty) {
        form.handleSubmit(handleCustomerSubmit)();
      }
    };
  }, []);

  if (!customer) return <>'Loading...'</>;

  return (
    <Form key={customer.id} {...form}>
      <FormAutosave interval={50} onAutosave={form.handleSubmit(handleCustomerSubmit)}>
        <form onSubmit={form.handleSubmit(handleCustomerSubmit)} className="@contianer space-y-3">
          <div className="flex flex-col space-y-3 py-4">
            <div className="flex flex-col space-y-3 md:flex-row md:space-x-2 md:space-y-0">
              <FormInput control={form.control} name="first_name" label="First Name" />
              <FormInput control={form.control} name="last_name" label="Last Name" />
              <FormCheckbox control={form.control} name="is_organization" label="Business?" />
            </div>
            {(customer.is_organization || watchedOrg) && (
              <>
                <FormInput
                  control={form.control}
                  name="organization_name"
                  label="Organization Name"
                />
                <FormSelect
                  control={form.control}
                  name="organization_type"
                  label="Organization Type"
                  options={OrganizationTypeOptions}
                />
              </>
            )}
            <div className="flex flex-col space-y-3 @md:flex-row md:space-x-2 md:space-y-0">
              <FormPhoneInput
                control={form.control}
                name="phone"
                label="Phone"
                phoneType={customer.phone_type}
                phoneValid={customer.phone_valid !== 0}
              />
              <FormInput control={form.control} name="phone_alt" label="Phone alt" />
              <FormEmailInput control={form.control} name="email" label="Email" />
            </div>
            <FormInput control={form.control} name="address" label="Mailing Address" />
            <FormInput control={form.control} name="address_line2" label="Address Line 2" />
            <div className="flex flex-row space-x-2">
              <FormInput control={form.control} name="city" label="City" />
              <div className="w-full max-w-48">
                <FormStateSelect control={form.control} name="state" label="State" style="abbr" />
              </div>
              <div className="w-full max-w-64">
                <FormInput control={form.control} name="zipcode" label="Zip" />
              </div>
            </div>
            <div>
              {copyFrom && (
                <Button type="button" variant="outline" size="sm" onClick={handleCopyFromEvent}>
                  Copy from Event
                </Button>
              )}
            </div>
            <div className="flex flex-col @md:flex-row">
              <FormCheckbox
                control={form.control}
                name="sms_event_updates"
                label="Event updates by text"
              />
              <FormCheckbox
                control={form.control}
                name="sms_lead_updates"
                label="Lead updates by text"
              />
              <FormCheckbox control={form.control} name="do_not_email" label="Do Not Email" />
            </div>

            <h2 className="pt-6">Billing Address</h2>
            <FormInput control={form.control} name="billing_name" label="Billing Name" />
            <div className="flex flex-col space-y-3 md:flex-row md:space-x-2 md:space-y-0">
              <FormInput control={form.control} name="billing_phone" label="Phone" />
              <FormInput control={form.control} name="billing_email" label="Email" />
            </div>
            <FormInput control={form.control} name="billing_address" label="Billing Address" />
            <FormInput control={form.control} name="billing_address_line2" label="Address Line 2" />
            <div className="flex flex-row space-x-2">
              <FormInput control={form.control} name="billing_city" label="City" />
              <div className="w-full max-w-48">
                <FormStateSelect control={form.control} name="billing_state" label="State" />
              </div>
              <div className="w-full max-w-64">
                <FormInput control={form.control} name="billing_zipcode" label="Zip" />
              </div>
            </div>
            <div className="pb-6">
              <Button type="button" variant="outline" size="sm" onClick={handleBillingIsSame}>
                Same as Above
              </Button>
            </div>

            <h2 className="pt-6 flex flex-row justify-between">
              <span>Additional Details</span>
              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={() => setExpanded(!expanded)}
              >
                {expanded ? 'Hide' : 'Show'}
              </Button>
            </h2>
            <div className={expanded ? 'flex flex-col space-y-3' : 'hidden'}>
              <div className="flex flex-col md:flex-row md:space-x-2">
                <FormSelect
                  control={form.control}
                  name="bank"
                  label="Bank"
                  options={CUSTOMER_BANK_TYPE_OPTIONS}
                />
                <FormSelect
                  control={form.control}
                  name="relationship_strength"
                  label="Relationship Strength"
                  options={[
                    {
                      label: 'Strong',
                      value: 3,
                    },
                    {
                      label: 'Developing',
                      value: 2,
                    },
                    {
                      label: 'Early',
                      value: 1,
                    },
                  ]}
                />

                <div>
                  <FormDate control={form.control} name="lastcontact_at" label="Last Contact At" />
                </div>
                <div>
                  <FormDate control={form.control} name="followup_at" label="Next Follow Up At" />
                </div>
              </div>
              <FormTextarea
                control={form.control}
                name="important_details"
                label="Important Details"
              />
              <FormInput control={form.control} name="preferred_staff" label="Preferred Staff" />
              <FormTextarea control={form.control} name="medical_notes" label="Medical Notes" />
              <FormCheckbox control={form.control} name="is_married" label="Is Married" />
              <FormInput control={form.control} name="spouse_name" label="Spouse Name" />
              <FormInput control={form.control} name="school_name" label="Kid's School Name" />
              <FormInput control={form.control} name="company_name" label="Employed By" />
              <FormInput control={form.control} name="where_met" label="Where Met" />
              <FormInput control={form.control} name="linkedin_profile" label="Linkedin Profile" />
              <div className="h-96" />
            </div>
          </div>
        </form>
      </FormAutosave>
    </Form>
  );
};
