import { toast } from '@/components/ui/elements/use-toast';
import { ModelID } from '@/types';
import { post } from '@/utils/api';
import { useMutation } from '@tanstack/react-query';
import { EventSearchParams, featurePath } from '.';

type ExportEventsDTO = {
  location: ModelID;
  search?: EventSearchParams;
};

type ExportEventsResponse = {
  message: string;
};

const exportEvents = ({ location, search = {} }: ExportEventsDTO) =>
  post<ExportEventsResponse>(`${featurePath}/export`, {
    franchise_id: location,
    ...search,
  });

export const useExportEvents = () => {
  return useMutation({
    mutationFn: exportEvents,
    onSuccess: () => {
      toast({
        title: 'Export Started',
        description: "You'll receive a notification when your download is ready.",
        duration: 3000,
      });
    },
  });
};
