import { ModelBase, ModelID, SearchParams } from '@/types';
import { PaginatedResource } from '@/utils/api';
import { MutationFunction, useMutation, UseMutationOptions } from '@tanstack/react-query';
import { queryClient } from '.';

export type UpdateDTO<T, S = SearchParams> = {
  data: Partial<T>;
  id: ModelID;
  params?: S;
};

export type UseUpdateMutationProps<TModel> = {
  onMutate?: (value: UpdateDTO<TModel>) => unknown;
  onError?: (error: unknown, value: Partial<TModel>, context?: unknown) => unknown;
  onSuccess?: (response: TModel) => unknown;
  config?: UseMutationOptions<TModel>;
};

type UpdateMutationFactoryProps<TDataDTO, TModel> = UseUpdateMutationProps<TModel> & {
  mutationFn: MutationFunction<TModel, TDataDTO>;
  invalidateKeys?: any;
};

type UpdateMutationStrategyOptions = {
  shouldInvalidateAll: boolean;
};

export const genericUpdateOnMutateStrategy = async <T, S>(
  invalidationKeys: any,
  newData: UpdateDTO<T, S>,
) => {
  await queryClient.cancelQueries(invalidationKeys.detail(newData.id));

  const previousData = queryClient.getQueryData<T>(invalidationKeys.detail(newData.id));

  queryClient.setQueryData(invalidationKeys.detail(newData.id), {
    ...previousData,
    ...newData.data,
    id: newData.id,
  });

  return { previousData };
};

export const genericUpdateErrorOnMutateStrategy = (
  _: any,
  __: any,
  context: any,
  invalidationKeys: any,
) => {
  if (context?.previousData) {
    queryClient.setQueryData(
      invalidationKeys.detail(context.previousData.id),
      context.previousData,
    );
  }
};

export const genericUpdateOnSuccessMergeStrategy = <T extends ModelBase>(
  invalidationKeys: any,
  data: T,
) => {
  queryClient.setQueriesData<T | T[] | PaginatedResource<T> | undefined>(
    { queryKey: invalidationKeys.all, exact: false },
    (previous) => {
      // If the result is undefined, return the previous without modification
      if (!previous) return previous;

      // If the result set is an array, loop through and replace the matching item data
      if (Array.isArray(previous)) {
        return [
          ...previous.map((item) =>
            item.id === data.id
              ? {
                  ...item,
                  ...data,
                }
              : item,
          ),
        ];
      } else {
        // If the result is an object, check if it is a paginator
        const isPaginator = Object.keys(previous).indexOf('paginator') > -1;
        if (isPaginator) {
          const resource = previous as PaginatedResource<T>;
          return {
            paginator: resource.paginator,
            results: [
              ...resource.results.map((item) =>
                item.id === data.id
                  ? {
                      ...item,
                      ...data,
                    }
                  : item,
              ),
            ],
          };
        } else {
          const resource = previous as T;
          if (resource.id === data.id) {
            return {
              ...previous,
              ...data,
            };
          }
        }
      }

      return undefined;
    },
  );
};

export const useUpdateMutationFactory = <TDataDTO, TModel extends ModelBase>(
  options: UpdateMutationFactoryProps<TDataDTO, TModel>,
  strategyOptions?: UpdateMutationStrategyOptions,
) => {
  const { mutationFn, onMutate, onError, onSuccess, invalidateKeys = {}, config } = options;

  const mutationConfig = Object.assign(
    {
      onMutate: (newData: UpdateDTO<TModel>) => {
        if (onMutate) {
          onMutate(newData);
        } else {
          genericUpdateOnMutateStrategy(invalidateKeys, newData);
        }
      },

      onError: (_: unknown, __: any, context: any) => {
        if (onError) {
          onError(_, __, context);
        } else {
          genericUpdateErrorOnMutateStrategy(_, __, context, invalidateKeys);
        }
      },

      onSuccess: (data: TModel) => {
        if (!strategyOptions || strategyOptions.shouldInvalidateAll === true) {
          queryClient.invalidateQueries({ queryKey: invalidateKeys.all });
        }

        genericUpdateOnSuccessMergeStrategy(invalidateKeys, data);

        if (onSuccess) {
          onSuccess(data);
        }
      },

      mutationFn,
    },
    config,
  );

  return useMutation<TModel, unknown, TDataDTO, unknown>(
    mutationConfig as UseMutationOptions<TModel, unknown, TDataDTO, unknown>,
  );
};
