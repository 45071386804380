import { Blackout, ModelID, SearchParams } from '@/types';

export type Filter = 'all' | 'active' | 'inactive';
export type Sort = 'created_at' | 'updated_at' | 'start_at' | 'end_at' | 'blackout_id';
export interface BlackoutSearchParams extends SearchParams<Filter, Sort>, Partial<Blackout> {
  dates?: {
    start: Date;
    end: Date;
  };
}

export const featurePath = '/v4/equipment/assets/blackout';

export const featureKeys = {
  all: [{ scope: 'blackouts' }] as const,

  lists: (location: ModelID) => [{ ...featureKeys.all[0], location, entity: 'list' }] as const,

  list: (location: ModelID, search: BlackoutSearchParams = {}) =>
    [{ ...featureKeys.lists(location)[0], search }] as const,

  details: () => [{ ...featureKeys.all[0], entity: 'detail' }] as const,

  detail: (id: ModelID) => [{ ...featureKeys.details()[0], id }] as const,
};
