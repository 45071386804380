export const useFileDownload = () => {
  const download = (data: Blob, filename: string, mode?: 'download' | 'open') => {
    const element = document.createElement('a');
    element.href = URL.createObjectURL(data);
    if (mode === 'open') {
      element.target = '_blank';
      element.rel = 'noopener noreferrer';
    } else {
      element.download = filename;
    }
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return { download };
};
