import { Customer } from '@/types';
import { cn } from '@/utils/format';
import { StarIcon } from '@heroicons/react/24/solid';

interface PastCustomerBadgeProps {
  customer: Customer | null | undefined;
  className?: string;
}

export const PastCustomerBadge = ({
  customer,
  className = 'text-yellow-500',
}: PastCustomerBadgeProps) => {
  if (!customer || !customer.is_repeat_customer) return <></>;

  return (
    <span>
      <StarIcon className={cn('h-4 w-4 my-auto mx-auto', className)} />
    </span>
  );
};
