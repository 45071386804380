import { cn } from '@/utils/format';
import { cva, VariantProps } from 'cva';

interface PlaceholderProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof placeholderVariants> {
  className?: string;
}

const placeholderVariants = cva(['inline-flex items-center justify-center my-auto animate-pulse'], {
  variants: {
    variant: {
      default: 'bg-gray-200 rounded-md',
      circle: 'bg-gray-200 rounded-full',
    },
    size: {
      xs: 'h-2 w-4',
      sm: 'h-4 w-12',
      md: 'h-8 w-24',
      lg: 'h-16 w-48',
      xl: 'h-32 w-96',
      full: 'w-full h-full',
    },
  },
  compoundVariants: [
    { variant: ['circle'], size: ['xs'], class: 'w-2' },
    { variant: ['circle'], size: ['sm'], class: 'w-4' },
    { variant: ['circle'], size: ['md'], class: 'w-8' },
    { variant: ['circle'], size: ['lg'], class: 'w-16' },
    { variant: ['circle'], size: ['xl'], class: 'w-32' },
  ],
  defaultVariants: {
    variant: 'default',
    size: 'md',
  },
});

export const Placeholder = ({ className, variant, size, ...props }: PlaceholderProps) => {
  return <div className={cn(placeholderVariants({ variant, size }), className)} {...props} />;
};

export const ListingPlaceholder = () => {
  return (
    <div className="mx-3">
      <Placeholder size="md" className="w-full mt-3" />
      <Placeholder size="sm" className="w-full" />
      <Placeholder size="sm" className="w-full" />
      <Placeholder size="lg" className="w-3/4" />

      <Placeholder size="md" className="w-full mt-3" />
      <Placeholder size="sm" className="w-full" />
      <Placeholder size="sm" className="w-full" />
      <Placeholder size="lg" className="w-3/4" />

      <Placeholder size="md" className="w-full mt-3" />
      <Placeholder size="sm" className="w-full" />
      <Placeholder size="sm" className="w-full" />
      <Placeholder size="lg" className="w-3/4" />
    </div>
  );
};

export const TablePlaceholder = () => {
  return (
    <div className="mx-3">
      <Placeholder size="md" className="w-full mt-6" />

      <div className="flex flex-row w-full my-2 space-x-3">
        <Placeholder size="sm" className="basis-1/4" />
        <Placeholder size="sm" className="basis-1/2" />
        <Placeholder size="sm" className="basis-4/6" />
        <Placeholder size="sm" className="basis-2/6" />
      </div>

      <div className="flex flex-row w-full my-2 space-x-3">
        <Placeholder size="sm" className="basis-1/4" />
        <Placeholder size="sm" className="basis-1/2" />
        <Placeholder size="sm" className="basis-4/6" />
        <Placeholder size="sm" className="basis-2/6" />
      </div>

      <div className="flex flex-row w-full my-2 space-x-3">
        <Placeholder size="sm" className="basis-1/4" />
        <Placeholder size="sm" className="basis-1/2" />
        <Placeholder size="sm" className="basis-4/6" />
        <Placeholder size="sm" className="basis-2/6" />
      </div>

      <div className="flex flex-row w-full my-2 space-x-3">
        <Placeholder size="sm" className="basis-1/4" />
        <Placeholder size="sm" className="basis-1/2" />
        <Placeholder size="sm" className="basis-4/6" />
        <Placeholder size="sm" className="basis-2/6" />
      </div>

      <div className="flex flex-row w-full my-2 space-x-3">
        <Placeholder size="sm" className="basis-1/4" />
        <Placeholder size="sm" className="basis-1/2" />
        <Placeholder size="sm" className="basis-4/6" />
        <Placeholder size="sm" className="basis-2/6" />
      </div>

      <div className="flex flex-row w-full my-2 space-x-3">
        <Placeholder size="sm" className="basis-1/4" />
        <Placeholder size="sm" className="basis-1/2" />
        <Placeholder size="sm" className="basis-4/6" />
        <Placeholder size="sm" className="basis-2/6" />
      </div>

      <div className="flex flex-row w-full my-2 space-x-3">
        <Placeholder size="sm" className="basis-1/4" />
        <Placeholder size="sm" className="basis-1/2" />
        <Placeholder size="sm" className="basis-4/6" />
        <Placeholder size="sm" className="basis-2/6" />
      </div>

      <div className="flex flex-row w-full my-2 space-x-3">
        <Placeholder size="sm" className="basis-1/4" />
        <Placeholder size="sm" className="basis-1/2" />
        <Placeholder size="sm" className="basis-4/6" />
        <Placeholder size="sm" className="basis-2/6" />
      </div>

      <div className="flex flex-row w-full my-2 space-x-3">
        <Placeholder size="sm" className="basis-1/4" />
        <Placeholder size="sm" className="basis-1/2" />
        <Placeholder size="sm" className="basis-4/6" />
        <Placeholder size="sm" className="basis-2/6" />
      </div>

      <Placeholder size="md" className="w-full" />
    </div>
  );
};
