import { ModelID } from '@/types';
import { create } from 'zustand';

type PinType = 'event' | 'depot' | 'lead';

type MapMode =
  | 'mapbox://styles/mapbox/streets-v12'
  | 'mapbox://styles/mapbox/satellite-streets-v12'
  | 'mapbox://styles/mapbox/navigation-day-v1'
  | 'mapbox://styles/mapbox/navigation-night-v1';

export interface Pin {
  id: ModelID;
  itemId?: ModelID;
  type: PinType;
  assetType: string;
  lat: number;
  lng: number;
  title: string;
  message?: string;
  color?: string;
}

type MapStore = {
  defaultLocationId: ModelID | null;
  setDefaultLocationId: (id: ModelID) => void;

  activeMapLocationId: ModelID | null;
  setActiveMapLocationId: (id: ModelID) => void;
  resetActiveMapLocationId: () => void;

  mode: MapMode;
  setMode: (mode: MapMode) => void;

  hiddenLayers: ModelID[];
  toggleHiddenLayer: (id: ModelID) => void;
  setHiddenLayers: (ids: ModelID[]) => void;
  resetHiddenLayers: () => void;

  center: {
    longitude: number;
    latitude: number;
  } | null;
  setCenter: (longitude: number, latitude: number) => void;

  zoom: number;
  setZoom: (zoom: number) => void;

  pins: Pin[];
  addPin: (pin: Pin) => void;
  removePin: (id: ModelID) => void;
  clearPins: (type?: PinType | null) => void;
};

export const useMapStore = create<MapStore>((set) => ({
  defaultLocationId: null,
  setDefaultLocationId: (id: ModelID) =>
    set(() => ({ defaultLocationId: id, activeMapLocationId: id })),

  activeMapLocationId: null,
  setActiveMapLocationId: (id: ModelID) => set(() => ({ activeMapLocationId: id })),
  resetActiveMapLocationId: () =>
    set((state) => ({ activeMapLocationId: state.defaultLocationId })),

  mode: 'mapbox://styles/mapbox/streets-v12',
  setMode: (mode: MapMode) => set(() => ({ mode })),

  hiddenLayers: [],
  toggleHiddenLayer: (id: ModelID) =>
    set((state) => ({
      hiddenLayers: state.hiddenLayers.includes(id)
        ? state.hiddenLayers.filter((layer) => layer !== id)
        : [...state.hiddenLayers, id],
    })),
  setHiddenLayers: (ids: ModelID[]) => set(() => ({ hiddenLayers: ids })),
  resetHiddenLayers: () => set(() => ({ hiddenLayers: [] })),

  center: { longitude: 0, latitude: 0 },
  setCenter: (longitude, latitude) => set(() => ({ center: { longitude, latitude } })),

  zoom: 9,
  setZoom: (zoom) => set(() => ({ zoom })),

  pins: [],
  addPin: (pin) =>
    set((state) => ({
      pins: [...state.pins, { ...pin, color: colorReducer(pin.type ?? '', pin.assetType ?? '') }],
    })),
  removePin: (id) =>
    set((state) => ({
      pins: state.pins.filter((pin) => pin.id !== id),
    })),
  clearPins: (type = null) =>
    set((state) => ({
      pins: type ? state.pins.filter((pin) => pin.type !== type) : [],
    })),
}));

const colorReducer = (type: string, assetType: string) => {
  switch (type.toLowerCase()) {
    case 'event':
      switch (assetType.toLowerCase()) {
        case 'lt':
          return 'text-orange-400';
        case 'wt':
          return 'text-blue-800';
        case 'gel':
          return 'text-yellow-800';
        case 'zt':
          return 'text-lightsalmon-800';
        case 'gx':
        case 'gxs':
        case 'gxp':
        case 'gxm':
          return 'text-purple-800';
        case 'fp':
          return 'text-teal-800';
        default:
          return 'text-green-800';
      }

    case 'lead':
      return 'text-black';
    default:
      return 'text-gray-600';
  }
};
