import { LocationUserAccess, ModelID, SearchParams } from '@/types';

export type Filter = 'all' | 'active' | 'inactive';
export type Sort = 'created_at' | 'updated_at' | 'franchise_user_id' | 'type_code';
export interface LocationUserAccessSearchParams
  extends SearchParams<Filter, Sort>,
    Partial<LocationUserAccess> {}

export const featurePath = '/v4/locations/access';

export const featureKeys = {
  all: [{ scope: 'access' }] as const,

  lists: (location: ModelID) => [{ ...featureKeys.all[0], location, entity: 'list' }] as const,

  list: (location: ModelID, search: LocationUserAccessSearchParams = {}) =>
    [{ ...featureKeys.lists(location)[0], search }] as const,

  details: () => [{ ...featureKeys.all[0], entity: 'detail' }] as const,

  detail: (id: ModelID) => [{ ...featureKeys.details()[0], id }] as const,
};
