import { MessageTemplate, ModelID, SearchParams } from '@/types';

export type Filter = 'all' | 'active' | 'inactive';
export type Sort = 'created_at' | 'updated_at' | 'message-template_id';
export interface MessageTemplateSearchParams
  extends SearchParams<Filter, Sort>,
    Partial<MessageTemplate> {}

export const featurePath = '/v4/support/message-template';

export const featureKeys = {
  all: [{ scope: 'message-templates' }] as const,

  lists: (location: ModelID) => [{ ...featureKeys.all[0], location, entity: 'list' }] as const,

  list: (location: ModelID, search: MessageTemplateSearchParams = {}) =>
    [{ ...featureKeys.lists(location)[0], search }] as const,

  details: () => [{ ...featureKeys.all[0], entity: 'detail' }] as const,

  detail: (id: ModelID) => [{ ...featureKeys.details()[0], id }] as const,
};
