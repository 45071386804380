import {
  DeleteDTO,
  useDeleteMutationFactory,
  UseDeleteMutationProps,
} from '@/lib/react-query/delete';
import { MessageTemplate } from '@/types';
import { remove } from '@/utils/api';
import { featureKeys, featurePath, MessageTemplateSearchParams } from '.';

export type DeleteMessageTemplateDTO = DeleteDTO<MessageTemplate, MessageTemplateSearchParams>;

const deleteMessageTemplate = ({ id, data, params }: DeleteMessageTemplateDTO) =>
  remove(`${featurePath}/${id}`);

export const useDeleteMessageTemplate = (options?: UseDeleteMutationProps<MessageTemplate>) => {
  return useDeleteMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: deleteMessageTemplate,
    ...options,
  });
};
