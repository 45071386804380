import { useUpdateMutationFactory, UseUpdateMutationProps } from '@/lib/react-query/update';
import { CompleteTransferDTO, Event, ModelID, RejectTransferDTO } from '@/types';
import { post } from '@/utils/api';
import { EventSearchParams, featureKeys, featurePath } from '.';

export type TransferEventDTO = {
  id: ModelID;
  data: {
    destination_id: ModelID;
  };
  params?: EventSearchParams;
};

const transferEvent = ({ data, id, params = {} }: TransferEventDTO) =>
  post<Event>(`${featurePath}/${id}/transfer`, data, params);

const completeTransfer = ({ data, id, params = {} }: CompleteTransferDTO<EventSearchParams>) =>
  post<Event>(`${featurePath}/${id}/complete-transfer`, data, params);

const rejectTransfer = ({ id, params = {} }: RejectTransferDTO<EventSearchParams>) =>
  post<Event>(`${featurePath}/${id}/reject-transfer`, {}, params);

export const useTransferEvent = (options?: UseUpdateMutationProps<Event>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: transferEvent,
    ...options,
  });
};

export const useCompleteTransfer = (options?: UseUpdateMutationProps<Event>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: completeTransfer,
    ...options,
  });
};

export const useRejectTransfer = (options?: UseUpdateMutationProps<Event>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: rejectTransfer,
    ...options,
  });
};
