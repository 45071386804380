import { memo } from 'react';

interface CountIndicatorProps {
  count: number | null | undefined;
}

export const CountIndicator = memo(({ count }: CountIndicatorProps) => {
  if (count === null || count === undefined || count === 0) {
    return null;
  }

  return (
    <>
      {count && (
        <span className="relative">
          <span className="absolute rounded-full py-0.5 px-2 right-4 -top-3 bg-gt-green-500 text-white">
            {count}
          </span>
        </span>
      )}
    </>
  );
});
