import {
  DeleteDTO,
  useDeleteMutationFactory,
  UseDeleteMutationProps,
} from '@/lib/react-query/delete';
import { CustomerList } from '@/types';
import { remove } from '@/utils/api';
import { CustomerListSearchParams, featureKeys, featurePath } from '.';

export type DeleteCustomerListDTO = DeleteDTO<CustomerList, CustomerListSearchParams>;

const deleteCustomerList = ({ id, data, params }: DeleteCustomerListDTO) =>
  remove(`${featurePath}/${id}`);

export const useDeleteCustomerList = (options?: UseDeleteMutationProps<CustomerList>) => {
  return useDeleteMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: deleteCustomerList,
    ...options,
  });
};
