import { useUpdateLead } from '@/api/leads/leads/updateLead';
import { Button } from '@/components/ui/elements/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/elements/dropdown-menu';
import { LEAD_STATUS_OPTIONS } from '@/config';
import { Lead } from '@/types';

interface StatusContextMenuProps {
  lead: Lead;
  onSuccess?: () => void;
}

export const StatusContextMenu = ({ lead, onSuccess }: StatusContextMenuProps) => {
  const { mutate: updateLead } = useUpdateLead();

  const StatusActions = LEAD_STATUS_OPTIONS.map((option) => {
    return {
      label: option.label,
      onClick: () => updateLead({ id: lead.id, data: { status: option.value } }, { onSuccess }),
    };
  });

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="sm">
          Status
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {StatusActions.map((action, i) => {
          return (
            <DropdownMenuItem
              key={`ddm-archive-${i}`}
              onClick={action.onClick}
              className="cursor-pointer"
            >
              {action.label}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
