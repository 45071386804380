import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ModelID, PaymentMethod } from '@/types';
import { get } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { featureKeys, featurePath, PaymentMethodSearchParams } from '.';

type QueryFnType = typeof fetchPaymentMethods;

type UsePaymentMethodsOptions = {
  location: ModelID;
  search?: PaymentMethodSearchParams;
  config?: QueryConfig<QueryFnType>;
};

export const fetchPaymentMethods = (location: ModelID, search: PaymentMethodSearchParams = {}) =>
  get<PaymentMethod[]>(`${featurePath}?franchise_id=${location}`, search);

export const usePaymentMethods = ({
  location,
  search = {},
  config = {},
}: UsePaymentMethodsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: featureKeys.list(location, search),
    queryFn: () => fetchPaymentMethods(location, search),
  });
};
