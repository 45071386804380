import { PastCustomerBadge } from '@/components/Customer/PastCustomerBadge';
import { EmailButton, Link, PhoneButton } from '@/components/Elements';
import { Checkbox } from '@/components/ui/elements/checkbox';
import { DropdownMenuTrigger } from '@/components/ui/elements/dropdown-menu';
import { Customer, ModelID } from '@/types';
import { cn, formatApiDate, formatMoney } from '@/utils/format';
import { openGoogleMap } from '@/utils/logic';
import {
  ArrowTopRightOnSquareIcon,
  ChevronDoubleRightIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/outline';
import { CustomerActionDropdownMenu } from './CustomerActionDropdownMenu';
import { SurveyBadge } from './SurveyBadge';

interface CustomerResultProps {
  customer: Customer;
  selected: boolean;
  setSelected: (id: ModelID) => void;
}

export const CustomerResult = ({ customer, selected, setSelected }: CustomerResultProps) => {
  const { notes, leads, events } = customer;

  const lifetimeCustoemrValue = events.reduce((acc, event) => event.total + acc, 0);

  const customerSince = events.sort(
    (a, b) => new Date(a.start_at).getTime() - new Date(b.start_at).getTime(),
  )[0]?.start_at;

  const mostRecentEvent = events.sort(
    (a, b) => new Date(b.start_at).getTime() - new Date(a.start_at).getTime(),
  )[0]?.start_at;

  const eventCount = events.length;

  const openLeadCount = leads.filter(
    (lead) => lead.status !== 'Closed' && lead.status !== 'Booked',
  ).length;

  return (
    <div className={cn('pb-0 border-y bg-white text-sm my-0')}>
      <div className="flex bg-gray-50 border-b border-b-gray-100 transition-all">
        <span className={cn('w-5', customer.is_repeat_customer ? 'bg-green-400' : '')}>
          <PastCustomerBadge customer={customer} className="text-white mt-4" />
        </span>

        <span className="w-8 h-8 mt-3 mx-2">
          <Checkbox
            checked={selected}
            onCheckedChange={() => setSelected(customer.id)}
            aria-label="Select Event"
          />
        </span>

        <div className="w-full font-medium whitespace-nowrap overflow-x-hidden overflow-y-hidden">
          <div className="pt-3.5 space-x-3">
            <Link
              variant="link"
              to={'/customers/customer/$id/overview'}
              params={{ id: customer.id }}
              className="p-0 text-black"
            >
              {customer.is_organization || customer.organization_name
                ? `${customer.organization_name} (${customer.full_name})`
                : customer.full_name}
            </Link>
            <SurveyBadge customer={customer} />
          </div>
        </div>

        <CustomerActionDropdownMenu customer={customer}>
          <DropdownMenuTrigger>
            <EllipsisVerticalIcon
              className="w-12 h-full p-3 bg-gray-50 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
              aria-hidden="true"
            />
          </DropdownMenuTrigger>
        </CustomerActionDropdownMenu>

        <Link
          variant="link"
          to="/customers/customer/$id/overview"
          params={{ id: customer.id }}
          className="p-0"
        >
          <ChevronDoubleRightIcon className="w-12 h-full p-3.5 bg-gray-50 text-gray-500 hover:bg-gray-100 hover:text-gray-700" />
        </Link>
      </div>

      <div className="flex flex-col md:flex-row px-2 py-2">
        <div className="flex flex-col basis-1/3 px-2 py-2 space-y-1">
          <div className="flex flex-col w-full max-w-[220px] space-y-2">
            <PhoneButton
              phone={customer.phone}
              preferSms={customer.sms_lead_updates}
              isInvalid={customer.phone_valid === 0}
              phoneType={customer.phone_type}
            />
            <EmailButton
              email={customer.email}
              isInvalid={customer.has_bad_email}
              customerId={customer.id}
            />
            {customer.zipcode && (
              <div>
                <button type="button" onClick={() => openGoogleMap(customer)}>
                  {customer.city} {customer.state}, {customer.zipcode}{' '}
                  <ArrowTopRightOnSquareIcon className="w-3 h-3 inline" />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col basis-1/3 px-2 py-2 leading-6">
          <div>
            <span className="font-medium text-gray-600">Org Type: </span>
            {customer.organization_type}
          </div>
          <div>
            <span className="font-medium text-gray-600">Last Contact: </span>
            {customer.lastcontact_at ? formatApiDate(customer.lastcontact_at) : 'never'}
          </div>
          <div>
            <span className="font-medium text-gray-600">Follow Up At: </span>
            {customer.followup_at ? formatApiDate(customer.followup_at) : 'n/a'}
          </div>
          <div>
            <span className="font-medium text-gray-600">Open Leads: </span>
            {openLeadCount}
          </div>
        </div>
        <div className="flex flex-row basis-1/3 justify-between">
          <div className="flex flex-col px-2 py-2 leading-6">
            <div>
              <span className="font-medium text-gray-600">Most Recent Booking: </span>
              {mostRecentEvent ? formatApiDate(mostRecentEvent, 'MMM yyyy') : 'never'}
            </div>
            <div>
              <span className="font-medium text-gray-600">Customer Since: </span>
              {customerSince ? formatApiDate(customerSince, 'MMM yyyy') : 'never'}
            </div>
            <div>
              <span className="font-medium text-gray-600">Lifetime Value: </span>
              {formatMoney(lifetimeCustoemrValue ?? 0)}
            </div>
            <div>
              <span className="font-medium text-gray-600">Total Events: </span>
              {eventCount}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col space-y-2 px-4 pb-2">
        <div className="text-xs">
          <span className="font-medium"></span>
        </div>
        {notes[0] && (
          <div className="text-xs">
            <span className="font-medium">Latest Note:</span> {formatApiDate(notes[0].created_at)}{' '}
            {notes[0].message}
          </div>
        )}
      </div>
    </div>
  );
};
