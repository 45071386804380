import {
  UpdateDTO,
  useUpdateMutationFactory,
  UseUpdateMutationProps,
} from '@/lib/react-query/update';
import { Customer } from '@/types';
import { post } from '@/utils/api';
import { CustomerSearchParams, featureKeys, featurePath } from '.';

type LogContactAttemptAction = {
  contactType: string;
  result?: string;
};
export type LogContactAttemptDTO = UpdateDTO<LogContactAttemptAction, CustomerSearchParams>;

const logContactAttempt = async ({ data, id, params }: LogContactAttemptDTO) =>
  post<Customer, any, LogContactAttemptAction>(`${featurePath}/${id}/log`, data, params);

export const useLogContactAttempt = (options?: UseUpdateMutationProps<Customer>) => {
  return useUpdateMutationFactory(
    {
      invalidateKeys: featureKeys,
      mutationFn: logContactAttempt,
      ...options,
    },
    { shouldInvalidateAll: false },
  );
};
