import { NumberFormat } from '@/types';
import { cn } from '@/utils/format';
import { calculatePercentChange, formatNumber } from '@/utils/numbers-utils';
import { ArrowUpIcon } from '@heroicons/react/24/outline';
import { valueCoalesce } from '../../logic/helpers';

interface KpiIndicatorProps {
  name: string;
  format?: NumberFormat;
  base?: number;
  comparison?: number;
  higherIsBetter?: boolean;
}

export const KpiIndicator = ({
  name,
  format = 'decimal',
  base = 0,
  comparison,
  higherIsBetter = true,
}: KpiIndicatorProps) => {
  const _base = valueCoalesce(base, format === 'currency');
  const _comparison = valueCoalesce(comparison, format === 'currency');

  const percentageChange = calculatePercentChange(_comparison ?? null, _base ?? null);
  const baseDisplay = formatNumber(_base, format);
  const comparisonDisplay = comparison !== undefined ? formatNumber(_comparison, format) : null;
  const changeDisplay = formatNumber(percentageChange, 'percent');
  const isIncrease = percentageChange && percentageChange > 0;
  const isImproved = (higherIsBetter && isIncrease) || (!higherIsBetter && !isIncrease);

  return (
    <div className="py-5 sm:p-2">
      <dt className="text-sm font-semibold text-gray-900">{name}</dt>
      <dd className="flex items-baseline justify-between md:block lg:flex">
        <div className="flex flex-col items-baseline text-2xl font-semibold text-gray-600">
          <span
            className={cn(
              comparison !== undefined
                ? isImproved
                  ? 'text-green-600'
                  : 'text-red-700'
                : 'text-gray-700',
            )}
          >
            {baseDisplay}
          </span>
          {comparison !== undefined && (
            <span className="text-sm font-medium text-gray-500">vs {comparisonDisplay}</span>
          )}
        </div>
        {comparison !== undefined && (
          <div
            className={cn(
              isImproved ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
              'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 xl:mt-0',
            )}
          >
            <ArrowUpIcon
              className={cn(
                '-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500',
                isImproved ? 'text-green-500' : 'text-red-500',
                isIncrease ? '' : 'rotate-180',
              )}
              aria-hidden="true"
            />
            <span className="sr-only"> {isIncrease ? 'Increased' : 'Decreased'} by </span>
            {changeDisplay}
          </div>
        )}
      </dd>
    </div>
  );
};
