export const email = 'contact@gametruck.com';

export const messages = {
  app: {
    crash: {
      title: 'Oops... Something went wrong. You try an app reset or email us:',
      options: {
        email: `Email: ${email}`,
        reset: 'Reset App',
      },
    },
  },
  loader: {
    fail: 'Error loading feature. Your app may be out of date!',
  },
  images: {
    failed: 'Cannot load image...',
  },
  404: 'Page Not Found',
};
