import { PolicyCheck } from '@/features/auth/authorization';

export const CalendarPolicies: Record<string, PolicyCheck> = {
  'calendar.view': ({ acl, user, meta }) => {
    return acl.calendar.view;
  },
  'calendar.notes.create': ({ acl, user, meta }) => {
    return acl.calendar.notes.create;
  },
  'calendar.notes.update': ({ acl, user, meta }) => {
    return acl.calendar.notes.edit;
  },
  'calendar.notes.delete': ({ acl, user, meta }) => {
    return acl.calendar.notes.delete;
  },
};
