import { featureKeys } from '@/api/calendar';
import { CalendarData } from '@/api/calendar/getCalendarEvents';
import CalendarViewer from '@/components/CalendarViewer/CalendarViewer';
import { useMapStore } from '@/components/MapViewer/MapStateStore';
import { queryClient } from '@/lib/react-query';
import { LocationContext } from '@/providers/location';
import { useCalendarStore } from '@/stores/calendar';
import { formatDate, stringToCalendarDate } from '@/utils/format';
import { useContext, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

export const LocationCalendar = () => {
  const { activeLocationId } = useContext(LocationContext);
  const { addPin, clearPins } = useMapStore(
    useShallow((state) => ({
      addPin: state.addPin,
      clearPins: state.clearPins,
    })),
  );

  const { dates } = useCalendarStore(
    useShallow((state) => ({
      dates: { start: state.startDate, end: state.endDate },
    })),
  );

  useEffect(() => {
    clearPins('event');

    const calendarData = queryClient.getQueryData<CalendarData>(
      featureKeys.list(activeLocationId, {
        start: dates.start.toString(),
        end: dates.end.toString(),
      }),
    ) || { events: [] };

    const events = calendarData.events.filter((event) => {
      const start = stringToCalendarDate(event.start);

      return (
        event.cal_event_type === 'event' &&
        event.latitude &&
        event.longitude &&
        start &&
        start.compare(dates.end) < 0
      );
    });
    events.map((event) => {
      if (event.latitude && event.longitude) {
        addPin({
          id: event.id,
          itemId: event.id,
          type: 'event',
          assetType: event.assetType,
          title: event.title,
          lat: event.latitude,
          lng: event.longitude,
          message: `${formatDate(event.start, 'M/d h:mm a')} ${event.assetAbbr}`,
        });
      }
    });

    return () => clearPins('event');
  }, [dates, activeLocationId]);

  return <CalendarViewer fallbackLocationId={activeLocationId} />;
};
