import { featureKeys as customerFeatureKeys } from '@/api/customers/customers';
import queryClient from '@/lib/react-query';
import {
  CreateDTO,
  useCreateMutationFactory,
  UseCreateMutationProps,
} from '@/lib/react-query/create';
import { Customer, CustomerNote } from '@/types';
import { create } from '@/utils/api';
import { CustomerNoteSearchParams, featureKeys, featurePath } from '.';

export type CreateNoteDTO = CreateDTO<CustomerNote, CustomerNoteSearchParams>;

const createNote = ({ data, params = {} }: CreateNoteDTO) =>
  create<CustomerNote>(`${featurePath}`, data, params);

export const useCreateCustomerNote = (options?: UseCreateMutationProps<CustomerNote>) => {
  return useCreateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: createNote,
    ...options,
    onSuccess: (newData) => {
      queryClient.setQueriesData({ queryKey: featureKeys.lists() }, (previous: any) => [
        newData,
        ...previous,
      ]);
      queryClient.setQueriesData({ queryKey: customerFeatureKeys.all }, (previous: any) => {
        if (Array.isArray(previous)) {
          const remapped = previous.map((item) => {
            if (item.id === newData.customer_id) {
              item.notes = [newData, ...item.notes];
              return item;
            }
            return item;
          });

          return [...remapped];
        } else if (previous.paginator && previous.results) {
          const results = previous.results.map((item: Customer) => {
            if (item.id === newData.customer_id) {
              item.notes = [newData, ...item.notes];
              return { ...item };
            }
            return { ...item };
          });

          return {
            ...previous,
            results: [...results],
          };
        } else {
          const recordNotes = previous.notes ? previous.notes : [];
          previous.notes = [newData, ...recordNotes];

          return { ...previous };
        }
      });
    },
  });
};
