import {
  CreateDTO,
  useCreateMutationFactory,
  UseCreateMutationProps,
} from '@/lib/react-query/create';
import { MessageTemplate } from '@/types';
import { create } from '@/utils/api';
import { featureKeys, featurePath, MessageTemplateSearchParams } from '.';

export type CreateMessageTemplateDTO = CreateDTO<MessageTemplate, MessageTemplateSearchParams>;

const createMessageTemplate = ({ data, params = {} }: CreateMessageTemplateDTO) =>
  create<MessageTemplate>(`${featurePath}`, data, params);

export const useCreateMessageTemplate = (options?: UseCreateMutationProps<MessageTemplate>) => {
  return useCreateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: createMessageTemplate,
    ...options,
  });
};
