import { useLeads } from '@/api/leads/leads/getLeads';
import List from '@/components/Elements/List/List';
import NoResults from '@/components/Elements/NoResults/NoResults';
import { LocationContext } from '@/providers/location';
import { formatApiDate } from '@/utils/format';
import { useContext } from 'react';

export const LeadsInbox = () => {
  const { activeLocationId } = useContext(LocationContext);

  const { data: leads } = useLeads({
    location: activeLocationId,
    search: {
      limit: 15,
      filter: 'inbox',
    },
    config: {
      staleTime: 1000 * 60 * 5,
    },
  });

  return (
    <div id="leads-inbox" className="rounded border px-4 bg-white">
      <h2 className="text-sm font-medium pt-2 pb-1">Inbox</h2>
      {!leads?.length && <NoResults label="leads" />}
      <List className="h-full max-h-[470px] overflow-y-auto -mx-4">
        {leads?.map((lead, index) => (
          <List.ItemLink
            key={`inbox-${lead.id}`}
            to={`/leads/lead/${lead.id}/quote`}
            className="flex flex-col"
          >
            <div className="space-x-2">
              <span>#{lead.id}</span>
              <span>{lead.full_name || 'No Name'}</span>
            </div>
            <span className="text-xs font-thin">{formatApiDate(lead.created_at)}</span>
          </List.ItemLink>
        ))}
      </List>
    </div>
  );
};
