import { useCreateLead } from '@/api/leads/leads/createLead';
import { FormInput } from '@/components/Form';
import { Button } from '@/components/ui/elements/button';
import { Form } from '@/components/ui/form/form';
import { phoneRegex } from '@/utils/format';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const schema = z.object({
  zipcode: z.string().min(1),
  phone: z
    .string()
    .regex(phoneRegex, {
      message: 'Invalid phone number',
    })
    .optional()
    .or(z.literal('')),
  email: z.string().email().optional().or(z.literal('')),
});

export const NewLeadForm = () => {
  const navigate = useNavigate();
  const { mutate: createLead } = useCreateLead();

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      zipcode: '',
      phone: '',
      email: '',
    },
  });

  const control = form.control;
  const formState = form.formState;

  const handleSubmit = (data: z.infer<typeof schema>) => {
    createLead(
      {
        data,
      },
      {
        onSuccess: (data) => {
          navigate({ to: `/leads/lead/${data.id}/quote` });
        },
      },
    );
  };

  return (
    <div className="rounded border px-4 pb-4 bg-white">
      <h2 className="text-sm font-medium pt-2 pb-1">New Quote</h2>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1"
        >
          <FormInput
            control={control}
            name="zipcode"
            type="number"
            placeholder="Zip Code *"
            autoComplete="off"
          />
          <FormInput control={control} name="phone" placeholder="Phone" autoComplete="off" />
          <FormInput
            control={control}
            name="email"
            type="email"
            placeholder="Email"
            autoComplete="off"
          />
          <Button type="submit" disabled={!formState.isValid}>
            Next
          </Button>
        </form>
      </Form>
    </div>
  );
};
