import { Badge, BadgeProps } from '@/components/ui/elements/badge';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/elements/tooltip';
import { Event } from '@/types';

interface SurveyBadgeProps {
  event: Event;
  hoverable?: boolean;
}

export const SurveyBadge = ({ event, hoverable = true }: SurveyBadgeProps) => {
  if (!event?.survey || !event.survey?.completed_at) return <></>;

  let variant = 'gray' as BadgeProps['variant'];
  switch (event.survey.recommend) {
    case 10:
    case 9:
      variant = 'green';
      break;
    case 8:
    case 7:
    case 6:
      variant = 'yellow';
      break;
    default:
      variant = 'red';
      break;
  }

  if (!hoverable || !event.survey.comments) {
    return (
      <Badge size="sm" variant={variant}>
        NPS: {event.survey.recommend}
      </Badge>
    );
  }

  return (
    <TooltipProvider delayDuration={50}>
      <Tooltip>
        <TooltipTrigger>
          <Badge size="sm" variant={variant}>
            NPS: {event.survey.recommend}
          </Badge>
        </TooltipTrigger>
        <TooltipContent side="top" className="w-48">
          {event.survey.comments || 'No comments'}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
