import { EventSearchParams } from '@/api/events/events';
import { FormCheckbox, FormDate, FormInput, FormSelect } from '@/components/Form';
import { FormMultiSelect } from '@/components/Form/FormMultiSelect';
import { SelectOption } from '@/components/Form/FormSelect';
import SearchForm, { defaultSearchSchema } from '@/components/SearchForm/SearchForm';
import { Button } from '@/components/ui/elements/button';
import { CUSTOMER_ORGANIZATION_TYPES, HOW_HEARD_OPTIONS } from '@/config';
import { EventType } from '@/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';

const schema = defaultSearchSchema.merge(
  z.object({
    name: z.string().nullish(),
    customer_name: z.string().nullish(),
    phone: z.string().nullish(),
    email: z.string().nullish(),
    address_city: z.string().nullish(),
    date_begin: z.string().nullish(),
    date_end: z.string().nullish(),
    booked_begin: z.string().nullish(),
    booked_end: z.string().nullish(),
    event_type_ids: z
      .array(
        z.object({
          value: z.string(),
          label: z.string(),
          checked: z.boolean().default(false),
        }),
      )
      .nullish(),
    organization_type: z.string().nullish(),
    event_id: z.string().nullish(),
    invoice_num: z.string().nullish(),
    booked_online: z.coerce.boolean().nullish(),
    how_heard: z.string().nullish(),
  }),
);

type EventSearchFormProps = {
  onSubmit: (data: any) => void;
  refetch?: () => void;
  eventTypes: EventType[];
  defaultValues: EventSearchParams;
  recordsFound?: number;
  children?: React.ReactNode;
};

export const EventSearchForm = ({
  onSubmit,
  refetch,
  eventTypes,
  defaultValues,
  recordsFound,
  children,
}: EventSearchFormProps) => {
  const eventTypeArrayDefaults =
    eventTypes?.map((type) => {
      return {
        value: type.event_type_id.toString(),
        label: type.name,
        checked: false,
      };
    }) ?? [];

  const defaultEventTypes =
    eventTypeArrayDefaults?.map((type) => {
      return {
        ...type,
        checked: defaultValues.event_type_ids?.includes(type.value) ?? false,
      };
    }) ?? [];

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      keyword: '',
      ...defaultValues,
      name: defaultValues.name ?? '',
      customer_name: defaultValues.customer_name ?? '',
      phone: defaultValues.phone ?? '',
      email: defaultValues.email ?? '',
      address_city: defaultValues.address_city ?? '',
      date_begin: defaultValues.date_begin ?? '',
      date_end: defaultValues.date_end ?? '',
      booked_begin: defaultValues.booked_begin ?? '',
      booked_end: defaultValues.booked_end ?? '',
      event_type_ids: defaultEventTypes,
      organization_type: defaultValues.organization_type ?? '',
      event_id: defaultValues.event_id ?? '',
      invoice_num: defaultValues.invoice_num ?? '',
      booked_online: defaultValues.booked_online ?? undefined,
      how_heard: defaultValues.how_heard ?? '',
    },
  });
  const eventTypeFieldArray = useFieldArray({
    control: form.control,
    name: 'event_type_ids',
  });

  const onReset = () => {
    eventTypeFieldArray.replace(eventTypeArrayDefaults);
  };

  const OrganizationTypeDefault = { value: '', label: 'All' } as SelectOption;
  const OrganizationTypeOptions = [...CUSTOMER_ORGANIZATION_TYPES] as SelectOption[];
  OrganizationTypeOptions.push(OrganizationTypeDefault);

  return (
    <SearchForm form={form} onSubmit={onSubmit} onReset={onReset} hasAdvanced className="m-4">
      <SearchForm.KeywordInput />
      <SearchForm.SearchChips
        formatters={[
          {
            key: 'event_type_ids',
            resetFn: onReset,
            formatter: (value: string[]): string => {
              const label = eventTypes
                ?.filter((type) => value.includes(type.event_type_id.toString()))
                .map((type) => type.name);
              return label?.join(', ') ?? '';
            },
          },
          {
            key: 'organization_type',
            formatter: (value: string): string => {
              const orgTypeName = OrganizationTypeOptions?.find((type) => type.value === value);
              return orgTypeName?.label ?? '';
            },
          },
        ]}
      />
      <SearchForm.AdvancedOptions>
        <div className="flex flex-col space-y-3">
          <FormInput control={form.control} name="name" label="Event Name" />
          <div className="grid lg:grid-cols-3 gap-2">
            <FormInput control={form.control} name="event_id" label="Event ID" />
            <FormInput control={form.control} name="invoice_num" label="Invoice Number" />
            <FormCheckbox control={form.control} name="booked_online" label="Booked Online" />
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-2">
            <FormDate control={form.control} name="date_begin" label="Event Date (From)" />
            <FormDate control={form.control} name="date_end" label="Event Date (Until)" />
            <FormDate control={form.control} name="booked_begin" label="Booked Date (From)" />
            <FormDate control={form.control} name="booked_end" label="Booked Date (Until)" />
          </div>
          <div className="grid lg:grid-cols-3 gap-2">
            <FormInput control={form.control} name="customer_name" label="Customer Name" />
            <FormInput control={form.control} name="phone" label="Phone" />
            <FormInput control={form.control} name="email" label="Email" />
          </div>
          <div className="grid lg:grid-cols-3 gap-2">
            <FormInput control={form.control} name="address_city" label="Address / City" />
            <FormMultiSelect<z.infer<typeof schema>, 'event_type_ids'>
              control={form.control}
              fieldArray={eventTypeFieldArray}
              name="event_type_ids"
              label="Event Types"
              placeholder="All"
            />
            <FormSelect
              control={form.control}
              name="organization_type"
              options={OrganizationTypeOptions}
              label="Organization Type"
            />
          </div>
          <div className="grid lg:grid-cols-3 gap-2">
            <FormSelect
              control={form.control}
              name="how_heard"
              options={HOW_HEARD_OPTIONS}
              label="How heard"
            />
          </div>
          <div className="text-right space-x-2">
            <Button type="submit">Search</Button>
          </div>
        </div>
      </SearchForm.AdvancedOptions>

      <div className="flex flex-row space-x-2 items-center justify-between pt-3">
        {children}
        <SearchForm.RecordsFound recordCount={recordsFound} />
        {refetch && <SearchForm.Refetch refetch={refetch} />}
        <SearchForm.OrderBy
          options={[
            { value: 'start_at', label: 'Event Date' },
            { value: 'created_at', label: 'Date Added' },
            { value: 'updated_at', label: 'Last Update' },
          ]}
        />
        <SearchForm.PageLimit />
        <SearchForm.OrderDirection />
      </div>
    </SearchForm>
  );
};
