import { CustomerAvatar } from '@/components/Customer/CustomerAvatar';
import { ListsBadge } from '@/components/Customer/ListsBadge';
import { PastCustomerBadge } from '@/components/Customer/PastCustomerBadge';
import { EmailButton, PhoneButton } from '@/components/Elements';
import AreaOpBadge from '@/components/Location/AreaOpBadge';
import { PinButton } from '@/components/PinButton/PinButton';
import { Event } from '@/types';
import { formatDate } from '@/utils/format';
import { BalanceDueBadge } from '../Badges/BalanceDueBadge';
import { IdBadge } from '../Badges/IdBadge';
import { StatusBadge } from '../Badges/StatusBadge';
import { EventActionDropdownMenu } from '../EventActionDropdownMenu';

interface HeaderBarProps {
  event: Event;
}

export const HeaderBar = ({ event }: HeaderBarProps) => {
  return (
    <>
      <div className="@container bg-white pt-4">
        <div className="flex flex-col @3xl:flex-row @3xl:items-end border-b px-5 pb-4">
          <div className="grow">
            <div className="flex flex-row">
              <CustomerAvatar customer={event.customer} />
              <span className="flex flex-col">
                <div className="flex flex-col @lg:flex-row items-baseline grow">
                  <dl className="flex flex-col @lg:flex-row gap-x-2 text-sm @lg:text-lg items-start @lg:items-center">
                    <dt>
                      <span className="text-xl font-bold pr-1.5">{event.customer.full_name}</span>
                    </dt>
                    <dt className="hidden @lg:inline-block">
                      <svg viewBox="0 0 2 2" className="h-1 w-1 fill-current">
                        <title>Dot</title>
                        <circle cx={1} cy={1} r={1} />
                      </svg>
                    </dt>
                    <dt>
                      {event.vips.length > 0 && (
                        <>
                          {' '}
                          VIP:{' '}
                          {event.vips.map((vip) => `${vip.first_name} (${vip.age})`).join(', ')}
                        </>
                      )}
                    </dt>
                    <dt className="hidden @lg:inline-block">
                      <svg viewBox="0 0 2 2" className="h-1 w-1 fill-current">
                        <title>Dot</title>
                        <circle cx={1} cy={1} r={1} />
                      </svg>
                    </dt>
                    <dt>
                      {formatDate(event.start_at, 'E MMM d, yyyy h:mma')}-
                      {formatDate(event.end_at, 'h:mma')}
                    </dt>
                  </dl>
                </div>
                <div className="flex flex-col @md:flex-row @md:space-x-2 max-w-[calc(100%-20px)] md:max-w-full">
                  <PhoneButton
                    phone={event.customer.phone ?? event.phone}
                    preferSms={event.customer.sms_lead_updates}
                    isInvalid={event.customer?.phone_valid === 0}
                    phoneType={event.customer?.phone_type}
                  />
                  <EmailButton
                    email={event.customer.email}
                    isInvalid={event.customer.has_bad_email}
                    customerId={event.customer.id}
                  />
                </div>
              </span>
            </div>
          </div>
          <div className="flex flex-row pt-2 space-x-2 justify-end items-baseline">
            <PinButton mode="event" id={event.id} />
            <EventActionDropdownMenu event={event} />
          </div>
        </div>
      </div>
      <div className="flex flex-row items-baseline whitespace-nowrap max-w-[100vw] overflow-x-auto overflow-y-hidden space-y-3 px-4 pb-4 space-x-2 bg-white">
        <PastCustomerBadge customer={event.customer} />
        <IdBadge event={event} />
        <AreaOpBadge location={event.franchise} />
        <StatusBadge event={event} />
        <ListsBadge customer={event.customer} />
        <span className="grow" />
        <BalanceDueBadge event={event} />
      </div>
    </>
  );
};
