import { useCreateCustomer } from '@/api/customers/customers/createCustomer';
import { useCustomers } from '@/api/customers/customers/getCustomers';
import { Link } from '@/components/Elements';
import { FormInput } from '@/components/Form';
import { FormEmailInput } from '@/components/Form/FormEmailInput';
import { Button } from '@/components/ui/elements/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/elements/dialog';
import { Form } from '@/components/ui/form/form';
import { LocationContext } from '@/providers/location';
import { Customer } from '@/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from '@tanstack/react-router';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const schema = z.object({
  zipcode: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  phone: z.string().min(1, 'Required'),
  email: z.string(),
});

const defaultValues = {
  zipcode: '',
  phone: '',
  email: '',
  first_name: '',
  last_name: '',
};

export const NewCustomerDialog = () => {
  const [open, setOpen] = useState(false);
  const { activeLocationId } = useContext(LocationContext);
  const { mutate: createCustomer } = useCreateCustomer();
  const navigate = useNavigate();

  const [customerSearchValues, setCustomerSearchValues] = useState<Partial<Customer>>({});

  const { data: similarCustomers, refetch: refetchSimilar } = useCustomers({
    location: activeLocationId,
    search: {
      similar: JSON.stringify(customerSearchValues),
      limit: 50,
    },
    config: {
      enabled: !!(
        customerSearchValues.phone ||
        customerSearchValues.first_name ||
        customerSearchValues.last_name ||
        customerSearchValues.email
      ),
    },
  });

  const handleSubmit = (data: Partial<Customer>) => {
    createCustomer(
      { location: activeLocationId, data },
      {
        onSuccess: (customer) => {
          navigate({ to: `/customers/customer/${customer.id}/edit` });
        },
      },
    );
  };

  const handleOpenChange = (value: boolean) => {
    setOpen(value);
    form.reset(defaultValues);
  };

  const handleFindSimilar = () => {
    const data = form.getValues();
    const search: Partial<Customer> = {};

    if (data.first_name) {
      search.first_name = data.first_name;
    }
    if (data.last_name) {
      search.last_name = data.last_name;
    }
    if (data.phone) {
      search.phone = data.phone;
    }
    if (data.email) {
      search.email = data.email;
    }
    if (data.zipcode) {
      search.zipcode = data.zipcode;
    }

    setCustomerSearchValues(search);
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <span className="isolate inline-flex ">
        <DialogTrigger asChild>
          <Button variant="outline" onClick={() => setOpen(!open)}>
            New Customer
          </Button>
        </DialogTrigger>
      </span>
      <DialogContent className="max-w-2xl">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
            <DialogHeader>
              <DialogTitle>New Customer</DialogTitle>
            </DialogHeader>
            <div className="flex flex-row space-x-4">
              <div className="space-y-3">
                <FormInput
                  control={form.control}
                  name="zipcode"
                  type="number"
                  label="Zip Code"
                  onFieldBlur={handleFindSimilar}
                />
                <FormInput
                  control={form.control}
                  name="first_name"
                  label="First Name"
                  onFieldBlur={handleFindSimilar}
                />
                <FormInput
                  control={form.control}
                  name="last_name"
                  label="Last Name"
                  onFieldBlur={handleFindSimilar}
                />
                <FormInput
                  control={form.control}
                  name="phone"
                  label="Phone Number"
                  onFieldBlur={handleFindSimilar}
                />
                <FormEmailInput
                  control={form.control}
                  name="email"
                  label="Email Address"
                  onFieldBlur={handleFindSimilar}
                />
              </div>

              {similarCustomers && (
                <div className="w-full">
                  <h3 className="font-semibold text-sm">Similar Customers</h3>
                  <div className="h-56 border px-2 overflow-y-scroll divide-y">
                    {similarCustomers?.map((similar) => (
                      <div
                        key={`simcust-${similar.id}`}
                        className="text-sm flex flex-row py-2 items-center"
                      >
                        <div className="flex flex-col grow">
                          <div className="font-semibold">
                            Name: {similar.full_name || 'Unknown'}
                          </div>
                          {similar.organization_name && <div>Org: {similar.organization_name}</div>}
                          <div>Email: {similar.email || 'Unknown'}</div>
                          <div>Phone: {similar.phone}</div>
                          <div>Zip: {similar.zipcode || 'Unknown'}</div>
                        </div>
                        <div>
                          <Link to={`/customers/customer/${similar.id}/edit`}>View</Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <DialogFooter>
              <Button type="submit">Next</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
