import { PolicyCheck } from '@/features/auth/authorization';

export const AddonPolicies: Record<string, PolicyCheck> = {
  'products.addons.view': ({ acl, user, meta }) => {
    return acl.products.addons.view;
  },
  'products.addons.create': ({ acl, user, meta }) => {
    return acl.products.addons.create;
  },
  'products.addons.update': ({ acl, user, meta }) => {
    return acl.products.addons.edit;
  },
  'products.addons.delete': ({ acl, user, meta }) => {
    return acl.products.addons.delete;
  },
};
