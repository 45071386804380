import { useCreateNote } from '@/api/calendar/notes/createNote';
import { useDeleteNote } from '@/api/calendar/notes/deleteNote';
import { useUpdateNote } from '@/api/calendar/notes/updateNote';
import { Button } from '@/components/ui/elements/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/elements/dialog';
import { Form } from '@/components/ui/form/form';
import { PolicyGate } from '@/features/auth/authorization';
import { queryClient } from '@/lib/react-query';
import { CalendarNote, ModelID } from '@/types';
import { TrashIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { ConfirmationDialog } from '../Elements/ConfirmationDialog';
import { FormDate, FormTextarea } from '../Form';

type NoteModalProps = {
  location: ModelID;
  open: boolean;
  setOpen: (open: boolean) => void;
  note?: CalendarNote | undefined | null;
};

const schema = z.object({
  title: z.string(),
  start_at: z.string(),
  end_at: z.coerce.string().nullish(),
});

export const NoteModal = ({ location, open, setOpen, note = null }: NoteModalProps) => {
  const defaultValues = {
    title: note?.title ?? '',
    start_at: note?.start_at ?? '',
    end_at: note?.end_at ?? '',
  };
  const submitSuccess = (note: CalendarNote) => {
    queryClient.invalidateQueries({
      queryKey: [
        {
          scope: 'calendar-events',
          location,
          entity: 'list',
        },
      ],
    });
    setOpen(false);
  };

  const { mutate: createNote } = useCreateNote({
    onSuccess: submitSuccess,
  });

  const { mutate: updateNote } = useUpdateNote({
    onSuccess: submitSuccess,
  });

  const { mutate: deleteNote } = useDeleteNote({
    onSuccess: submitSuccess,
  });

  const handleSubmit = (values: z.infer<typeof schema>) => {
    if (note) {
      updateNote({
        id: note.calendar_note_id,
        data: {
          title: values.title,
          start_at: values.start_at,
          end_at: values.end_at ?? undefined,
        },
      });
    } else {
      createNote({
        data: {
          franchise_id: location,
          title: values.title,
          start_at: values.start_at,
          end_at: values.end_at ?? undefined,
        },
      });
    }
  };

  const handleDelete = () => {
    if (note?.calendar_note_id)
      deleteNote({
        id: note.calendar_note_id,
      });
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);

    return () => {
      form.reset();
    };
  }, [note]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <Form key={`note-${note?.calendar_note_id ?? 'new'}`} {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-3">
            <DialogHeader>
              <DialogTitle>{note ? 'Edit' : 'Add a'} Note</DialogTitle>
              <DialogDescription>
                {note ? 'Edit' : 'Add a'} note {note ? 'on a' : 'to a'} calendar date.
              </DialogDescription>
            </DialogHeader>
            <div className="flex flex-col space-y-3 py-4">
              <FormDate control={form.control} name="start_at" label="Start Date" />
              <FormDate control={form.control} name="end_at" label="End Date" />
              <FormTextarea control={form.control} name="title" placeholder="Enter notes here..." />
            </div>
            <DialogFooter>
              {note && (
                <PolicyGate policy="calendar.notes.delete">
                  <ConfirmationDialog
                    triggerButton={
                      <Button variant="destructive">
                        <TrashIcon className="h-5 w-5" />
                      </Button>
                    }
                    confirmButtonText="Delete"
                    confirmAction={handleDelete}
                    title={`Delete note on ${note.start_at} through ${note.end_at}?`}
                    body="Are you sure you want to delete this note? This action cannot be undone."
                  />
                </PolicyGate>
              )}
              <PolicyGate policy="calendar.notes.update">
                <div className="grow" />
                <Button type="submit">Save</Button>
              </PolicyGate>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
