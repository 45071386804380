import queryClient from '@/lib/react-query';
import { ModelID } from '@/types';
import { post } from '@/utils/api';
import { useMutation } from '@tanstack/react-query';
import { EventSearchParams, featureKeys, featurePath } from '.';

type ExportEventsDTO = {
  location: ModelID;
  ids: ModelID[];
  search?: EventSearchParams;
};

interface UseBatchConfirmEventsProps {
  onSuccess?: () => void;
}

const batchConfirmEvents = ({ location, ids, search = {} }: ExportEventsDTO) =>
  post<string>(`${featurePath}/batch-confirm`, {
    franchise_id: location,
    ids,
    ...search,
  });

export const useBatchConfirmEvents = ({ onSuccess }: UseBatchConfirmEventsProps) => {
  return useMutation({
    mutationFn: batchConfirmEvents,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: featureKeys.lists(variables.location) });
      if (onSuccess) onSuccess();
    },
  });
};
