import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ModelID, Vip } from '@/types';
import { get } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { featureKeys, featurePath, VipSearchParams } from '.';

type QueryFnType = typeof fetchVips;

type UseVipsOptions = {
  location: ModelID;
  search?: VipSearchParams;
  config?: QueryConfig<QueryFnType>;
};

export const fetchVips = (location: ModelID, search: VipSearchParams = {}) =>
  get<Vip[]>(`${featurePath}?franchise_id=${location}`, search);

export const useVips = ({ location, search = {}, config = {} }: UseVipsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: featureKeys.list(location, search),
    queryFn: () => fetchVips(location, search),
  });
};
