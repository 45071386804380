import { LeadNote, ModelID, SearchParams } from '@/types';

export type Filter = 'all' | 'open' | 'new' | 'closed';
export type Sort = 'created_at' | 'updated_at';
export interface LeadNoteSearchParams extends SearchParams<Filter, Sort>, Partial<LeadNote> {}

export const featurePath = '/v4/leads/note';

export const featureKeys = {
  all: [{ scope: 'lead-notes' }] as const,

  lists: () => [{ ...featureKeys.all[0], entity: 'list' }] as const,

  list: (location: ModelID, search: LeadNoteSearchParams = {}) =>
    [{ search, location, entity: 'list' }] as const,

  details: () => [{ ...featureKeys.all[0], entity: 'detail' }] as const,

  detail: (id: ModelID, search: LeadNoteSearchParams = {}) =>
    [
      { ...featureKeys.details()[0], id, search },
      { ...featureKeys.details()[0], id },
    ] as const,
};
