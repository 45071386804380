import { Badge } from '@/components/ui/elements/badge';
import { Lead } from '@/types';
import { LockClosedIcon } from '@heroicons/react/24/solid';

interface LockedBadgeProps {
  lead: Lead;
}

export const LockedBadge = ({ lead }: LockedBadgeProps) => {
  const initials = lead.locked_by_full_name
    ? lead.locked_by_full_name
        .split(' ')
        .map((n) => n[0])
        .join('')
    : '';

  return (
    <>
      {initials && (
        <Badge size="sm">
          <LockClosedIcon className="w-4 h-4 mr-1" />
          {initials}
        </Badge>
      )}
    </>
  );
};
