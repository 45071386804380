import { useAssets } from '@/api/equipment/assets/getAssets';
import { useDownloadEventPack } from '@/api/events/events/downloadEventPack';
import { useEventActivities } from '@/api/events/events/getEventActivities';
import { DateRangePickerCalendarGrid } from '@/components/DatePicker/DateRangePicker';
import { Spinner } from '@/components/Elements';
import { Alert } from '@/components/Elements/Alert/Alert';
import { ScrollableLayout } from '@/components/Layout';
import Page from '@/components/Layout/Page';
import { Button } from '@/components/ui/elements/button';
import { PolicyGate } from '@/features/auth/authorization';
import { InsufficientPermissions } from '@/features/auth/components/InsufficientPermissions';
import { LocationContext } from '@/providers/location';
import { Asset } from '@/types';
import { ArrowDownOnSquareIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { getLocalTimeZone, today } from '@internationalized/date';
import { useContext, useState } from 'react';
import { Button as AriaButton, DateRange, Heading, RangeCalendar } from 'react-aria-components';

export const EventPackPage = () => {
  const { activeLocationId } = useContext(LocationContext);
  const { mutate: download, isPending } = useDownloadEventPack();

  const [dateRange, setDateRange] = useState<DateRange>({
    start: today(getLocalTimeZone()),
    end: today(getLocalTimeZone()),
  });

  const { data: assets } = useAssets({
    location: activeLocationId,
    search: {
      active: true,
    },
  });

  const { data: activities } = useEventActivities({
    location: activeLocationId,
    search: {
      date_begin: dateRange?.start.toString(),
      date_end: dateRange?.end.toString(),
      status_not: 'cancelled',
    },
  });

  const handleDownload = (asset: Asset) => {
    download({
      location: activeLocationId,
      asset,
      start: dateRange?.start.toString(),
      end: dateRange?.end.toString(),
    });
  };

  const assetCountList = assets?.map((asset) => ({
    asset,
    count: activities?.filter((activity) => activity.asset_id === asset.asset_id).length || 0,
  }));

  return (
    <PolicyGate policy="events.view" forbiddenFallback={<InsufficientPermissions />}>
      <ScrollableLayout>
        <Page>
          <Page.Header className="mx-8 my-2">
            <Page.HeaderContent title="Download Event Day Packs">
              Download packs for the selected day(s).
            </Page.HeaderContent>
          </Page.Header>
          <Page.Body policy="customers.lists.view">
            <div className="flex flex-row space-x-5 p-3">
              <div>
                <h3 className="text-lg pl-2">Step 1: Select Dates</h3>
                <Alert title="Note" size="sm" color="blue" className="my-2 py-2 px-1">
                  Date ranges are currently limited to seven days or less.
                </Alert>
                <div className="border p-5">
                  <RangeCalendar
                    className="w-full"
                    value={dateRange}
                    onChange={setDateRange}
                    defaultValue={{
                      start: today(getLocalTimeZone()),
                      end: today(getLocalTimeZone()).add({ days: 1 }),
                    }}
                  >
                    <header className="w-full flex flex-row text-center mb-4">
                      <AriaButton slot="previous" className="text-gray-400 hover:text-gray-700">
                        <ChevronRightIcon className="h-5 w-5 rotate-180" />
                      </AriaButton>
                      <Heading className="grow" />
                      <AriaButton slot="next" className="text-gray-400 hover:text-gray-700">
                        <ChevronRightIcon className="h-5 w-5" />
                      </AriaButton>
                    </header>
                    <DateRangePickerCalendarGrid />
                  </RangeCalendar>
                </div>
              </div>
              <div className="min-w-96">
                <h3 className="text-lg">Step 2: Download Event Packs</h3>
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th className="px-3 py-2.5 md:px-6 text-left text-sm font-semibold text-gray-900">
                        Asset
                      </th>
                      <th className="px-3 py-2.5 md:px-6 text-left text-sm font-semibold text-gray-900">
                        Events
                      </th>
                      <th className="px-3 py-2.5 md:px-6 text-left text-sm font-semibold text-gray-900">
                        Download
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {assetCountList?.map((node) => (
                      <tr key={node.asset.id}>
                        <td className="whitespace-nowrap py-2 px-3 md:py-2.5 md:px-6 text-sm">
                          {node.asset.name}
                        </td>
                        <td className="whitespace-nowrap py-2 px-3 md:py-2.5 md:px-6 text-sm">
                          {node.count}
                        </td>
                        <td className="whitespace-nowrap py-2 px-3 md:py-2.5 md:px-6 text-sm">
                          {node.count > 0 && (
                            <Button disabled={isPending} onClick={() => handleDownload(node.asset)}>
                              {isPending ? (
                                <Spinner variant="light" size="sm" />
                              ) : (
                                <ArrowDownOnSquareIcon className="h-5 w-5" />
                              )}
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Page.Body>
        </Page>
      </ScrollableLayout>
    </PolicyGate>
  );
};
