import queryClient from '@/lib/react-query';
import {
  UpdateDTO,
  useUpdateMutationFactory,
  UseUpdateMutationProps,
} from '@/lib/react-query/update';
import { Event, Lead, ModelID } from '@/types';
import { post, update } from '@/utils/api';
import { useMutation } from '@tanstack/react-query';
import { featureKeys, featurePath, LeadSearchParams } from '.';

export type UpdateLeadDTO = UpdateDTO<Lead, LeadSearchParams>;

export type LeadManageQuoteDTO = {
  id: ModelID;
  command: string;
  quote_id: ModelID | null;
  data: any;
  params?: LeadSearchParams;
};

export interface BookEventParams {
  charge_deposit: boolean;
  amount?: number;
  payment_method_id?: ModelID;
  send_email?: boolean;
  send_text?: boolean;
}

type LogLeadAction = {
  contactType: string;
  result?: string;
};

interface BookEventDTO {
  id: ModelID;
  params: BookEventParams;
}

interface RequestLockDTO {
  id: ModelID;
}

interface ReleaseLockDTO {
  id: ModelID;
}

interface ClearWaitDTO {
  id: ModelID;
}

interface SetPriorityDTO {
  id: ModelID;
  is_priority: boolean;
}

export type LogLeadDTO = UpdateDTO<LogLeadAction, LeadSearchParams>;

export const updateLead = ({ data, id, params }: UpdateLeadDTO) =>
  update<Lead>(`${featurePath}/${id}`, data, params);

const closeLead = async ({ data, id, params }: UpdateLeadDTO) =>
  post<Lead>(`${featurePath}/${id}/close`, data, params);

const logLead = async ({ data, id, params }: LogLeadDTO) =>
  post<Lead, any, LogLeadAction>(`${featurePath}/${id}/log`, data, params);

const manageQuote = async ({ id, command, quote_id, data, params }: LeadManageQuoteDTO) =>
  post<Lead>(`${featurePath}/${id}/manage-quote`, { command, quote_id, data }, params);

const sendQuote = async ({ id, params }: UpdateLeadDTO) =>
  post<Lead>(`${featurePath}/${id}/send-quote`, {}, params);

const bookEvent = async ({ id, params }: BookEventDTO) =>
  post<Event>(`${featurePath}/${id}/book`, params);

const requestLock = async ({ id }: RequestLockDTO) =>
  post<Lead>(`${featurePath}/${id}/request-lock`, {});

const releaseLock = async ({ id }: ReleaseLockDTO) =>
  post<Lead>(`${featurePath}/${id}/release-lock`, {});

const clearWait = async ({ id }: ClearWaitDTO) => post<Lead>(`${featurePath}/${id}/clear-wait`, {});

const setPriority = async ({ id, is_priority }: SetPriorityDTO) =>
  post<Lead>(`${featurePath}/${id}/priority`, {
    is_priority,
  });

export const useUpdateLead = (options?: UseUpdateMutationProps<Lead>) => {
  return useUpdateMutationFactory(
    {
      invalidateKeys: featureKeys,
      mutationFn: updateLead,
      onSuccess: (lead) => {
        queryClient.invalidateQueries({ queryKey: featureKeys.validation(lead.id) });
      },
      ...options,
    },
    { shouldInvalidateAll: false },
  );
};

export const useCloseLead = (options?: UseUpdateMutationProps<Lead>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: closeLead,
    ...options,
  });
};

export const useLogLead = (options?: UseUpdateMutationProps<Lead>) => {
  return useUpdateMutationFactory(
    {
      invalidateKeys: featureKeys,
      mutationFn: logLead,
      ...options,
    },
    { shouldInvalidateAll: false },
  );
};

export const useLeadManageQuote = (options?: UseUpdateMutationProps<Lead>) => {
  return useUpdateMutationFactory(
    {
      invalidateKeys: featureKeys,
      mutationFn: manageQuote,
      onSuccess: (lead) => {
        queryClient.invalidateQueries({ queryKey: featureKeys.validation(lead.id) });
      },
      ...options,
    },
    { shouldInvalidateAll: false },
  );
};

export const useLeadSendQuote = (options?: UseUpdateMutationProps<Lead>) => {
  return useMutation<Lead, unknown, UpdateLeadDTO, unknown>({
    mutationFn: sendQuote,
    ...options,
  });
};

export const useBookEvent = (options?: UseUpdateMutationProps<Event>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: bookEvent,
    ...options,
  });
};

export const useLockLead = (options?: UseUpdateMutationProps<Lead>) => {
  return useUpdateMutationFactory(
    {
      invalidateKeys: featureKeys,
      mutationFn: requestLock,
      ...options,
    },
    { shouldInvalidateAll: false },
  );
};

export const useReleaseLead = (options?: UseUpdateMutationProps<Lead>) => {
  return useUpdateMutationFactory(
    {
      invalidateKeys: featureKeys,
      mutationFn: releaseLock,
      ...options,
    },
    { shouldInvalidateAll: false },
  );
};

export const useClearWait = (options?: UseUpdateMutationProps<Lead>) => {
  return useUpdateMutationFactory(
    {
      invalidateKeys: featureKeys,
      mutationFn: clearWait,
      ...options,
    },
    { shouldInvalidateAll: false },
  );
};

export const useSetPriority = (options?: UseUpdateMutationProps<Lead>) => {
  return useUpdateMutationFactory(
    {
      invalidateKeys: featureKeys,
      mutationFn: setPriority,
      ...options,
    },
    { shouldInvalidateAll: false },
  );
};
