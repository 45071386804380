import { Badge, BadgeProps } from '@/components/ui/elements/badge';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/elements/tooltip';
import { Customer } from '@/types';

interface SurveyBadgeProps {
  customer: Customer;
}

export const SurveyBadge = ({ customer }: SurveyBadgeProps) => {
  const survey = customer.surveys?.[0];

  if (!survey || !survey.completed_at) return <></>;

  let variant = 'gray' as BadgeProps['variant'];
  switch (survey.recommend) {
    case 10:
    case 9:
      variant = 'green';
      break;
    case 8:
    case 7:
    case 6:
      variant = 'yellow';
      break;
    default:
      variant = 'red';
      break;
  }

  return (
    <TooltipProvider delayDuration={50}>
      <Tooltip>
        <TooltipTrigger>
          <Badge size="sm" variant={variant}>
            NPS: {survey.recommend}
          </Badge>
        </TooltipTrigger>
        <TooltipContent side="top" className="w-48">
          {survey.comments || 'No comments'}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
