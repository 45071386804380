import { useAssets } from '@/api/equipment/assets/getAssets';
import { useAddons } from '@/api/locations/addons/getAddons';
import { usePackages } from '@/api/locations/packages/getPackages';
import { useServices } from '@/api/locations/services/getServices';
import { FormSelect } from '@/components/Form';
import { SelectOption } from '@/components/Form/FormSelect';
import { Button } from '@/components/ui/elements/button';
import { Form } from '@/components/ui/form/form';
import { LocationContext } from '@/providers/location';
import { Activity, CompleteTransferDTO, LineItem, ModelID } from '@/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';

const schema = z.object({
  activities: z
    .array(
      z.object({
        source: z.string(),
        name: z.string(),
        target: z.string(),
        assetType: z.string(),
        asset: z.string(),
      }),
    )
    .nullish(),
  packages: z
    .array(z.object({ source: z.string(), name: z.string(), target: z.string() }))
    .nullish(),
  lineitems: z
    .array(z.object({ source: z.string(), name: z.string(), target: z.string() }))
    .nullish(),
});

interface Quotable {
  franchise_id: ModelID;
  activities: Activity[];
  line_items: LineItem[];
}

interface QuoteTransferProps<T> {
  quoteable: T extends Quotable ? T : never;
  onComplete: (values: CompleteTransferDTO<T>['data']) => void;
  onReject: () => void;
}

export function QuoteTransfer<T>({ quoteable, onComplete, onReject }: QuoteTransferProps<T>) {
  const { activeLocationId } = useContext(LocationContext);

  const { data: services, isPending: servicesIsLoading } = useServices({
    location: quoteable.franchise_id,
    search: { active: true, orderBy: 'asset_type_id', orderDir: 'asc' },
  });
  const serviceOptions =
    services?.map((service) => ({
      label: service.name,
      value: service.id.toString(),
      meta: { asset_type_id: service.asset_type_id.toString() },
    })) ?? ([] as SelectOption[]);

  const { data: addons, isPending: addonsIsLoading } = useAddons({
    location: quoteable.franchise_id,
    search: { active: true, orderBy: 'name', orderDir: 'asc' },
  });
  const addonOptions =
    addons?.map((addon) => ({
      label: addon.name,
      value: addon.id.toString(),
    })) ?? ([] as SelectOption[]);

  const { data: packages, isPending: packagesIsLoading } = usePackages({
    location: quoteable.franchise_id,
    search: { active: true, orderBy: 'name', orderDir: 'asc' },
  });
  const packageOptions =
    packages?.map((pkg) => ({
      label: pkg.name,
      value: pkg.id.toString(),
    })) ?? ([] as SelectOption[]);
  const packageLineItems = quoteable.line_items?.filter((pkg) => pkg.package_id > 0) ?? [];
  const lineItems =
    quoteable.line_items?.filter(
      (lineitem) => !lineitem.package_id && lineitem.managed_by_package < 1,
    ) ?? [];

  const { data: assets, isPending: assetsIsLoading } = useAssets({
    location: quoteable.franchise_id,
    search: { active: true, orderBy: 'name', orderDir: 'asc' },
  });

  const handleAcceptTransfer = (values: z.infer<typeof schema>) => {
    onComplete({ map: values });
  };

  const handleRejectTransfer = () => {
    onReject();
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      activities: quoteable.activities?.map((activity) => {
        return {
          source: activity.id.toString(),
          name: activity.name,
          assetType: activity?.service?.asset_type_id.toString(),
        };
      }),
      packages: packageLineItems.map((pkg) => {
        return { source: pkg.lineitem_id.toString(), name: pkg.name };
      }),
      lineitems: lineItems.map((lineitem) => {
        return { source: lineitem.id.toString(), name: lineitem.name };
      }),
    },
  });

  const { fields: activityFields } = useFieldArray({
    control: form.control,
    name: 'activities',
  });

  const { fields: packageFields } = useFieldArray({
    control: form.control,
    name: 'packages',
  });

  const { fields: lineitemFields } = useFieldArray({
    control: form.control,
    name: 'lineitems',
  });

  return (
    <>
      <h1 className="text-lg font-semibold">Pending Transfer</h1>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleAcceptTransfer)}
          className="flex flex-col space-y-4"
        >
          <section>
            <h2 className="pb-2 font-semibold">Activities</h2>
            {activityFields.map((field, index) => {
              return (
                <div
                  key={`${field.id}-${index}`}
                  className="flex flex-row py-4 space-x-2 items-center border-b border-b-gray-200"
                >
                  <span className="basis-1/2">{field.name}</span>
                  <span className="basis-1/4">
                    <FormSelect
                      control={form.control}
                      name={`activities.${index}.target`}
                      label="New Value"
                      options={serviceOptions.filter(
                        (option) => field.assetType === option.meta.asset_type_id.toString(),
                      )}
                    />
                  </span>
                  <span className="basis-1/4">
                    <FormSelect
                      control={form.control}
                      name={`activities.${index}.asset`}
                      label="Equipment"
                      options={
                        assets
                          ?.filter((asset) => field.assetType === asset.asset_type_id.toString())
                          .map((asset) => {
                            return { value: asset.id, label: asset.name };
                          }) ?? []
                      }
                    />
                  </span>
                </div>
              );
            })}
          </section>

          <section>
            <h2 className="pb-2 font-semibold">Packages</h2>
            {packageFields.map((field, index) => {
              return (
                <div
                  key={`${field.id}-${index}`}
                  className="flex flex-row py-4 space-x-2 items-center border-b border-b-gray-200"
                >
                  <span className="basis-1/2">{field.name}</span>
                  <span className="basis-1/2">
                    <FormSelect
                      control={form.control}
                      name={`packages.${index}.target`}
                      label="New Value"
                      options={packageOptions}
                    />
                  </span>
                </div>
              );
            })}
          </section>

          <section>
            <h2 className="pb-2 font-semibold">Other Line Items</h2>
            {lineitemFields.map((field, index) => {
              return (
                <div
                  key={`${field.id}-${index}`}
                  className="flex flex-row py-4 space-x-2 items-center border-b border-b-gray-200"
                >
                  <span className="basis-1/2">{field.name}</span>
                  <span className="basis-1/2">
                    <FormSelect
                      control={form.control}
                      name={`lineitems.${index}.target`}
                      label="New Value"
                      options={addonOptions}
                    />
                  </span>
                </div>
              );
            })}
          </section>
          <div className="flex flex-row py-5 justify-between">
            <Button type="button" variant="destructive" onClick={handleRejectTransfer}>
              Reject Transfer
            </Button>
            <Button type="submit">Accept Transfer</Button>
          </div>
        </form>
      </Form>
    </>
  );
}
