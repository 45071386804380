interface SendNotificationParams {
  title: string;
  options: NotificationOptions;
  onClick?: () => void;
}

export const useBrowserNotifications = () => {
  const grantNotificationPermission = async () => {
    if (Notification.permission !== 'granted') {
      return await Notification.requestPermission();
    }
  };

  const sendNotification = (notification: SendNotificationParams) => {
    const { title, options, onClick } = notification;

    if (!('Notification' in window)) {
      return;
    } else if (Notification.permission === 'granted') {
      makeNotification(title, options, onClick);
    } else {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          makeNotification(title, options, onClick);
        }
      });
    }
  };

  const makeNotification = (title: string, options: NotificationOptions, onClick?: () => void) => {
    const notification = new Notification(`Portal: ${title}`, {
      // icon: 'https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png',
      ...options,
    });
    notification.onclick = (event) => {
      event.preventDefault();
      window.focus();
      notification.close();
      if (onClick) onClick();
    };
  };

  return { grantNotificationPermission, sendNotification };
};
