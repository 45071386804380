import { useHeartbeat } from '@/api/locations/useHeartbeat';
import logo from '@/assets/gametruck-fireball.svg';
import { MainMenuItems } from '@/config/mainmenu';
import { PolicyGate, useAuthorization } from '@/features/auth/authorization';
import { LocationContext } from '@/providers/location';
import { cn } from '@/utils/format';
import { Link, useMatchRoute } from '@tanstack/react-router';
import { useContext, useMemo } from 'react';
import { isAndroid, isTablet } from 'react-device-detect';
import { MobileNav } from './Mobile/MobileNav';

interface MainNavigationProps {
  mobileMenuOpen: boolean;
  setMobileMenuOpen: (open: boolean) => void;
}

export const MainNavigation = ({ mobileMenuOpen, setMobileMenuOpen }: MainNavigationProps) => {
  const { waiting, eventsWaiting, unreadMessages } = useHeartbeat();
  const { activeLocation } = useContext(LocationContext);
  const matchRoute = useMatchRoute();
  const { checkPolicy } = useAuthorization();

  const menuItems = useMemo(
    () =>
      MainMenuItems.filter((item) => {
        return item.access_rights.every((right: string) => {
          return checkPolicy({ policy: right, meta: { location: activeLocation } });
        });
      }),
    [activeLocation],
  );

  const activatedMenuItems = menuItems.map((item) => {
    if (item.path === '/') {
      return item;
    }

    const sectionPath = item.path.split('/')[1] ?? '';
    item.active = matchRoute({ to: sectionPath, fuzzy: true }) !== false;

    return item;
  });

  return (
    <>
      {/* Narrow sidebar */}
      <div
        className={cn(
          'h-full',
          isAndroid && isTablet ? 'h-[calc(100vh-95px)]' : '',
          isAndroid && !isTablet ? 'h-[calc(100vh-55px)]' : '',
          'hidden md:block md:w-10 lg:w-20 xl:w-24 overflow-y-auto bg-gt-green-500',
        )}
      >
        <div className="flex w-full flex-col items-center py-2 lg:py-4">
          <div className="flex flex-shrink-0 items-center">
            <img className="mx-auto px-2 my-2 w-16" src={logo} alt="GameTruck" />
          </div>
          <div className="lg:mt-6 w-full flex-1 space-y-0.5 px-2">
            {activatedMenuItems.map((item) => (
              <Link
                key={item.label}
                to={item.path}
                search={item.search}
                params={{}}
                className={cn(
                  'group flex w-full flex-col items-center rounded-md px-2 py-1 xl:py-2 text-xs tracking-tight text-gt-green-50 transition-colors',
                  '[&.active]:bg-gt-green-600/70 [&.active]:hover:bg-gt-green-600',
                  'text-gt-green-50  hover:bg-gt-green-700/30',
                  item.active ? 'bg-gt-green-600/70 hover:bg-gt-green-600' : '',
                )}
              >
                <item.icon
                  className={cn('text-gt-green-50 group-hover:text-gt-green-50', 'h-5 w-5')}
                  aria-hidden="true"
                />
                <span className="block mt-1">
                  <span className="hidden lg:block">{item.label}</span>
                  <PolicyGate policy="messages.view">
                    {item.label === 'Messages' && !!unreadMessages && (
                      <div className="bg-red-600 rounded-3xl px-0.5 w-full min-w-4 max-w-8 mx-auto text-center text-[9px] font-bold">
                        {unreadMessages}
                      </div>
                    )}
                    {item.label === 'Leads' && !!waiting && (
                      <div className="bg-red-600 rounded-3xl px-0.5 w-full min-w-4 max-w-8 mx-auto text-center text-[9px] font-bold">
                        {waiting}
                      </div>
                    )}
                    {item.label === 'Events' && !!eventsWaiting && (
                      <div className="bg-red-600 rounded-3xl px-0.5 w-full min-w-4 max-w-8 mx-auto text-center text-[9px] font-bold">
                        {eventsWaiting}
                      </div>
                    )}
                  </PolicyGate>
                </span>
              </Link>
            ))}
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <MobileNav open={mobileMenuOpen} setOpen={setMobileMenuOpen} />
    </>
  );
};
