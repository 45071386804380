import { useAddons } from '@/api/locations/addons/getAddons';
import { usePackages } from '@/api/locations/packages/getPackages';
import { useServices } from '@/api/locations/services/getServices';
import { Table } from '@/components/Elements';
import { ServicePricing } from '@/components/ServicePricing/ServicePricing';
import { LeadContext } from '@/features/leads/contexts/LeadContext';
import { Addon, Lead, Package } from '@/types';
import { formatMoney } from '@/utils/format';
import { useContext } from 'react';

interface PricingProps {
  lead: Lead;
}

export const PricingTab = () => {
  const { lead } = useContext(LeadContext);

  if (!lead) return <>Loading...</>;

  return <Pricing lead={lead} />;
};

const Pricing = ({ lead }: PricingProps) => {
  const { data: services, isSuccess: servicesIsSuccess } = useServices({
    location: lead.franchise.id,
    search: { active: true, orderBy: 'asset_type_id', orderDir: 'asc' },
  });

  const { data: addons, isSuccess: addonsIsSuccess } = useAddons({
    location: lead.franchise.id,
    search: { active: true, orderBy: 'name', orderDir: 'asc' },
  });

  const { data: packages, isSuccess: packagesIsSuccess } = usePackages({
    location: lead.franchise.id,
    search: { active: true, orderBy: 'name', orderDir: 'asc' },
  });

  return (
    <div className="@container">
      {servicesIsSuccess && services && (
        <div className="grid grid-cols-1 @md:grid-cols-2 @5xl:grid-cols-3 text-sm leading-6 ">
          {services?.map((service) => (
            <div key={`service-pricing-${service.id}`} className="border-2 p-2">
              <h2 className="text-lg font-bold">{service.name}</h2>
              <ServicePricing service={service} />
            </div>
          ))}
        </div>
      )}

      <div className="grid grid-cols-1 @md:grid-cols-2 text-sm leading-6 ">
        {packagesIsSuccess && packages && (
          <div className="w-full">
            <Table<Package>
              label="Packages"
              data={packages}
              columns={[
                {
                  title: 'Package',
                  field: 'name',
                },
                {
                  title: 'Price',
                  field: 'price',
                  Cell({ entry: { price } }) {
                    return <>{formatMoney(price)}</>;
                  },
                },
              ]}
            />
          </div>
        )}

        {addonsIsSuccess && addons && (
          <div className="w-full">
            <Table<Addon>
              label="Addons"
              data={addons}
              columns={[
                {
                  title: 'Addon',
                  field: 'name',
                },
                {
                  title: 'Price',
                  field: 'price',
                  Cell({ entry: { price } }) {
                    return <>{formatMoney(price)}</>;
                  },
                },
              ]}
            />
          </div>
        )}
      </div>
      <div className="mb-96"> </div>
    </div>
  );
};
