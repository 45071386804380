import { CopyToClipboard } from '@/components/CopyToClipboard/CopyToClipboard';
import { Badge } from '@/components/ui/elements/badge';
import { Event } from '@/types';

interface IdBadgeProps {
  event: Event;
  copyable?: boolean;
}

export const IdBadge = ({ event, copyable = true }: IdBadgeProps) => {
  if (!copyable) return <Badge size="sm">EID #{event.event_id}</Badge>;

  return (
    <Badge size="sm">
      <CopyToClipboard value={event.event_id}>EID #{event.event_id}</CopyToClipboard>
    </Badge>
  );
};
