export const useScrollToTop = (ref: React.RefObject<HTMLDivElement>) => {
  const scrollToTop = () => {
    if (ref?.current) {
      ref.current.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return {
    scrollToTop,
  };
};
