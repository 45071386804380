import { useOverlayContext } from '@/components/Layout/OverlayControls';
import { toast } from '@/components/ui/elements/use-toast';
import { useBrowserNotifications } from '@/hooks/useBrowserNotifications';
import queryClient from '@/lib/react-query';
import { AuthContext } from '@/providers/auth';
import { LocationContext } from '@/providers/location';
import useNotificationStore from '@/stores/notifications';
import { CustomerMessage, Lead, User } from '@/types';
import { useChannel, useEvent } from '@harelpls/use-pusher';
import { useNavigate } from '@tanstack/react-router';
import { useContext } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useShallow } from 'zustand/react/shallow';

interface RealtimeUpdate<T> {
  message: T;
  user?: User;
}

interface PrivateUserMessage {
  title: string;
  message: string;
  type: string;
  link?: string;
}

export const useUserNotificationsChannel = () => {
  const { user } = useContext(AuthContext);
  const { activeLocationId } = useContext(LocationContext);
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore(
    useShallow((state) => ({
      addNotification: state.add,
    })),
  );
  const { sendNotification } = useBrowserNotifications();

  const { trayVisible, trayMode, togglePanels, setTrayMode } = useOverlayContext();
  const handleNotificationIconClick = () => {
    if (trayMode !== 'notifications' || !trayVisible) {
      if (!trayVisible) togglePanels();
      setTrayMode('notifications');
    }
  };

  const channel = useChannel(`private-user-notifications.${user?.user_id}`);

  // Generic notifications
  useEvent<any>(channel, 'notification', (data: PrivateUserMessage) => {
    addNotification(data);

    if (document.hidden) {
      sendNotification({
        title: data.title,
        options: {
          body: data.message,
        },
      });
    } else {
      toast({
        title: data.title,
        description: data.message,
        duration: 3000,
        onClick: handleNotificationIconClick,
      });
    }
  });

  // New leads
  useEvent<RealtimeUpdate<Lead>>(channel, 'new-lead', (data) => {
    if (!data || !data.message) return;

    addNotification({
      title: 'New Lead Received',
      link: `/leads/lead/${data.message?.id}`,
    });

    if (document.hidden) {
      sendNotification({
        title: 'New Lead Received',
        options: {
          body: data.message?.id.toString(),
        },
        onClick: () => {
          navigate({ to: `/leads/lead/${data.message?.id}` });
        },
      });
    } else {
      toast({
        title: 'New Lead Received',
        description: data.message?.id,
        duration: 3000,
        onClick: () => {
          navigate({ to: `/leads/lead/${data.message?.id}` });
        },
      });
    }
  });

  // Download is ready
  useEvent<PrivateUserMessage>(channel, 'download-ready', (data) => {
    console.log('Download ready', data);
    if (!data) return;

    addNotification({
      title: data.title,
      message: data.message,
      link: data.link,
    });

    if (!document.hidden && !isMobileOnly) {
      toast({
        title: data.title,
        description: data.message,
        duration: 3000,
        onClick: handleNotificationIconClick,
      });
    }
  });

  // New Message
  useEvent<RealtimeUpdate<CustomerMessage>>(channel, 'new-customer-message', (data) => {
    if (!data || !data.message) return;

    let url = undefined as string | undefined;
    if (data.message?.contextable_type === 'lead') {
      url = `/leads/lead/${data.message?.contextable_id}/messages`;
    } else if (data.message?.contextable_type === 'event') {
      url = `/events/event/${data.message?.contextable_id}/messages`;
    } else if (data.message?.customer_id) {
      url = `/customers/customer/${data.message?.customer_id}/messages`;
    }

    let title = 'New message from customer';
    if (data?.message.sender_first_name) {
      title = `New message from ${data.message.sender_first_name} ${data.message.sender_last_name}`;
    }

    queryClient.invalidateQueries({ queryKey: [{ scope: 'heartbeat' }] });
    queryClient.invalidateQueries({
      queryKey: [
        { entity: 'list', location: activeLocationId, scope: 'leads', search: { filter: 'inbox' } },
      ],
      exact: false,
    });

    addNotification({
      title: title,
      message: data.message?.message,
      link: url,
    });

    if (document.hidden) {
      const icon =
        data.message?.media && data.message?.media.length > 0
          ? data.message?.media[0].url
          : undefined;

      sendNotification({
        title: title,
        options: {
          body: data.message?.message,
          icon,
        },
        onClick: () => {
          if (url) navigate({ to: `/${url}` });
        },
      });
    } else {
      toast({
        title: title,
        description: data.message?.message,
        duration: 2000,
        onClick: () => {
          if (url) navigate({ to: `/${url}` });
        },
      });
    }
  });
};
