import { SearchParams } from '@/types';
import { MutationFunction, useMutation, UseMutationOptions } from '@tanstack/react-query';
import { queryClient } from '.';

export type CreateDTO<T, S = SearchParams> = {
  data: Partial<T>;
  params?: S;
};

export type UseCreateMutationProps<TModel> = {
  onMutate?: (value: Partial<TModel>) => void;
  onError?: (error: unknown, value: Partial<TModel>, context?: unknown) => void;
  onSuccess?: (response: TModel) => void;
  config?: UseMutationOptions<TModel>;
};

type CreateMutationFactoryProps<TDataDTO, TModel> = UseCreateMutationProps<TModel> & {
  mutationFn: MutationFunction<TModel, TDataDTO>;
  invalidateKeys?: any;
};

export const genericCreateOnMutateStrategy = async <T>(invalidationKeys: any, newData: any) => {
  await queryClient.cancelQueries(invalidationKeys.all);

  const previousData = queryClient.getQueryData<T[]>(invalidationKeys.all);

  queryClient.setQueryData(invalidationKeys.all, [...(previousData || []), newData.data]);

  return { previousData };
};

export const genericCreateErrorOnMutateStrategy = (
  _: any,
  __: any,
  context: any,
  invalidationKeys: any,
) => {
  if (context?.previousData) {
    queryClient.setQueryData(invalidationKeys.all, context.previousData);
  }
};

export const useCreateMutationFactory = <TDataDTO, TModel>(
  options: CreateMutationFactoryProps<TDataDTO, TModel>,
) => {
  const { mutationFn, onMutate, onError, onSuccess, invalidateKeys = {}, config } = options;

  const mutationConfig = Object.assign(
    {
      onMutate: (newData: Partial<TModel>) => {
        if (onMutate) {
          onMutate(newData);
        } else {
          genericCreateOnMutateStrategy(invalidateKeys, newData);
        }
      },

      onError: (_: unknown, __: any, context: any) => {
        if (onError) {
          onError(_, __, context);
        } else {
          genericCreateErrorOnMutateStrategy(_, __, context, invalidateKeys);
        }
      },

      onSuccess: (data: TModel) => {
        queryClient.invalidateQueries({ queryKey: invalidateKeys.all });
        if (onSuccess) {
          onSuccess(data);
        }
      },

      mutationFn,
    },
    config,
  );

  return useMutation<TModel, unknown, TDataDTO, unknown>(
    mutationConfig as UseMutationOptions<TModel, unknown, TDataDTO, unknown>,
  );
};
