import { Avatar as UiAvatar, AvatarFallback, AvatarImage } from '@/components/ui/elements/avatar';
import { cn } from '@/utils/format';
import { cva, VariantProps } from 'cva';
import React from 'react';

export const avatarVariants = cva(['inline-block rounded-full ring-2 ring-white leading-none'], {
  variants: {
    size: {
      sm: 'h-6 w-6 text-xs font-semibold',
      md: 'h-8 w-8',
      lg: 'h-10 w-10',
      xl: 'h-14 w-14 text-2xl font-extrabold ',
    },
    color: {
      gray: 'bg-gray-400',
      green: 'bg-green-700',
      purple: 'bg-purple-700',
    },
  },
  compoundVariants: [],
  defaultVariants: {
    size: 'sm',
    color: 'gray',
  },
});

export type AvatarProps = React.HTMLAttributes<HTMLSpanElement> &
  VariantProps<typeof avatarVariants> & {
    fallback?: string;
    src?: string;
    alt?: string;
  };

export const Avatar = ({ src, alt, fallback, size, color, className, ...props }: AvatarProps) => {
  return (
    <UiAvatar className={cn(avatarVariants({ size, color, className }))} {...props}>
      <AvatarImage src={src} alt={alt} />
      {fallback && <AvatarFallback>{fallback}</AvatarFallback>}
    </UiAvatar>
  );
};
