import List from '@/components/Elements/List/List';
import { formFieldVariants } from '@/components/Form/FormInput';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/elements/dialog';
import { Input } from '@/components/ui/elements/input';
import { FormItem, FormLabel } from '@/components/ui/form/form';
import { useDebounce } from '@/hooks/useDebounce';
import { MapBoxContext } from '@/providers/mapbox';
import { cn } from '@/utils/format';
import { ArrowPathIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { LngLatLike } from 'mapbox-gl';
import { useContext, useState } from 'react';

export type AddressSelection = {
  address: string;
  city?: string;
  state?: string;
  zipcode?: string;
  latitude?: number;
  longitude?: number;
};

type AddressLookupProps = {
  address: string;
  onSelect: (address: AddressSelection) => void;
  className?: string;
  searchArea?: LngLatLike;
};

export const AddressLookup = ({ onSelect, className, address, searchArea }: AddressLookupProps) => {
  const { session } = useContext(MapBoxContext);
  const [open, setOpen] = useState(false);
  const [searchAddress, setSearchAddress] = useState(address);
  const [suggestions, setSuggestions] = useState<any[]>([]);

  const handleOpenChange = (value: boolean) => {
    setSearchAddress(address);
    setOpen(value);
  };

  const runDebouncedSearch = useDebounce(async () => {
    if (searchAddress.length > 5) {
      const res = await session.suggest(searchAddress, {
        proximity: searchArea,
      });
      setSuggestions(res.suggestions);
    }
  }, 1000);

  const onChange = (value: string) => {
    setSearchAddress(value);
    runDebouncedSearch();
  };

  const handleListItemClick = async (suggestion: any) => {
    const result = await session.retrieve(suggestion);

    const location = result.features[0].properties;
    onSelect({
      address: location.address,
      city: location.context.place?.name,
      state: location.context.region?.region_code,
      zipcode: location.context.postcode?.name,
      latitude: location.coordinates.latitude,
      longitude: location.coordinates.longitude,
    });

    setOpen(false);
  };

  return (
    <>
      <div className={cn('', className)}>
        <span className="rounded-full block hover:cursor-pointer p-2 m-1">
          <MagnifyingGlassIcon
            className="h-4 w-4 text-gray-500 hover:text-gray-800"
            onClick={() => setOpen(true)}
          />
        </span>
      </div>
      <Dialog open={open} onOpenChange={handleOpenChange}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              <span className="flex justify-between pb-3">Address Lookup</span>
            </DialogTitle>
          </DialogHeader>
          <FormItem className="relative">
            <FormLabel className={cn(formFieldVariants({ variant: 'inset' }))}>
              Enter Address
            </FormLabel>

            <div
              className={cn(
                'block w-full rounded-sm border-0 text-gray-900 shadow-sm placeholder:text-gray-400',
              )}
            >
              <Input
                className="w-full"
                defaultValue={searchAddress}
                onChange={(event) => onChange(event.target.value)}
              />
              <div className="absolute right-0 top-0">
                <span className="rounded-full block  hover:cursor-pointer p-2 m-1">
                  <ArrowPathIcon
                    className="h-4 w-4 text-gray-500 hover:text-gray-800"
                    onClick={runDebouncedSearch}
                  />
                </span>
              </div>
            </div>
          </FormItem>
          <div className="overflow-y-auto max-h-96">
            <List>
              {suggestions.map((suggestion) => (
                <List.Item
                  key={suggestion.mapbox_id}
                  className="hover:cursor-pointer py-2 px-4 text-xs font-normal flex-col"
                  onClick={() => handleListItemClick(suggestion)}
                >
                  <span className="font-medium">{suggestion.name}</span>
                  <span className="font-normal">
                    {suggestion.context.place.name}, {suggestion.context.region.region_code}{' '}
                    {suggestion.context.postcode.name}
                  </span>
                </List.Item>
              ))}
            </List>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
