import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_VAPID_KEY,
} from '@/config/globals';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported, MessagePayload, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
};

export const firebase = initializeApp(firebaseConfig);

export const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebase);
    }
    console.log('Firebase not supported this browser');
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

export const onMessageListener = async () =>
  new Promise<MessagePayload | null>((resolve) =>
    (async () => {
      const messagingResolve = await messaging;
      if (!messagingResolve) {
        resolve(null);
        return;
      }

      onMessage(messagingResolve, (payload) => {
        // console.log('On message: ', messaging, payload);
        resolve(payload);
      });
    })(),
  );

export const requestForToken = async () => {
  try {
    const messagingResolve = await messaging;
    if (!messagingResolve) throw new Error('Firebase not supported this browser');
    const currentToken = await getToken(messagingResolve, {
      vapidKey: FIREBASE_VAPID_KEY,
    });
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      // Perform any other neccessary action with the token
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};
