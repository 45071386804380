import { ModelID, SearchParams, Vip } from '@/types';

export type Filter = 'all' | 'active' | 'inactive';
export type Sort = 'created_at' | 'updated_at' | 'vip_id';
export interface VipSearchParams extends SearchParams<Filter, Sort>, Partial<Vip> {
  customer_id?: ModelID;
}

export const featurePath = '/v4/customers/vip';

export const featureKeys = {
  all: [{ scope: 'vips' }] as const,

  lists: (location: ModelID) => [{ ...featureKeys.all[0], location, entity: 'list' }] as const,

  list: (location: ModelID, search: VipSearchParams = {}) =>
    [{ ...featureKeys.lists(location)[0], search }] as const,

  details: () => [{ ...featureKeys.all[0], entity: 'detail' }] as const,

  detail: (id: ModelID) => [{ ...featureKeys.details()[0], id }] as const,
};
