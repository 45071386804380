import {
  CreateDTO,
  useCreateMutationFactory,
  UseCreateMutationProps,
} from '@/lib/react-query/create';
import { CalendarNote } from '@/types';
import { create } from '@/utils/api';
import { featureKeys, featurePath, NoteSearchParams } from '.';

export type CreateNoteDTO = CreateDTO<CalendarNote, NoteSearchParams>;

const createNote = ({ data, params = {} }: CreateNoteDTO) =>
  create<CalendarNote>(`${featurePath}`, data, params);

export const useCreateNote = (options?: UseCreateMutationProps<CalendarNote>) => {
  return useCreateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: createNote,
    ...options,
  });
};
