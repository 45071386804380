import { useCreatePaymentMethod } from '@/api/customers/payment-methods/createPaymentMethod';
import { Button } from '@/components/ui/elements/button';
import { Customer, PaymentMethod } from '@/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import clsx from 'clsx';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormInput } from '../Form';
import { FormStateSelect } from '../Form/FormStateSelect';
import { Form } from '../ui/form/form';

interface NewPaymentMethodProps {
  customer: Customer;
  defaultOpen: boolean;
}

const copyableFields = [
  'address',
  'address_line2',
  'city',
  'state',
  'zipcode',
  'phone',
  'email',
] as const;

const schema = z.object({
  name: z.string().min(1, 'Required'),
  phone: z.string().nullish(),
  email: z.string().nullish(),
  address: z.string(),
  address_line2: z.string().nullish(),
  city: z.string(),
  state: z.string(),
  zipcode: z.string().min(1, 'Required'),
});

export const NewPaymentMethod = ({ customer }: NewPaymentMethodProps) => {
  const [error, setError] = useState<string>();
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const { mutate: createPaymentMethod, isPending } = useCreatePaymentMethod({
    onError: (error: any) => {
      setError(error?.response?.data?.message);
      setIsProcessing(false);
    },
    onSuccess: () => {
      setIsProcessing(false);
      form.reset();
      elements?.getElement('card')?.clear();
    },
  });

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      address: '',
      address_line2: '',
      city: '',
      state: '',
      zipcode: '',
    },
  });

  const handleCreate = async (data: Partial<PaymentMethod>) => {
    if (!stripe || !elements || isPending || isProcessing) {
      return;
    }
    setIsProcessing(true);

    const cardElement = elements.getElement('card');
    if (!cardElement) {
      return;
    }

    const tokenResult = await stripe.createToken(cardElement, {
      name: data.name,
      address_line1: data.address ?? '',
      address_line2: data.address_line2 ?? '',
      address_city: data.city ?? '',
      address_state: data.state ?? '',
      address_zip: data.zipcode ?? '',
    });

    if (tokenResult.error || !tokenResult.token || !tokenResult.token.card) {
      setError(tokenResult?.error?.message ?? 'Unable to save card.');
      setIsProcessing(false);
      return;
    }

    createPaymentMethod({
      data: {
        ...data,
        customer_id: customer.id,
        token: tokenResult.token.id,
        exp_month: tokenResult.token.card.exp_month,
        exp_year: tokenResult.token.card.exp_year,
        brand: tokenResult.token.card.brand,
        funding: tokenResult.token.card.funding,
        last4: tokenResult.token.card.last4,
      },
    });
  };

  const copyCustomer = () => {
    form.setValue('name', customer.full_name ?? '');

    const cust = customer as unknown as { [k: string]: string };
    copyableFields.map((key: (typeof copyableFields)[number]) =>
      form.setValue(key, cust[key] ?? ''),
    );
  };

  const copyBilling = () => {
    form.setValue('name', customer.billing_name ?? '');

    const cust = customer as unknown as { [k: string]: string };
    copyableFields.map((key: (typeof copyableFields)[number]) =>
      form.setValue(key, cust[`billing_${key}`] ?? ''),
    );
  };

  if (!stripe || !elements) {
    // Stripe.js has not yet loaded.
    return <>Loading...</>;
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleCreate)}>
        <div className="flex flex-col space-y-3">
          <div>
            <div
              className={clsx(
                'bg-white border border-gray-300 shadow-sm py-3 px-2 rounded-sm',
                error ? 'border-red-500' : '',
              )}
            >
              <CardElement
                options={{
                  disableLink: true,
                }}
              />
            </div>
            {!!error && <div className="text-xs pt-1 pl-1 font-semibold text-red-500">{error}</div>}
          </div>

          <FormInput control={form.control} name="name" label="Name on Card" />
          <FormInput control={form.control} name="address" label="Address" />
          <FormInput control={form.control} name="address_line2" label="Address Line 2" />
          <div className="flex flex-row space-x-2">
            <div className="w-3/6">
              <FormInput control={form.control} name="city" label="City" />
            </div>
            <div className="w-2/6">
              <FormStateSelect control={form.control} name="state" label="State" style="abbr" />
            </div>
            <div className="w-2/6">
              <FormInput control={form.control} name="zipcode" label="Zip" />
            </div>
          </div>
          <FormInput control={form.control} name="phone" label="Phone" />
          <FormInput control={form.control} name="email" label="Email" />

          <div className="flex flex-row space-x-2">
            <Button type="button" variant="outline" size="sm" onClick={() => copyCustomer()}>
              Copy Customer
            </Button>
            <Button type="button" variant="outline" size="sm" onClick={() => copyBilling()}>
              Copy Billing
            </Button>
            <span className="grow" />
            <Button type="submit" disabled={isPending}>
              Save
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};
