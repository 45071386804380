import { toast } from '@/components/ui/elements/use-toast';
import { API_URL } from '@/config';
import Axios from 'axios';

export const axios = Axios.create({
  baseURL: API_URL,
});

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

const isJsonBlob = (data: unknown) => data instanceof Blob && data.type === 'application/json';

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    const responseData = isJsonBlob(error.response?.data)
      ? await error.response?.data?.text()
      : error.response?.data || {};
    const responseJson = typeof responseData === 'string' ? JSON.parse(responseData) : responseData;
    const message = responseJson?.message || error.message;

    if (error.response?.status === 401) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'There was an error with your credentials. Please try again.',
      });
    } else if (error.response?.status === 403) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'You are not authorized.',
      });
    } else {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: message,
      });
    }

    return Promise.reject(error);
  },
);
