import { KpiIndicator } from '@/features/reports/components/Graphs';
import { useSummaryReport } from '../hooks/useSummaryReport';

export const DashboardKpis = () => {
  const { data } = useSummaryReport();

  if (!data) return <></>;

  return (
    <div>
      <div className="text-gray-600 text-sm py-2 text-center">
        Trailing 30 days performance vs previous year
      </div>
      <div className="grid grid-cols-1 @lg:grid-cols-2 @4xl:grid-cols-4 gap-2 divide-x">
        <KpiIndicator
          name="NPS"
          format="percent"
          base={(data.nps?.base?.avg ?? 0) / 100 || 0}
          comparison={(data.nps?.comparison?.avg ?? 0) / 100}
        />
        <KpiIndicator
          name="Leads"
          // format="percent"
          base={data.leads?.base?.count}
          comparison={data.leads?.comparison?.count}
        />
        <KpiIndicator
          name="Sales"
          format="currency"
          base={data.sales?.base?.sum}
          comparison={data.sales?.comparison?.sum}
        />
        <KpiIndicator
          name="Revenue"
          format="currency"
          base={data.revenue?.base?.sum}
          comparison={data.revenue?.comparison?.sum}
        />
      </div>
    </div>
  );
};
