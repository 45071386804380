import { useFileDownload } from '@/hooks/useFileDownload';
import { Asset, ModelID } from '@/types';
import { postRaw } from '@/utils/api';
import { useMutation } from '@tanstack/react-query';
import { featurePath } from '.';

type DownloadEventPackDTO = {
  location: ModelID;
  asset: Asset;
  start: string;
  end: string;
};

const downloadEventPack = ({ location, asset, start, end }: DownloadEventPackDTO) =>
  postRaw<Blob>(`${featurePath}/pack`, {
    franchise_id: location,
    asset_id: asset.id,
    start,
    end,
  });

export const useDownloadEventPack = () => {
  const { download } = useFileDownload();

  return useMutation({
    mutationFn: downloadEventPack,
    onSuccess: (data, variables) => {
      const blob = new Blob([data], { type: 'text/pdf' });
      download(
        blob,
        `event-pack-${variables.asset.abbr}-${variables.start}-${variables.end}.pdf`,
        'download',
      );
    },
  });
};
