import { Button } from '@/components/ui/elements/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@/components/ui/elements/dropdown-menu';
import { Lead } from '@/types';
import { isMobile } from 'react-device-detect';
import { useLogLead } from '../hooks/useLogLead';

interface LogContactContextMenuProps {
  lead: Lead;
  onSuccess?: () => void;
}

export const LogContactContextMenu = ({ lead, onSuccess }: LogContactContextMenuProps) => {
  const { logLead } = useLogLead();

  const LogCallActions = [
    {
      label: 'Log Call - Spoke to Customer',
      onClick: () => logLead(lead.id, 'call', 'Spoke to Customer', onSuccess),
    },
    {
      label: 'Log Call - No Answer',
      onClick: () => logLead(lead.id, 'call', 'No Answer', onSuccess),
    },
    {
      label: 'Log Call - Left Voicemail',
      onClick: () => logLead(lead.id, 'call', 'Left Voicemail', onSuccess),
    },
  ];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size={isMobile ? 'xs' : 'sm'}>
          Log
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        <DropdownMenuGroup>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <span>Phone Call</span>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                {LogCallActions.map((action, i) => {
                  return (
                    <DropdownMenuItem
                      key={`ddm-archive-${i}`}
                      onClick={action.onClick}
                      className="cursor-pointer"
                    >
                      {action.label}
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="cursor-pointer"
          onClick={() => logLead(lead.id, 'txt', '', onSuccess)}
        >
          Log TXT
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="cursor-pointer"
          onClick={() => logLead(lead.id, 'email', '', onSuccess)}
        >
          Log Email
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
