import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { MessageTemplate, ModelID } from '@/types';
import { get } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { featureKeys, featurePath, MessageTemplateSearchParams } from '.';

type QueryFnType = typeof fetchMessageTemplates;

type UseMessageTemplatesOptions = {
  location: ModelID;
  search?: MessageTemplateSearchParams;
  config?: QueryConfig<QueryFnType>;
};

export const fetchMessageTemplates = (
  location: ModelID,
  search: MessageTemplateSearchParams = {},
) => get<MessageTemplate[]>(`${featurePath}?franchise_id=${location}`, search);

export const useMessageTemplates = ({
  location,
  search = {},
  config = {},
}: UseMessageTemplatesOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: featureKeys.list(location, search),
    queryFn: () => fetchMessageTemplates(location, search),
  });
};
