import { ModelID } from '@/types';
import {
  DefaultOptions,
  QueryClient,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

const DEFAULT_CACHE_EXPIRY = 1000 * 15; // 15 seconds

const queryConfig: DefaultOptions = {
  queries: {
    throwOnError: true,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: DEFAULT_CACHE_EXPIRY,
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export type FeatureKeys<T> = {
  all: readonly [{ scope: string }];
  lists?: (location: ModelID) => readonly [{ scope: string; location: ModelID; entity: 'list' }];
  list?: (
    location: ModelID,
    search?: T,
  ) => readonly [{ scope: string; location: ModelID; entity: 'list'; search: T }];
  paged?: (
    location: ModelID,
    search?: T,
  ) => [{ scope: string; location: ModelID; entity: 'paged'; search: T }];
  details?: () => readonly [{ scope: string; entity: 'detail' }];
  detail?: (
    id: ModelID,
    search?: T,
  ) => readonly [
    { scope: string; id: ModelID; search: T },
    { scope: string; entity: 'detail'; id: ModelID },
  ];
};

export type ExtractFnReturnType<FnType extends (...args: any) => any> = Awaited<ReturnType<FnType>>;

export type QueryConfig<QueryFnType extends (...args: any) => any> = Omit<
  UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
  'queryKey' | 'queryFn'
>;

export type MutationConfig<MutationFnType extends (...args: any) => any> = UseMutationOptions<
  ExtractFnReturnType<MutationFnType>,
  AxiosError,
  Parameters<MutationFnType>[0]
>;

export default queryClient;
