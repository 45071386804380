import { AssociativeReportNode, ReportValues } from './types';

export const defaultReportValues = {
  avg: 0,
  count: 0,
  max: 0,
  min: 0,
  sum: 0,
} as ReportValues;

export function valueCoalesce(number: number | undefined | null, currency = true): number {
  const _number = number || 0;

  return currency ? (_number || 0) / 100 : _number || 0;
}

export function getOrderedUniqueKeys(data: AssociativeReportNode | null) {
  const keys = new Map<string, number>(); // Add type annotation for keys
  const uniqueKeys = [
    ...new Set([...Object.keys(data?.base ?? {}), ...Object.keys(data?.comparison ?? {})]),
  ];

  uniqueKeys.forEach((key) => keys.set(key, data?.base?.[key]?.count ?? 0));

  return [...keys.entries()].sort((a, b) => b[1] - a[1]).map((key) => key[0]);
}
