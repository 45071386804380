import { Button } from '@/components/ui/elements/button';
import { PolicyGate } from '@/features/auth/authorization';
import { InsufficientPermissions } from '@/features/auth/components/InsufficientPermissions';
import { EventContext } from '@/features/events/contexts/EventContext';
import { useEventInvoice } from '@/hooks/useEventInvoice';
import { Event } from '@/types';
import { useContext } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

interface InvoiceTabProps {
  event: Event;
}

export const InvoiceTab = () => {
  const { event } = useContext(EventContext);

  if (!event) return <>Loading...</>;

  return <Invoice event={event} />;
};

const Invoice = ({ event }: InvoiceTabProps) => {
  const { pdfData, isFetching, downloadFile } = useEventInvoice({ event });

  return (
    <PolicyGate policy="events.view" forbiddenFallback={<InsufficientPermissions />}>
      <div className="flex flex-col">
        <div className="flex flex-row justify-center">
          <Button
            className="mx-2 my-3"
            size="xl"
            disabled={isFetching}
            onClick={() => downloadFile('open')}
          >
            Open PDF Invoice
          </Button>
          <Button
            className="mx-2 my-3"
            size="xl"
            disabled={isFetching}
            onClick={() => downloadFile('download')}
          >
            Download PDF Invoice
          </Button>
        </div>
        {pdfData && (
          <Document file={`data:application/pdf;base64,${pdfData}`}>
            <Page pageNumber={1} />
          </Document>
        )}
      </div>
    </PolicyGate>
  );
};
