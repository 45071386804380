import queryClient from '@/lib/react-query';
import { LocationContext } from '@/providers/location';
import useNotificationStore from '@/stores/notifications';
import { useNavigate } from '@tanstack/react-router';
import { useCallback, useContext, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

export const useServiceWorkerMessages = () => {
  const navigate = useNavigate();
  const { activeLocationId } = useContext(LocationContext);

  const { addNotification } = useNotificationStore(
    useShallow((state) => ({
      addNotification: state.add,
    })),
  );

  const handleServiceWorkerMessages = useCallback((event: MessageEvent) => {
    if (!event.data.action) {
      return;
    }

    switch (event.data.action) {
      case 'redirect-from-notification-click':
        navigate({ to: event.data.url || '/' });
        break;
      case 'push-background-message-received':
        console.log('Push background message received.', event.data);

        addNotification({
          title: event.data.title,
          message: event.data.message,
          link: event.data.url,
        });

        queryClient.invalidateQueries({ queryKey: [{ scope: 'heartbeat' }] });
        queryClient.invalidateQueries({
          queryKey: [
            {
              entity: 'list',
              location: activeLocationId,
              scope: 'leads',
              search: { filter: 'inbox' },
            },
          ],
          exact: false,
        });
        break;
    }
  }, []);

  useEffect(() => {
    if (navigator.serviceWorker) {
      // Listen to service worker messages sent via postMessage()
      navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessages);
    }

    return () => {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessages);
      }
    };
  }, []);
};
