import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ModelID, Pin } from '@/types';
import { get } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { featureKeys, featurePath, PinSearchParams } from '.';

type QueryFnType = typeof fetchPins;

type UsePinsOptions = {
  location: ModelID;
  search?: PinSearchParams;
  config?: QueryConfig<QueryFnType>;
};

export const fetchPins = (location: ModelID, search: PinSearchParams = {}) =>
  get<Pin[]>(`${featurePath}?franchise_id=${location}`, search);

export const usePins = ({ location, search = {}, config = {} }: UsePinsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: featureKeys.list(location, search),
    queryFn: () => fetchPins(location, search),
    staleTime: Infinity,
  });
};
