import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ModelID } from '@/types';
import { get } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { FeatureCollection } from 'geojson';
import { featurePath, LocationSearchParams } from '.';

type QueryFnType = typeof getTravelCharges;

type UseGetTravelChargesOptions = {
  id: ModelID;
  search?: LocationSearchParams;
  config?: QueryConfig<QueryFnType>;
};

export const getTravelCharges = (id: ModelID, search: any = {}) =>
  get<FeatureCollection>(`${featurePath}/${id}/travel-charge/geojson`, { ...search });

export const useLocationTravelCharges = ({
  id,
  search = {},
  config = {},
}: UseGetTravelChargesOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [{ scope: 'locations', entity: 'travelcharges', id, search }],
    queryFn: () => getTravelCharges(id, search),
  });
};
