import { MAPBOX_KEY } from '@/config';
import { SearchBoxCore } from '@mapbox/search-js-core';
import { useSearchBoxCore, useSearchSession } from '@mapbox/search-js-react';
import { SearchSessionType } from '@mapbox/search-js-react/dist/hooks/useSearchSession';
import { createContext, ReactNode } from 'react';

type MapBoxContextData = {
  search: SearchBoxCore;
  session: SearchSessionType;
  accessToken: string;
};

type MapBoxProviderProps = {
  children: ReactNode;
};

export const MapBoxContext = createContext({} as MapBoxContextData);

export function MapBoxProvider({ children }: MapBoxProviderProps) {
  const search = useSearchBoxCore({
    accessToken: MAPBOX_KEY,
    country: 'us',
    types: 'address',
  });
  const session = useSearchSession(search);

  return (
    <MapBoxContext.Provider
      value={{
        session,
        search,
        accessToken: search.accessToken,
      }}
    >
      {children}
    </MapBoxContext.Provider>
  );
}
