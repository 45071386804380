import _ from 'lodash';
import { ACL } from '.';
import { NewbieACL } from './newbie';
export const ACL_ROLE_BOOKING_AGENT = 'Booking Agent';

export const BookingAgentACL: ACL = _.merge({}, NewbieACL, {
  role: ACL_ROLE_BOOKING_AGENT,
  dashboard: {
    view: true,
    news: true,
    motd: true,
  },
  calendar: {
    view: true,
    notes: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
  },
  map: {
    view: true,
  },
  leads: {
    view: true,
    create: true,
    edit: true,
    history: true,
    export: true,
    transfer: true,
  },
  events: {
    view: true,
    create: true,
    edit: true,
    confirm: true,
    cancel: true,
    history: true,
    export: true,
    transfer: true,
  },
  equipment: {
    blackouts: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
  },
  customers: {
    view: true,
    edit: true,
    history: true,
    lists: {
      view: true,
      create: true,
      edit: true,
      delete: true,
      export: true,
    },
    rewards: {
      view: true,
      approve: false,
    },
  },
  messages: {
    view: true,
    send: true,
  },
  payments: {
    view: true,
    create: true,
    edit: true,
    refund: true,
  },
  payment_methods: {
    view: true,
    create: true,
    edit: true,
    delete: true,
  },
  help: {
    view: true,
  },
  ordering: {
    view: false,
  },
  resources: {
    view: true,
    directory: {
      view: true,
      internal: {
        view: true,
      },
    },
    store: {
      view: true,
    },
  },
});
