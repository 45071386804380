import { useValidatePhone } from '@/api/system/validation/validatePhone';
import { Input } from '@/components/ui/elements/input';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  useFormAutosave,
} from '@/components/ui/form/form';
import { LineTypeIntelligenceType } from '@/types';
import { cn } from '@/utils/format';
import { VariantProps } from 'cva';
import { ClassProp } from 'cva/dist/types';
import { useState } from 'react';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { formFieldVariants } from '../Form/FormInput';

type FormInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  VariantProps<typeof formFieldVariants> &
  ClassProp & {
    phoneType?: LineTypeIntelligenceType | null;
    phoneValid?: boolean | null;
    label?: string;
    description?: string;
    placeholder?: string;
    prefix?: string;
    suffix?: string;
    step?: string | number;
    autoComplete?: string;
    onFieldFocus?: (value?: string) => void;
    onFieldBlur?: (value: string) => void;
  };

export const FormPhoneInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  placeholder,
  phoneType,
  phoneValid,
  label,
  description,
  variant,
  className,
  prefix,
  suffix,
  step,
  autoComplete = 'false',
  onFieldFocus,
  onFieldBlur,
  ...props
}: FormInputProps<TFieldValues, TName>) => {
  const autosave = useFormAutosave();
  const { mutate: validator } = useValidatePhone({});
  const [isValid, setIsValid] = useState<boolean>(phoneValid ?? true);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const validatePhone = (value: string) => {
    if (!value) return true;

    const basicTest = value.length >= 10;
    if (!basicTest) {
      setValidationErrors(['TOO_SHORT']);
      return false;
    }

    validator(
      { phone: value },
      {
        onSuccess: (data) => {
          setIsValid(data.valid);
          setValidationErrors(data.validationErrors ?? []);
        },
      },
    );
  };

  return (
    <FormField
      control={control}
      name={name}
      render={({ field: { onBlur, ...field } }) => (
        <FormItem className="relative">
          {label && <FormLabel className={cn(formFieldVariants({ variant }))}>{label}</FormLabel>}
          <FormControl>
            <div>
              {prefix && (
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">{prefix}</span>
                </div>
              )}
              <Input
                id={name}
                placeholder={placeholder}
                type="tel"
                step={step}
                autoComplete={autoComplete}
                aria-invalid={control?._formState.errors[name] ? 'true' : 'false'}
                className={cn(className, prefix ? 'pl-7' : '', suffix ? 'pr-12' : '')}
                onFocus={(event) => {
                  if (onFieldFocus) onFieldFocus(event.target.value);
                  if (autosave.enabled) autosave.setPaused(true);
                }}
                onBlur={(event) => {
                  if (onFieldBlur) onFieldBlur(event.target.value);
                  if (autosave.enabled) autosave.setPaused(false);
                  onBlur();
                  validatePhone(event.target.value);
                }}
                {...field}
              />
              {suffix && (
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <span className="text-gray-500 sm:text-sm" id="price-currency">
                    {suffix}
                  </span>
                </div>
              )}
            </div>
          </FormControl>
          <div className="mt-1 flex flex-col space-y-1">
            {validationErrors.map((error, i) => (
              <div
                key={`email-validation-error-${i}`}
                className={cn(
                  'items-center px-1.5 py-0.5 rounded-sm text-xs truncate bg-red-100 text-red-900',
                )}
              >
                {error}
              </div>
            ))}
          </div>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
      {...props}
    />
  );
};
