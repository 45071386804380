import { cn } from '@/utils/format';
import { Link } from '@tanstack/react-router';
import { MouseEventHandler } from 'react';

type ListProps = {
  children: React.ReactNode;
  className?: string;
};

type ListItemProps = {
  children: React.ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLElement>;
};

type ListButtonProps = ListItemProps & {
  onClick: MouseEventHandler<HTMLAnchorElement>;
};

type ListLinkProps = ListItemProps & {
  to: string;
  search?: any;
  params?: any;
};

const ListItem = ({ children, className, onClick, ...restProps }: ListItemProps) => {
  return (
    <li
      className={cn('flex px-6 py-4 text-sm font-medium text-gray-900 hover:bg-gray-50', className)}
      onClick={onClick}
      {...restProps}
    >
      {children}
    </li>
  );
};

const ListItemButton = ({ children, onClick, className, ...restProps }: ListButtonProps) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(
        'flex px-6 py-4 text-sm font-medium text-gray-900 hover:bg-gray-50 cursor-pointer',
        className,
      )}
      {...restProps}
    >
      {children}
    </button>
  );
};

const ListItemLink = ({
  children,
  to,
  search = {},
  params = {},
  className,
  ...restProps
}: ListLinkProps) => {
  return (
    <Link
      search={search}
      params={params}
      to={to}
      className={cn(
        'flex px-6 py-4 text-sm font-medium text-gray-900 hover:bg-gray-50 cursor-pointer',
        className,
      )}
      {...restProps}
    >
      {children}
    </Link>
  );
};

const List = ({ children, className = '' }: ListProps) => {
  return (
    <ul role="list" className={cn('divide-y divide-gray-200', className)}>
      {children}
    </ul>
  );
};

List.Item = ListItem;
List.ItemButton = ListItemButton;
List.ItemLink = ListItemLink;

export default List;
