import { AuthContextData } from '@/providers/auth';
import { LocationContextData } from '@/providers/location';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';

interface AppRouterContext {
  // The ReturnType of your useAuth hook or the value of your AuthContext
  auth: AuthContextData;
  location: LocationContextData;
}

export const Route = createRootRouteWithContext<AppRouterContext>()({
  component: () => <Outlet />,
});
