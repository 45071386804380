import { PastCustomerBadge } from '@/components/Customer/PastCustomerBadge';
import { EmailButton, Link, PhoneButton } from '@/components/Elements';
import List from '@/components/Elements/List/List';
import { Checkbox } from '@/components/ui/elements/checkbox';
import { Event, ModelID } from '@/types';
import { formatDate } from '@/utils/format';
import { formatNumber } from '@/utils/numbers-utils';
import { IdBadge } from './Badges/IdBadge';
import { StatusBadge } from './Badges/StatusBadge';
import { SurveyBadge } from './Badges/SurveyBadge';
import { EventActionDropdownMenu } from './EventActionDropdownMenu';

interface EventResultProps {
  event: Event;
  selected: boolean;
  setSelected: (id: ModelID) => void;
}

export const EventResult = ({ event, selected, setSelected }: EventResultProps) => {
  return (
    <List.Item
      key={event.id}
      className="@container flex items-center justify-between py-5 @md:space-x-2 @2xl:space-x-4"
    >
      <Checkbox
        checked={selected}
        onCheckedChange={() => setSelected(event.id)}
        className="flex-none hidden @md:block"
        aria-label="Select Event"
      />
      <div className="grow">
        <div className="flex flex-col @2xl:flex-row @2xl:space-x-2 items-start pb-3">
          <p className="font-semibold text-gray-900">
            <Link
              to={
                event.transfer_pending
                  ? '/events/event/$id/accept-transfer'
                  : '/events/event/$id/overview'
              }
              params={{ id: event.id }}
              variant="link"
              className="text-lg @md:text-base"
            >
              <PastCustomerBadge customer={event.customer} className="mr-1" /> {event.name}
            </Link>
          </p>
          <span className="space-x-2">
            <StatusBadge event={event} />
            <IdBadge event={event} />
            <SurveyBadge event={event} />
          </span>
        </div>
        <div className="flex flex-col @xl:flex-row space-y-2 @xl:space-x-3 justify-start @xl:justify-between">
          <div>
            <div className="flex flex-col @md:flex-row items-start space-y-2 @md:space-x-2 @md:space-y-0 py-1">
              <PhoneButton
                phone={event.customer.phone ?? event.phone}
                preferSms={event.customer.sms_lead_updates}
                isInvalid={event.customer?.phone_valid === 0}
                phoneType={event.customer?.phone_type}
              />
              <EmailButton
                email={event.customer.email}
                isInvalid={event.customer.has_bad_email}
                customerId={event.customer.id}
              />
            </div>
            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              <p className="whitespace-nowrap">
                {formatDate(event.start_at, 'MMM d, yyyy')} at {formatDate(event.start_at, 'h:mma')}{' '}
                - {formatDate(event.end_at, 'h:mma')}
              </p>
            </div>

            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              <p className="whitespace-nowrap">{event.event_type.name}</p>
              <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                <title>Dot</title>
                <circle cx={1} cy={1} r={1} />
              </svg>
              <p className="truncate">Booked by {event.booked_by_name}</p>
            </div>
            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              {(event.activities?.length || 0) > 0 && (
                <p>
                  Staff:{' '}
                  {event?.activities?.map(
                    (activity) =>
                      activity.staff?.map((person) => person.full_name).join(', ') ?? '',
                  ) || 'None assigned'}
                </p>
              )}
            </div>
            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              <p className="whitespace-nowrap">Total: {formatNumber(event.total, 'currency')}</p>
              <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                <title>Dot</title>
                <circle cx={1} cy={1} r={1} />
              </svg>
              <p className="truncate">Due: {formatNumber(event.balance_due, 'currency')}</p>
              <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                <title>Dot</title>
                <circle cx={1} cy={1} r={1} />
              </svg>
              <p className="truncate">
                Gratuity Paid: {formatNumber(event.gratuity_paid, 'currency')}
              </p>
            </div>
          </div>
          <div>
            <div className="mt-1 text-xs w-full @xl:text-sm @xl:max-w-48 leading-5 text-gray-600">
              {event.activities.map((activity) => activity.name).join(', ')}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <EventActionDropdownMenu event={event} />
      </div>
    </List.Item>
  );
};
