import { Customer, DateMode, Event, Lead } from '@/types';
import {
  endOfMonth,
  endOfYear,
  getLocalTimeZone,
  startOfMonth,
  startOfWeek,
  startOfYear,
  today,
} from '@internationalized/date';
import { DateRange } from 'react-aria-components';
import { apiDateToCalendarDate, todayAsCalendarDate } from './format';

export const calculateActivityDateBounds = (quotable: Lead | Event | undefined) => {
  // Determine the date we will use to pull in events for the day
  // which will then be displayed on the map view
  const activityDates = quotable?.activities
    ? quotable.activities.map((activity) => {
        return apiDateToCalendarDate(activity.start_at);
      })
    : [];
  let searchDateBegin = todayAsCalendarDate();
  let searchDateEnd = todayAsCalendarDate();
  if (quotable) {
    const sortedByStart = [...quotable.activities].sort(
      (a, b) =>
        Math.floor(new Date(a.start_at.replace(' ', 'T')).getTime() / 1000) -
        Math.floor(new Date(b.start_at.replace(' ', 'T')).getTime() / 1000),
    );
    if (sortedByStart.length > 0) {
      searchDateBegin = apiDateToCalendarDate(sortedByStart[0].start_at);
    }

    const sortedByEnd = [...quotable.activities].sort(
      (a, b) =>
        Math.floor(new Date(b.end_at.replace(' ', 'T')).getTime() / 1000) -
        Math.floor(new Date(a.end_at.replace(' ', 'T')).getTime() / 1000),
    );
    if (sortedByEnd.length > 0) {
      searchDateEnd = apiDateToCalendarDate(
        sortedByEnd[0].end_at ?? sortedByStart[0].start_at ?? '',
      );
    } else {
      searchDateEnd = searchDateBegin.copy().add({ days: 1 });
    }
  }

  return {
    activityDates,
    searchDateBegin,
    searchDateEnd,
  };
};

export const convertDateModeToRange = (mode: DateMode): DateRange => {
  const now = today(getLocalTimeZone());
  const lastMonth = startOfMonth(now.subtract({ months: 1 }));
  const lastYear = startOfYear(now.subtract({ years: 1 }));

  switch (mode) {
    case '7day':
      return {
        start: now.subtract({ days: 7 }),
        end: now.subtract({ days: 1 }),
      };
    case 'wtd':
      return {
        start: startOfWeek(now, 'fr-FR'),
        end: now,
      };
    case 'mtd':
      return {
        start: startOfMonth(now),
        end: now,
      };
    case 'ytd':
      return {
        start: startOfYear(now),
        end: now,
      };
    case 'last-month':
      return {
        start: lastMonth,
        end: endOfMonth(lastMonth),
      };
    case 'last-year':
      return {
        start: lastYear,
        end: endOfYear(lastYear),
      };
    default:
      return {
        start: now.subtract({ days: 31 }),
        end: now.subtract({ days: 1 }),
      };
  }
};

export const openGoogleMap = (mappable: Lead | Event | Customer) => {
  let linkAddress = '';
  linkAddress += `${mappable.address}+`;
  linkAddress += `${mappable.city}+${mappable.state}+${mappable.zipcode}`;
  linkAddress = encodeURI(linkAddress.replace(/\s/g, '+'));

  window.open(`http://maps.google.com/maps/search/?api=1&query=${linkAddress}`, '_blank');
};

export const uniqueVaues = (value: string[] | null | undefined) => {
  return [...new Set(value)];
};
