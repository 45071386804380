import _ from 'lodash';
import { ACL } from '.';
import { NewbieACL } from './newbie';
export const ACL_ROLE_GTL_STAFF = 'GT Licensing Staff';

export const GTLicensingACL: ACL = _.merge({}, NewbieACL, {
  role: ACL_ROLE_GTL_STAFF,
  is_admin: true,
  dashboard: {
    view: true,
    news: true,
    motd: true,
  },
  calendar: {
    view: true,
    notes: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
  },
  scheduler: {
    view: true,
    edit: true,
  },
  map: {
    view: true,
  },
  leads: {
    view: true,
    create: true,
    edit: true,
    history: true,
    export: true,
    transfer: true,
  },
  events: {
    view: true,
    create: true,
    edit: true,
    cancel: true,
    confirm: true,
    history: true,
    export: true,
    transfer: true,
  },
  customers: {
    view: true,
    edit: true,
    history: true,
    lists: {
      view: true,
      create: true,
      edit: true,
      delete: true,
      export: true,
    },
    rewards: {
      view: true,
      approve: false,
    },
  },
  messages: {
    view: true,
    send: true,
  },
  payments: {
    view: true,
    create: true,
    edit: true,
    refund: false,
  },
  payment_methods: {
    view: true,
    create: true,
    edit: true,
    delete: true,
  },
  products: {
    view: true,
    services: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
    addons: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
    packages: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
    coupons: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
  },
  equipment: {
    view: true,
    assets: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
    depots: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
    vehicles: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
    inspections: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
    maintenance: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
    gamescans: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
    blackouts: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
  },
  settings: {
    view: true,
    edit: true,
  },
  franchisor: {
    view: true,
  },
  reports: {
    view: true,
    beta: true,
  },
  users: {
    view: true,
    create: true,
    edit: true,
    delete: true,
    grant: true,
  },
  help: {
    view: true,
  },
  ordering: {
    view: false,
  },
  resources: {
    view: true,
    directory: {
      view: true,
      internal: {
        view: true,
      },
    },
    store: {
      view: true,
    },
  },
});
