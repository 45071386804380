import { UpdateDTO, useUpdateMutationFactory, UseUpdateMutationProps } from '@/lib/react-query/update';
import { Quote } from '@/types';
import { update } from '@/utils/api';
import { QuoteSearchParams, featureKeys, featurePath } from '.';

export type UpdateQuoteDTO = UpdateDTO<Quote, QuoteSearchParams>;

const updateQuote = ({ data, id, params = {} }: UpdateQuoteDTO) =>
  update<Quote>(`${featurePath}/${id}`, data, params);

export const useUpdateQuote = (options?: UseUpdateMutationProps<Quote>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: updateQuote,
    ...options,
  });
};
