import { Motd, SearchParams } from '@/types';

export type Filter = 'all' | 'active' | 'inactive';
export type Sort = 'created_at' | 'updated_at' | 'motd_id';
export interface MotdSearchParams extends SearchParams<Filter, Sort>, Partial<Motd> {}

export const featurePath = '/v4/support/motd';

export const featureKeys = {
  all: [{ scope: 'motds' }] as const,
  latest: [{ scope: 'motds', entity: 'latest' }] as const,
};
