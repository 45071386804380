import { TrayDirection, TrayMode, usePanelStore } from '@/stores/panels';
import { createContext, ReactNode, useContext, useRef } from 'react';
import { ImperativePanelHandle } from 'react-resizable-panels';
import { useShallow } from 'zustand/react/shallow';

type OverlayControlContextData = {
  panelRef: React.RefObject<ImperativePanelHandle>;
  trayVisible: boolean;
  trayMode: TrayMode;
  trayDirection: TrayDirection;
  size: number;
  handleOnTrayHandleDrag: (isDragging: any) => void;
  togglePanels: () => void;
  setTrayVisible: (visible: boolean) => void;
  setTrayMode: (mode: TrayMode) => void;
  setTrayDirection: (direction: TrayDirection) => void;
  toggleDirection: () => void;
  setSize: (size: number) => void;
};

type OverlayControlProviderProps = {
  children: ReactNode;
};

export const OverlayControlContext = createContext({} as OverlayControlContextData);

export const OverlayControlProvider = ({ children }: OverlayControlProviderProps) => {
  const {
    size,
    trayVisible,
    trayDirection,
    trayMode,
    setSize,
    setTrayVisible,
    setTrayDirection,
    setTrayMode,
    toggleDirection,
  } = usePanelStore(
    useShallow((state) => ({
      size: state.size,
      trayVisible: state.trayVisible,
      trayDirection: state.trayDirection,
      trayMode: state.trayMode,
      setSize: state.setSize,
      setTrayVisible: state.setTrayVisible,
      setTrayDirection: state.setTrayDirection,
      setTrayMode: state.setTrayMode,
      toggleDirection: state.toggleDirection,
    })),
  );

  const panelRef = useRef<ImperativePanelHandle>(null);

  const togglePanels = () => {
    const panel = panelRef.current;
    if (!panel) return;

    if (panel.getCollapsed() === false) {
      setTrayVisible(!trayVisible);
      panel.collapse();
    } else {
      setTrayVisible(true);
      panel.expand();
    }
  };

  const handleOnTrayHandleDrag = (isDragging: any) => {
    const panel = panelRef.current;
    if (!panel) return;

    if (!isDragging) setSize(panel.getSize());
  };

  const handleToggleDirection = () => {
    const panel = panelRef.current;
    if (!panel) return;

    panel.resize(25);
    toggleDirection();
  };

  return (
    <OverlayControlContext.Provider
      value={{
        panelRef,
        trayVisible,
        trayMode,
        trayDirection,
        size,
        handleOnTrayHandleDrag,
        togglePanels,
        setTrayVisible,
        setTrayMode,
        setTrayDirection,
        toggleDirection: handleToggleDirection,
        setSize,
      }}
    >
      {children}
    </OverlayControlContext.Provider>
  );
};

export const useOverlayContext = () => {
  return useContext(OverlayControlContext);
};
