import { usePaymentMethods } from '@/api/customers/payment-methods/getPaymentMethods';
import { BookEventParams, useBookEvent } from '@/api/leads/leads/updateLead';
import { FormCheckbox, FormInput, FormSelect } from '@/components/Form';
import { Button } from '@/components/ui/elements/button';
import { Form } from '@/components/ui/form/form';
import { PolicyGate } from '@/features/auth/authorization';
import { InsufficientPermissions } from '@/features/auth/components/InsufficientPermissions';
import { LeadContext } from '@/features/leads/contexts/LeadContext';
import { Lead } from '@/types';
import { cn, formatDate, formatMoney } from '@/utils/format';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from '@tanstack/react-router';
import { cva, VariantProps } from 'cva';
import { formatDistanceToNow, isAfter } from 'date-fns';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

interface ReviewProps {
  lead: Lead;
}

const eventSchema = z.object({
  address: z
    .string({ invalid_type_error: 'The event location address is missing.' })
    .min(5, 'The event location address is incomplete.'),
  city: z
    .string({ invalid_type_error: 'The event location city is missing.' })
    .min(2, 'The event location city is incomplete.'),
  state: z
    .string({ invalid_type_error: 'The event location state is missing.' })
    .min(2, 'The event location state is incomplete.'),
  zipcode: z
    .string({ invalid_type_error: 'The event location zip code is missing.' })
    .min(5, 'The event location zip code is incomplete.'),
  event_type_id: z.coerce.number().min(1, 'The event type is missing.'),
});

const eventWarningSchema = z.object({
  guest_count: z.coerce.number().min(1, 'The guest count is missing.'),
  vips: z.array(z.object({})).min(1, 'No VIP is assigned.'),
});

const customerSchema = z.object({
  phone: z
    .string({ invalid_type_error: 'The customer phone is missing.' })
    .min(2, 'The customer phone is missing.'),
  email: z
    .string({ invalid_type_error: 'The customer email is missing.' })
    .min(2, 'The customer email is missing.'),
});

const businessSchema = z.object({
  organization_name: z
    .string({ invalid_type_error: 'The organization name is missing.' })
    .min(2, 'The organization name is missing.'),
  organization_type: z
    .string({ invalid_type_error: 'The organization type is missing.' })
    .min(2, 'The organization type is missing.'),
});

const customerWarningSchema = z.object({
  full_name: z
    .string({ invalid_type_error: 'The customer name is missing.' })
    .min(2, 'The customer name is missing.'),
  address: z
    .string({ invalid_type_error: 'The customer mailing address is incomplete.' })
    .min(5, 'The customer mailing address is incomplete.'),
  city: z
    .string({ invalid_type_error: 'The customer mailing city is missing.' })
    .min(2, 'The customer mailing city is missing.'),
  zipcode: z
    .string({ invalid_type_error: 'The customer mailing zip code is missing.' })
    .min(5, 'The customer mailing zip code is missing.'),
});

const paymentsSchema = z.array(z.object({})).min(1, 'No payment method available.');

const bookSchema = z.object({
  send_email: z.boolean(),
  sms_optin: z.boolean(),
  amount: z.coerce.number().min(1, 'Charge must be at least $1.'),
  payment_method_id: z.coerce.string().min(1, 'You must select a payment option.'),
});

export const ReviewTab = () => {
  const { lead } = useContext(LeadContext);

  if (!lead) return <>Loading...</>;

  return <Review lead={lead} key={lead.updated_at} />;
};

const Review = ({ lead }: ReviewProps) => {
  const chargeAmountCalculate =
    lead.franchise.deposit_mode === 'fixed'
      ? lead.franchise.deposit_amount
      : (lead.total ?? 0) * (lead.franchise.deposit_amount / 100);

  const navigate = useNavigate();
  const { data: paymentMethods } = usePaymentMethods({
    location: lead.franchise_id,
    search: { customer_id: lead.customer_id, orderBy: 'created_at', orderDir: 'desc' },
    config: {
      enabled: !!lead.customer_id,
    },
  });
  const { mutate: bookEvent, isPending: isBooking } = useBookEvent({
    onSuccess: (result) => {
      navigate({ to: `/events/event/${result.id}/overview` });
    },
  });
  const handleBookEvent = (data: BookEventParams) => {
    bookEvent({ id: lead.id, params: data });
  };
  const eventResult = eventSchema.safeParse(lead);
  const eventErrors = eventResult.success ? ({} as any) : (eventResult.error.format() as any);
  const eventWarnResult = eventWarningSchema.safeParse(lead);
  const eventWarnings = eventWarnResult.success
    ? ({} as any)
    : (eventWarnResult.error.format() as any);
  const paymentsResult = paymentsSchema.safeParse(paymentMethods);
  const paymentsErrors = paymentsResult.success
    ? ({} as any)
    : (paymentsResult.error.format() as any);
  const customerResult =
    lead.event_type_id > 2
      ? customerSchema.and(businessSchema).safeParse(lead.customer ?? {})
      : customerSchema.safeParse(lead.customer ?? {});
  const customerErrors = customerResult.success
    ? ({} as any)
    : (customerResult.error.format() as any);
  const customerWarnResult = customerWarningSchema.safeParse(lead.customer ?? {});
  const customerWarnings = customerWarnResult.success
    ? ({} as any)
    : (customerWarnResult.error.format() as any);

  const handleBookWithDeposit = (values: any) => {
    handleBookEvent({
      charge_deposit: true,
      amount: values.amount,
      payment_method_id: values.payment_method_id,
      send_email: values.send_email,
      send_text: values.sms_optin,
    });
  };

  const handleBookWithoutDeposit = (values: any) => {
    handleBookEvent({
      charge_deposit: false,
      amount: 0,
      send_email: form.getValues('send_email'),
      send_text: form.getValues('sms_optin'),
    });
  };

  const readyToSubmit =
    eventResult.success &&
    customerResult.success &&
    lead.activities.length > 0 &&
    lead.activities.filter((act) => act.asset_id === null).length === 0 &&
    lead.activities.filter((act) => isAfter(new Date(act.start_at.replace(' ', 'T')), new Date()))
      .length === lead.activities.length;

  const readyToPay = readyToSubmit && paymentMethods && paymentMethods.length > 0;

  const paymentMethodOptions =
    paymentMethods?.map((paymentMethod) => ({
      value: paymentMethod.id,
      label: `${paymentMethod.brand} ${paymentMethod.last4} - ${paymentMethod.exp_month}/${
        paymentMethod.exp_year
      } | created ${formatDistanceToNow(new Date(paymentMethod.created_at.replace(' ', 'T')), {
        includeSeconds: true,
        addSuffix: true,
      })}`,
    })) ?? [];

  const form = useForm<z.infer<typeof bookSchema>>({
    resolver: zodResolver(bookSchema),
    defaultValues: {
      send_email: true,
      sms_optin: lead.customer?.sms_event_updates ?? lead.customer?.sms_lead_updates ?? false,
      amount: chargeAmountCalculate,
      payment_method_id:
        paymentMethodOptions.length > 0 ? paymentMethodOptions[0]?.value?.toString() : '',
    },
  });

  const [watchedAmount] = form.watch(['amount']);
  let amountToCharge = chargeAmountCalculate;
  if (watchedAmount && watchedAmount > 0) {
    amountToCharge = parseFloat(watchedAmount?.toString() ?? 0);
  }

  return (
    <PolicyGate policy="events.create" forbiddenFallback={<InsufficientPermissions />}>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="py-2 mr-4 order-2 md:order-1">
          <div className="space-y-1 text-sm">
            <div>
              Event: {lead.name}, ({lead.guest_count} guests)
            </div>
            <div>Type: {lead.event_type?.name ?? 'Missing!'}</div>
            <div>
              Address: {lead.address} {lead.city} {lead.state} {lead.zipcode}
            </div>
            <div>
              Event Contact: {lead.customer?.full_name} {lead.customer?.phone}
            </div>
            <div>Total: {formatMoney(lead.total ?? 0)}</div>
            <div>
              Activities:
              {lead.activities?.map((activity, i) => (
                <div key={`review-activity-${i}`}>
                  {activity.service?.name} on {formatDate(activity.start_at, 'E LLL do, yyyy')}
                  {' at '}
                  {formatDate(activity.start_at, 'h:mma')}
                  {' - '}
                  {formatDate(activity.end_at, 'h:mma')}
                </div>
              ))}
            </div>
            <div>
              VIP:{' '}
              {lead.vips?.map((vip, i) => (
                <span key={`review-vip-${i}`} className="ml-2">
                  {vip.first_name}, age: {vip.age}
                </span>
              ))}
            </div>
          </div>
          <div className="@container">
            <div className="grid grid-cols-1 @md:grid-cols-2 gap-2 pt-2">
              <CheckPhone errors={customerErrors?.phone?._errors} />
              <CheckEmail errors={customerErrors?.email?._errors} />
              {lead.event_type_id > 2 && (
                <CheckOrganization
                  errors={[
                    ...(customerErrors?.organization_name?._errors ?? []),
                    ...(customerErrors?.organization_type?._errors ?? []),
                  ]}
                />
              )}
              <CheckCustomerAddress
                errors={[
                  ...(customerWarnings?.address?._errors ?? []),
                  ...(customerWarnings?.city?._errors ?? []),
                  ...(customerWarnings?.state?._errors ?? []),
                  ...(customerWarnings?.zipcode?._errors ?? []),
                ]}
              />
              <CheckEventAddress
                errors={[
                  ...(eventErrors?.address?._errors ?? []),
                  ...(eventErrors?.city?._errors ?? []),
                  ...(eventErrors?.state?._errors ?? []),
                  ...(eventErrors?.zipcode?._errors ?? []),
                ]}
              />
              <CheckPaymentMethod errors={paymentsErrors?._errors} />
              <CheckEventType errors={eventErrors?.event_type_id?._errors} />
              <CheckActivities lead={lead} />
              <CheckPastDates lead={lead} />
              <CheckGuestCount errors={eventWarnings?.guest_count?._errors} />
              <CheckVIP errors={eventWarnings?.vips?._errors} />
            </div>
          </div>
        </div>
        <div className=" order-1 md:order-2">
          <Form {...form} key={`deposit-form-${lead.updated_at}`}>
            <form onSubmit={form.handleSubmit(handleBookWithDeposit)}>
              <h2>Options</h2>
              <FormCheckbox
                control={form.control}
                name="send_email"
                label="Send Confirmation Email?"
              />

              <FormCheckbox
                control={form.control}
                name="sms_optin"
                label="Send important event updates by text message?"
              />
              <h2>Payment Details</h2>
              <div className="py-4 px-1 flex flex-col space-y-4">
                <FormInput
                  control={form.control}
                  type="number"
                  step={0.01}
                  name="amount"
                  label="Amount to Charge"
                />
                <FormSelect
                  control={form.control}
                  name="payment_method_id"
                  options={paymentMethodOptions}
                  label="Payment Method"
                />
              </div>

              <Button type="submit" disabled={!readyToPay || !form.formState.isValid || isBooking}>
                Book with Deposit - {formatMoney(amountToCharge)}
              </Button>
            </form>
          </Form>

          <div className="border-t mt-4 pt-4">
            <Button disabled={!readyToSubmit || isBooking} onClick={handleBookWithoutDeposit}>
              Book without Deposit - $0
            </Button>
          </div>
        </div>
      </div>
      <div className="mb-96"> </div>
    </PolicyGate>
  );
};

const reviewItemVariants = cva(
  ['flex flex-row rounded-lg py-1 px-2 space-x-2 text-sm items-center'],
  {
    variants: {
      variant: {
        ok: 'bg-green-100 border border-green-200 text-green-800 ',
        warn: 'bg-yellow-100 border border-yellow-200 text-yellow-700 ',
        error: 'bg-red-100 border border-red-200 text-red-800 ',
      },
    },
    defaultVariants: {
      variant: 'ok',
    },
  },
);

type ReviewItemProps = VariantProps<typeof reviewItemVariants> & {
  label: string;
  errors?: string[];
  gotoProblem?: () => void;
};

const ReviewItem = ({ variant, label, errors, gotoProblem }: ReviewItemProps) => {
  let StatusIcon;
  switch (variant) {
    case 'ok':
      StatusIcon = <CheckCircleIcon className="h-6 w-6" />;

      break;
    case 'warn':
      StatusIcon = <ExclamationCircleIcon className="h-6 w-6" />;

      break;
    case 'error':
      StatusIcon = <ExclamationTriangleIcon className="h-6 w-6" />;

      break;
  }

  return (
    <div className={cn(reviewItemVariants({ variant }))}>
      {StatusIcon}
      <span>
        {label}: {errors?.join(', ')}
      </span>
    </div>
  );
};

interface CheckProps {
  errors: string[] | undefined;
}

const CheckPhone = ({ errors }: CheckProps) => {
  let variant = 'error' as ReviewItemProps['variant'];

  if (!errors?.length) {
    variant = 'ok';
    errors = ['OK!'];
  }

  return <ReviewItem variant={variant} label="Phone" errors={errors} />;
};

const CheckEmail = ({ errors }: CheckProps) => {
  let variant = 'error' as ReviewItemProps['variant'];

  if (!errors?.length) {
    variant = 'ok';
    errors = ['OK!'];
  }

  return <ReviewItem variant={variant} label="Email" errors={errors} />;
};

const CheckCustomerAddress = ({ errors }: CheckProps) => {
  let variant = 'warn' as ReviewItemProps['variant'];

  if (!errors?.length) {
    variant = 'ok';
    errors = ['OK!'];
  }

  return <ReviewItem variant={variant} label="Customer Address" errors={errors} />;
};

const CheckOrganization = ({ errors }: CheckProps) => {
  let variant = 'error' as ReviewItemProps['variant'];

  if (!errors?.length) {
    variant = 'ok';
    errors = ['OK!'];
  }

  return <ReviewItem variant={variant} label="Organization" errors={errors} />;
};

const CheckEventAddress = ({ errors }: CheckProps) => {
  let variant = 'error' as ReviewItemProps['variant'];

  if (!errors?.length) {
    variant = 'ok';
    errors = ['OK!'];
  }

  return <ReviewItem variant={variant} label="Event Address" errors={errors} />;
};

const CheckPaymentMethod = ({ errors }: CheckProps) => {
  let variant = 'warn' as ReviewItemProps['variant'];

  if (!errors?.length) {
    variant = 'ok';
    errors = ['OK!'];
  }

  return <ReviewItem variant={variant} label="Payment Method" errors={errors} />;
};

const CheckEventType = ({ errors }: CheckProps) => {
  let variant = 'error' as ReviewItemProps['variant'];

  if (!errors?.length) {
    variant = 'ok';
    errors = ['OK!'];
  }

  return <ReviewItem variant={variant} label="Event Type" errors={errors} />;
};

const CheckActivities = ({ lead }: ReviewProps) => {
  let variant = 'ok' as ReviewItemProps['variant'];
  let errors = ['OK!'];

  if (!lead.activities || lead.activities.length < 1) {
    variant = 'error';
    errors = ['No activity selected'];
  } else {
    const hasIssue = lead.activities.some((activity, i) => {
      if (!activity.asset_id) {
        return true;
      }
    });
    if (hasIssue) {
      variant = 'error';
      errors = ['Equipment assignment is missing!'];
    }
  }

  return <ReviewItem variant={variant} label="Activities" errors={errors} />;
};

const CheckPastDates = ({ lead }: ReviewProps) => {
  let variant = 'ok' as ReviewItemProps['variant'];
  let errors = ['OK!'];

  if (!lead.activities || lead.activities.length < 1) {
    variant = 'error';
    errors = ['No date selected'];
  } else {
    const hasIssue = lead.activities.some((activity, i) => {
      if (isAfter(new Date(), new Date(activity.start_at.replace(' ', 'T')))) {
        return true;
      }
    });
    if (hasIssue) {
      variant = 'error';
      errors = ['Event date is in the past!'];
    }
  }

  return <ReviewItem variant={variant} label="Event Date" errors={errors} />;
};

const CheckGuestCount = ({ errors }: CheckProps) => {
  let variant = 'warn' as ReviewItemProps['variant'];

  if (!errors?.length) {
    variant = 'ok';
    errors = ['OK!'];
  }

  return <ReviewItem variant={variant} label="Guest Count" errors={errors} />;
};

const CheckVIP = ({ errors }: CheckProps) => {
  let variant = 'warn' as ReviewItemProps['variant'];

  if (!errors?.length) {
    variant = 'ok';
    errors = ['OK!'];
  }

  return <ReviewItem variant={variant} label="VIP" errors={errors} />;
};
