import { PolicyCheck } from '@/features/auth/authorization';

export const CustomerListPolicies: Record<string, PolicyCheck> = {
  'customers.lists.view': ({ acl, user, meta }) => {
    return acl.customers.lists.view;
  },
  'customers.lists.create': ({ acl, user, meta }) => {
    return acl.customers.lists.create;
  },
  'customers.lists.update': ({ acl, user, meta }) => {
    return acl.customers.lists.edit;
  },
  'customers.lists.delete': ({ acl, user, meta }) => {
    return acl.customers.lists.delete;
  },
  'customers.lists.export': ({ acl, user, meta }) => {
    return acl.customers.lists.export;
  },
};
