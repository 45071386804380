import { useUpdateCustomer } from '@/api/customers/customers/updateCustomer';
import { useReferralCampaigns } from '@/api/locations/referral-campaigns/getReferralCampaigns';
import { Link } from '@/components/Elements';
import { NotesTimeline } from '@/components/NotesTimeline';
import { QrCode } from '@/components/QrGenerator/QrCode';
import { qrDefaults } from '@/components/QrGenerator/qrDefaults';
import { Badge } from '@/components/ui/elements/badge';
import { Button } from '@/components/ui/elements/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/elements/select';
import { PolicyGate } from '@/features/auth/authorization';
import { CustomerContext } from '@/features/customers/contexts/CustomerContext';
import { useCreateBlankLead } from '@/features/customers/hooks/useCreateBlankLead';
import { ConditionBadge } from '@/features/leads/components/Badges/ConditionBadge';
import { IdBadge } from '@/features/leads/components/Badges/IdBadge';
import { LocationContext } from '@/providers/location';
import { formatDate } from '@/utils/format';
import { useContext } from 'react';
import { CustomerSurveyList } from '../CustomerSurveyList';
import {
  CustomerTimeline,
  CustomerTimelineProps,
  TypedEvent,
  TypedLead,
  TypedPayment,
  TypedSurvey,
} from '../CustomerTimeline';
import NewNoteForm from '../NewNoteForm';

export const OverviewTab = () => {
  const { activeLocationId } = useContext(LocationContext);
  const { customer } = useContext(CustomerContext);
  const { mutate: updateCustomer } = useUpdateCustomer();
  const { createBlankLead } = useCreateBlankLead({ customer });

  const { data: campaigns } = useReferralCampaigns({
    location: activeLocationId,
    search: {
      orderBy: 'created_at',
      orderDir: 'asc',
      active: true,
    },
  });

  const handleReferralCampaignChange = (value: string) => {
    if (!customer) return;
    updateCustomer({
      id: customer.id,
      data: {
        referral_campaign_id: value,
      },
    });
  };

  if (!customer) return <>Loading...</>;

  const openLeads = customer.leads.filter(
    (lead) => lead.status !== 'Closed' && lead.status !== 'Booked',
  );

  // Customer timeline is an array of leads created, event bookings, payments,
  // and surveys in order of created_at throughout the customer's history
  let customerTimeline = [] as CustomerTimelineProps['items'];
  customerTimeline = [
    ...customer.leads.map((lead) => ({ ...lead, timeline_type: 'Lead' } as TypedLead)),
    ...customer.events.map((event) => ({ ...event, timeline_type: 'Event' } as TypedEvent)),
    ...customer.payments.map(
      (payment) => ({ ...payment, timeline_type: 'Payment' } as TypedPayment),
    ),
    ...customer.surveys.map((survey) => ({ ...survey, timeline_type: 'Survey' } as TypedSurvey)),
  ].sort(
    (a, b) =>
      new Date(b.created_at.replace(' ', 'T')).getTime() -
      new Date(a.created_at.replace(' ', 'T')).getTime(),
  );

  const referralLink = `https://gametruck.com/referral/${customer.referral_code}/redeem`;
  const enrolledCampaign =
    campaigns?.find((campaign) => campaign.id === customer.referral_campaign_id) ?? null;
  const enrolledInOtherCampaign = !enrolledCampaign && !!customer.referral_campaign_id;

  return (
    <PolicyGate policy="customers.view">
      <div className="@container">
        <div className="flex flex-col @xl:flex-row md:space-x-4">
          <div className="basis-3/4">
            {customer.surveys.length > 0 && (
              <>
                <h3 className="font-semibold mb-1 ml-3">Surveys</h3>
                <CustomerSurveyList customer={customer} />
              </>
            )}

            <h3 className="font-semibold mb-1 ml-3">Open Leads</h3>
            <section className="bg-white border border-gray-100 py-2 px-4">
              {openLeads.length === 0 && (
                <div className="h-24 flex flex-col align-center items-center justify-center">
                  <div className="text-gray-500 text-center py-3">
                    No open leads for this customer
                  </div>
                  <Button onClick={createBlankLead}>Create New Lead</Button>
                </div>
              )}
              <div className="flex flex-col divide-y">
                {openLeads.map((lead) => (
                  <div key={`cust-lead-${lead.id}`} className="py-3">
                    <div className="flex items-start gap-x-3">
                      <p className="font-semibold leading-6 text-gray-900">
                        <Link
                          to={
                            lead.transfer_pending
                              ? '/leads/lead/$id/accept-transfer'
                              : '/leads/lead/$id/quote'
                          }
                          params={{ id: lead.id }}
                          variant="link"
                          className="text-md"
                        >
                          {lead.name || 'No name entered...'}
                        </Link>
                      </p>
                      <ConditionBadge lead={lead} />
                      <IdBadge lead={lead} />
                    </div>
                    {lead.date_wanted && (
                      <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                        <p className="whitespace-nowrap">
                          Date Wanted: {formatDate(lead.date_wanted, 'MMM d, yyyy')}
                        </p>
                      </div>
                    )}
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <p className="whitespace-nowrap">Type: {lead.event_type?.name}</p>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <p>
                        Activities: {lead.activities?.map((activity) => activity.name).join(', ')}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </section>

            <h3 className="font-semibold mb-1 ml-3 mt-3">Referral Program</h3>
            <section className="bg-white border border-gray-100 py-3 px-4">
              <div className="flex flex-col @md:flex-row">
                <div className="grow mr-0 lg:mr-8 mt-3">
                  <p className="px-2 py-1 text-sm text-gray-800">
                    The referral rewards program allows customers to share a unique link with their
                    friends and family and earn rewards when those people make a purchase. The link
                    below is unique to this customer and can be shared with anyone. When someone
                    uses the link to book an event, the customer will receive a reward!
                  </p>
                  <p className="px-2 py-1 mb-4 text-sm text-gray-800">
                    The QR code on this page is also tied to this customer's unique referral link.
                    Scanning this QR code will take people to the same page as the referral link.
                  </p>

                  {customer.referral_code && (
                    <div className="mt-2 flex rounded shadow-sm">
                      <span className="inline-flex items-center rounded-l border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                        Shareable Link
                      </span>
                      <input
                        aria-label="Referral Link"
                        type="text"
                        name="referral_code"
                        id="referral_code"
                        className="block w-full min-w-0 flex-1 rounded-none rounded-r border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                        defaultValue={referralLink}
                      />
                    </div>
                  )}
                  <div className="text-sm py-2">
                    <span className="px-2 text-gray-800">Enrolled Campaign:</span>
                    {enrolledInOtherCampaign ? (
                      "Already enrolled in other location's campaign."
                    ) : (
                      <>
                        {campaigns && (
                          <Select
                            onValueChange={(value) => handleReferralCampaignChange(value)}
                            defaultValue={customer.referral_campaign_id?.toString() ?? ''}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Referral Campaign" />
                            </SelectTrigger>
                            <SelectContent className="max-h-96">
                              <SelectItem value="">Auto-select Default Campaign</SelectItem>
                              {campaigns.map((campaign) => (
                                <SelectItem
                                  key={`campaign-${campaign.id}`}
                                  value={campaign.id.toString()}
                                >
                                  <span className="flex flex-row space-x-2">
                                    <span>{campaign.name ?? 'No name provided...'}</span>
                                    {!campaign.active && (
                                      <Badge size="xs" variant="red">
                                        Inactive
                                      </Badge>
                                    )}
                                    {campaign.default && (
                                      <Badge size="xs" variant="gray">
                                        Default
                                      </Badge>
                                    )}
                                  </span>
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )}
                        {!campaigns && <>No active campaign</>}
                      </>
                    )}
                  </div>
                </div>
                {customer.referral_code && (
                  <div>
                    <QrCode
                      url={referralLink}
                      options={qrDefaults}
                      size={200}
                      className="max-w-[228px] m-2"
                    />
                  </div>
                )}
              </div>
            </section>

            <div className="mt-3">
              <h3 className="font-semibold mb-1 ml-3">Notes</h3>
              <NewNoteForm className="mt-1 mb-3" customer={customer} />
              <NotesTimeline notes={customer.notes} />
            </div>
          </div>
          <div className="basis-1/4">
            <CustomerTimeline className="my-5" items={customerTimeline} />
          </div>
        </div>
      </div>
    </PolicyGate>
  );
};
