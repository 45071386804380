import { featureKeys as calendarFeatureKeys } from '@/api/calendar';
import queryClient from '@/lib/react-query';
import { useCreateMutationFactory, UseCreateMutationProps } from '@/lib/react-query/create';
import { Blackout } from '@/types';
import { post } from '@/utils/api';
import { BlackoutSearchParams, featureKeys, featurePath } from '.';

export type CreateBlackoutsDTO = {
  data: {
    blackouts: Partial<Blackout>[];
  };
  params?: BlackoutSearchParams;
};

const createBlackouts = ({ data, params = {} }: CreateBlackoutsDTO) =>
  post<Blackout[]>(`${featurePath}/bulk`, data, params);

export const useCreateBlackouts = (options?: UseCreateMutationProps<Blackout[]>) => {
  return useCreateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: createBlackouts,
    ...options,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: calendarFeatureKeys.all });
      options?.onSuccess?.(data);
    },
  });
};
