import { useEvent } from '@/api/events/events/getEvent';
import { useCompleteTransfer, useRejectTransfer } from '@/api/events/events/transferEvent';
import { ScrollableLayout } from '@/components/Layout';
import Page from '@/components/Layout/Page';
import { QuoteTransfer } from '@/components/QuoteTransfer/QuoteTransfer';
import queryClient from '@/lib/react-query';
import { LocationContext } from '@/providers/location';
import { CompleteTransferDTO, Event } from '@/types';
import { useNavigate, useParams } from '@tanstack/react-router';
import { useContext } from 'react';

export const EventTransfer = () => {
  const { id: eventId } = useParams({
    strict: false,
  });
  const { activeLocationId } = useContext(LocationContext);
  const navigate = useNavigate();
  const {
    isPending,
    isError,
    data: event,
    error,
  } = useEvent({
    id: eventId,
    search: {
      include: 'transferredFromFranchise',
    },
  });
  const { mutate: completeTransfer } = useCompleteTransfer();
  const { mutate: rejectTransfer } = useRejectTransfer();

  const handleAcceptTransfer = (data: CompleteTransferDTO<Event>['data']) => {
    completeTransfer(
      {
        id: eventId,
        data,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['availability'] });
          navigate({ to: `/events/event/${eventId}/edit` });
        },
      },
    );
  };

  const handleRejectTransfer = () => {
    rejectTransfer(
      { id: eventId },
      {
        onSuccess: () => navigate({ to: '/events/upcoming' }),
      },
    );
  };

  if (!event) return <>No Event Found</>;

  if (event.franchise_id !== activeLocationId && activeLocationId !== 0) {
    return <>You do not have permission to view this transfer</>;
  }

  return (
    <ScrollableLayout className="w-full">
      <Page className="max-w-3xl lg:ml-16">
        <Page.Header className="mx-8 my-2">
          <Page.HeaderContent title="Lead Transfer is Pending">
            Process transer from {event?.transferred_from_franchise?.areaop} to{' '}
            {event?.franchise?.areaop}
          </Page.HeaderContent>
        </Page.Header>
        <Page.Body className="mx-8 my-2">
          <QuoteTransfer<Event>
            quoteable={event}
            onComplete={handleAcceptTransfer}
            onReject={handleRejectTransfer}
          />
        </Page.Body>
      </Page>
    </ScrollableLayout>
  );
};
