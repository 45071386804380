import _ from 'lodash';
import { ACL } from '.';
import { ManagerACL } from './manager';
export const ACL_ROLE_ZEE_ADMIN = 'Franchise Admin';

export const FranchiseAdminACL: ACL = _.merge({}, ManagerACL, {
  role: ACL_ROLE_ZEE_ADMIN,
  products: {
    view: true,
    services: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
    addons: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
    packages: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
    coupons: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
  },
  equipment: {
    view: true,
    assets: {
      view: true,
      edit: true,
    },
    depots: {
      view: true,
      create: true,
      edit: true,
    },
    vehicles: {
      view: false,
      create: false,
      edit: false,
    },
  },
  settings: {
    view: true,
    edit: true,
  },
  users: {
    view: false,
    edit: false,
    delete: false,
    grant: true,
  },
  reports: {
    view: true,
  },
  resources: {
    view: true,
    directory: {
      view: true,
      internal: {
        view: true,
      },
    },
    store: {
      view: true,
    },
  },
});
