'use client';

import { Button } from '@/components/ui/elements/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/elements/popover';
import { calendarDateToDate, cn, dateToCalendarDate } from '@/utils/format';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { CalendarDate, CalendarDateTime, ZonedDateTime } from '@internationalized/date';
import { format } from 'date-fns';
import { useState } from 'react';
import { DatePicker as DatePickerPrimitive } from 'react-aria-components';
import { Calendar, DatePickerClearButton, DatePickerTodayButton } from '../ui/elements/calendar';

interface DayPickerProps {
  date: Date;
  setDate: (date: Date) => void;
  mode?: 'icon' | 'button';
  inlineButtonClassName?: string;
  isRequired?: boolean;
}

export const DayPicker = ({
  date,
  setDate,
  mode = 'button',
  inlineButtonClassName,
  isRequired = true,
}: DayPickerProps) => {
  const [open, setOpen] = useState(false);

  const selectDate = (value: CalendarDate | CalendarDateTime | ZonedDateTime) => {
    setDate(calendarDateToDate(value));
  };

  return (
    <DatePickerPrimitive
      shouldForceLeadingZeros={true}
      defaultValue={dateToCalendarDate(date)}
      onChange={(value) => selectDate(value)}
      isOpen={open}
      onOpenChange={setOpen}
      aria-label="Pick a date"
    >
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          {mode === 'icon' ? (
            <button
              aria-label="Pick a date"
              title="Pick a date"
              type="button"
              className={cn(
                inlineButtonClassName ??
                  'hidden md:inline-flex relative -ml-px items-center bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10',
              )}
              onClick={() => setOpen(!open)}
            >
              <span className="sr-only">Pick Date</span>
              <CalendarIcon className="h-5 w-5" />
            </button>
          ) : (
            <Button
              title="Pick a date"
              aria-label="Pick a date"
              variant={'outline'}
              onClick={() => setOpen(!open)}
              className={cn(
                'w-[280px] justify-start text-left font-normal',
                !date && 'text-muted-foreground',
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {date ? format(date, 'M/d/y') : <span>Pick a date</span>}
            </Button>
          )}
        </PopoverTrigger>

        <PopoverContent className="w-52 p-2" align="end">
          <Calendar
            calendarControls={
              <>
                <DatePickerTodayButton />
                {!isRequired && <DatePickerClearButton />}
              </>
            }
          />
        </PopoverContent>
      </Popover>
    </DatePickerPrimitive>
  );
};
