import { useCopyEvent } from '@/api/events/events/copyEvent';
import { FormCheckbox, FormDate, FormInput, FormSelect } from '@/components/Form';
import { FormEmailInput } from '@/components/Form/FormEmailInput';
import { Button } from '@/components/ui/elements/button';
import { Checkbox } from '@/components/ui/elements/checkbox';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/elements/dialog';
import { Form } from '@/components/ui/form/form';
import { Event } from '@/types';
import { formatDate } from '@/utils/format';
import { zodResolver } from '@hookform/resolvers/zod';
import { DialogProps } from '@radix-ui/react-dialog';
import { useNavigate } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { z } from 'zod';

type CopyEventDialogProps = DialogProps & {
  source: Event;
};

const schema = z.object({
  new_date: z.coerce.string().min(1),
  copy_mode: z.enum(['lead', 'event']),
  new_name: z.string(),
  keep_basic_info: z.boolean(),
  keep_address: z.boolean(),
  keep_notes: z.boolean(),
  keep_services: z.boolean(),
  keep_addons: z.boolean(),
  keep_vips: z.boolean(),
  send_email: z.boolean(),
  customer_id: z.string().nullish(),
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  phone: z.string().nullish(),
  email: z.string().nullish(),
});

export const CopyEventDialog = ({ source, ...props }: CopyEventDialogProps) => {
  const [sameCustomer, setSameCustomer] = useState(true);
  const navigate = useNavigate();
  const { mutate: copyEvent } = useCopyEvent();

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      new_date: '',
      new_name: `${source.name} - Copy`,
      customer_id: source.customer_id.toString(),
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      copy_mode: 'event',
      send_email: false,
      keep_basic_info: true,
      keep_address: true,
      keep_notes: true,
      keep_vips: true,
      keep_services: false,
      keep_addons: false,
    },
  });

  const handleSubmit = (values: z.infer<typeof schema>) => {
    copyEvent(
      {
        id: source.id,
        data: values,
      },
      {
        onSuccess: (data) => {
          if (props.onOpenChange) props.onOpenChange(false);
          form.reset();
          if (copyMode === 'lead') {
            navigate({ to: `/leads/lead/${data.id}/quote` });
          } else {
            navigate({ to: `/events/event/${data.id}/edit` });
          }
        },
      },
    );
  };

  const handleCustomerMode = (useSame: boolean) => {
    setSameCustomer(useSame);
    if (!useSame) {
      form.setValue('customer_id', '');
    }
  };

  const copyMode = form.watch('copy_mode');
  useEffect(() => {
    if (copyMode === 'lead') {
      setSameCustomer(true);
      form.setValue('customer_id', source.customer_id.toString());
      form.setValue('first_name', '');
      form.setValue('last_name', '');
      form.setValue('phone', '');
      form.setValue('email', '');
      form.setValue('send_email', false);
      form.setValue('keep_basic_info', true);
      form.setValue('keep_address', true);
      form.setValue('keep_notes', true);
    }
  }, [copyMode]);

  return (
    <Dialog {...props}>
      <DialogContent className="w-full max-w-[80vw]">
        <DialogHeader>
          <DialogTitle>Copy Event: {source.name}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-2">
            <div className="grid grid-cols-2 gap-2">
              <FormDate control={form.control} name="new_date" label="New Date" />
              <FormInput control={form.control} name="new_name" label="New Name" />

              <FormSelect
                control={form.control}
                name="copy_mode"
                label="Copy Mode"
                options={[
                  { label: 'Copy as Lead', value: 'lead' },
                  { label: 'Copy as Event', value: 'event' },
                ]}
              />
              <span />
              {copyMode !== 'lead' && (
                <div>
                  <div className="flex flex-row items-center justify-between border-b border-b-gray-300 py-3">
                    <span>
                      <Checkbox
                        defaultChecked={sameCustomer}
                        onCheckedChange={() => handleCustomerMode(!sameCustomer)}
                        className="mr-2"
                      />
                    </span>
                    <label className="grow">
                      Use Same Customer Record:{' '}
                      {source.customer.is_organization
                        ? source.customer.organization_name
                        : source.customer.full_name}
                      ?
                    </label>
                  </div>

                  {!sameCustomer ? (
                    <div className="grid sm:grid-cols-2 gap-2">
                      <FormInput control={form.control} name="first_name" label="First Name" />
                      <FormInput control={form.control} name="last_name" label="Last Name" />
                      <FormInput control={form.control} name="phone" label="Phone" />
                      <FormEmailInput control={form.control} name="email" label="Email" />
                    </div>
                  ) : (
                    <FormInput type="hidden" control={form.control} name="customer_id" />
                  )}
                  <div className="flex flex-row items-center justify-between border-b border-b-gray-300 py-3">
                    <span className="basis-1/2">
                      <FormCheckbox
                        control={form.control}
                        name="send_email"
                        label="Send Confirmation Email?"
                      />
                    </span>
                    {sameCustomer && (
                      <span className="basis-1/2 text-sm">{source.customer.email}</span>
                    )}
                  </div>
                </div>
              )}
              <div>
                {copyMode === 'lead' && (
                  <>
                    <div className="flex flex-row items-center justify-between border-b border-b-gray-300 py-3">
                      <span className="basis-1/2">
                        <FormCheckbox
                          control={form.control}
                          label="Keep Basic Info?"
                          name="keep_basic_info"
                        />
                      </span>
                      <span className="basis-1/2 text-sm">
                        {source.event_type?.name}
                        <br />
                        {source.guest_count} Guests
                      </span>
                    </div>
                    <div className="flex flex-row items-center justify-between border-b border-b-gray-300 py-3">
                      <span className="basis-1/2">
                        <FormCheckbox
                          control={form.control}
                          label="Keep Location?"
                          name="keep_address"
                        />
                      </span>
                      <span className="basis-1/2 text-sm">
                        {source.address}
                        <br />
                        {source.city}, {source.state} {source.zipcode}
                      </span>
                    </div>
                    <div className="flex flex-row items-center justify-between border-b border-b-gray-300 py-3">
                      <span className="basis-1/2">
                        <FormCheckbox
                          control={form.control}
                          label="Keep Special Instructions?"
                          name="keep_notes"
                        />
                      </span>
                      <span className="basis-1/2 text-sm">
                        {source.special_instructions ?? 'n/a'}
                      </span>
                    </div>
                  </>
                )}
                <div className="flex flex-row items-center justify-between border-b border-b-gray-300 py-3">
                  <span className="basis-1/2">
                    <FormCheckbox control={form.control} label="Keep VIP(s)?" name="keep_vips" />
                  </span>
                  <span className="basis-1/2 text-sm">
                    {source.vips?.map((vip) => vip.first_name).join(', ') ?? 'n/a'}
                  </span>
                </div>

                <div className="flex flex-row items-center justify-between border-b border-b-gray-300 py-3">
                  <span className="basis-1/2">
                    <FormCheckbox
                      control={form.control}
                      label="Keep Activities?"
                      name="keep_services"
                    />
                  </span>
                  <span className="basis-1/2 text-sm">
                    <ul>
                      {source.activities?.map((activity, i) => {
                        return (
                          <li key={`build-activity-${i}`} className="py-2">
                            <b>{activity.name}</b>
                            <br />
                            {formatDate(activity.start_at)}
                            <br />
                            {activity.duration / 60} hours @ ${activity.total}
                          </li>
                        );
                      })}
                    </ul>
                  </span>
                </div>

                <div className="flex flex-row items-center justify-between border-b border-b-gray-300 py-3">
                  <span className="basis-1/2">
                    <FormCheckbox control={form.control} label="Keep Addons?" name="keep_addons" />
                  </span>
                  <span className="basis-1/2 text-sm">
                    <ul>
                      {source.line_items?.map((line_item, i) => {
                        return (
                          <li key={`build-line_item-${i}`}>
                            {line_item.name} - {line_item.quantity} @ ${line_item.total}
                          </li>
                        );
                      })}
                    </ul>
                  </span>
                </div>
              </div>
            </div>

            <DialogFooter>
              <Button type="submit">
                {copyMode === 'event' ? 'Copy the Event!' : 'Copy as New Lead'}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
