import queryClient from '@/lib/react-query';
import {
  UpdateDTO,
  useUpdateMutationFactory,
  UseUpdateMutationProps,
} from '@/lib/react-query/update';
import { CustomerList, ModelID } from '@/types';
import { post, update } from '@/utils/api';
import { CustomerListSearchParams, featureKeys, featurePath } from '.';

export type UpdateCustomerListDTO = UpdateDTO<CustomerList, CustomerListSearchParams>;

export type AddCustomerToListDTO = {
  id: ModelID;
  data: {
    customer_id: ModelID;
  };
  params?: CustomerListSearchParams;
};

export type RemoveCustomerFromListDTO = {
  id: ModelID;
  data: {
    customer_id: ModelID;
  };
  params?: CustomerListSearchParams;
};

const updateCustomerList = ({ data, id, params = {} }: UpdateCustomerListDTO) =>
  update<CustomerList>(`${featurePath}/${id}`, data, params);

const addCustomerToList = ({ data, id, params = {} }: AddCustomerToListDTO) =>
  post<CustomerList>(`${featurePath}/${id}/add-customer`, data, params);

const removeCustomerFromList = ({ data, id, params = {} }: RemoveCustomerFromListDTO) =>
  post<CustomerList>(`${featurePath}/${id}/remove-customer`, data, params);

export const useUpdateCustomerList = (options?: UseUpdateMutationProps<CustomerList>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: updateCustomerList,
    ...options,
  });
};

export const useAddCustomerToList = (options?: UseUpdateMutationProps<CustomerList>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: addCustomerToList,
    ...options,
    onSuccess: (data: CustomerList) => {
      queryClient.invalidateQueries({ queryKey: featureKeys.detail(data.id) });
      if (options?.onSuccess) {
        options.onSuccess(data);
      }
    },
  });
};

export const useRemoveCustomerFromList = (options?: UseUpdateMutationProps<CustomerList>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: removeCustomerFromList,
    ...options,
    onSuccess: (data: CustomerList) => {
      queryClient.invalidateQueries({ queryKey: featureKeys.details() });
      if (options?.onSuccess) {
        options.onSuccess(data);
      }
    },
  });
};
