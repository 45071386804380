import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Addon, ModelID } from '@/types';
import { get, getPaginated, PaginatedResource } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { AddonSearchParams, featureKeys, featurePath } from '.';

type QueryFnType = typeof fetchAddons;
type PagedQueryFnType = typeof fetchPagedAddons;

type UseAddonsOptions = {
  location: ModelID;
  search?: AddonSearchParams;
  config?: QueryConfig<QueryFnType>;
};

type UsePagedAddonsOptions = {
  location: ModelID;
  search?: AddonSearchParams;
  config?: QueryConfig<PagedQueryFnType>;
};

const ONE_HOUR = 1000 * 60 * 60;

export const fetchAddons = (location: ModelID, search: AddonSearchParams = {}) =>
  get<Addon[]>(`${featurePath}?franchise_id=${location}`, search);

export const fetchPagedAddons = (location: ModelID, search?: AddonSearchParams) =>
  getPaginated<Addon>(`${featurePath}?franchise_id=${location}`, search);

export const useAddons = ({ location, search = {}, config = {} }: UseAddonsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    staleTime: ONE_HOUR,
    queryKey: featureKeys.list(location, search),
    queryFn: () => fetchAddons(location, search),
  });
};

export const usePagedAddons = ({ location, search, config = {} }: UsePagedAddonsOptions) => {
  return useQuery<ExtractFnReturnType<PagedQueryFnType>, Error, PaginatedResource<Addon>, any>({
    ...config,
    queryKey: featureKeys.list(location, search),
    queryFn: () => fetchPagedAddons(location, search),
  });
};
