import {
  CreateDTO,
  useCreateMutationFactory,
  UseCreateMutationProps,
} from '@/lib/react-query/create';
import { CustomerList } from '@/types';
import { create } from '@/utils/api';
import { CustomerListSearchParams, featureKeys, featurePath } from '.';

export type CreateCustomerListDTO = CreateDTO<CustomerList, CustomerListSearchParams>;

const createCustomerList = ({ data, params = {} }: CreateCustomerListDTO) =>
  create<CustomerList>(`${featurePath}`, data, params);

export const useCreateCustomerList = (options?: UseCreateMutationProps<CustomerList>) => {
  return useCreateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: createCustomerList,
    ...options,
  });
};
