import { featureKeys as eventFeatureKeys } from '@/api/events/events';
import { CustomerEditForm } from '@/components/Customer/CustomerEditForm';
import { Link } from '@/components/Elements/Link/Link';
import NoResults from '@/components/Elements/NoResults/NoResults';
import PaymentMethodManager from '@/components/PaymentMethodManager/PaymentMethodManager';
import { PolicyGate } from '@/features/auth/authorization';
import { EventContext } from '@/features/events/contexts/EventContext';
import { queryClient } from '@/lib/react-query';
import { useContext } from 'react';

export const CustomerTab = () => {
  const { event } = useContext(EventContext);

  const onUpdate = () => {
    queryClient.invalidateQueries({ queryKey: eventFeatureKeys.details() });
  };

  const copyFrom = () => {
    return {
      address: event?.address,
      address_line2: event?.address_line2,
      city: event?.city,
      state: event?.state,
      zipcode: event?.zipcode,
    };
  };

  if (!event) return <>Loading...</>;

  if (!event?.customer) return <NoResults label="customer" />;

  return (
    <div className="@container">
      <div className="flex flex-col @xl:flex-row md:space-x-4">
        <PolicyGate policy="customers.update">
          <div className="basis-1/2">
            <h2 className="text-lg">
              Customer{' '}
              <Link className="text-lg" to={`/customers/customer/${event.customer.id}/overview`}>
                #{event.customer.id}
              </Link>
            </h2>

            <CustomerEditForm
              key={`custedit-${event.customer_id}`}
              customer={event.customer}
              onUpdate={onUpdate}
              copyFrom={copyFrom}
            />
          </div>
          <div className="basis-1/2">
            <h2>Payment Methods</h2>
            <PaymentMethodManager customer={event.customer} />
          </div>
        </PolicyGate>
      </div>
    </div>
  );
};
