import { useUpdateMutationFactory, UseUpdateMutationProps } from '@/lib/react-query/update';
import { Event, ModelID } from '@/types';
import { post } from '@/utils/api';
import { featureKeys, featurePath } from '.';

interface UnsnoozeDTO {
  id: ModelID;
}

interface SnoozeDTO {
  id: ModelID;
  snooze_until: string;
}

const snooze = async ({ id, snooze_until }: SnoozeDTO) =>
  post<Event>(`${featurePath}/${id}/snooze`, {
    snooze_until,
  });

const clearSnooze = async ({ id }: UnsnoozeDTO) =>
  post<Event>(`${featurePath}/${id}/clear-snooze`, {});

export const useSnooze = (options?: UseUpdateMutationProps<Event>) => {
  return useUpdateMutationFactory(
    {
      invalidateKeys: featureKeys,
      mutationFn: snooze,
      ...options,
    },
    { shouldInvalidateAll: false },
  );
};

export const useClearSnooze = (options?: UseUpdateMutationProps<Event>) => {
  return useUpdateMutationFactory(
    {
      invalidateKeys: featureKeys,
      mutationFn: clearSnooze,
      ...options,
    },
    { shouldInvalidateAll: false },
  );
};
