import { Badge } from '@/components/ui/elements/badge';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/elements/tooltip';
import { Location } from '@/types';

interface AreaOpBadgeProps {
  location: Location;
}

const AreaOpBadge = ({ location }: AreaOpBadgeProps) => {
  return (
    <TooltipProvider delayDuration={300}>
      <Tooltip>
        <TooltipTrigger>
          <Badge size="sm" className="py-0.5">
            {location.areaop}
          </Badge>
        </TooltipTrigger>
        <TooltipContent>
          {location.booking_name} {location.booking_phone}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default AreaOpBadge;
