import { BaseNote } from '@/types';
import { formatApiDate } from '@/utils/format';
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/20/solid';
import { BoltIcon } from '@heroicons/react/24/outline';
import { Avatar } from '../Elements/Avatar';

type NotesTimelineProps = {
  notes: BaseNote[];
};

export const NotesTimeline = ({ notes }: NotesTimelineProps) => {
  const notesList = notes.map((note) => {
    note.message = note.message.replace(/(https:\/\/.*\.com\/survey\/)[^\s]+/g, '$1**********');
    return note;
  });

  return (
    <div className="flow-root">
      <ul role="list" className="-mb-8">
        {notesList.map((activityItem, index) => (
          <li key={activityItem.id}>
            <div className="relative pb-8">
              {index !== notes.length - 1 ? (
                <span
                  className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex items-start space-x-3">
                {activityItem.created_by || activityItem.type === 'comment' ? (
                  <>
                    <div className="relative">
                      {activityItem.created_by && (
                        <Avatar
                          src={activityItem.created_by.avatar}
                          alt={activityItem.created_by.full_name}
                          size="lg"
                          className="flex items-center justify-center rounded-full bg-gray-400 ring-3 ring-white"
                        />
                      )}

                      <span className="absolute -bottom-1 -right-1 rounded-full bg-white px-0.5 py-px">
                        <ChatBubbleLeftEllipsisIcon
                          className="h-4 w-4 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1">
                      <div>
                        <div className="text-sm">
                          <span className="font-medium text-gray-900">
                            {activityItem.created_by?.full_name}
                          </span>
                        </div>
                        <p className="mt-0.5 text-sm text-gray-500">
                          Commented {formatApiDate(activityItem.created_at)}
                        </p>
                      </div>
                      <div className="mt-2 text-sm text-gray-700">
                        <p>{activityItem.message}</p>
                      </div>
                    </div>
                  </>
                ) : !activityItem.created_by || activityItem.type === 'system' ? (
                  <>
                    <div>
                      <div className="relative px-1">
                        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-3 ring-white">
                          <BoltIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </div>
                      </div>
                    </div>
                    <div className="min-w-0 flex-1">
                      <div>
                        <div className="text-sm">{activityItem.message}</div>
                        <p className="mt-0.5 text-sm text-gray-500">{activityItem.created_at}</p>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NotesTimeline;
