import { useCreateBlackouts } from '@/api/equipment/blackouts/createBlackouts';
import { Button } from '@/components/ui/elements/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/elements/dialog';
import { Form } from '@/components/ui/form/form';
import { ALL_START_TIMES } from '@/config/settings';
import { queryClient } from '@/lib/react-query';
import { Asset, ModelID } from '@/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormDate, FormSelect, FormTextarea } from '../Form';
import { blackoutSchema, blackoutTypeOptions, prepareBlackoutData } from './helpers';

const timeOptions = ALL_START_TIMES;

type CreateBlackoutsModalProps = {
  location: ModelID;
  assets: Asset[];
  open: boolean;
  setOpen: (open: boolean) => void;
};

const schema = z.object({
  blackouts: z.array(blackoutSchema),
});

export const CreateBlackoutsModal = ({
  location,
  assets,
  open,
  setOpen,
}: CreateBlackoutsModalProps) => {
  const { mutate: createBlackouts } = useCreateBlackouts({
    onSuccess: (blackouts) => {
      queryClient.invalidateQueries({
        queryKey: [
          {
            scope: 'calendar-events',
            location,
            entity: 'list',
          },
        ],
      });
      setOpen(false);
      form.reset();
    },
  });

  const handleSubmit = (values: z.infer<typeof schema>) => {
    const blackouts = values.blackouts.map((blackout) => prepareBlackoutData(blackout));

    createBlackouts({
      data: {
        blackouts,
      },
    });
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      blackouts: [
        {
          asset_id: '',
          start_date: '',
          start_time: '',
          end_date: '',
          end_time: '',
          reason: '',
          type: null,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'blackouts', // unique name for your Field Array
  });

  const copyLastItem = () => {
    const lastItem = fields[fields.length - 1];
    append(lastItem);
  };

  const assetOptions =
    assets?.map((asset) => {
      return { label: asset.name, value: asset.id };
    }) ?? [];

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="w-[95vw] sm:max-w-full @container">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-3">
            <DialogHeader>
              <DialogTitle>Add a Blackout</DialogTitle>
              <DialogDescription>
                Add a blackout to block a date or mutliple dates from being booked.
              </DialogDescription>
            </DialogHeader>
            <div className="flex flex-col space-y-2 py-4 pr-4 h-[65vh] overflow-y-auto">
              {fields.map((field, index) => {
                return (
                  <div
                    key={`blackout-${field.id}-${index}`}
                    className="flex flex-row space-x-2 border-b-2 w-full py-2 pb-4 my-3 items-center"
                  >
                    {fields.length > 0 && <div className="text-lg text-gray-400">{index + 1}</div>}
                    <div className="flex flex-col grow space-y-2">
                      <div className="flex flex-col space-y-2 @xl:flex-row @xl:space-x-2 @xl:space-y-0">
                        <FormSelect
                          control={form.control}
                          name={`blackouts.${index}.asset_id`}
                          options={assetOptions}
                          label="Asset"
                        />
                        <FormDate
                          control={form.control}
                          name={`blackouts.${index}.start_date`}
                          label="Start Date"
                        />

                        <FormSelect
                          control={form.control}
                          options={timeOptions}
                          name={`blackouts.${index}.start_time`}
                          label="Start Time"
                        />
                        <FormDate
                          control={form.control}
                          name={`blackouts.${index}.end_date`}
                          label="End Date"
                        />
                        <FormSelect
                          control={form.control}
                          options={timeOptions}
                          name={`blackouts.${index}.end_time`}
                          label="End Time"
                        />
                        <FormSelect
                          control={form.control}
                          name={`blackouts.${index}.type`}
                          options={blackoutTypeOptions}
                          label="Type"
                        />
                      </div>
                      <div>
                        <FormTextarea
                          control={form.control}
                          name={`blackouts.${index}.reason`}
                          placeholder="Enter notes here..."
                        />
                      </div>
                    </div>
                    <div>
                      <Button type="button" variant="destructive" onClick={() => remove(index)}>
                        Remove
                      </Button>
                    </div>
                  </div>
                );
              })}
              {fields.length > 0 && (
                <Button type="button" variant="outline" onClick={copyLastItem}>
                  Copy from Above
                </Button>
              )}
            </div>
            <DialogFooter className="flex-row">
              <Button
                type="button"
                variant="outline"
                onClick={() =>
                  append({
                    asset_id: '',
                    start_date: '',
                    start_time: '',
                    end_date: '',
                    end_time: '',
                    reason: '',
                    type: null,
                  })
                }
              >
                Add Blackout
              </Button>
              <div className="grow" />
              <Button type="submit">Create</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
