import { Button } from '@/components/ui/elements/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/elements/dropdown-menu';
import { LEAD_CLOSE_REASONS } from '@/config';
import { Lead } from '@/types';
import { isMobile } from 'react-device-detect';
import { useArchiveLead } from '../hooks/useArchiveLead';

interface ArchiveContextMenuProps {
  lead: Lead;
  onSuccess?: () => void;
}

export const ArchiveContextMenu = ({ lead, onSuccess }: ArchiveContextMenuProps) => {
  const { archiveLead } = useArchiveLead();

  const ArchiveActions = LEAD_CLOSE_REASONS.map((reason) => {
    return { label: reason, onClick: () => archiveLead(lead.id, reason, onSuccess) };
  });

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size={isMobile ? 'xs' : 'sm'}>
          Archive
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {ArchiveActions.map((action, i) => {
          return (
            <DropdownMenuItem
              key={`ddm-archive-${i}`}
              onClick={action.onClick}
              className="cursor-pointer"
            >
              {action.label}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
