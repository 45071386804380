import { ArchiveBoxIcon } from '@heroicons/react/24/outline';

const NoResults = ({ label = 'results' }: { label?: string }) => {
  return (
    <div className="flex flex-col border-dashed border-gray-300 m-3 rounded border items-center justify-center text-gray-500 h-80">
      <ArchiveBoxIcon className="w-16 h-16" />
      <h4>No {label} found...</h4>
    </div>
  );
};

export default NoResults;
