import { useUpdateMutationFactory, UseUpdateMutationProps } from '@/lib/react-query/update';
import { Customer, ModelID } from '@/types';
import { post } from '@/utils/api';
import { featureKeys, featurePath } from '.';

export type ClearEmailBlockDTO = {
  data: { email: string };
  id: ModelID;
};

const clearEmailBlock = ({ data, id }: ClearEmailBlockDTO) =>
  post<Customer>(`${featurePath}/${id}/clear-email-blocks`, data, {});

export const useCustomerClearEmailBlock = (options?: UseUpdateMutationProps<Customer>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: clearEmailBlock,
    ...options,
  });
};
