import { PolicyCheck } from '@/features/auth/authorization';

export const VehiclePolicies: Record<string, PolicyCheck> = {
  'equipment.vehicles.view': ({ acl, user, meta }) => {
    return acl.equipment.vehicles.view;
  },
  'equipment.vehicles.create': ({ acl, user, meta }) => {
    return acl.equipment.vehicles.create;
  },
  'equipment.vehicles.update': ({ acl, user, meta }) => {
    return acl.equipment.vehicles.edit;
  },
  'equipment.vehicles.delete': ({ acl, user, meta }) => {
    return false;
  },
};
