import { Button } from '@/components/ui/elements/button';
import { Checkbox } from '@/components/ui/elements/checkbox';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/elements/dialog';
import { DialogProps } from '@radix-ui/react-dialog';
import { useState } from 'react';

type ConfirmEventDialogProps = DialogProps & {
  onSubmit: (sendEmail: boolean) => void;
};

export const ConfirmEventDialog = ({ onSubmit, ...props }: ConfirmEventDialogProps) => {
  const [sendEmail, setSendEmail] = useState(false);

  const handleSubmit = () => {
    onSubmit(sendEmail);
    if (props.onOpenChange) props.onOpenChange(false);
  };

  return (
    <Dialog {...props}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Confirm Event</DialogTitle>
          <DialogDescription>
            <span className="flex flex-row justify-start items-center py-4">
              <Checkbox
                defaultChecked={sendEmail}
                onCheckedChange={() => setSendEmail(!sendEmail)}
                className="mr-2"
              />
              <label>Send/Resend Confirmation Email?</label>
            </span>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button onClick={handleSubmit}>Confirm the Event!</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
