import { useCalendarEvents } from '@/api/calendar/getCalendarEvents';
import { useAssets } from '@/api/equipment/assets/getAssets';
import { useAssignStaff } from '@/api/events/events/assignStaff';
import { useLocationUserAccesses } from '@/api/locations/access/getAccesses';
import { FULLCALENDAR_LICENSE_KEY } from '@/config/globals';
import { LocationContext } from '@/providers/location';
import { ModelID } from '@/types';
import { cn, dateFromApiDateTime, dateToCalendarDate, todayAsCalendarDate } from '@/utils/format';
import { uniqueVaues } from '@/utils/logic';
import {
  ArrowPathRoundedSquareIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  FireIcon,
} from '@heroicons/react/24/outline';
import { DateFormatter, getLocalTimeZone } from '@internationalized/date';
import { createRef, useContext, useEffect, useState } from 'react';
import { isAndroid } from 'react-device-detect';
import { DayPicker } from '../DatePicker/DayPicker';
import { inlineButtonDefaultStyles } from '../Elements';
import ButtonGroup from '../Elements/ButtonGroup/ButtonGroup';
import { Button } from '../ui/elements/button';
import { DayPlannerModal } from './DayPlannerModal';
import { RenderEventContent } from './RenderEventContent';

import FullCalendar from '@fullcalendar/react';

import { useAuthorization } from '@/features/auth/authorization';
import { DatesSetArg, EventDropArg } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';

const views = {
  day: {
    type: 'resourceTimelinePlugin',
    duration: { days: 1 },
    buttonText: 'Day',
  },
};

export const Scheduler = () => {
  const { activeLocationId, activeLocation } = useContext(LocationContext);
  const calendarRef = createRef<FullCalendar>();
  const [date, setDate] = useState(todayAsCalendarDate());
  const [dayPlannerOpen, setDayPlannerOpen] = useState(false);
  const { checkPolicy } = useAuthorization();

  const canMakeEdits = checkPolicy({ policy: 'scheduler.edit' });

  const { data: assets } = useAssets({
    location: activeLocationId,
    search: { active: true, orderBy: 'asset_type_id', orderDir: 'asc' },
  });

  const { data: calendarData, refetch } = useCalendarEvents({
    location: activeLocationId,
    search: { start: date, end: date },
    config: {
      staleTime: 0,
    },
  });

  const { data: userAccessResults } = useLocationUserAccesses({
    location: activeLocationId,
    search: {
      active: true,
      orderBy: 'type_code',
      orderDir: 'desc',
    },
  });

  const { mutate: assignStaff } = useAssignStaff({
    onSuccess: () => {
      refetch();
    },
    onError: () => {
      refetch();
    },
  });

  const handleEventDrop = (dropEvent: EventDropArg) => {
    if (!dropEvent) return;
    assignStaff({
      old_user_id: dropEvent.oldResource?.id ?? 'Unassigned',
      new_user_id: dropEvent.newResource?.id ?? 'Unassigned',
      event_activity_id: dropEvent.event.extendedProps.activity_id,
      action: 'reassign',
    });
  };

  const handleAddStaff = (user_id: ModelID, activity_id: ModelID) => {
    assignStaff({
      old_user_id: 'Unassigned',
      new_user_id: user_id,
      event_activity_id: activity_id,
      action: 'assign',
    });
  };

  let calendarEvents: any[] = [];
  let resources: any[] = [
    {
      id: 'Unassigned',
      group: 'Unassigned',
      type_code: 1000,
      title: 'Unassigned',
      resource: 'Unassigned',
      assets: '',
    },
  ];

  calendarEvents = [
    ...(calendarData?.events.map((calendarEvent) => {
      return {
        ...calendarEvent,
        resourceIds: calendarEvent.assignmentIds ?? [],
      };
    }) ?? []),
  ];

  const assetIdsInUse = uniqueVaues(
    calendarEvents
      .filter((event) => event.cal_event_type === 'event')
      .map((event) => event.resourceId.toString()) ?? [],
  );
  const assetsInUse =
    assets?.filter((asset) => assetIdsInUse.indexOf(asset.id.toString()) > -1) ?? [];

  resources = [
    ...resources,
    ...(userAccessResults
      ? userAccessResults.map((access, i): any => {
          const assignedAssetAbbrs = uniqueVaues(
            calendarEvents
              .filter((event) => event.resourceIds.includes(access.user.id))
              .map((event) => assets?.find((asset) => asset.id === event.resourceId)?.abbr || '') ??
              [],
          ).join(', ');

          return {
            id: access.user.id,
            group: access.type,
            type_code: access.type_code,
            title: access.user.full_name,
            resource: access.user.id,
            assets: assignedAssetAbbrs,
          };
        })
      : []),
  ];

  const handleDateChange = (dates: DatesSetArg) => {
    setDate(dateToCalendarDate(dates.start));
  };

  const calendarPrev = () => {
    calendarRef.current?.getApi().prev();
  };

  const calendarNext = () => {
    calendarRef.current?.getApi().next();
  };

  const gotoDate = (date: Date) => {
    calendarRef.current?.getApi().gotoDate(date);
  };

  const gotoToday = () => {
    gotoDate(new Date());
  };

  const firstEvent = calendarData?.events
    .filter((event) => event.cal_event_type !== 'background' && Boolean(event.allDay) !== true)
    .sort(
      (a, b) => dateFromApiDateTime(a.start).getTime() - dateFromApiDateTime(b.start).getTime(),
    )[0];
  const startTime = firstEvent ? firstEvent.start.split(' ')[1] ?? '09:00:00' : '09:00:00';

  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.getApi().scrollToTime(startTime);
    }
  }, [startTime]);

  return (
    <>
      <div className="@container mx-3">
        <div className="flex justify-between items-center my-4 space-x-3">
          <span className="text-lg font-semibold text-left grow">
            {new DateFormatter('en-US', {
              weekday: 'short',
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            }).format(date.toDate(getLocalTimeZone()))}
          </span>

          <Button variant="outline" onClick={() => setDayPlannerOpen(true)}>
            Auto-Plan
            <FireIcon className="h-5 w-5 ml-2" />
          </Button>

          <ButtonGroup>
            <ButtonGroup.Button title="Go to Today" isFirst onClick={gotoToday} className="p-2">
              <span className="sr-only">Go to Today</span>
              <ArrowPathRoundedSquareIcon className="h-5 w-5" />
            </ButtonGroup.Button>

            <ButtonGroup.Button title="Choose Day" asChild className="p-2">
              <DayPicker
                setDate={gotoDate}
                date={date.toDate(getLocalTimeZone())}
                mode="icon"
                inlineButtonClassName={cn(inlineButtonDefaultStyles)}
              />
            </ButtonGroup.Button>

            {/* <PolicyGate policy="events.export">
              <ButtonGroup.Button
                title="Export"
                className="p-2 hidden @md:inline-flex"
                onClick={exportCalendar}
              >
                <span className="sr-only">Export</span>
                <DocumentArrowDownIcon className="h-5 w-5" />
              </ButtonGroup.Button>
            </PolicyGate> */}

            <ButtonGroup.Button title="Back" className="p-2" onClick={calendarPrev}>
              <span className="sr-only">Back</span>
              <ChevronLeftIcon className="h-5 w-5" />
            </ButtonGroup.Button>

            <ButtonGroup.Button
              title="Next"
              className="p-2 @md:rounded-r-md"
              onClick={calendarNext}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" />
            </ButtonGroup.Button>
          </ButtonGroup>
        </div>
      </div>
      <FullCalendar
        ref={calendarRef}
        plugins={[resourceTimelinePlugin, interactionPlugin]}
        schedulerLicenseKey={FULLCALENDAR_LICENSE_KEY}
        views={views}
        initialView={'resourceTimeline'}
        initialDate={date.toString()}
        datesAboveResources={true}
        firstDay={1}
        slotDuration={'00:15:00'}
        scrollTime={startTime}
        eventMinHeight={1}
        slotMinTime={'00:00:00'}
        slotMaxTime={'24:00:00'}
        expandRows={true}
        eventOverlap={true}
        eventResourceEditable={canMakeEdits}
        headerToolbar={false}
        resourceOrder={'-type_code,title'}
        eventOrder={'resourceId'}
        // eventMouseEnter={handleEventMouseOver}
        // eventMouseLeave={handleEventMouseLeave}
        // eventResize={handleResize}
        eventDrop={handleEventDrop}
        datesSet={handleDateChange}
        // dateClick={handleDateClick}
        // eventClick={handleEventClick}
        // resourceGroupField={'group'}
        resourceAreaColumns={[
          {
            field: 'title',
            headerContent: 'Staff',
          },
          {
            field: 'assets',
            headerContent: 'Asset(s) Assigned',
            cellClassNames: 'font-bold bg-gray-100 text-gray-600',
          },
        ]}
        resourceAreaWidth={'20%'}
        eventContent={(eventInfo) =>
          RenderEventContent({
            eventInfo,
            staff: userAccessResults ?? [],
            handleAddStaff,
          })
        }
        resources={resources}
        events={calendarEvents}
        height={[isAndroid ? 'calc(100% - 202px)' : 'calc(100% - 106px)'].join(' ')}
      />

      <DayPlannerModal
        date={date}
        location={activeLocation.franchise_id}
        open={dayPlannerOpen}
        setOpen={setDayPlannerOpen}
        staff={userAccessResults ?? []}
        assets={assetsInUse}
      />
    </>
  );
};
