import {
  CreateDTO,
  useCreateMutationFactory,
  UseCreateMutationProps,
} from '@/lib/react-query/create';
import { Lead } from '@/types';
import { create } from '@/utils/api';
import { featureKeys, featurePath, LeadSearchParams } from '.';

export type CreateLeadDTO = CreateDTO<
  Lead & {
    relations_map?: {
      services: number[] | undefined;
      addons: number[] | undefined;
    };
  },
  LeadSearchParams & { force_new?: boolean }
>;

export const createLead = ({ data, params }: CreateLeadDTO) =>
  create<Lead>(`${featurePath}`, data, params);

export const useCreateLead = (options?: UseCreateMutationProps<Lead>) => {
  return useCreateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: createLead,
    ...options,
  });
};
