import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@/components/ui/elements/dropdown-menu';
import { ModelID } from '@/types';
import { Square3Stack3DIcon } from '@heroicons/react/24/solid';
import { GeoJsonProperties } from 'geojson';
import { useContext, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { MapContext } from './MapContext';
import { useMapStore } from './MapStateStore';

export const LayerFilterControl = () => {
  const { travelChargeLayers } = useContext(MapContext);
  const [expanded, setExpanded] = useState(false);

  const { hiddenLayers, toggleHiddenLayer, setHiddenLayers, showAllLayers } = useMapStore(
    useShallow((state) => ({
      hiddenLayers: state.hiddenLayers,
      toggleHiddenLayer: state.toggleHiddenLayer,
      showAllLayers: state.resetHiddenLayers,
      setHiddenLayers: state.setHiddenLayers,
    })),
  );

  const hideAllLayers = () => {
    setHiddenLayers(layers.map((layer) => layer?.id));
  };

  const layers = travelChargeLayers?.features.map((feature) => feature.properties) ?? [];

  const globalLayers = layers.filter((layer) => !layer?.asset_id && !layer?.asset_type_id);
  const hiddenGlobalLayers = globalLayers.filter((layer) => hiddenLayers.includes(layer?.id));
  const showAllGlobalLayers = () => {
    setHiddenLayers([
      ...hiddenAssetTypeLayers.map((layer) => layer?.id),
      ...hiddenAssetLayers.map((layer) => layer?.id),
    ]);
  };
  const hideAllGlobalLayers = () => {
    setHiddenLayers([
      ...globalLayers.map((layer) => layer?.id),
      ...hiddenAssetTypeLayers.map((layer) => layer?.id),
      ...hiddenAssetLayers.map((layer) => layer?.id),
    ]);
  };
  const showOnlyGlobalLayers = () => {
    setHiddenLayers([
      ...assetTypeLayers.map((layer) => layer?.id),
      ...assetLayers.map((layer) => layer?.id),
    ]);
  };

  const assetTypeLayers = layers.filter((layer) => !!layer?.asset_type_id);
  const hiddenAssetTypeLayers = assetTypeLayers.filter((layer) => hiddenLayers.includes(layer?.id));
  const hideAllAssetTypeLayers = () => {
    setHiddenLayers([
      ...hiddenGlobalLayers.map((layer) => layer?.id),
      ...hiddenAssetLayers.map((layer) => layer?.id),
      ...assetTypeLayers.map((layer) => layer?.id),
    ]);
  };
  const showAllAssetTypeLayers = () => {
    setHiddenLayers([
      ...hiddenGlobalLayers.map((layer) => layer?.id),
      ...hiddenAssetLayers.map((layer) => layer?.id),
    ]);
  };

  const assetLayers = layers.filter((layer) => !!layer?.asset_id);
  const hiddenAssetLayers = assetLayers.filter((layer) => hiddenLayers.includes(layer?.id));
  const hideAllAssetLayers = () => {
    setHiddenLayers([
      ...hiddenGlobalLayers.map((layer) => layer?.id),
      ...hiddenAssetTypeLayers.map((layer) => layer?.id),
      ...assetLayers.map((layer) => layer?.id),
    ]);
  };
  const showAllAssetLayers = () => {
    setHiddenLayers([
      ...hiddenGlobalLayers.map((layer) => layer?.id),
      ...hiddenAssetTypeLayers.map((layer) => layer?.id),
    ]);
  };

  return (
    <div className="z-10 absolute top-2.5 left-2.5">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button
            type="button"
            onClick={() => setExpanded(!expanded)}
            className="bg-white hover:bg-gray-200 hover:cursor-pointer rounded shadow shadow-gray-500 block p-1"
          >
            <Square3Stack3DIcon className="w-6 h-6 text-gray-700" />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          <DropdownMenuLabel>Layers</DropdownMenuLabel>

          {assetTypeLayers.length > 0 && assetLayers.length > 0 && (
            <DropdownMenuGroup>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={showOnlyGlobalLayers}>Only Show Global</DropdownMenuItem>
            </DropdownMenuGroup>
          )}

          {globalLayers && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuSub>
                  <DropdownMenuSubTrigger>
                    <span>Global Layers</span>
                  </DropdownMenuSubTrigger>
                  <DropdownMenuPortal>
                    <DropdownMenuSubContent>
                      {globalLayers.map((layer, i) => (
                        <LayerDropdownMenuCheckboxItem
                          key={`layerfilter-opt-${layer?.id}`}
                          layer={layer}
                          checked={!hiddenLayers.includes(layer?.id)}
                          toggle={toggleHiddenLayer}
                        />
                      ))}
                      <DropdownMenuSeparator />
                      {hiddenGlobalLayers.length > 0 && (
                        <DropdownMenuItem onClick={showAllGlobalLayers}>Show All</DropdownMenuItem>
                      )}
                      {hiddenGlobalLayers.length < globalLayers.length && (
                        <DropdownMenuItem onClick={hideAllGlobalLayers}>Hide All</DropdownMenuItem>
                      )}
                    </DropdownMenuSubContent>
                  </DropdownMenuPortal>
                </DropdownMenuSub>
              </DropdownMenuGroup>
            </>
          )}

          {assetTypeLayers && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuSub>
                  <DropdownMenuSubTrigger>
                    <span>Equipment Type Layers</span>
                  </DropdownMenuSubTrigger>
                  <DropdownMenuPortal>
                    <DropdownMenuSubContent>
                      {assetTypeLayers.map((layer, i) => (
                        <LayerDropdownMenuCheckboxItem
                          key={`layerfilter-opt-${layer?.id}`}
                          layer={layer}
                          checked={!hiddenLayers.includes(layer?.id)}
                          toggle={toggleHiddenLayer}
                        />
                      ))}
                      <DropdownMenuSeparator />
                      {hiddenAssetTypeLayers.length > 0 && (
                        <DropdownMenuItem onClick={showAllAssetTypeLayers}>
                          Show All
                        </DropdownMenuItem>
                      )}
                      {hiddenAssetTypeLayers.length < assetTypeLayers.length && (
                        <DropdownMenuItem onClick={hideAllAssetTypeLayers}>
                          Hide All
                        </DropdownMenuItem>
                      )}
                    </DropdownMenuSubContent>
                  </DropdownMenuPortal>
                </DropdownMenuSub>
              </DropdownMenuGroup>
            </>
          )}

          {assetLayers && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuSub>
                  <DropdownMenuSubTrigger>
                    <span>Equipment Layers</span>
                  </DropdownMenuSubTrigger>
                  <DropdownMenuPortal>
                    <DropdownMenuSubContent>
                      {assetLayers.map((layer, i) => (
                        <LayerDropdownMenuCheckboxItem
                          key={`layerfilter-opt-${layer?.id}`}
                          layer={layer}
                          checked={!hiddenLayers.includes(layer?.id)}
                          toggle={toggleHiddenLayer}
                        />
                      ))}
                      <DropdownMenuSeparator />
                      {hiddenAssetLayers.length > 0 && (
                        <DropdownMenuItem onClick={showAllAssetLayers}>Show All</DropdownMenuItem>
                      )}
                      {hiddenAssetLayers.length < assetLayers.length && (
                        <DropdownMenuItem onClick={hideAllAssetLayers}>Hide All</DropdownMenuItem>
                      )}
                    </DropdownMenuSubContent>
                  </DropdownMenuPortal>
                </DropdownMenuSub>
              </DropdownMenuGroup>
            </>
          )}

          <DropdownMenuGroup>
            {hiddenLayers.length > 0 && (
              <>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={showAllLayers}>Show All</DropdownMenuItem>
              </>
            )}
            {hiddenLayers.length < layers.length && (
              <>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={hideAllLayers}>Hide All</DropdownMenuItem>
              </>
            )}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

type LayerDropdownMenuCheckboxItemProps = {
  layer: GeoJsonProperties;
  checked: boolean;
  toggle: (id: ModelID) => void;
};

const LayerDropdownMenuCheckboxItem = ({
  layer,
  checked,
  toggle,
}: LayerDropdownMenuCheckboxItemProps) => {
  return (
    <DropdownMenuCheckboxItem
      key={`layerfilter-opt-${layer?.id}`}
      checked={checked}
      onCheckedChange={(checked) => toggle(layer?.id)}
    >
      {layer?.name} - {layer?.type}
    </DropdownMenuCheckboxItem>
  );
};
