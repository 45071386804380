import { PolicyCheck } from '@/features/auth/authorization';

export const DepotPolicies: Record<string, PolicyCheck> = {
  'equipment.depots.view': ({ acl, user, meta }) => {
    return acl.equipment.depots.view;
  },
  'equipment.depots.create': ({ acl, user, meta }) => {
    return acl.equipment.depots.create;
  },
  'equipment.depots.update': ({ acl, user, meta }) => {
    return acl.equipment.depots.edit;
  },
  'equipment.depots.delete': ({ acl, user, meta }) => {
    return acl.equipment.depots.edit || meta.depot.assets.length === 0;
  },
};
