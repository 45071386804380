import { usePaymentMethods } from '@/api/customers/payment-methods/getPaymentMethods';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/elements/accordion';
import { STRIPE_KEY } from '@/config';
import { LocationContext } from '@/providers/location';
import { Customer } from '@/types';
import { dateFromApiDateTime } from '@/utils/format';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { formatDistanceToNow } from 'date-fns';
import { useContext } from 'react';
import { EditPaymentMethod } from './EditPaymentMethod';
import { NewPaymentMethod } from './NewPaymentMethod';

interface PaymentMethodManagerProps {
  customer: Customer;
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_KEY);

const AccordionItemButtonStyle =
  'flex w-full justify-between bg-gray-100 border px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75';
const AccordionItemPanelStyle = 'text-sm border border-t-0 bg-gray-100 p-2';

const PaymentMethodManager = ({ customer }: PaymentMethodManagerProps) => {
  const { activeLocationId } = useContext(LocationContext);
  const { data: paymentMethods } = usePaymentMethods({
    location: activeLocationId,
    search: { customer_id: customer.id },
  });

  const options = {
    appearance: {
      labels: 'floating',
      theme: 'stripe',
    },
  } as StripeElementsOptions;

  return (
    <>
      <Elements stripe={stripePromise} options={options}>
        <Accordion type="multiple">
          {paymentMethods?.map((paymentMethod, index) => (
            <AccordionItem
              key={`payment-${index}`}
              value={`payment-${paymentMethod.payment_method_id}`}
              className="data-[state=open]:my-3"
            >
              <AccordionTrigger className={AccordionItemButtonStyle}>
                <div className="px-2">**** **** **** {paymentMethod.last4}</div>
                <div className="grow">
                  {paymentMethod.exp_month} / {paymentMethod.exp_year}
                </div>
                <div className="text-sm px-1">
                  Added:{' '}
                  {formatDistanceToNow(dateFromApiDateTime(paymentMethod.created_at), {
                    includeSeconds: true,
                    addSuffix: true,
                  })}
                </div>
              </AccordionTrigger>
              <AccordionContent className={AccordionItemPanelStyle}>
                <EditPaymentMethod paymentMethod={paymentMethod} />
              </AccordionContent>
            </AccordionItem>
          ))}
          <AccordionItem value="new-payment-method">
            <AccordionTrigger className={AccordionItemButtonStyle}>
              New Payment Method
            </AccordionTrigger>
            <AccordionContent className={AccordionItemPanelStyle}>
              <NewPaymentMethod
                customer={customer}
                defaultOpen={!paymentMethods || paymentMethods.length < 1}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </Elements>
    </>
  );
};

export default PaymentMethodManager;
