import { useHeartbeat } from '@/api/locations/useHeartbeat';
import { Link } from '@/components/Elements';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/elements/tooltip';
import { cn } from '@/utils/format';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

export const GamificationSummary = () => {
  const { gamification } = useHeartbeat();
  const { level, score, level_progress } = gamification;
  const { bookings, calls, contacts, emails, first_contacts, quotes, txts } = gamification;

  const [showAdvanced, setShowAdvanced] = useState(false);

  const actionPointMap = {
    first_contacts: 5,
    contacts: 0.5,
    calls: 1,
    txts: 0.5,
    emails: 0.5,
    quotes: 1,
    bookings: 5,
  } as Record<string, number>;

  return (
    <div className="ml-4 mt-8 mr-4">
      <span className="text-xs font-bold leading-tight flex justify-between items-center text-gray-500 pl-1">
        <span>Weekly Journey</span>
        <span>
          <TooltipProvider delayDuration={300}>
            <Tooltip>
              <TooltipTrigger>
                <InformationCircleIcon className="h-4 w-4 text-gray-300" />
              </TooltipTrigger>
              <TooltipContent className="text-xs font-normal w-40" align="end">
                Perform activities like sending texts and quotes, making calls, and booking events
                to earn points and level up.
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </span>
      </span>
      <div className="w-full h-2 rounded bg-gray-200 border shadow-inner border-gray-300">
        <span
          className="block h-full bg-green-500 rounded-l shadow-inner transition-all duration-300"
          style={{ width: `${level_progress * 100}%` }}
        />
      </div>
      <span className="text-xs leading-none flex justify-between text-gray-500 pl-1 pt-1.5 pr-1">
        <button
          type="button"
          className="flex flex-inline hover:cursor-pointer"
          onClick={() => setShowAdvanced(!showAdvanced)}
        >
          <span>Level {level}</span>
          <ChevronDownIcon
            className={cn(
              'h-4 w-4 text-gray-500 inline-block ml-1 relative -top-0.5 transition-all duration-300',
              showAdvanced ? '' : 'rotate-180',
            )}
          />
        </button>
        <span>{score} pts</span>
      </span>
      {showAdvanced && (
        <div className=" flex flex-col w-full mt-3 border-t pt-3 space-y-3 py-2 px-1 text-xs text-gray-500">
          <AdvancedStatRow title="Bookings" count={bookings} points={actionPointMap.bookings} />
          <AdvancedStatRow title="Quotes" count={quotes} points={actionPointMap.quotes} />
          <AdvancedStatRow title="Calls" count={calls} points={actionPointMap.calls} />
          <AdvancedStatRow title="Texts" count={txts} points={actionPointMap.txts} />
          <AdvancedStatRow title="Emails" count={emails} points={actionPointMap.emails} />
          <AdvancedStatRow title="Contacts" count={contacts} points={actionPointMap.contacts} />
          <AdvancedStatRow
            title="First Contacts"
            count={first_contacts}
            points={actionPointMap.first_contacts}
          />
        </div>
      )}
      <Link to="/leaderboard/current" className="text-xs text-gray-500 hover:underline mt-2 ml-1">
        View Leaderboard
      </Link>
    </div>
  );
};

interface AdvancedStatRowProps {
  title: string;
  count: number;
  points: number;
}

const AdvancedStatRow = ({ title, count, points }: AdvancedStatRowProps) => {
  return (
    <div className="flex">
      <span className="basis-1/2">{title}</span>
      <span className="basis-1/4 text-center">{count}</span>
      <span className="basis-1/4 text-right">{count * points}pts</span>
    </div>
  );
};
