import { useDeleteVip } from '@/api/customers/vips/deleteVip';
import { useLinkVip, useUpdateVip } from '@/api/customers/vips/updateVip';
import { Button } from '@/components/ui/elements/button';
import { Form } from '@/components/ui/form/form';
import { queryClient } from '@/lib/react-query';
import { Event, Lead, Vip } from '@/types';
import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { ConfirmationDialog } from '../Elements/ConfirmationDialog';
import { FormDate, FormInput } from '../Form';
import { Checkbox } from '../ui/elements/checkbox';

type VipRowProps = {
  vip: Vip;
  readonly?: boolean;
} & (
  | {
      mode: 'lead';
      record: Lead;
    }
  | {
      mode: 'event';
      record: Event;
    }
  | {
      mode?: null;
      record?: undefined;
    }
);

const schema = z.object({
  first_name: z.string().min(1, 'Required'),
  last_name: z.string().nullish(),
  age: z.coerce.string().nullish(),
  gender: z.string().nullish(),
  date_of_birth: z.string().nullish(),
});

const VipRow = ({ mode, vip, record, readonly = false }: VipRowProps) => {
  const [editMode, setEditMode] = useState(false);
  const { mutate: updateVip } = useUpdateVip();
  const { mutate: deleteVip } = useDeleteVip();
  const { mutate: linkVip } = useLinkVip();
  const handleUpdateVip = (data: Partial<Vip>) => {
    updateVip(
      {
        id: vip.id,
        data: {
          ...data,
          customer_id: vip.customer_id,
        },
      },
      {
        onSuccess: () => setEditMode(false),
      },
    );
  };

  const handleVipCheckbox = (checked: boolean) => {
    if (!record) return;

    linkVip(
      {
        vip,
        targetKey: record.id,
        action: !checked ? 'unlink' : 'link',
        mode,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [{ entity: 'detail', id: record.id, scope: `${mode}s` }],
          });
        },
      },
    );
  };

  const handleVipDelete = () => {
    deleteVip(
      {
        id: vip.id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [{ entity: 'detail', id: record?.id, scope: `${mode}s` }],
          });
        },
      },
    );
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      first_name: vip.first_name ?? '',
      last_name: vip.last_name ?? '',
      age: vip.age?.toString() ?? '',
      gender: vip.gender ?? '',
      date_of_birth: vip.date_of_birth ?? '',
    },
  });

  return (
    <div className="py-3">
      <div className="flex flex-row items-center space-x-2">
        {record && (
          <Checkbox
            defaultChecked={record.vips.some((v) => v.id === vip.id)}
            onCheckedChange={handleVipCheckbox}
            value={vip.id}
            disabled={readonly}
          />
        )}
        <span className="grow">
          <span className="flex flex-col sm:flex-row sm:items-center gap-x-2">
            <span>
              {vip.first_name} {vip.last_name}
            </span>
            <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
              <title>Dot</title>
              <circle cx={1} cy={1} r={1} />
            </svg>
            <span>Age: {vip.age}</span>
          </span>
        </span>

        {!readonly && (
          <ConfirmationDialog
            triggerButton={
              <Button variant="icon" className="w-7 h-7">
                <TrashIcon className="w-4 h-4" />
              </Button>
            }
            confirmButtonText="Delete"
            confirmAction={handleVipDelete}
            title={`Delete VIP: ${vip.first_name}?`}
            body="Are you sure you want to delete this VIP? This action cannot be undone."
          />
        )}

        {!readonly && (
          <Button variant="icon" className="w-7 h-7" onClick={() => setEditMode(!editMode)}>
            {editMode ? (
              <PlusIcon className="w-4 h-4 rotate-45" />
            ) : (
              <PencilIcon className="w-4 h-4" />
            )}
          </Button>
        )}
      </div>

      {editMode && (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleUpdateVip)} className="space-y-8">
            <div className="py-4 px-1 grid gap-3 grid-cols-2">
              <FormInput control={form.control} name="first_name" label="First Name" />
              <FormInput control={form.control} name="last_name" label="Last Name" />
              <FormInput control={form.control} name="gender" label="Gender" />
              <FormInput control={form.control} name="age" label="Age" />
              <FormDate control={form.control} name="date_of_birth" label="Birth Date" />
              <Button type="submit">Save</Button>
            </div>
          </form>
        </Form>
      )}
    </div>
  );
};

export default VipRow;
