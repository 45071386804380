import { PUSHER_APP_CLUSTER, PUSHER_APP_KEY } from '@/config';
import { get } from '@/utils/api';
import Pusher from 'pusher-js';

export const config = {
  clientKey: PUSHER_APP_KEY,
  cluster: PUSHER_APP_CLUSTER,

  // // optional if you'd like to trigger events. BYO endpoint.
  // // see "Trigger Server" below for more info
  // triggerEndpoint: '/pusher/trigger',

  // required for private/presence channels
  // also sends auth headers to trigger endpoint
  authorizer: ({ name }: { name: string }) => ({
    authorize: async (
      socketId: string,
      callback: (error: Error | null, token: { auth: string } | null) => void,
    ) => {
      const { auth: channelToken } = await get<any>('/broadcasting/auth', {
        channel_name: name,
        socket_id: socketId,
      });

      if (channelToken) {
        callback(null, { auth: channelToken });
      } else {
        callback(new Error('Authentication Failed'), null);
      }
    },
  }),
};

export const pusher = new Pusher(PUSHER_APP_KEY, config);
