'use client';

import { cn } from '@/utils/format';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import * as React from 'react';

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      'peer h-[1.60rem] w-[1.65rem] shrink-0 rounded border border-transparent border-gray-300 bg-white',
      'ring-0 ring-inset focus:ring-1 focus:ring-inset focus:ring-primary-600',
      'disabled:cursor-not-allowed disabled:opacity-50',
      'data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground',
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
      <svg width="14" height="14" viewBox="0 0 24 24" fill="#FFFFFF">
        <title>Checkmark</title>
        <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
      </svg>
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
