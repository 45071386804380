import { useDeleteBlackout } from '@/api/equipment/blackouts/deleteBlackout';
import { useUpdateBlackout } from '@/api/equipment/blackouts/updateBlackout';
import { Button } from '@/components/ui/elements/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/elements/dialog';
import { Form } from '@/components/ui/form/form';
import { ALL_START_TIMES } from '@/config/settings';
import { queryClient } from '@/lib/react-query';
import { Asset, Blackout, ModelID } from '@/types';
import { formatApiDate, formatDate } from '@/utils/format';
import { TrashIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { ConfirmationDialog } from '../Elements/ConfirmationDialog/ConfirmationDialog';
import { FormDate, FormSelect, FormTextarea } from '../Form';
import { blackoutSchema, blackoutTypeOptions, prepareBlackoutData } from './helpers';

const timeOptions = ALL_START_TIMES;

type EditBlackoutModalProps = {
  location: ModelID;
  assets?: Asset[];
  blackout: Blackout;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const prepareDefaults = (blackout: Partial<Blackout> | undefined) => {
  if (!blackout || !blackout.start_at || !blackout.end_at || !blackout.asset_id) {
    return {
      asset_id: '',
      start_date: '',
      start_time: '',
      end_date: '',
      end_time: '',
      reason: '',
      type: '',
    };
  }
};

export const EditBlackoutModal = ({
  location,
  assets,
  blackout,
  open,
  setOpen,
}: EditBlackoutModalProps) => {
  const startDate = formatApiDate(blackout.start_at, 'yyyy-MM-dd');
  const startTime = formatDate(blackout.start_at, 'HH:mm');
  const endDate = formatApiDate(blackout.end_at, 'yyyy-MM-dd');
  let endTime = formatDate(blackout.end_at, 'HH:mm');
  if (endTime === '23:59') {
    endTime = '';
  }

  const defaultValues = {
    asset_id: blackout.asset_id.toString(),
    start_date: startDate,
    start_time: startTime,
    end_date: endDate,
    end_time: endTime,
    reason: blackout.reason ?? '',
    type: blackout.type ?? '',
  };
  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [
        {
          scope: 'calendar-events',
          location,
          entity: 'list',
        },
      ],
    });
    setOpen(false);
    form.reset();
  };

  const { mutate: updateBlackout } = useUpdateBlackout({
    onSuccess: onSuccess,
  });

  const { mutate: deleteBlackout } = useDeleteBlackout({
    onSuccess: onSuccess,
  });

  const handleSubmit = (values: z.infer<typeof blackoutSchema>) => {
    if (blackout.blackout_id)
      updateBlackout({
        id: blackout.blackout_id,
        data: prepareBlackoutData(values),
      });
  };

  const handleDelete = () => {
    if (blackout.blackout_id)
      deleteBlackout({
        id: blackout.blackout_id,
      });
  };

  const form = useForm<z.infer<typeof blackoutSchema>>({
    resolver: zodResolver(blackoutSchema),
    defaultValues,
  });

  const assetOptions = assets?.map((asset) => {
    return { label: asset.name, value: asset.id.toString() };
  });

  useEffect(() => {
    form.reset(defaultValues);

    return () => {
      form.reset();
    };
  }, [blackout]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-3">
            <DialogHeader>
              <DialogTitle>Edit Blackout: {blackout.blackout_id}</DialogTitle>
              <DialogDescription>Editing blackout</DialogDescription>
            </DialogHeader>
            <div className="flex flex-col space-y-2 py-4 pr-4">
              {assetOptions && (
                <FormSelect
                  control={form.control}
                  name="asset_id"
                  options={assetOptions}
                  label="Asset"
                />
              )}
              <FormDate control={form.control} name="start_date" label="Start Date" />

              <FormSelect
                control={form.control}
                options={timeOptions}
                name="start_time"
                label="Start Time"
              />
              <FormDate control={form.control} name="end_date" label="End Date" />
              <FormSelect
                control={form.control}
                options={timeOptions}
                name="end_time"
                label="End Time"
              />
              <FormSelect
                control={form.control}
                name="type"
                options={blackoutTypeOptions}
                label="Type"
              />

              <FormTextarea
                control={form.control}
                name="reason"
                placeholder="Enter notes here..."
              />
            </div>
            <DialogFooter>
              <ConfirmationDialog
                triggerButton={
                  <Button variant="destructive">
                    <TrashIcon className="h-5 w-5" />
                  </Button>
                }
                confirmButtonText="Delete"
                confirmAction={handleDelete}
                title={`Delete blackout from ${blackout.start_at} through ${blackout.end_at}?`}
                body="Are you sure you want to delete this blackout? This action cannot be undone."
              />
              <div className="grow" />
              <Button type="submit">Save</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
