import { Badge } from '@/components/ui/elements/badge';
import { Lead } from '@/types';

interface CompletionBadgeProps {
  lead: Lead;
}

export const CompletionBadge = ({ lead }: CompletionBadgeProps) => {
  return <Badge size="sm">{lead.completion}%</Badge>;
};
