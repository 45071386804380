import {
  DeleteDTO,
  useDeleteMutationFactory,
  UseDeleteMutationProps,
} from '@/lib/react-query/delete';
import { Vip } from '@/types';
import { remove } from '@/utils/api';
import { featureKeys, featurePath, VipSearchParams } from '.';

export type DeleteVipDTO = DeleteDTO<Vip, VipSearchParams>;

const deleteVip = ({ id, data, params }: DeleteVipDTO) => remove(`${featurePath}/${id}`);

export const useDeleteVip = (options?: UseDeleteMutationProps<Vip>) => {
  return useDeleteMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: deleteVip,
    ...options,
  });
};
