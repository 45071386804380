import { featureKeys as calendarFeatureKeys } from '@/api/calendar';
import queryClient from '@/lib/react-query';
import {
  UpdateDTO,
  useUpdateMutationFactory,
  UseUpdateMutationProps,
} from '@/lib/react-query/update';
import { Blackout } from '@/types';
import { update } from '@/utils/api';
import { BlackoutSearchParams, featureKeys, featurePath } from '.';

export type UpdateBlackoutDTO = UpdateDTO<Blackout, BlackoutSearchParams>;

const updateBlackout = ({ data, id, params = {} }: UpdateBlackoutDTO) =>
  update<Blackout>(`${featurePath}/${id}`, data, params);

export const useUpdateBlackout = (options?: UseUpdateMutationProps<Blackout>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: updateBlackout,
    ...options,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: calendarFeatureKeys.all });
      options?.onSuccess?.(data);
    },
  });
};
