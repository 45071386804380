import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { AssetType, ModelID } from '@/types';
import { get } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { AssetTypeSearchParams, featureKeys, featurePath } from '.';

type QueryFnType = typeof fetchAssetTypes;

type UseAssetTypesOptions = {
  location: ModelID;
  search?: AssetTypeSearchParams;
  config?: QueryConfig<QueryFnType>;
};

const defaultSearchParams: AssetTypeSearchParams = {
  orderBy: 'asset_type_id',
  orderDir: 'asc',
  active: true,
};

const ONE_HOUR = 1000 * 60 * 60;

export const fetchAssetTypes = (search: AssetTypeSearchParams) =>
  get<AssetType[]>(`${featurePath}`, search);

export const useAssetTypes = ({
  location,
  search = defaultSearchParams,
  config = {},
}: UseAssetTypesOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    staleTime: ONE_HOUR,
    queryKey: featureKeys.list(location, search),
    queryFn: () => fetchAssetTypes(search),
  });
};
