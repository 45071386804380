import { QueryConfig } from '@/lib/react-query';
import { Lead } from '@/types';
import { get } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { featureKeys, featurePath, LeadSearchParams } from '.';

export interface BookingValidationResponse {
  problems: BookingValidationError[];
}

export interface BookingValidationError {
  type: 'critical' | 'warning';
  message: string;
}

type QueryFnType = typeof getBookingValidation;

type UseBookingValidationOptions = {
  lead: Lead;
  search?: LeadSearchParams;
  config?: QueryConfig<QueryFnType>;
};

const getBookingValidation = async (lead: Lead, search?: LeadSearchParams) => {
  const response = await get<BookingValidationResponse>(
    `${featurePath}/${lead.id}/booking-validation`,
    search,
  );
  return response.problems;
};

export const useBookingValidation = ({
  lead,
  search,
  config = {},
}: UseBookingValidationOptions) => {
  return useQuery<BookingValidationError[]>({
    ...config,
    queryKey: featureKeys.validation(lead.id),
    queryFn: () => getBookingValidation(lead, search),
  });
};
