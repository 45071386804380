import {
  DeleteDTO,
  useDeleteMutationFactory,
  UseDeleteMutationProps,
} from '@/lib/react-query/delete';
import { CalendarNote } from '@/types';
import { remove } from '@/utils/api';
import { featureKeys, featurePath, NoteSearchParams } from '.';

export type DeleteNoteDTO = DeleteDTO<CalendarNote, NoteSearchParams>;

const deleteNote = ({ id, data, params }: DeleteNoteDTO) => remove(`${featurePath}/${id}`);

export const useDeleteNote = (options?: UseDeleteMutationProps<CalendarNote>) => {
  return useDeleteMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: deleteNote,
    ...options,
  });
};
