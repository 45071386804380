import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export const InsufficientPermissions = () => {
  return (
    <div className="flex flex-col w-full border-dashed border-gray-300 m-3 rounded border items-center justify-center text-gray-500 h-80">
      <ExclamationTriangleIcon className="w-16 h-16" />
      <h4>Insufficient Permissions</h4>
      <p>
        You do not have sufficient permissions to view this page. Please contact your supervisor.
      </p>
    </div>
  );
};
