import { cn } from '@/utils/format';

interface CCIProps {
  srcPath: string;
  className?: string;
}
const CCI = ({ srcPath, className = '' }: CCIProps) => {
  return <iframe title="CCI" className={cn('w-full h-[1030px]', className)} src={srcPath} />;
};

export default CCI;
