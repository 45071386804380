import { BulkAssignStaffDTO, useBulkAssignStaff } from '@/api/events/events/assignStaff';
import { FormSelect } from '@/components/Form';
import { Button } from '@/components/ui/elements/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/elements/dialog';
import { Form } from '@/components/ui/form/form';
import { queryClient } from '@/lib/react-query';
import { Asset, LocationUserAccess, ModelID } from '@/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { CalendarDate, DateFormatter, getLocalTimeZone } from '@internationalized/date';
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';

type DayPlannerModalProps = {
  date: CalendarDate;
  location: ModelID;
  open: boolean;
  setOpen: (open: boolean) => void;
  staff: LocationUserAccess[];
  assets: Asset[];
};

const schema = z.object({
  map: z.array(
    z.object({
      asset_id: z.string(),
      name: z.string(),
      assigned_user_id: z.string(),
      secondary_user_id: z.string(),
    }),
  ),
});

export const DayPlannerModal = ({
  date,
  location,
  open,
  setOpen,
  staff,
  assets,
}: DayPlannerModalProps) => {
  const { mutate: assignStaff } = useBulkAssignStaff({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          {
            scope: 'calendar-events',
            location,
            entity: 'list',
          },
        ],
      });
      setOpen(false);
    },
  });

  const handleSubmit = (values: Omit<BulkAssignStaffDTO, 'franchise_id' | 'date'>) => {
    assignStaff({
      franchise_id: location,
      map: values.map,
      date,
    });
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      map: [],
    },
  });

  const { fields, replace } = useFieldArray({
    control: form.control,
    name: 'map',
  });

  useEffect(() => {
    replace(
      assets.map((asset) => ({
        id: asset.id.toString(),
        asset_id: asset.id.toString(),
        name: asset.abbr || asset.name,
        assigned_user_id: '',
        secondary_user_id: '',
      })),
    );

    return () => {
      replace([]);
    };
  }, [assets, replace]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-3">
            <DialogHeader>
              <DialogTitle>
                Assign Staff for{' '}
                {new DateFormatter('en-US', {
                  weekday: 'short',
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                }).format(date.toDate(getLocalTimeZone()))}
              </DialogTitle>
              <DialogDescription>
                Use this form to link staff members to their equipment for the entire day. Note:
                this will not change currently assigned schedules.
              </DialogDescription>
            </DialogHeader>
            <div className="flex flex-col space-y-3 py-4">
              {fields.map((field, index) => (
                <div key={`faq-${field.id}-${index}`} className="flex flex-row space-x-3">
                  <div className="w-16">{field.name}</div>
                  <FormSelect
                    control={form.control}
                    name={`map.${index}.assigned_user_id`}
                    label="Staff"
                    options={staff.map((staffMember) => ({
                      value: staffMember.user.id,
                      label: staffMember.user.full_name,
                    }))}
                  />
                  <FormSelect
                    control={form.control}
                    name={`map.${index}.secondary_user_id`}
                    label="Add'l Staff"
                    options={staff.map((staffMember) => ({
                      value: staffMember.user.id,
                      label: staffMember.user.full_name,
                    }))}
                  />
                </div>
              ))}
            </div>
            <DialogFooter>
              <Button type="submit">Save</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
