import { useTransferEvent } from '@/api/events/events/transferEvent';
import { useLocations } from '@/api/locations/locations/getLocations';
import { FormSelect } from '@/components/Form';
import { Button } from '@/components/ui/elements/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/elements/dialog';
import { Form } from '@/components/ui/form/form';
import queryClient from '@/lib/react-query';
import { LocationContext } from '@/providers/location';
import { Event } from '@/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { DialogProps } from '@radix-ui/react-dialog';
import { useNavigate } from '@tanstack/react-router';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

type TransferEventDialogProps = DialogProps & {
  event: Event;
};

export const TransferEventDialog = ({ event, ...props }: TransferEventDialogProps) => {
  const { activeLocationId } = useContext(LocationContext);
  const navigate = useNavigate();
  const { mutate: transferEvent } = useTransferEvent();
  const { data: locations } = useLocations({
    location: activeLocationId,
    search: {
      active: true,
      orderBy: 'areaop',
      orderDir: 'asc',
    },
  });

  const schema = z.object({
    destination_id: z.coerce.string(),
  });

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      destination_id: '',
    },
  });

  const handleSubmit = (values: z.infer<typeof schema>) => {
    transferEvent(
      {
        id: event.id,
        data: values,
      },
      {
        onSuccess: () => {
          if (props.onOpenChange) props.onOpenChange(false);
          form.reset();
          queryClient.invalidateQueries({
            queryKey: [{ scope: 'events', location: event.franchise_id }],
          });
          queryClient.invalidateQueries({
            queryKey: [{ scope: 'calendar-events', location: event.franchise_id }],
          });
          navigate({ to: '/events/upcoming' });
        },
      },
    );
  };

  const locationOptions =
    locations
      ?.filter((location) => location.id !== event.franchise_id)
      .map((location) => ({
        label: location.areaop,
        value: location.id,
      })) ?? [];

  return (
    <Dialog {...props}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Transfer Event: {event.name}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
            <FormSelect
              control={form.control}
              name="destination_id"
              label="Receiving Location"
              options={locationOptions}
            />

            <DialogFooter>
              <Button type="submit">Transfer the Event</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
