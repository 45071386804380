import {
  UpdateDTO,
  useUpdateMutationFactory,
  UseUpdateMutationProps,
} from '@/lib/react-query/update';
import { Customer } from '@/types';
import { update } from '@/utils/api';
import { CustomerSearchParams, featureKeys, featurePath } from '.';

export type UpdateCustomerDTO = UpdateDTO<Customer, CustomerSearchParams>;

const updateCustomer = ({ data, id, params = {} }: UpdateCustomerDTO) =>
  update<Customer>(`${featurePath}/${id}`, data, params);

export const useUpdateCustomer = (options?: UseUpdateMutationProps<Customer>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: updateCustomer,
    ...options,
  });
};
