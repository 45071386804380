import queryClient from '@/lib/react-query';
import {
  genericUpdateOnSuccessMergeStrategy,
  UpdateDTO,
  useUpdateMutationFactory,
  UseUpdateMutationProps,
} from '@/lib/react-query/update';
import { Payment } from '@/types';
import { post } from '@/utils/api';
import { featureKeys, featurePath, PaymentSearchParams } from '.';

export type UpdatePaymentDTO = UpdateDTO<Payment, PaymentSearchParams>;

const updatePayment = ({ data, id, params = {} }: UpdatePaymentDTO) =>
  post<Payment>(`${featurePath}/${id}`, data, params);

export const useUpdatePayment = (options?: UseUpdateMutationProps<Payment>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: updatePayment,
    ...options,
    onSuccess: (newData) => {
      genericUpdateOnSuccessMergeStrategy(featureKeys, newData);
      queryClient.setQueriesData(
        { queryKey: [{ entity: 'detail', id: newData.event_id, scope: 'events' }] },
        (previous: any) => {
          if (previous?.payments) {
            previous.payments = previous.payments.map((payment: Payment) => {
              if (payment.id === newData.id) {
                return { ...newData };
              }
              return { ...payment };
            });
          }
        },
      );
    },
  });
};
