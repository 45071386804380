import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Blackout, CalendarEvent, ModelID } from '@/types';
import { post } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { CalendarEventSearchParams, featureKeys, featurePath } from '.';

type QueryFnType = typeof fetchCalendarEvents;

type UseCalendarEventsOptions = {
  location: ModelID;
  search: CalendarEventSearchParams;
  config?: QueryConfig<QueryFnType>;
};

export type CalendarData = {
  events: CalendarEvent[];
  blackouts: Blackout[];
};

export const fetchCalendarEvents = (location: ModelID, search: CalendarEventSearchParams) =>
  post<CalendarData>(`${featurePath}`, {
    franchise_id: location,
    start: search.start.toString(),
    end: search.end.toString(),
  });

export const useCalendarEvents = ({ location, search, config = {} }: UseCalendarEventsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    staleTime: 50,
    queryKey: featureKeys.list(location, {
      ...search,
      start: search.start.toString(),
      end: search.end.toString(),
    }),
    queryFn: () => fetchCalendarEvents(location, search),
  });
};
