import {
  ArrowTopRightOnSquareIcon,
  BookOpenIcon,
  BuildingOffice2Icon,
  CalendarDaysIcon,
  CalendarIcon,
  ChartBarIcon,
  ChatBubbleLeftIcon,
  CogIcon,
  HomeIcon,
  MapIcon,
  PhoneIcon,
  QuestionMarkCircleIcon,
  ShoppingCartIcon,
  TruckIcon,
  UserCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { ForwardRefExoticComponent, RefAttributes, SVGProps } from 'react';

export type MainMenuItem = {
  label: string;
  icon: ForwardRefExoticComponent<
    Omit<SVGProps<SVGSVGElement>, 'ref'> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & RefAttributes<SVGSVGElement>
  >;
  path: string;
  access_rights: string[];
  active?: boolean;
  search?: Record<string, string>;
};

export const MainMenuItems = [
  {
    label: 'Dashboard',
    icon: HomeIcon,
    path: '/',
    access_rights: ['dashboard.view'],
  },
  {
    label: 'Calendar',
    icon: CalendarIcon,
    path: '/calendar',
    access_rights: ['location.active', 'calendar.view'],
  },
  {
    label: 'Scheduler',
    icon: UserGroupIcon,
    path: '/scheduler',
    access_rights: ['location.active', 'scheduler.view'],
  },
  {
    label: 'Map',
    icon: MapIcon,
    path: '/map/leads',
    access_rights: ['location.active', 'map.view'],
  },
  {
    label: 'Messages',
    icon: ChatBubbleLeftIcon,
    path: '/conversations',
    access_rights: ['messages.view'],
    search: { filter: 'unread' },
  },
  {
    label: 'Leads',
    icon: PhoneIcon,
    path: '/leads/inbox',
    access_rights: ['leads.view'],
  },
  {
    label: 'Events',
    icon: CalendarDaysIcon,
    path: '/events/upcoming',
    access_rights: ['events.view'],
  },
  {
    label: 'Customers',
    icon: UserCircleIcon,
    path: '/customers/recent',
    access_rights: ['customers.view'],
  },
  {
    label: 'Products',
    icon: ShoppingCartIcon,
    path: '/products/services',
    access_rights: ['location.active', 'products.view'],
  },
  {
    label: 'Equipment',
    icon: TruckIcon,
    path: '/equipment/assets',
    access_rights: ['location.active', 'equipment.view'],
  },
  {
    label: 'Settings',
    icon: CogIcon,
    path: '/settings/general',
    access_rights: ['location.active', 'settings.view'],
  },
  {
    label: 'Reports',
    icon: ChartBarIcon,
    path: '/reports/dashboard',
    access_rights: ['reports.view'],
  },
  {
    label: 'Resources',
    icon: BookOpenIcon,
    path: '/resources/directory',
    access_rights: ['resources.view'],
  },
  {
    label: 'Help',
    icon: QuestionMarkCircleIcon,
    path: '/help',
    access_rights: ['help.view'],
  },
] as MainMenuItem[];

export const SecondaryMenuItems = [
  {
    label: 'Franchisor',
    icon: BuildingOffice2Icon,
    path: 'https://control.gametruck.com',
    access_rights: ['franchisor.view'],
  },
  {
    label: 'Stripe Dashboard',
    icon: ArrowTopRightOnSquareIcon,
    path: 'https://dashboard.stripe.com',
    access_rights: ['payments.create'],
  },
];
