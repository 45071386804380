import { ScrollableLayout } from '@/components/Layout';
import Page from '@/components/Layout/Page';
import { PolicyGate } from '@/features/auth/authorization';
import { AuthContext } from '@/providers/auth';
import { useContext } from 'react';
import { DashboardKpis } from '../components/DashboardKpis';
import { EventLookup } from '../components/EventLookup';
import { EventsToday } from '../components/EventsToday';
import { LeadLookup } from '../components/LeadLookup';
import { LeadsInbox } from '../components/LeadsInbox';
import { MessageOfTheDay } from '../components/MessageOfTheDay';
import { NewLeadForm } from '../components/NewLeadForm';
import { NewsFeed } from '../components/NewsFeed';

/** @Feature: Sales Stats */

export const Dashboard = () => {
  const { user } = useContext(AuthContext);

  if (!user) return null;

  return (
    <ScrollableLayout>
      <Page>
        <Page.Header className="mx-4 my-1">
          <Page.HeaderContent title={`Welcome, ${user?.first_name}!`} />
        </Page.Header>
        <Page.Body policy="dashboard.view" className="@container space-y-4 p-4">
          <PolicyGate policy="dashboard.motd">
            <MessageOfTheDay />
          </PolicyGate>
          <div className="grid grid-cols-1 @4xl:grid-cols-2 gap-2">
            <PolicyGate policy="leads.create">
              <NewLeadForm />
            </PolicyGate>
            <div className="grid grid-cols-1 @lg:grid-cols-2 gap-2">
              <PolicyGate policy="leads.view">
                <LeadLookup />
              </PolicyGate>
              <PolicyGate policy="events.view">
                <EventLookup />
              </PolicyGate>
            </div>
          </div>
          <PolicyGate policy="reports.view">
            <DashboardKpis />
          </PolicyGate>
          <div className="grid grid-cols-1 @2xl:grid-cols-3 gap-2">
            <PolicyGate policy="leads.view">
              <LeadsInbox />
            </PolicyGate>
            <PolicyGate policy="events.view">
              <EventsToday />
            </PolicyGate>
            <PolicyGate policy="dashboard.news">
              <NewsFeed />
            </PolicyGate>
          </div>
        </Page.Body>
      </Page>
    </ScrollableLayout>
  );
};
