import { Button } from '@/components/ui/elements/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/elements/dialog';
import { Input } from '@/components/ui/elements/input';
import { Label } from '@/components/ui/elements/label';
import { DialogProps } from '@radix-ui/react-dialog';
import { useState } from 'react';

type RequestPaymentDialogProps = DialogProps & {
  onSubmit: (data: { amount: number | null }) => void;
};

export const RequestPaymentDialog = ({ onSubmit, ...props }: RequestPaymentDialogProps) => {
  const [amount, setAmount] = useState<number | null>(null);

  const handleSubmit = () => {
    onSubmit({
      amount,
    });
    setAmount(null);
    if (props.onOpenChange) props.onOpenChange(false);
  };

  return (
    <Dialog {...props}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Send Payment Link</DialogTitle>
          <DialogDescription>
            Sends an email and/or text message to the customer with a link to our online payment
            form, allowing them to make a payment towards their event. You may optional prefill the
            amount for them as well.
          </DialogDescription>
        </DialogHeader>
        <span className="flex flex-col justify-start space-y-3 py-4">
          <Label>Amount (optional)</Label>
          <Input
            name="amount"
            type="number"
            defaultValue={amount?.toString()}
            onChange={(e) => setAmount(parseFloat(e.currentTarget.value))}
          />
        </span>
        <DialogFooter>
          <Button onClick={handleSubmit}>Send Payment Link</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
