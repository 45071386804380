import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';

export const GlobalLoadingIndicator = () => {
  const ref = useRef<LoadingBarRef>(null);
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  useEffect(() => {
    if (ref.current && (isFetching || isMutating)) {
      ref.current.continuousStart();
    }

    if (ref.current && !isFetching && !isMutating) {
      ref.current.complete();
    }

    return () => {
      if (ref.current) {
        ref.current.complete();
      }
    };
  }, [isFetching, isMutating]);

  return <LoadingBar color="#7aa52b" shadow={false} ref={ref} />;
};
