import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ModelID, Service } from '@/types';
import { get } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { featureKeys, featurePath, ServiceSearchParams } from '.';

type QueryFnType = typeof fetchServices;

type UseServicesOptions = {
  location: ModelID;
  search?: ServiceSearchParams;
  config?: QueryConfig<QueryFnType>;
};

const ONE_HOUR = 1000 * 60 * 60;

export const fetchServices = (location: ModelID, search: ServiceSearchParams = {}) =>
  get<Service[]>(`${featurePath}?franchise_id=${location}`, search);

export const useServices = ({ location, search = {}, config = {} }: UseServicesOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    staleTime: ONE_HOUR,
    queryKey: featureKeys.list(location, search),
    queryFn: () => fetchServices(location, search),
  });
};
