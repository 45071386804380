import { CustomerSearchParams } from '@/api/customers/customers';
import { FormCheckbox, FormDate, FormInput, FormSelect } from '@/components/Form';
import { FormMultiSelect } from '@/components/Form/FormMultiSelect';
import SearchForm, { defaultSearchSchema } from '@/components/SearchForm/SearchForm';
import { Button } from '@/components/ui/elements/button';
import { CUSTOMER_BANK_TYPE_OPTIONS, CUSTOMER_BANK_TYPE_VALUES } from '@/config';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useOrganizationTypesFieldArray } from '../hooks/useOrganizationTypesFieldArray';

const schema = defaultSearchSchema.merge(
  z.object({
    name: z.string().nullish(),
    phone: z.string().nullish(),
    email: z.string().nullish(),
    organization_types: z
      .array(
        z.object({
          value: z.string(),
          label: z.string(),
          checked: z.boolean().default(false),
        }),
      )
      .nullish(),
    bank: z.enum(CUSTOMER_BANK_TYPE_VALUES).nullish(),
    lastcontact_at_begin: z.string().nullish(),
    lastcontact_at_end: z.string().nullish(),
    followup_at_begin: z.string().nullish(),
    followup_at_end: z.string().nullish(),
    query_all_locations: z.coerce.boolean().nullish(),
    is_past_customer: z.coerce.boolean().nullish(),
  }),
);

type CustomerSearchFormProps = {
  onSubmit: (data: any) => void;
  refetch?: () => void;
  defaultValues: CustomerSearchParams;
  recordsFound?: number;
  children?: React.ReactNode;
};

export const CustomerSearchForm = ({
  onSubmit,
  refetch,
  defaultValues,
  recordsFound,
  children,
}: CustomerSearchFormProps) => {
  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      keyword: '',
      ...defaultValues,
      name: defaultValues.name ?? '',
      phone: defaultValues.phone ?? '',
      email: defaultValues.email ?? '',
      organization_types: [],
      bank: defaultValues.bank ?? null,
      lastcontact_at_begin: defaultValues.lastcontact_at_begin ?? '',
      lastcontact_at_end: defaultValues.lastcontact_at_end ?? '',
      followup_at_begin: defaultValues.followup_at_begin ?? '',
      followup_at_end: defaultValues.followup_at_end ?? '',
      query_all_locations: false,
      is_past_customer: false,
    },
  });
  const {
    fieldArray: organizationTypeFieldArray,
    formatter: organizationTypeFormatter,
    defaultOptions: organizationDefaultOptions,
  } = useOrganizationTypesFieldArray({
    control: form.control,
    defaultValues: defaultValues,
  });

  const handleSearch = () => {
    const _values = form.getValues();
    onSubmit({
      ..._values,
      organization_types:
        _values.organization_types
          ?.filter((type: any) => type.checked)
          .map((type: any) => type.value) ?? undefined,
    });
  };

  useEffect(() => {
    form.reset(
      { ...defaultValues, organization_types: organizationDefaultOptions },
      {
        keepDirtyValues: false,
        keepSubmitCount: true,
      },
    );
  }, [defaultValues]);

  return (
    <SearchForm form={form} onSubmit={handleSearch} hasAdvanced className="m-4">
      <SearchForm.KeywordInput />
      <SearchForm.SearchChips formatters={[organizationTypeFormatter]} />
      <SearchForm.AdvancedOptions>
        <div className="flex flex-col space-y-3">
          <div className="grid lg:grid-cols-3 gap-2">
            <FormInput control={form.control} name="name" label="Customer Name" />
            <FormInput control={form.control} name="phone" label="Phone" />
            <FormInput control={form.control} name="email" label="Email" />
          </div>
          <div className="grid md:grid-cols-2 gap-2">
            <FormMultiSelect<z.infer<typeof schema>, 'organization_types'>
              control={form.control}
              fieldArray={organizationTypeFieldArray}
              name="organization_types"
              label="Organization Type"
              placeholder="All"
            />
            <FormSelect
              control={form.control}
              name="bank"
              label="Relationship Bank"
              options={CUSTOMER_BANK_TYPE_OPTIONS}
            />
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-2">
            <FormDate
              control={form.control}
              name="lastcontact_at_begin"
              label="Last Contact (From)"
            />
            <FormDate
              control={form.control}
              name="lastcontact_at_end"
              label="Last Contact (Until)"
            />
            <FormDate control={form.control} name="followup_at_begin" label="Follow Up (From)" />
            <FormDate control={form.control} name="followup_at_end" label="Follow Up (Until)" />
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-2">
            <FormCheckbox
              control={form.control}
              name="query_all_locations"
              label="System-wide Search"
            />
            <FormCheckbox
              control={form.control}
              name="is_past_customer"
              label="Only Past Customers"
            />
          </div>
          <div className="text-right">
            <Button type="submit">Search</Button>
          </div>
        </div>
      </SearchForm.AdvancedOptions>

      <div className="flex flex-col md:flex-row space-x-2 items-center justify-between pt-3">
        {children}

        <SearchForm.RecordsFound recordCount={recordsFound} />
        {refetch && <SearchForm.Refetch refetch={refetch} />}
        <SearchForm.OrderBy
          options={[
            // { value: 'start_at', label: 'Event Date' },
            { value: 'created_at', label: 'Date Added' },
            { value: 'updated_at', label: 'Last Update' },
            { value: 'followup_at', label: 'Follow Up Date' },
          ]}
        />
        <SearchForm.PageLimit />
        <SearchForm.OrderDirection />
      </div>
    </SearchForm>
  );
};
