import { Dictionary, ModelID } from '@/types';
import {
  CalendarDate,
  CalendarDateTime,
  getLocalTimeZone,
  now,
  parseDate,
  ZonedDateTime,
} from '@internationalized/date';
import { clsx, type ClassValue } from 'clsx';
import { format } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import { twMerge } from 'tailwind-merge';

export const phoneRegex = new RegExp(/^(\+1\s?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/);

export const getTimezone = () => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return tz;
};

export const formatUTCDate = (date: string, dateFormat = 'MM/dd/yyyy h:mm a') => {
  const utcDate = new Date(date);
  const offsetMinutes = utcDate.getTimezoneOffset();

  return format(new Date(utcDate.getTime() - offsetMinutes * 60 * 1000), dateFormat);
};

export const formatApiDate = (date: string, dateFormat = 'MM/dd/yyyy h:mm a') => {
  if (!date) return undefined;
  return format(dateFromApiDateTime(date.replace(' ', 'T')), dateFormat);
};

export const formatDate = (date: Date | string | number, dateFormat = 'MM/dd/yyyy h:mm a') => {
  if (!date) return undefined;

  try {
    if (isValidDate(date)) {
      return format(date as Date, dateFormat);
    }

    if (typeof date === 'number') {
      return format(new Date(date), dateFormat);
    }

    if (typeof date === 'string') {
      return format(new Date(date.replace(' ', 'T')), dateFormat);
    }
  } catch (e) {
    console.error('formatDate: invalid date', e);
    return '??/??/????';
  }
};

export const calendarDatetoString = (date: CalendarDate | CalendarDateTime | ZonedDateTime) => {
  return `${date.year.toString().padEnd(4, '0')}-${date.month
    .toString()
    .padStart(2, '0')}-${date.day.toString().padStart(2, '0')}`;
};

export const calendarDateToDate = (date: CalendarDate | CalendarDateTime | ZonedDateTime) => {
  const tz = getTimezone();
  return date.toDate(tz);
};

export const dateToCalendarDate = (date: Date) => {
  return new CalendarDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
};

export const apiDateToCalendarDate = (date: string) => {
  const parseable = date.split(' ')[0];
  return parseDate(parseable);
};

export const todayAsCalendarDate = () => {
  const nowDT = now(getLocalTimeZone());
  return new CalendarDate(nowDT.year, nowDT.month, nowDT.day);
};

export const stringToCalendarDate = (date: string | null) => {
  if (!date) return undefined;
  const [year, month, day] = date.split('-');
  return new CalendarDate(parseInt(year), parseInt(month), parseInt(day));
};

export const dateFromApiDateTime = (date: string) => {
  try {
    const phxOffset = getTimezoneOffset('America/Phoenix', new Date(date.replace(' ', 'T')));
    // const localOffset = new Date().getTimezoneOffset() * 60 * 1000 * -1;

    // const timeOffset = phxOffset - localOffset;
    const hoursOffset = Math.abs(Math.floor(phxOffset / 60 / 60 / 1000));

    const dateTZ = `${date.replace(' ', 'T')}-0${hoursOffset}:00`;
    const newTZDate = new Date(dateTZ);

    return newTZDate;
  } catch (e) {
    // Report to Sentry
    console.error('dateFromApiDateTime: invalid date', e);
    return new Date(date);
  }
};

export function mapDictionary<T, S>(
  inputDictionary: Dictionary<T>,
  mapFunction: (original: T, key: string) => S,
): any[] {
  const outDictionary: Dictionary<S> = {};
  const outArray = [];
  for (const k of Object.keys(inputDictionary)) {
    const thisVal = inputDictionary[k];
    outDictionary[k] = mapFunction(thisVal, k);
    outArray.push(mapFunction(thisVal, k));
  }
  return outArray;
}

export function removeEmptyFields(data: Record<string, any>) {
  Object.keys(data).forEach((key: string) => {
    if (data[key] === '' || data[key] == null) {
      delete data[key];
    }
  });

  return data;
}

export function modelID(id: any): ModelID {
  return id as ModelID;
}

export const titleCase = (s: string) =>
  s
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_, c) => ` ${c.toUpperCase()}`);

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const formatMoney = (value: number) => {
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const formatInteger = (value: number) => {
  return value.toLocaleString('en-US', {
    style: 'decimal',
  });
};

export const mapsAreEqual = (m1: Map<string, unknown>, m2: Map<string, unknown>) =>
  m1.size === m2.size && Array.from(m1.keys()).every((key) => m1.get(key) === m2.get(key));

export const truncateText = (text: string, length: number) => {
  if (text.length <= length) return text;
  return `${text.slice(0, length)}...`;
};

function isValidDate(date: any): boolean {
  return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
}
