import { PolicyGate } from '@/features/auth/authorization';
import { InsufficientPermissions } from '@/features/auth/components/InsufficientPermissions';
import { cn } from '@/utils/format';
import { createContext } from 'react';
import { Head } from '../Head';

type PageProps = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  className?: string;
};

type PageHeaderContentProps = React.HTMLAttributes<HTMLDivElement> & {
  children?: React.ReactNode;
  className?: string;
};

type PageBodyProps = PageProps & {
  policy?: string;
};

type PageContextProps = object;

const PageContext = createContext<PageContextProps>({});

const Page = ({ children, className, ...props }: PageProps) => {
  return (
    <PageContext.Provider value={{}}>
      <div className={cn('w-full py-4 px-0', className)} {...props}>
        {children}
      </div>
    </PageContext.Provider>
  );
};

const Header = ({ children, className, ...props }: PageProps) => {
  return (
    <div
      className={cn(
        'flex flex-col md:flex-row md:items-center md:justify-between space-y-3 md:space-y-0',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

const HeaderContent = ({ children, title, className, ...props }: PageHeaderContentProps) => {
  return (
    <div className={cn('', className)} {...props}>
      <Title>{title}</Title>
      <Head title={title} />
      {children && <Description>{children}</Description>}
    </div>
  );
};

const Title = ({ children, className, ...props }: PageProps) => {
  return (
    <h1 className="text-xl font-medium leading-6 text-gray-900" {...props}>
      {children}
    </h1>
  );
};

const Description = ({ children, className, ...props }: PageProps) => {
  return (
    <p className={cn('mt-2 text-sm text-gray-700', className)} {...props}>
      {children}
    </p>
  );
};

const HeaderAction = ({ children, className, ...props }: PageProps) => {
  return (
    <div className={cn('flex', className)} {...props}>
      {children}
    </div>
  );
};

const Body = ({ children, className, policy, ...props }: PageBodyProps) => {
  return policy ? (
    <>
      <PolicyGate policy={policy} forbiddenFallback={<InsufficientPermissions />}>
        <div className={cn('py-4', className)} {...props}>
          {children}
        </div>
      </PolicyGate>
    </>
  ) : (
    <div className={cn('py-4', className)} {...props}>
      {children}
    </div>
  );
};

Page.Header = Header;
Page.HeaderContent = HeaderContent;
Page.HeaderAction = HeaderAction;
Page.Body = Body;

Page.displayName = 'Page';

export default Page;
