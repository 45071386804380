import CCI from '@/components/Location/CCI';
import { EventContext } from '@/features/events/contexts/EventContext';
import { useContext } from 'react';

export const CciTab = () => {
  const { event } = useContext(EventContext);

  if (!event) return <>Loading...</>;

  if (!event.franchise.cci_sheet_path) return <>No CCI sheet configured.</>;

  return <CCI srcPath={event.franchise.cci_sheet_path} />;
};
