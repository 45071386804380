export const {
  MODE: ENVIRONMENT,
  VITE_APP_VERSION: APP_VERSION,
  VITE_APP_API_URL: API_URL,
  VITE_APP_API_VERSION: API_VERSION,
  VITE_APP_SENTRY_DSN: SENTRY_DSN,
  VITE_APP_STRIPE_CLIENT_ID: STRIPE_CLIENT_ID,
  VITE_APP_STRIPE_KEY: STRIPE_KEY,
  VITE_APP_MAPBOX_KEY: MAPBOX_KEY,
  VITE_APP_FULLCALENDAR_LICENSE_KEY: FULLCALENDAR_LICENSE_KEY,
  VITE_APP_PUSHER_APP_ID: PUSHER_APP_ID,
  VITE_APP_PUSHER_APP_KEY: PUSHER_APP_KEY,
  VITE_APP_PUSHER_CLUSTER: PUSHER_APP_CLUSTER,
  VITE_APP_PUSHER_BEAMS_INSTANCE_ID: PUSHER_BEAMS_INSTANCE_ID,
  VITE_APP_TREMENDOUS_DOMAIN: TREMENDOUS_DOMAIN,
  VITE_APP_TREMENDOUS_CLIENT_ID: TREMENDOUS_CLIENT_ID,
  VITE_APP_TREMENDOUS_REDIRECT_URI: TREMENDOUS_REDIRECT_URI,
  VITE_APP_FIREBASE_API_KEY: FIREBASE_API_KEY,
  VITE_APP_FIREBASE_AUTH_DOMAIN: FIREBASE_AUTH_DOMAIN,
  VITE_APP_FIREBASE_PROJECT_ID: FIREBASE_PROJECT_ID,
  VITE_APP_FIREBASE_STORAGE_BUCKET: FIREBASE_STORAGE_BUCKET,
  VITE_APP_FIREBASE_MESSAGING_SENDER_ID: FIREBASE_MESSAGING_SENDER_ID,
  VITE_APP_FIREBASE_APP_ID: FIREBASE_APP_ID,
  VITE_APP_FIREBASE_VAPID_KEY: FIREBASE_VAPID_KEY,
  VITE_APP_SUPABASE_URL: SUPABASE_URL,
  VITE_APP_SUPABASE_ANON_KEY: SUPABASE_ANON_KEY,
  VITE_APP_INSTAGRAM_CLIENT_ID: INSTAGRAM_CLIENT_ID,
  VITE_APP_INSTAGRAM_REDIRECT_URI: INSTAGRAM_REDIRECT_URI,
} = import.meta.env;
