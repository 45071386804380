import {
  CreateDTO,
  useCreateMutationFactory,
  UseCreateMutationProps,
} from '@/lib/react-query/create';
import { Customer, ModelID } from '@/types';
import { create } from '@/utils/api';
import { CustomerSearchParams, featureKeys, featurePath } from '.';

export type CreateCustomerDTO = { location: ModelID } & CreateDTO<Customer, CustomerSearchParams>;

const createCustomer = ({ location, data, params = {} }: CreateCustomerDTO) =>
  create<Customer>(`${featurePath}`, { franchise_id: location, ...data }, params);

export const useCreateCustomer = (options?: UseCreateMutationProps<Customer>) => {
  return useCreateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: createCustomer,
    ...options,
  });
};
