import { PolicyCheck } from '@/features/auth/authorization';
import { CustomerListPolicies } from './lists';

export const CustomerPolicies: Record<string, PolicyCheck> = {
  'customers.view': ({ acl, user, meta }) => {
    return acl.customers.view;
  },
  'customers.update': ({ acl, user, meta }) => {
    return acl.customers.edit;
  },
  'customers.history': ({ acl, user, meta }) => {
    return acl.customers.history;
  },
  'customers.rewards.view': ({ acl, user, meta }) => {
    return acl.customers.rewards.view;
  },
  'customers.rewards.approve': ({ acl, user, meta }) => {
    return acl.customers.rewards.approve;
  },
  ...CustomerListPolicies,
};
