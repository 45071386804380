import QRCodeStyling, { Options } from 'qr-code-styling';
import { useEffect, useRef } from 'react';

export const useQrCode = (options: Options) => {
  const qrCode = new QRCodeStyling(options);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current);
    }
  }, [qrCode, ref]);

  useEffect(() => {
    if (!qrCode) return;
    qrCode.update(options);
  }, [qrCode, options]);

  return {
    ref,
    qrCode,
  };
};
