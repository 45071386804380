import { router } from '@/router';
import { removeEmptyFields } from '@/utils/format';
import { AnyRoute, useNavigate, useSearch } from '@tanstack/react-router';

type UseSearchParamsReturn<T> = {
  search: T;
  setSearchParams: (values: Partial<T>) => void;
  resetSearchParams: () => void;
};

export const useSearchParams = <T>(): UseSearchParamsReturn<T> => {
  const navigate = useNavigate();
  const search = useSearch<AnyRoute, true, boolean, T>({
    strict: false,
  });

  // @todo: remove the any type
  const setSearchParams = (values: Partial<T> | any) => {
    values.page = 1;
    navigate({ search: removeEmptyFields(values) });
  };

  const resetSearchParams = () => {
    navigate({ to: router.state.location.pathname, search: {}, params: {} });
  };

  return { search, setSearchParams, resetSearchParams };
};
