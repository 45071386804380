import { cn } from '@/utils/format';
import { cva, VariantProps } from 'cva';
import * as React from 'react';

const inputVariants = cva(
  [
    'flex w-full',
    'rounded-sm',
    'text-md file:text-sm file:font-medium sm:text-sm sm:leading-6',
    'text-gray-900 placeholder:text-muted-foreground',
    'ring-0 ring-inset focus:ring-1 focus:ring-inset focus:ring-primary-600',
    'disabled:cursor-not-allowed disabled:text-gray-400',
  ],
  {
    variants: {
      variant: {
        default: 'shadow-sm border border-gray-300 bg-white',
        secondary: 'shadow-sm border-0 bg-white',
        outline:
          'border border-input bg-transparent file:border-0 file:bg-transparent date:border-0 date:bg-transparent',
        destructive: 'border border-red-500 bg-white',
        ghost:
          'bg-transparent border-0 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
      },
      size: {
        xs: '',
        sm: '',
        md: 'h-10 px-3 py-2',
        lg: '',
        xl: '',
      },
    },
    compoundVariants: [],
    defaultVariants: {
      variant: 'default',
      size: 'md',
    },
  },
);

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>,
    VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ variant, size, className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(inputVariants({ variant, size }), className)}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = 'Input';

export { inputVariants, Input };
