import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { CustomerList, ModelID } from '@/types';
import { get } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { CustomerListSearchParams, featureKeys, featurePath } from '.';

type QueryFnType = typeof fetchCustomerLists;

type UseCustomerListsOptions = {
  location: ModelID;
  search?: CustomerListSearchParams;
  config?: QueryConfig<QueryFnType>;
};

const sixHours = 1000 * 60 * 60 * 6;

export const fetchCustomerLists = (location: ModelID, search: CustomerListSearchParams = {}) =>
  get<CustomerList[]>(`${featurePath}?franchise_id=${location}`, search);

export const useCustomerLists = ({
  location,
  search = {},
  config = {},
}: UseCustomerListsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: featureKeys.list(location, search),
    queryFn: () => fetchCustomerLists(location, search),
    staleTime: sixHours,
  });
};
