import { PolicyCheck } from '@/features/auth/authorization';
import { AssetPolicies } from './assets';
import { BlackoutPolicies } from './blackouts';
import { DepotPolicies } from './depots';
import { InspectionsPolicies } from './inspections';
import { MaintenancePolicies } from './maintenance';
import { VehiclePolicies } from './vehicles';

export const EquipmentPolicies: Record<string, PolicyCheck> = {
  'equipment.view': ({ acl, user, meta }) => {
    return acl.equipment.view;
  },
  ...AssetPolicies,
  ...VehiclePolicies,
  ...DepotPolicies,
  ...BlackoutPolicies,
  ...MaintenancePolicies,
  ...InspectionsPolicies,
};
