import { useClearWait } from '@/api/leads/leads/updateLead';
import { PastCustomerBadge } from '@/components/Customer/PastCustomerBadge';
import { EmailButton, Link, PhoneButton } from '@/components/Elements';
import AreaOpBadge from '@/components/Location/AreaOpBadge';
import { NotesTimeline } from '@/components/NotesTimeline';
import { Button } from '@/components/ui/elements/button';
import { LocationContext } from '@/providers/location';
import { Lead } from '@/types';
import { cn, formatApiDate, formatMoney } from '@/utils/format';
import { openGoogleMap } from '@/utils/logic';
import { ArrowTopRightOnSquareIcon, ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import {
  ChatBubbleLeftRightIcon as ChatBubbleLeftRightIconFilled,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/solid';
import { formatDistanceToNow, fromUnixTime } from 'date-fns';
import { useContext, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { ArchiveContextMenu } from '../ArchiveContextMenu';
import { CompletionBadge } from '../Badges/CompletionBadge';
import { ConditionBadge } from '../Badges/ConditionBadge';
import { IdBadge } from '../Badges/IdBadge';
import { LockedBadge } from '../Badges/LockedBadge';
import { FollowUpContextMenu } from '../FollowUpContextMenu';
import { LogContactContextMenu } from '../LogContactContextMenu';
import { NewNoteForm } from '../NewNoteForm';
import { PriorityFlag } from '../PriorityFlag';
import { StatusContextMenu } from '../StatusContextMenu';

interface LeadResultProps {
  lead: Lead;
}

export const LeadResult = ({ lead }: LeadResultProps) => {
  const { activeLocationId } = useContext(LocationContext);
  const { mutate: clearWait } = useClearWait();
  const { notes, customer } = lead;
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className={cn('pb-0 border-y bg-white text-sm', expanded ? 'm-4 border shadow-lg' : 'my-0')}
    >
      <div className="flex bg-gray-50 border-b border-b-gray-100 transition-all">
        <span
          className={cn(
            'w-5 bg-gray-400',
            lead.condition === 'action-required' ? 'bg-orange-600' : '',
            lead.condition === 'pending' ? 'bg-yellow-600 ' : '',
            lead.condition === 'resolved' ? 'bg-green-600 ' : '',
            lead.status === 'Closed' ? 'bg-gray-600 ' : '',
          )}
        >
          <PastCustomerBadge customer={customer} className="text-white mt-4" />
        </span>

        <span className="w-8 h-full py-3 pl-1 bg-gray-50 text-gray-500">
          <PriorityFlag lead={lead} />
        </span>

        <div
          className={cn(
            isMobileOnly ? 'max-w-[240px]' : 'max-w-full',
            'w-full font-medium whitespace-nowrap overflow-x-hidden overflow-y-hidden',
          )}
        >
          <div
            className="py-3 space-x-1 @container cursor-pointer"
            onClick={() => setExpanded(!expanded)}
          >
            <CompletionBadge lead={lead} />
            <IdBadge lead={lead} />
            <ConditionBadge lead={lead} />
            {activeLocationId === 0 && <AreaOpBadge location={lead.franchise} />}
            <LockedBadge lead={lead} />
          </div>
        </div>

        <Link variant="link" to="/leads/lead/$id/messages" params={{ id: lead.id }} className="p-0">
          {lead.waiting_since ? (
            <ChatBubbleLeftRightIconFilled className="w-12 h-full p-3.5 bg-gray-50 text-gray-500 hover:bg-gray-100 hover:text-gray-700" />
          ) : (
            <ChatBubbleLeftRightIcon className="w-12 h-full p-3.5 bg-gray-50 text-gray-500 hover:bg-gray-100 hover:text-gray-700" />
          )}
        </Link>

        <Link
          variant="link"
          to={lead.transfer_pending ? '/leads/lead/$id/accept-transfer' : '/leads/lead/$id/quote'}
          params={{ id: lead.id }}
          className="p-0"
        >
          <ChevronDoubleRightIcon className="w-12 h-full p-3.5 bg-gray-50 text-gray-500 hover:bg-gray-100 hover:text-gray-700" />
        </Link>
      </div>

      <div className="flex flex-col md:flex-row px-2 py-2">
        <div className="flex flex-col basis-1/3 px-2 py-2 space-y-1">
          <div className="flex flex-col w-full max-w-[220px] space-y-2">
            {lead.event_type_id > 2 ? (
              <span className="font-semibold">
                {customer?.organization_name ?? customer?.full_name ?? lead.name}
              </span>
            ) : (
              <span className="font-semibold">{customer?.full_name ?? lead.name}</span>
            )}

            <PhoneButton
              phone={customer?.phone ?? lead.phone}
              preferSms={customer?.sms_lead_updates}
              isInvalid={customer?.phone_valid === 0}
              phoneType={customer?.phone_type}
            />
            <EmailButton
              email={customer?.email ?? lead.email}
              isInvalid={customer?.has_bad_email}
              customerId={customer?.id}
            />
          </div>
        </div>
        <div className="flex flex-col basis-1/3 px-2 py-2">
          <div>
            <span className="font-medium">Date Wanted:</span> {lead.date_wanted}
          </div>
          <div>
            <span className="font-medium">Length:</span>{' '}
            {lead.length_wanted && lead.length_wanted > 0 ? lead.length_wanted / 60 : '?'} hrs
          </div>
          <div>{lead.activity_interest?.join(', ')}</div>
        </div>
        <div className="flex flex-col basis-1/3 px-2 py-2">
          <div>
            <button type="button" onClick={() => openGoogleMap(lead)}>
              {lead.city} {lead.state} {lead.zipcode}{' '}
              <ArrowTopRightOnSquareIcon className="w-3 h-3 inline" />
            </button>
          </div>
          <div>{lead.event_type?.name}</div>
          <div>
            {!lead.total ? <span className="text-xs">Not Quoted</span> : formatMoney(lead.total)}
          </div>
        </div>
      </div>

      <div className="flex flex-col space-y-2 px-4 pb-2">
        <div className="text-xs">
          <span className="font-medium">
            Last Update: {lead.updated_at ? formatApiDate(lead.updated_at) : 'never'}
          </span>
        </div>

        {lead.waiting_since && (
          <div className="text-xs">
            <span className="font-medium">Latest Message from Customer:</span>{' '}
            <span className="italic">
              (
              {formatDistanceToNow(
                fromUnixTime(
                  Math.floor(new Date(lead.waiting_since.replace(' ', 'T')).getTime() / 1000),
                ),
              )}{' '}
              ago)
            </span>{' '}
            {lead.latest_message}{' '}
          </div>
        )}
        {lead.special_instructions && (
          <div className="text-xs">
            <span className="font-medium">Special Instructions:</span> {lead.special_instructions}
          </div>
        )}
        {notes[0] && (
          <div className="text-xs">
            <span className="font-medium">Latest Note:</span> {formatApiDate(notes[0].created_at)}{' '}
            {notes[0].message}
          </div>
        )}
      </div>
      {expanded && (
        <>
          <div className="mt-2 border-t">
            <NewNoteForm lead={lead} />
          </div>
          {notes[0] && (
            <div className="max-h-48 overflow-y-auto p-4 bg-white border-t-2 border-b">
              <NotesTimeline notes={notes} />
            </div>
          )}
          <div className="flex flex-row space-x-1 p-2 bg-gray-50">
            <LogContactContextMenu lead={lead} />
            {lead.waiting_since && (
              <Button variant="outline" size="sm" onClick={() => clearWait({ id: lead.id })}>
                Clear Wait
              </Button>
            )}
            <FollowUpContextMenu lead={lead} />
            <StatusContextMenu lead={lead} />
            <ArchiveContextMenu lead={lead} />
            <span className="grow"> </span>
            <Link
              to={`/leads/lead/${lead.id}/quote`}
              variant="outline"
              size="sm"
              className="inline-block"
            >
              Book
            </Link>
          </div>
        </>
      )}
    </div>
  );
};
