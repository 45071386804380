import { useAssetTypes } from '@/api/settings/asset-types/getAssetTypes';
import { useEventTypes } from '@/api/settings/event-types/getEventTypes';
import { NotesTimeline } from '@/components/NotesTimeline';
import { QuoteBuilder } from '@/components/QuoteBuilder';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/elements/accordion';
import { Badge } from '@/components/ui/elements/badge';
import VipManager from '@/components/VipManager/VipManager';
import { LeadContext } from '@/features/leads/contexts/LeadContext';
import { LocationContext } from '@/providers/location';
import { AssetType, Lead } from '@/types';
import { useContext } from 'react';
import { NewNoteForm } from '../../NewNoteForm';
import { BasicCustomerForm } from '../Forms/BasicCustomerForm';
import { LeadForm } from '../Forms/LeadForm';
import { MultiQuote } from '../MultiQuote';
import { OnlineBookingValidation } from '../OnlineBookingValidation';
import { AccordionItemButtonStyle, AccordionItemPanelStyle } from '../styles';
import { OtherBookingsAccordionItem } from './components/OtherBookingsAccordionItem';
import { SimilarCustomerAccordionItem } from './components/SimilarCustomerAccordionItem';
import { WaitingIndicator } from './components/WaitingIndicator';

interface QuoteProps {
  lead: Lead;
  eventTypes: any[];
  assetTypes: AssetType[];
}

export const QuoteTab = () => {
  const { activeLocationId } = useContext(LocationContext);
  const { lead } = useContext(LeadContext);

  const { data: eventTypes } = useEventTypes({
    location: lead?.franchise_id ?? activeLocationId,
  });

  const { data: assetTypes } = useAssetTypes({
    location: lead?.franchise_id ?? activeLocationId,
    search: { active: true },
  });

  if (!lead) return <>No lead has been loaded...</>;
  if (!eventTypes) return <>Loading...</>;
  if (!assetTypes) return <>Loading...</>;

  return <Quote lead={lead} eventTypes={eventTypes} assetTypes={assetTypes} />;
};

const Quote = ({ lead, eventTypes, assetTypes }: QuoteProps) => {
  const { manageQuote } = useContext(LeadContext);

  if (!lead) return <>No lead has been loaded...</>;

  const incompleteCustomer =
    !lead.customer ||
    !lead.customer?.first_name ||
    !lead.customer?.last_name ||
    !lead.customer?.phone ||
    !lead.customer?.email;

  return (
    <div className="@container">
      <div className="flex flex-col @2xl:flex-row space-x-2">
        <Accordion
          defaultValue={[
            'location',
            lead.activities?.length === 0 ? 'marketing' : '',
            incompleteCustomer ? 'customer' : '',
          ]}
          type="multiple"
          className="w-full pt-4 mr-4 @2xl:max-w-sm"
        >
          <WaitingIndicator lead={lead} />

          <AccordionItem value="customer">
            <AccordionTrigger className={AccordionItemButtonStyle}>Customer Info</AccordionTrigger>
            <AccordionContent className={AccordionItemPanelStyle}>
              <BasicCustomerForm key={`cust-${lead.lead_id}-${lead.customer?.id}`} lead={lead} />
            </AccordionContent>
          </AccordionItem>

          <SimilarCustomerAccordionItem />

          <OtherBookingsAccordionItem />

          <LeadForm
            key={`lead-${lead.lead_id}-${lead.updated_at}`}
            lead={lead}
            eventTypes={eventTypes}
            assetTypes={assetTypes}
          />

          <AccordionItem value="vip" className="mt-2">
            <AccordionTrigger className={AccordionItemButtonStyle}>VIP</AccordionTrigger>
            <AccordionContent className={AccordionItemPanelStyle}>
              <div className="flex flex-col space-y-3 py-4">
                {lead.customer && <VipManager customer={lead.customer} mode="lead" record={lead} />}
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <div className="grow">
          <OnlineBookingValidation />
          <MultiQuote key={`leadmultiquote-${lead.lead_id}`} />
          <QuoteBuilder<Lead>
            key={`leadquote-${lead.lead_id}`}
            readonly={false}
            quoteable={lead}
            mutator={manageQuote}
          >
            {lead.primary_quote?.is_expired && (
              <Badge variant="red" size="sm">
                Quote Expired
              </Badge>
            )}
            {((!lead.primary_quote && lead.source_platform === 'olb') ||
              (lead.primary_quote && !lead.primary_quote?.createdby_user_id)) && (
              <Badge variant="gray" size="sm">
                OLB
              </Badge>
            )}
          </QuoteBuilder>
        </div>
      </div>
      <div className="border-t mt-6">
        <div className="text-lg font-semibold pt-2">Notes</div>
        <NewNoteForm className="mt-1 mb-3" lead={lead} />
        <NotesTimeline notes={lead.notes} />
      </div>
      <div className="mb-96"> </div>
    </div>
  );
};
