import { useDeletePaymentMethod } from '@/api/customers/payment-methods/deletePaymentMethod';
import { useUpdatePaymentMethod } from '@/api/customers/payment-methods/updatePaymentMethod';
import { Button } from '@/components/ui/elements/button';
import { PaymentMethod } from '@/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormInput } from '../Form';
import { FormStateSelect } from '../Form/FormStateSelect';
import { Form } from '../ui/form/form';

interface EditPaymentMethodProps {
  paymentMethod: PaymentMethod;
}

const schema = z.object({
  name: z.string().min(1, 'Required'),
  phone: z.string().nullish(),
  email: z.string().nullish(),
  address: z.string(),
  address_line2: z.string().nullish(),
  city: z.string(),
  state: z.string(),
  zipcode: z.string().min(1, 'Required'),
});

export const EditPaymentMethod = ({ paymentMethod }: EditPaymentMethodProps) => {
  const [error, setError] = useState<string>();
  const { mutate: updatePaymentMethod } = useUpdatePaymentMethod({
    onError: (error: any) => setError(error?.response?.data?.message),
  });
  const { mutate: deletePaymentMethod } = useDeletePaymentMethod();

  const handleUpdate = (data: z.infer<typeof schema>) => {
    updatePaymentMethod({
      id: paymentMethod.id,
      data: {
        ...data,
        customer_id: paymentMethod.customer_id,
      },
    });
  };

  const handleDelete = () => {
    deletePaymentMethod({
      id: paymentMethod.id,
    });
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: paymentMethod.name ?? '',
      phone: paymentMethod.phone ?? '',
      email: paymentMethod.email ?? '',
      address: paymentMethod.address ?? '',
      address_line2: paymentMethod.address_line2 ?? '',
      city: paymentMethod.city ?? '',
      state: paymentMethod.state ?? '',
      zipcode: paymentMethod.zipcode ?? '',
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleUpdate)}>
        <div className="flex flex-col space-y-3">
          <div>
            {!!error && <div className="text-xs pt-1 pl-1 font-semibold text-red-500">{error}</div>}
          </div>
          <FormInput control={form.control} name="name" label="Name on Card" />
          <FormInput control={form.control} name="address" label="Address" />
          <FormInput control={form.control} name="address_line2" label="Address Line 2" />
          <div className="flex flex-row space-x-2">
            <div className="w-3/6">
              <FormInput control={form.control} name="city" label="City" />
            </div>
            <div className="w-2/6">
              <FormStateSelect control={form.control} name="state" label="State" style="abbr" />
            </div>
            <div className="w-2/6">
              <FormInput control={form.control} name="zipcode" label="Zip" />
            </div>
          </div>
          <FormInput control={form.control} name="phone" label="Phone" />
          <FormInput control={form.control} name="email" label="Email" />

          <div className="flex flex-row space-x-2">
            <Button variant="destructive" type="button" onClick={handleDelete}>
              Delete
            </Button>
            <span className="grow" />
            <Button type="submit">Save</Button>
          </div>
        </div>
      </form>
    </Form>
  );
};
