import _ from 'lodash';
import { ACL } from '.';
import { GameCoachACL } from './gamecoach';
export const ACL_ROLE_HEAD_GAMECOACH = 'Head Game Coach';

export const HeadGameCoachACL: ACL = _.merge({}, GameCoachACL, {
  role: ACL_ROLE_HEAD_GAMECOACH,
  dashboard: {
    view: true,
  },
  leads: {
    view: true,
    create: true,
    edit: true,
  },
  events: {
    create: true,
    edit: true,
  },
  customers: {
    edit: true,
  },
  payments: {
    view: true,
    create: true,
  },
  payment_methods: {
    create: true,
    edit: true,
  },
});
