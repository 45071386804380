import { Switch } from '@/components/ui/elements/switch';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form/form';
import { VariantProps } from 'cva';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { formFieldVariants } from './FormInput';

type FormSwitchProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  VariantProps<typeof formFieldVariants> & {
    label?: string;
    description?: string;
    placeholder?: string;
    onChange?: (checked: boolean) => void;
  };

export const FormSwitch = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  placeholder,
  label,
  description,
  variant,
  onChange,
  ...props
}: FormSwitchProps<TFieldValues, TName>) => {
  const handleChange = (checked: boolean, callback: any) => {
    callback(checked);
    onChange?.(checked);
  };

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="flex flex-row items-center space-x-2">
          <FormControl>
            <Switch
              checked={field.value}
              onCheckedChange={(checked) => handleChange(checked, field.onChange)}
            />
          </FormControl>
          <div className="space-y-0.5">
            {label && <FormLabel>{label}</FormLabel>}
            {description && <FormDescription>{description}</FormDescription>}
          </div>
          <FormMessage />
        </FormItem>
      )}
      {...props}
    />
  );
};
