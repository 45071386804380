import { MutationConfig } from '@/lib/react-query';
import { post } from '@/utils/api';
import { useMutation } from '@tanstack/react-query';
import { featurePath } from '.';

type QueryFnType = typeof postValidateEmail;

type UseValidationOptions = {
  config?: MutationConfig<QueryFnType>;
};

type ValidationInput = {
  email: string;
};

export type VerdictOption = 'Valid' | 'Risky' | 'Invalid';

type ValidationResponse = {
  result: {
    email: string;
    verdict: VerdictOption;
    score: number;
    local: string;
    host: string;
    suggestion?: string;
    checks: {
      domain: {
        has_valid_address_syntax: boolean;
        has_mx_or_a_record: boolean;
        is_suspected_disposable_address: boolean;
      };
      local_part: {
        is_suspected_role_address: boolean;
      };
      additional: {
        has_known_bounces: boolean;
        has_suspected_bounces: boolean;
      };
    };
    source: string;
    ip_address: string;
  };
};

const postValidateEmail = ({ email }: ValidationInput) =>
  post<ValidationResponse>(`${featurePath}/email`, { email });

export const useValidateEmail = ({ config = {} }: UseValidationOptions) => {
  return useMutation({
    ...config,
    mutationFn: postValidateEmail,
  });
};
