import {
  UpdateDTO,
  useUpdateMutationFactory,
  UseUpdateMutationProps,
} from '@/lib/react-query/update';
import { ModelID, Vip } from '@/types';
import { post, update } from '@/utils/api';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { featureKeys, featurePath, VipSearchParams } from '.';

export type UpdateVipDTO = UpdateDTO<Vip, VipSearchParams>;

export interface LinkVipDTO {
  vip: Vip;
  targetKey: ModelID;
  mode: 'lead' | 'event';
  action: 'link' | 'unlink';
  params?: VipSearchParams;
}

const updateVip = ({ data, id, params = {} }: UpdateVipDTO) =>
  update<Vip>(`${featurePath}/${id}`, data, params);

const linkVip = ({ vip, targetKey, mode, action, params = {} }: LinkVipDTO) =>
  post<Vip>(
    `${featurePath}/${vip.id}/link`,
    {
      targetKey,
      mode,
      action,
    },
    params,
  );

export const useUpdateVip = (options?: UseUpdateMutationProps<Vip>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: updateVip,
    ...options,
  });
};

export const useLinkVip = (options?: UseUpdateMutationProps<Vip>) => {
  return useMutation<Vip, unknown, LinkVipDTO, unknown>({
    mutationFn: linkVip,
    ...options,
  } as UseMutationOptions<Vip, unknown, LinkVipDTO, unknown>);
};
