import { PolicyCheck } from '@/features/auth/authorization';

export const ServicePolicies: Record<string, PolicyCheck> = {
  'products.services.view': ({ acl, user, meta }) => {
    return acl.products.services.view;
  },
  'products.services.create': ({ acl, user, meta }) => {
    return acl.products.services.create;
  },
  'products.services.update': ({ acl, user, meta }) => {
    return acl.products.services.edit;
  },
  'products.services.delete': ({ acl, user, meta }) => {
    return false;
  },
};
