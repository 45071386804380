import { useLeadChangelog } from '@/api/leads/leads/getLead';
import { ChangeLog } from '@/components/ChangeLog/ChangeLog';
import { PolicyGate } from '@/features/auth/authorization';
import { LeadContext } from '@/features/leads/contexts/LeadContext';
import { Lead } from '@/types';
import { useContext } from 'react';

interface ChangesProps {
  lead: Lead;
}
export const ChangesTab = () => {
  const { lead } = useContext(LeadContext);

  if (!lead) return <>Loading...</>;

  return <Changes lead={lead} />;
};

const Changes = ({ lead }: ChangesProps) => {
  const { data: changelog, isSuccess } = useLeadChangelog({
    lead,
  });

  return (
    <>
      {isSuccess && (
        <PolicyGate policy="leads.history">
          <ChangeLog changes={changelog ?? []} />
          <div className="mb-96"> </div>
        </PolicyGate>
      )}
    </>
  );
};
