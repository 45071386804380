import { useCloseLead } from '@/api/leads/leads/updateLead';
import queryClient from '@/lib/react-query';
import { ModelID } from '@/types';

export const useArchiveLead = () => {
  const { mutate: closeLead } = useCloseLead();

  const archiveLead = (leadId: ModelID, close_reason: string, onSuccess?: () => void) => {
    closeLead(
      { id: leadId, data: { close_reason } },
      {
        onSuccess: (data) => {
          if (onSuccess) onSuccess();
          queryClient.invalidateQueries({
            queryKey: [{ scope: 'heartbeat', location: data.franchise_id }],
          });
          queryClient.invalidateQueries({
            queryKey: [{ scope: 'leads', entity: 'paged' }],
            exact: false,
          });
        },
      },
    );
  };

  return {
    archiveLead,
  };
};
