import { CopyToClipboard } from '@/components/CopyToClipboard/CopyToClipboard';
import { LineTypeIntelligenceType } from '@/types';
import { cn } from '@/utils/format';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { ChatBubbleBottomCenterIcon, PhoneIcon } from '@heroicons/react/24/solid';

interface PhoneButtonProps {
  phone: string | null | undefined;
  variant?: 'sm' | 'lg';
  isInvalid?: boolean;
  preferSms?: boolean;
  phoneType?: LineTypeIntelligenceType | null;
}

export const PhoneButton = ({
  phone,
  variant = 'sm',
  isInvalid = false,
  preferSms = false,
  phoneType,
}: PhoneButtonProps) => {
  if (!phone) isInvalid = true;
  return (
    <span
      className={cn(
        'inline-flex flex-row align-middle items-center justify-center border rounded shadow-sm',
        isInvalid ? 'bg-red-100 border-red-300 ' : 'bg-white border-gray-300 hover:bg-gt-green-50',
      )}
    >
      <span
        className={cn(
          'mr-1 border-r border-1 rounded-l',
          isInvalid ? 'bg-red-200 border-red-400' : 'bg-gray-100 border-gray-300',
        )}
      >
        {preferSms && (!phoneType || phoneType === LineTypeIntelligenceType.mobile) ? (
          <ChatBubbleBottomCenterIcon
            className={cn('h-4 w-4 m-1', isInvalid ? 'text-red-400' : 'text-gray-400')}
          />
        ) : (
          <PhoneIcon className={cn('h-4 w-4 m-1', isInvalid ? 'text-red-400' : 'text-gray-400')} />
        )}
      </span>
      {phone ? (
        <>
          <a
            href={`tel:+1${phone.replace(/\D/g, '')}`}
            className={cn(
              'grow font-semibold mr-1.5 pl-1',
              'text-nowrap whitespace-nowrap text-ellipsis overflow-hidden',
              variant === 'sm' ? 'text-xs' : 'tracking-tighter text-xs lg:text-base',
              isInvalid ? 'text-red-500' : 'text-gray-700',
            )}
          >
            {phone}
          </a>
          {isInvalid ? (
            <ExclamationTriangleIcon className="h-4 w-4 m-1 text-red-500" />
          ) : (
            <CopyToClipboard value={phone} />
          )}
        </>
      ) : (
        <span
          className={cn(
            'grow font-semibold mr-1.5 pl-1',
            'text-nowrap whitespace-nowrap text-ellipsis overflow-hidden',
            variant === 'sm' ? 'text-xs' : 'tracking-tighter text-xs lg:text-base',
            isInvalid ? 'text-red-500' : 'text-gray-700',
          )}
        >
          No phone number
        </span>
      )}
    </span>
  );
};
