import { useCalendarStore } from '@/stores/calendar';
import { Event, Lead } from '@/types';
import { apiDateToCalendarDate, todayAsCalendarDate } from '@/utils/format';
import { calculateActivityDateBounds } from '@/utils/logic';
import { parseDate } from '@internationalized/date';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

interface ActivityCalendarLinkProps {
  quoteable: Lead | Event | undefined;
}

export const useActivityCalendarLink = ({ quoteable }: ActivityCalendarLinkProps) => {
  const { setCalendarDates } = useCalendarStore(
    useShallow((state) => ({
      setCalendarDates: state.setDates,
    })),
  );

  useEffect(() => {
    if (quoteable?.activities) {
      // Determine the date we will use to pull in events for the day
      // which will then be displayed on the calendar view
      const { searchDateBegin, searchDateEnd } = calculateActivityDateBounds(quoteable);

      // Handle Calendar Setup
      if (quoteable.activities.length > 0) {
        const sevenDays = searchDateBegin.copy().add({ days: 7 });

        const lastActivity = quoteable.activities[quoteable.activities.length - 1];
        const endDate = lastActivity
          ? apiDateToCalendarDate(lastActivity.start_at)
          : searchDateBegin;
        setCalendarDates(endDate, endDate.compare(sevenDays) < 0 ? sevenDays : searchDateEnd);
      }
    } else if (quoteable?.model_type === 'lead' && quoteable.date_wanted) {
      setCalendarDates(
        parseDate(quoteable.date_wanted),
        parseDate(quoteable.date_wanted).add({ days: 7 }),
      );
    } else {
      setCalendarDates(todayAsCalendarDate(), todayAsCalendarDate().add({ days: 7 }));
    }
  }, [quoteable?.activities]);
};
