import { useEvent } from '@/api/events/events/getEvent';
import {
  EventManageQuoteDTO,
  UpdateEventDTO,
  useEventManageQuote,
  useUpdateEvent,
} from '@/api/events/events/updateEvent';
import { Event, ModelID } from '@/types';
import { UseMutateFunction } from '@tanstack/react-query';
import { createContext, ReactNode, useState } from 'react';

type EventContextType = {
  eventId: ModelID;
  event: Event | undefined;
  isPending: boolean;
  isError: boolean;
  error: unknown;
  isUpdatePending: boolean;
  updateEvent: UseMutateFunction<Event, unknown, UpdateEventDTO, unknown>;
  manageQuote: UseMutateFunction<Event, unknown, EventManageQuoteDTO, unknown>;
  temporaryTextMessage: string;
  setTemporaryTextMessage: (message: string) => void;
};

type EventProviderProps = {
  eventId: ModelID;
  children: ReactNode;
};

export const EventContext = createContext<EventContextType>({} as EventContextType);

export const EventProvider = ({ eventId, children }: EventProviderProps) => {
  const {
    isPending,
    isError,
    data: event,
    error,
  } = useEvent({
    id: eventId,
    config: {
      // Find a way to not have to do this!
      structuralSharing: false,
    },
  });

  const { mutate: updateEvent, isPending: isUpdatePending } = useUpdateEvent();
  const { mutate: manageQuote } = useEventManageQuote();

  const [temporaryTextMessage, setTemporaryTextMessage] = useState('');

  return (
    <EventContext.Provider
      value={{
        eventId,
        event,
        isPending,
        isError,
        error,
        isUpdatePending,
        updateEvent,
        manageQuote,
        temporaryTextMessage,
        setTemporaryTextMessage,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};
