import { Toaster } from '@/components/ui/elements/toaster';
import AppErrorBoundaryFallback from '@/error-handling/fallbacks/App';
import { queryClient } from '@/lib/react-query';
import { AuthContext, AuthenticationWrapper, AuthProvider } from '@/providers/auth';
import { LocationContext, LocationProvider } from '@/providers/location';
import { MapBoxProvider } from '@/providers/mapbox';
import { RealtimeProvider } from '@/providers/realtime';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from '@tanstack/react-router';
import { useContext } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ENVIRONMENT, SENTRY_DSN } from './config';
import './main.css';
import { router } from './router';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/.*.gametruck.com/],
    }),
    new Sentry.Replay(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: ENVIRONMENT === 'test' ? 0 : 0.05,
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: ENVIRONMENT === 'test' ? 0 : 0.1,
  replaysOnErrorSampleRate: ENVIRONMENT === 'test' ? 0 : 1.0,
  enabled: process.env.NODE_ENV !== 'development',
});

const bootedLocationParam =
  typeof window !== 'undefined'
    ? new URLSearchParams(window.location.search).get('location_id')
    : null;
const bootedLocationId = bootedLocationParam ? parseInt(bootedLocationParam) : null;

const bootedTokenParam =
  typeof window !== 'undefined' ? new URLSearchParams(window.location.search).get('token') : null;
const token = bootedTokenParam || undefined;

const AppRoutes = () => {
  const auth = useContext(AuthContext);
  const location = useContext(LocationContext);

  return <RouterProvider router={router} context={{ auth, location }} />;
};

function App() {
  return (
    <Sentry.ErrorBoundary
      fallback={AppErrorBoundaryFallback}
      beforeCapture={(scope) => {
        scope.setTag('boundary', 'app-level');
      }}
      showDialog
    >
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
          <Toaster />
          <AuthenticationWrapper>
            <AuthProvider token={token}>
              <LocationProvider bootedLocationId={bootedLocationId}>
                <MapBoxProvider>
                  <RealtimeProvider>
                    <AppRoutes />
                  </RealtimeProvider>
                </MapBoxProvider>
              </LocationProvider>
            </AuthProvider>
          </AuthenticationWrapper>
        </QueryClientProvider>
      </HelmetProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
