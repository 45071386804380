import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ModelID, Package } from '@/types';
import { get } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { featureKeys, featurePath, PackageSearchParams } from '.';

type QueryFnType = typeof fetchPackages;

type UsePackagesOptions = {
  location: ModelID;
  search?: PackageSearchParams;
  config?: QueryConfig<QueryFnType>;
};

const ONE_HOUR = 1000 * 60 * 60;

export const fetchPackages = (location: ModelID, search: PackageSearchParams = {}) =>
  get<Package[]>(`${featurePath}?franchise_id=${location}`, search);

export const usePackages = ({ location, search = {}, config = {} }: UsePackagesOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    staleTime: ONE_HOUR,
    queryKey: featureKeys.list(location, search),
    queryFn: () => fetchPackages(location, search),
  });
};
