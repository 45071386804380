import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/elements/dropdown-menu';
import { formatApiDate } from '@/utils/format';
import { BellSnoozeIcon } from '@heroicons/react/24/outline';
import { DropdownMenuLabel } from '@radix-ui/react-dropdown-menu';
import { Button } from '../ui/elements/button';

interface SnoozeButtonProps {
  snoozedUntil: string | null | undefined;
  showDetails?: boolean;
  onSnooze: (snoozedUntil: string) => void;
  onClearSnooze: () => void;
}

export const SnoozeButton = ({
  snoozedUntil,
  showDetails = true,
  onSnooze,
  onClearSnooze,
}: SnoozeButtonProps) => {
  const SNOOZE_OPTIONS = [
    '15 mins',
    '30 mins',
    '1 hour',
    '2 hours',
    '4 hours',
    '8 hours',
    'Tomorrow',
  ];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="sm">
          {snoozedUntil ? (
            <span className="text-gray-700">
              {'\u{1F634}'} {showDetails && <>until {formatApiDate(snoozedUntil, 'HH:mm a')}</>}
            </span>
          ) : (
            <>
              <BellSnoozeIcon className="w-5 h-5 text-gray-700" />
            </>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel className="text-sm font-semibold px-2 py-1">
          {snoozedUntil ? (
            <span className="text-gray-700">
              Snoozed until {formatApiDate(snoozedUntil, 'HH:mm a')}
            </span>
          ) : (
            <>Snooze</>
          )}
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        {SNOOZE_OPTIONS.map((option) => (
          <DropdownMenuItem
            key={`snooze-${option}`}
            onClick={() => onSnooze(option)}
            className="hover:cursor-pointer"
          >
            {option}
          </DropdownMenuItem>
        ))}
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={onClearSnooze}
          className="hover:cursor-pointer focus:text-red-500 focus:bg-red-100"
        >
          Clear Snooze
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
