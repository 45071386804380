import { PolicyCheck } from '@/features/auth/authorization';

export const EventPolicies: Record<string, PolicyCheck> = {
  'events.view': ({ acl, user, meta }) => {
    return acl.events.view;
  },
  'events.create': ({ acl, user, meta }) => {
    return acl.events.create;
  },
  'events.update': ({ acl, user, meta }) => {
    return acl.events.edit;
  },
  'events.cancel': ({ acl, user, meta }) => {
    return acl.events.cancel;
  },
  'events.confirm': ({ acl, user, meta }) => {
    return acl.events.confirm;
  },
  'events.transfer': ({ acl, user, meta }) => {
    return acl.events.transfer;
  },
  'events.history': ({ acl, user, meta }) => {
    return acl.events.history;
  },
  'events.export': ({ acl, user, meta }) => {
    return acl.events.export;
  },
};
