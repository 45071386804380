import { useUpdateMutationFactory, UseUpdateMutationProps } from '@/lib/react-query/update';
import { Lead, ModelID } from '@/types';
import { post } from '@/utils/api';
import { featureKeys, featurePath } from '.';

export type StartNewQuoteDTO = {
  data: {
    lead_id: ModelID;
    is_bookable_online?: boolean;
    expires_at?: string | null;
    customer_message?: string | null;
    internal_notes?: string | null;
  };
};

export const startNewQuote = ({ data }: StartNewQuoteDTO) =>
  post<Lead>(`${featurePath}/${data.lead_id}/start-new-quote`, data, {});

export const useStartNewQuote = (options?: UseUpdateMutationProps<Lead>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: startNewQuote,
    ...options,
  });
};
