import { MapProvider } from '@/components/MapViewer/MapContext';
import { ScrollArea } from '@/components/ui/elements/scroll-area';
import { Sheet, SheetContent } from '@/components/ui/elements/sheet';
import AppErrorBoundaryFallback from '@/error-handling/fallbacks/App';
import { GlobalLoadingIndicator } from '@/lib/react-query/GlobalLoadingIndicator';
import { LocationContext } from '@/providers/location';
import { cn } from '@/utils/format';
import * as Sentry from '@sentry/react';
import { Outlet } from '@tanstack/react-router';
import { useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';
import CalendarViewer from '../../../CalendarViewer/CalendarViewer';
import MapViewer from '../../../MapViewer/MapViewer';
import { useOverlayContext } from '../../OverlayControls';
import { Header } from '../Header';
import { NotificationManager } from '../NotificationManager';
import { NotificationsStack } from '../NotificationsStack';
import { PinStack } from '../PinStack';
import { MobileNav } from './MobileNav';
import { MobileOverlayControls } from './MobileOverlayControls';

export const MobileShell = () => {
  const { activeLocationId: fallbackLocationId } = useContext(LocationContext);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const { trayVisible, trayMode } = useOverlayContext();

  let calendarHeight = null;
  if (isMobile) {
    calendarHeight = 'calc(100vh - 193px)';
  }

  return (
    <div>
      <div className="sticky top-0 z-50">
        <MobileNav open={mobileMenuOpen} setOpen={setMobileMenuOpen} />
        <div className="flex flex-1 flex-col overflow-hidden">
          <GlobalLoadingIndicator />
          <NotificationManager />
          <Header setMobileMenuOpen={setMobileMenuOpen}>
            <MobileOverlayControls open={overlayOpen} setOpen={setOverlayOpen} />
          </Header>
        </div>
        {trayVisible && (
          <Sheet open={overlayOpen} onOpenChange={setOverlayOpen}>
            <SheetContent side="top" className="px-0">
              <ScrollArea className="mt-5 h-[calc(100vh-3rem)]">
                <div className={cn('w-full h-full bg-white')}>
                  <Sentry.ErrorBoundary
                    fallback={AppErrorBoundaryFallback}
                    beforeCapture={(scope) => {
                      scope.setTag('boundary', 'sidebar-level');
                    }}
                  >
                    {trayMode === 'map' && (
                      <MapProvider>
                        <MapViewer size={100} />
                      </MapProvider>
                    )}
                    {trayMode === 'calendar' && (
                      <CalendarViewer
                        fallbackLocationId={fallbackLocationId}
                        heightOverride={calendarHeight}
                      />
                    )}
                    {trayMode === 'notifications' && <NotificationsStack />}
                    {trayMode === 'pins' && <PinStack />}
                  </Sentry.ErrorBoundary>
                </div>
              </ScrollArea>
            </SheetContent>
          </Sheet>
        )}
      </div>

      <div className="flex flex-1 items-start">
        <Sentry.ErrorBoundary
          fallback={AppErrorBoundaryFallback}
          beforeCapture={(scope) => {
            scope.setTag('boundary', 'page-level');
          }}
          showDialog
        >
          <Outlet />
        </Sentry.ErrorBoundary>
      </div>
    </div>
  );
};
