import { ModelID } from '@/types';
import { useReducer, useState } from 'react';

type Action =
  | { type: 'clear' }
  | { type: 'batch_select'; ids: ModelID[] }
  | { type: 'select'; id: ModelID }
  | { type: 'deselect'; id: ModelID };

const selectedReducer = (state: ModelID[], action: Action) => {
  if (action.type === 'select') {
    return [...state, action.id];
  }

  if (action.type === 'batch_select') {
    return [...action.ids];
  }

  if (action.type === 'deselect') {
    return [...state.filter((id: ModelID) => id !== action.id)];
  }

  if (action.type === 'clear') {
    return [];
  }

  throw Error('Unknown action.');
};

export const useBatchSelection = () => {
  const [selected, dispatchSelected] = useReducer(selectedReducer, []);
  const [allSelected, setAllSelected] = useState(false);

  const select = (id: ModelID) => {
    dispatchSelected({ type: 'select', id });
  };

  const batch = (ids: ModelID[]) => {
    dispatchSelected({ type: 'batch_select', ids });
  };

  const deselect = (id: ModelID) => {
    dispatchSelected({ type: 'deselect', id });
  };

  const toggle = (id: ModelID) => {
    dispatchSelected({ type: selected.includes(id) ? 'deselect' : 'select', id });
  };

  const clear = () => {
    dispatchSelected({ type: 'clear' });
    setAllSelected(false);
  };

  return {
    allSelected,
    selected,
    batch,
    select,
    deselect,
    toggle,
    clear,
    setAllSelected,
  };
};
