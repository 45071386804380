import { cn } from '@/utils/format';
import sanitizeHtml from 'sanitize-html';

const defaultOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'ul', 'ol', 'li'],
  allowedAttributes: {
    a: ['href'],
  },
  allowedIframeHostnames: ['www.youtube.com', 'player.vimeo.com'],
};

const sanitize = (dirty: string, options?: sanitizeHtml.IOptions) => {
  const opts = { ...defaultOptions, ...options };
  return {
    __html: sanitizeHtml(dirty, opts),
  };
};

interface SanitizeHTMLProps {
  html: string;
  className?: string;
  options?: sanitizeHtml.IOptions;
}

export const SanitizeHTML = ({ html, className, options }: SanitizeHTMLProps) => (
  <div dangerouslySetInnerHTML={sanitize(html, options)} className={cn(className)} />
);
