import ButtonGroup from '@/components/Elements/ButtonGroup/ButtonGroup';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/elements/dropdown-menu';
import { useCalendarStore } from '@/stores/calendar';
import { CalendarDaysIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { isMobileOnly } from 'react-device-detect';
import { useShallow } from 'zustand/react/shallow';

export const CalendarViewControls = () => {
  const { mode, setMode } = useCalendarStore(
    useShallow((state) => ({
      mode: state.mode,
      setMode: state.setMode,
    })),
  );

  if (isMobileOnly) {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <ButtonGroup.Button className="p-2 rounded-md">
            <span className="sr-only">Day Modes</span>
            <CalendarIcon className="h-5 w-5" />
          </ButtonGroup.Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          <DropdownMenuItem onClick={() => setMode('day')} className="cursor-pointer">
            1 Day
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setMode('threeDay')} className="cursor-pointer">
            3 Day
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setMode('week')} className="cursor-pointer">
            7 Day
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setMode('month')} className="cursor-pointer">
            Month
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }

  return (
    <ButtonGroup>
      <ButtonGroup.Button
        isFirst
        isActive={mode === 'day'}
        onClick={() => setMode('day')}
        disabled={mode === 'day'}
        className="p-2.5"
      >
        <CalendarIcon className="-my-3 -mx-2 -mr-3 h-8 w-8" aria-hidden="true" />
        <span className="relative right-2 top-1">1</span>
      </ButtonGroup.Button>
      <ButtonGroup.Button
        isActive={mode === 'threeDay'}
        onClick={() => setMode('threeDay')}
        disabled={mode === 'threeDay'}
        className="p-2.5"
      >
        <CalendarIcon className="-my-3 -mx-2 -mr-4 h-8 w-8" aria-hidden="true" />
        <span className="relative right-1 top-1">3</span>
      </ButtonGroup.Button>
      <ButtonGroup.Button
        isActive={mode === 'week'}
        onClick={() => setMode('week')}
        disabled={mode === 'week'}
        className="p-2.5"
      >
        <CalendarIcon className="-my-3 -mx-2 -mr-4 h-8 w-8" aria-hidden="true" />
        <span className="relative right-1 top-1">7</span>
      </ButtonGroup.Button>
      <ButtonGroup.Button
        isLast
        isActive={mode === 'month'}
        onClick={() => setMode('month')}
        disabled={mode === 'month'}
        className="p-2.5"
      >
        <CalendarDaysIcon className="-my-3 -mx-2 -mr-2 h-8 w-8" aria-hidden="true" />
      </ButtonGroup.Button>
    </ButtonGroup>
  );
};
