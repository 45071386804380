import { Badge, BadgeProps } from '@/components/ui/elements/badge';
import { Event } from '@/types';
import { formatNumber } from '@/utils/numbers-utils';
import { isBefore } from 'date-fns';

interface BalanceDueBadgeProps {
  event: Event;
}

export const BalanceDueBadge = ({ event }: BalanceDueBadgeProps) => {
  let variant = 'gray' as BadgeProps['variant'];
  switch (event.status) {
    case 'Collections':
      variant = 'orange';
      break;
    case 'Cancelled':
    case 'Canceled':
      variant = 'red';
      break;
    case 'Requested':
    case 'Verified':
      variant = 'blue';
      break;
    case 'Confirmed':
      variant = 'green';
      break;
    default:
      variant = 'blue';
      break;
  }

  const isPast = isBefore(new Date(event.start_at.replace(' ', 'T')), new Date());
  const hasBalance = event.balance_due > 0;

  return (
    <Badge
      size="sm"
      variant={isPast && hasBalance ? 'red' : isPast && !hasBalance ? 'green' : 'yellow'}
    >
      Balance Due: {formatNumber(event.balance_due, 'currency')}
    </Badge>
  );
};
