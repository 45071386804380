import { MutationConfig } from '@/lib/react-query';
import { LineTypeIntelligenceType } from '@/types';
import { post } from '@/utils/api';
import { useMutation } from '@tanstack/react-query';
import { featurePath } from '.';

type QueryFnType = typeof postValidatePhone;

type UseValidationOptions = {
  config?: MutationConfig<QueryFnType>;
};

type ValidationInput = {
  phone: string;
};

type ValidationResponse = {
  callingCountryCode: number;
  countryCode: string;
  phoneNumber: string;
  nationalFormat: string;
  valid: boolean;
  validationErrors: null;
  callerName: null;
  lineTypeIntelligence: {
    errorCode: number;
    mobileCountryCode: number;
    mobileNetworkCode: number;
    carrierName: string;
    type: LineTypeIntelligenceType;
  };
};

const postValidatePhone = ({ phone }: ValidationInput) =>
  post<ValidationResponse>(`${featurePath}/phone`, { phone });

export const useValidatePhone = ({ config = {} }: UseValidationOptions) => {
  return useMutation({
    ...config,
    mutationFn: postValidatePhone,
  });
};
