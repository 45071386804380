import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/elements/select';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form/form';
import { cn } from '@/utils/format';
import { VariantProps } from 'cva';
import { useId } from 'react';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { formFieldVariants } from './FormInput';

export type SelectOption = {
  label: string;
  value: string | number;
  meta?: any;
};

export type FormSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  VariantProps<typeof formFieldVariants> & {
    options: SelectOption[];
    label?: string;
    description?: string;
    placeholder?: string;
    onChange?: (value: string) => void;
  };

export const FormSelect = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  placeholder,
  label,
  description,
  options,
  variant,
  onChange,
  ...props
}: FormSelectProps<TFieldValues, TName>) => {
  const handleChange = (value: string, callback: any) => {
    callback(value);
    onChange?.(value);
  };

  const key = useId();

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem key={field.value} className="relative">
          {label && <FormLabel className={cn(formFieldVariants({ variant }))}>{label}</FormLabel>}
          <Select
            onValueChange={(value) => handleChange(value, field.onChange)}
            defaultValue={field.value?.toString() ?? undefined}
            disabled={props.disabled ?? false}
          >
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {options.map((option) => (
                <SelectItem
                  key={`select-${key}-${option.label}-${name}`}
                  value={option.value?.toString() ?? undefined}
                >
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
      {...props}
    />
  );
};
