import { Report, ReportNode, ReportSearchParams } from '@/features/reports/logic/types';
import { ExtractFnReturnType } from '@/lib/react-query';
import { LocationContext } from '@/providers/location';
import { ModelID } from '@/types';
import { get } from '@/utils/api';
import { todayAsCalendarDate } from '@/utils/format';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useContext, useMemo } from 'react';

type QueryFnType = typeof getSummaryReport;

type ReportDataStructure = {
  nps: ReportNode<{
    avg: number;
    count: number;
  }>;
  leads: ReportNode;
  sales: ReportNode;
  revenue: ReportNode;
};

export type SummaryReport = Report<ReportDataStructure>;

const buildData = (data: SummaryReport | undefined) => {
  if (!data) return undefined;

  return data.summary;
};

const getSummaryReport = (location: ModelID, search: ReportSearchParams) =>
  get<SummaryReport>(`/v4/reports/dashboard-summary`, {
    location,
    ...search,
  });

export const useSummaryReport = () => {
  const { activeLocationId } = useContext(LocationContext);

  // start date is 30 days ago
  const startDate = todayAsCalendarDate().subtract({ days: 30 });
  const endDate = todayAsCalendarDate().subtract({ days: 1 });

  const search = useMemo<ReportSearchParams>(
    () => ({
      start: startDate.toString(),
      end: endDate.toString(),
      group: 'month',
      compare: 'year',
    }),
    [],
  );

  const queryConfig = {
    staleTime: 1000 * 60 * 15,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: true,
  };

  const { data: rawData, ...query } = useQuery<ExtractFnReturnType<QueryFnType>>({
    ...queryConfig,
    queryKey: [{ context: 'dashboard', activeLocationId, search }],
    queryFn: () => getSummaryReport(activeLocationId, search),
  });

  const data = useMemo(() => buildData(rawData), [rawData]);

  return {
    data,
    query,
  };
};
