import { cn } from '@/utils/format';
import { CheckCircleIcon, ExclamationTriangleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { cva, VariantProps } from 'cva';

export const alertVariants = cva(['rounded-md'], {
  variants: {
    size: {
      sm: 'py-1 px-2 text-sm',
      md: 'p-4 text-md',
      lg: 'p-6 text-lg',
    },
    color: {
      red: 'bg-red-50',
      yellow: 'bg-yellow-50',
      green: 'bg-green-50',
      blue: 'bg-blue-50',
    },
  },
  compoundVariants: [],
  defaultVariants: {
    size: 'md',
    color: 'red',
  },
});

type AlertProps = VariantProps<typeof alertVariants> & {
  title: string;
  className?: string;
  children?: React.ReactNode;
};

export const Alert = ({ title, size, color, className, children }: AlertProps) => {
  return (
    <div className={cn(alertVariants({ size, color, className }))}>
      <div className="flex">
        <div className="flex-shrink-0">
          {color === 'red' && <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />}
          {color === 'yellow' && (
            <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          )}
          {color === 'green' && (
            <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
          )}
        </div>
        <div className="ml-3">
          <h3 className={cn('text-sm font-medium', `text-${color}-800`)}>{title}</h3>
          <div className={cn('mt-2 text-sm', `text-${color}-700`)}>{children}</div>
        </div>
      </div>
    </div>
  );
};
