import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Location, ModelID } from '@/types';
import { get } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { featureKeys, featurePath, LocationSearchParams } from '.';

type QueryFnType = typeof findLocation;
type QueryFindByZipcodeFnType = typeof findByZipcode;

type UseLocationOptions = {
  id: ModelID;
  search?: LocationSearchParams;
  config?: QueryConfig<QueryFnType>;
};

type UseLocationByZipcodeOptions = {
  zipcode: string | number;
  search?: LocationSearchParams;
  config?: QueryConfig<QueryFindByZipcodeFnType>;
};

const findLocation = (id: ModelID, search: any = {}) =>
  get<Location>(`${featurePath}/${id}`, search);

export const findByZipcode = (zipcode: string | number, search: any = {}) =>
  get<Location>(`${featurePath}/find-by-zipcode`, { zipcode, ...search });

export const useLocation = ({ id, search = {}, config = {} }: UseLocationOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: featureKeys.detail(+id),
    queryFn: () => findLocation(id, search),
  });
};

export const useLocationByZipcode = ({
  zipcode,
  search = {},
  config = {},
}: UseLocationByZipcodeOptions) => {
  return useQuery<ExtractFnReturnType<QueryFindByZipcodeFnType>>({
    ...config,
    queryKey: [{ scope: 'locations', entity: 'findByZipcode', zipcode, search }],
    queryFn: () => findByZipcode(zipcode, search),
  });
};
