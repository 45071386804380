import { CustomerSearchParams } from '@/api/customers/customers';
import { CUSTOMER_ORGANIZATION_TYPES } from '@/config';
import { Control, useFieldArray } from 'react-hook-form';

interface UseOrganizationTypesFieldArrayProps {
  control: Control;
  defaultValues: CustomerSearchParams;
}

export const useOrganizationTypesFieldArray = ({
  control,
  defaultValues,
}: UseOrganizationTypesFieldArrayProps) => {
  const organizationTypeArrayDefaults =
    CUSTOMER_ORGANIZATION_TYPES?.map((type) => {
      return {
        value: type.value.toString(),
        label: type.label,
        checked: false,
      };
    }) ?? [];

  const defaultOptions =
    organizationTypeArrayDefaults?.map((type) => {
      return {
        ...type,
        checked: defaultValues.organization_types?.includes(type.value) ?? false,
      };
    }) ?? [];

  const fieldArray = useFieldArray({
    control: control,
    name: 'organization_types',
  });

  const formatter = {
    key: 'organization_types',
    resetFn: () => fieldArray.replace(organizationTypeArrayDefaults),
    formatter: (value: string[]): string => {
      const label = CUSTOMER_ORGANIZATION_TYPES?.filter((type) =>
        value.includes(type.value.toString()),
      ).map((type) => type.label);
      return label?.join(', ') ?? '';
    },
  };

  return {
    fieldArray,
    formatter,
    defaultOptions,
  };
};
