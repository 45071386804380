import { EventSearchParams, Filter } from '@/api/events/events';
import { useAddSearchToList } from '@/api/events/events/addSearchToList';
import { useBatchConfirmEvents } from '@/api/events/events/batchConfirmEvents';
import { useExportEvents } from '@/api/events/events/exportEvents';
import { usePagedEvents } from '@/api/events/events/getEvents';
import { useEventTypes } from '@/api/settings/event-types/getEventTypes';
import { CustomerListDropdownMenuGroup } from '@/components/Dropdown/CustomerListDropdownMenuGroup';
import { ErrorResult, ListingPlaceholder, Paginator, Spinner } from '@/components/Elements';
import List from '@/components/Elements/List/List';
import NoResults from '@/components/Elements/NoResults/NoResults';
import { ScrollableLayout } from '@/components/Layout';
import { useMapStore } from '@/components/MapViewer/MapStateStore';
import { Button } from '@/components/ui/elements/button';
import { Checkbox } from '@/components/ui/elements/checkbox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/elements/dropdown-menu';
import { toast } from '@/components/ui/elements/use-toast';
import { PolicyGate } from '@/features/auth/authorization';
import { useBatchSelection } from '@/hooks/useBatchSelection';
import { useFileDownload } from '@/hooks/useFileDownload';
import { usePaginator } from '@/hooks/usePaginator';
import { useScrollToTop } from '@/hooks/useScrollToTop';
import { useSearchParams } from '@/hooks/useSearchParams';
import { LocationContext } from '@/providers/location';
import { CustomerList, Event, ModelID } from '@/types';
import { postRaw } from '@/utils/api';
import { formatDate } from '@/utils/format';
import {
  ArrowDownTrayIcon,
  CheckCircleIcon,
  CloudArrowDownIcon,
  DocumentIcon,
} from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/react';
import { keepPreviousData } from '@tanstack/react-query';
import { AnyRoute, useParams } from '@tanstack/react-router';
import { useContext, useEffect, useRef, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { EventResult } from '../components/EventResult';
import { EventSearchForm } from '../components/EventSearchForm';

/** @Feature: Quick actions */

type RouteParams = {
  filter: Filter;
};

const searchDefaults = {
  orderBy: 'start_at',
  orderDir: 'desc',
  limit: 50,
  page: 1,
} as EventSearchParams;

export interface EventSelectAction {
  type: 'select' | 'deselect';
  id: ModelID;
}

export const EventSearchPage = () => {
  const { activeLocationId } = useContext(LocationContext);
  const { filter } = useParams<AnyRoute, RouteParams>({
    strict: false,
  });
  const { search, setSearchParams, resetSearchParams } = useSearchParams<EventSearchParams>();
  const {
    allSelected,
    setAllSelected,
    selected: eventsSelected,
    toggle: selectEvent,
    clear: clearSelectedEvents,
    batch: selectEvents,
  } = useBatchSelection();
  const [isPending, setIsPending] = useState(false);
  const { download } = useFileDownload();

  const searchState = {
    ...searchDefaults,
    ...search,
  };

  const { mutate: exportResults, isPending: exportIsPending } = useExportEvents();
  const { mutate: addResultsToCustomerList, isPending: listBuildIsPending } = useAddSearchToList();
  const { mutate: batchConfirm, isPending: batchConfirmIsPending } = useBatchConfirmEvents({
    onSuccess: () => {
      toast({
        title: 'Event confirmations have been added to the queue.',
      });
      clearSelectedEvents();
    },
  });

  const { addPin, clearPins, setCenter } = useMapStore(
    useShallow((state) => ({
      setCenter: state.setCenter,
      addPin: state.addPin,
      clearPins: state.clearPins,
    })),
  );

  const { data: eventTypes } = useEventTypes({ location: activeLocationId });

  const {
    isPending: isLoading,
    isFetching,
    isError,
    data,
    error,
    refetch,
  } = usePagedEvents({
    location: activeLocationId,
    search: {
      ...searchState,
      filter: filter || 'upcoming',
    },
    config: {
      placeholderData: keepPreviousData,
      staleTime: 1000 * 5,
    },
  });

  const { results: events, paginator } = data ?? {};
  const { page, total: recordsFound, lastPage, gotoPage } = usePaginator({ paginator });

  const handleSearch = (values: any) => {
    setSearchParams({
      ...values,
      event_type_ids:
        values.event_type_ids?.filter((type: any) => type.checked).map((type: any) => type.value) ??
        undefined,
    });
  };

  const handleDownloadInvoiceBatch = async (mode: 'download' | 'open') => {
    setIsPending(true);
    const result = await postRaw<Blob>('/v4/events/event/batch-invoices', {
      ids: eventsSelected,
    });
    download(result, `events-batch-${Date.now()}.pdf`, mode);
    setIsPending(false);
  };

  const handleBatchConfirm = () => {
    batchConfirm({
      location: activeLocationId,
      ids: eventsSelected,
    });
  };

  const handleExportResults = () => {
    exportResults({
      location: activeLocationId,
      search: {
        ...searchState,
        filter: filter,
      },
    });
  };

  const handleAddResultsToCustomerList = (list: CustomerList) => {
    addResultsToCustomerList(
      {
        location: activeLocationId,
        list,
        search: {
          ...searchState,
          filter: filter,
        },
      },
      {
        onSuccess: () => {
          toast({
            title: `All results have been added to the ${list.name} list.`,
          });
        },
      },
    );
  };

  const selectAddEvents = (checked: boolean) => {
    if (checked && events) {
      selectEvents(events.map((event) => event.id));
      setAllSelected(true);
    } else {
      clearSelectedEvents();
      setAllSelected(false);
    }
  };

  useEffect(() => {
    if (events) {
      clearPins();
      const firstResult = events[0];
      if (firstResult) setCenter(firstResult.longitude, firstResult.latitude);

      events.map((event: Event) => {
        event.activities.map((activity) => {
          addPin({
            id: activity.id,
            itemId: activity.event_id,
            assetType: activity.asset?.abbr ?? '',
            type: 'event',
            title: event.name,
            lat: event.latitude,
            lng: event.longitude,
            message: `${formatDate(activity.start_at, 'M/d h:mm a')} ${activity.asset?.abbr}`,
          });
        });
      });
    }

    return () => clearPins();
  }, [events]);

  const scrollRef = useRef<HTMLDivElement>(null);
  const { scrollToTop } = useScrollToTop(scrollRef);
  useEffect(() => {
    scrollToTop();
    clearSelectedEvents();

    return () => clearSelectedEvents();
  }, [page]);

  const bulkActionPending = isPending || batchConfirmIsPending;
  const selectedCustomers =
    events
      ?.filter((event) => eventsSelected.includes(event.id))
      .map((event) => event.customer_id) ?? [];

  return (
    <ScrollableLayout ref={scrollRef}>
      <PolicyGate policy="events.view">
        {eventTypes && (
          <EventSearchForm
            onSubmit={handleSearch}
            refetch={refetch}
            eventTypes={eventTypes}
            defaultValues={searchState}
            recordsFound={recordsFound}
          >
            <Checkbox
              checked={allSelected}
              onCheckedChange={selectAddEvents}
              className="mx-2 hidden md:inline-block"
            />
            <DropdownMenu>
              <DropdownMenuTrigger asChild disabled={recordsFound === 0}>
                <Button type="button" variant="outline" className="mx-auto hidden md:inline-block">
                  Bulk Actions {eventsSelected.length > 0 && `(${eventsSelected.length})`}
                  {bulkActionPending && <Spinner className="ml-2" size="sm" />}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent side="bottom" align="start">
                <DropdownMenuLabel>All ({recordsFound})</DropdownMenuLabel>
                <PolicyGate policy="events.export">
                  <DropdownMenuItem
                    disabled={recordsFound === 0}
                    onClick={handleExportResults}
                    className="cursor-pointer"
                  >
                    <CloudArrowDownIcon className="mr-2 h-4 w-4" />
                    Export Results
                    {exportIsPending && <Spinner className="ml-2" size="sm" />}
                  </DropdownMenuItem>
                </PolicyGate>

                <CustomerListDropdownMenuGroup
                  disabled={recordsFound === 0}
                  actionText="Add Results to List"
                  onListClick={handleAddResultsToCustomerList}
                />

                <DropdownMenuSeparator />
                <DropdownMenuLabel>Selected ({eventsSelected.length})</DropdownMenuLabel>
                <DropdownMenuItem
                  disabled={eventsSelected.length === 0}
                  onClick={() => handleDownloadInvoiceBatch('open')}
                  className="cursor-pointer"
                >
                  <DocumentIcon className="mr-2 h-4 w-4" />
                  View Invoices
                </DropdownMenuItem>
                <DropdownMenuItem
                  disabled={eventsSelected.length === 0}
                  onClick={() => handleDownloadInvoiceBatch('download')}
                  className="cursor-pointer"
                >
                  <ArrowDownTrayIcon className="mr-2 h-4 w-4" />
                  Download Invoices
                </DropdownMenuItem>
                <PolicyGate policy="events.confirm">
                  <DropdownMenuItem
                    disabled={eventsSelected.length === 0}
                    onClick={handleBatchConfirm}
                    className="cursor-pointer"
                  >
                    <CheckCircleIcon className="mr-2 h-4 w-4" />
                    Confirm Events
                  </DropdownMenuItem>
                </PolicyGate>
                <CustomerListDropdownMenuGroup
                  disabled={eventsSelected.length === 0}
                  customerIds={selectedCustomers}
                />
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  disabled={eventsSelected.length === 0}
                  onClick={clearSelectedEvents}
                  className="cursor-pointer"
                >
                  Clear Selection
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </EventSearchForm>
        )}

        {isLoading || isFetching ? (
          <ListingPlaceholder />
        ) : isError ? (
          <ErrorResult error={error} />
        ) : events && events.length === 0 ? (
          <NoResults label="events" />
        ) : (
          events &&
          events.length > 0 && (
            <>
              <List>
                {events.map((event: Event) => (
                  <Sentry.ErrorBoundary
                    key={event.id}
                    fallback={() => EventResultFallback({ event })}
                    beforeCapture={(scope) => {
                      scope.setTag('boundary', 'event-result');
                    }}
                  >
                    <EventResult
                      event={event}
                      selected={eventsSelected.includes(event.id)}
                      setSelected={selectEvent}
                    />
                  </Sentry.ErrorBoundary>
                ))}
              </List>
              {paginator && <Paginator page={page} lastPage={lastPage} onPageChange={gotoPage} />}
              <div className="mt-20 mb-96 flex flex-row items-start justify-center">
                <Button variant="outline" onClick={scrollToTop}>
                  Back to Top
                </Button>
              </div>
            </>
          )
        )}
      </PolicyGate>
    </ScrollableLayout>
  );
};

interface EventResultFallbackProps {
  event: Event;
}

const EventResultFallback = ({ event }: EventResultFallbackProps) => {
  return (
    <div className="border-y bg-white text-sm my-0 py-8 text-center">
      ERROR: Could not load event data for EID #{event.event_id}
    </div>
  );
};
