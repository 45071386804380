import { Button } from '@/components/ui/elements/button';
import { Checkbox } from '@/components/ui/elements/checkbox';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/elements/dialog';
import { DialogProps } from '@radix-ui/react-dialog';
import { useState } from 'react';

type SendContractDialogProps = DialogProps & {
  onSubmit: (data: SendContracTypes) => void;
};

type SendContracTypes = {
  types: string[];
};

export const SendContractDialog = ({ onSubmit, ...props }: SendContractDialogProps) => {
  const [contracts, setContracts] = useState<string[]>([]);

  const handleSelectBasic = () => {
    if (contracts.includes('event')) {
      setContracts(contracts.filter((contract) => contract !== 'event'));
    } else {
      setContracts([...contracts, 'event']);
    }
  };

  const handleSelectRental = () => {
    if (contracts.includes('rental')) {
      setContracts(contracts.filter((contract) => contract !== 'rental'));
    } else {
      setContracts([...contracts, 'rental']);
    }
  };

  const handleSubmit = () => {
    onSubmit({
      types: contracts,
    });
    if (props.onOpenChange) props.onOpenChange(false);
  };

  return (
    <Dialog {...props}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Event Contracts</DialogTitle>
          <DialogDescription>
            <span className="flex flex-row justify-start items-center py-4">
              <Checkbox
                defaultChecked={contracts.includes('event')}
                onCheckedChange={() => handleSelectBasic()}
                className="mr-2"
              />
              <label>Event Contract</label>
            </span>
            <span className="flex flex-row justify-start items-center py-4">
              <Checkbox
                defaultChecked={contracts.includes('rental')}
                onCheckedChange={() => handleSelectRental()}
                className="mr-2"
              />
              <label>Rental Agreement</label>
            </span>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button onClick={handleSubmit}>Send Contract Request</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
