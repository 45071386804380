import {
  CreateDTO,
  useCreateMutationFactory,
  UseCreateMutationProps,
} from '@/lib/react-query/create';
import { ModelID, Vip } from '@/types';
import { create } from '@/utils/api';
import { featureKeys, featurePath, VipSearchParams } from '.';

export type CreateVipDTO = CreateDTO<
  Vip & {
    targetKey?: ModelID;
    mode?: 'lead' | 'event';
  },
  VipSearchParams
>;

const createVip = ({ data, params = {} }: CreateVipDTO) =>
  create<Vip>(`${featurePath}`, data, params);

export const useCreateVip = (options?: UseCreateMutationProps<Vip>) => {
  return useCreateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: createVip,
    ...options,
  });
};
