import { CalendarEventChipRenderProps } from '../types';
import { CalendarChip } from './CalendarChip';

export const BlackoutChip = ({ eventInfo }: CalendarEventChipRenderProps) => {
  const { timeText, type, reason } = eventInfo.event.extendedProps;

  let styles;
  switch (type) {
    case 'Repairs':
    case 'Service/Maintenance':
      styles = {
        backgroundColor: 'rgb(248 195 156)',
        background:
          'repeating-linear-gradient(45deg,rgb(248 195 156),rgb(248 195 156) 10px,rgb(226 169 128) 10px,rgb(226 169 128) 20px)',
        border: '1px solid rgb(226 169 128)',
      };
      break;
    case 'Schedule Closed':
    case 'Holiday':
    case 'Note':
    case 'Vehicle Reserved':
      styles = {
        backgroundColor: 'rgb(202 231 208)',
        background:
          'repeating-linear-gradient(45deg,rgb(202 231 208),rgb(202 231 208) 10px,rgb(174 202 180) 10px,rgb(174 202 180) 20px)',
        border: '1px solid rgb(174 202 180)',
      };
      break;
    default:
      styles = {
        backgroundColor: 'rgb(252 165 165)',
        background:
          'repeating-linear-gradient(45deg,rgb(252 165 165),rgb(252 165 165) 10px,rgb(248 113 113) 10px,rgb(248 113 113) 20px)',
        border: '1px solid rgb(248 113 113)',
      };
  }

  return (
    <CalendarChip eventInfo={eventInfo} className="text-gray-900 font-bold" style={styles}>
      <span>{timeText}</span>
      <span>{type ?? 'Blackout'}</span>
      <span>{reason}</span>
    </CalendarChip>
  );
};
