import { cn } from '@/utils/format';
import { Slot } from '@radix-ui/react-slot';
import { cva, VariantProps } from 'cva';
import * as React from 'react';
import { inputVariants } from './input';

const buttonVariants = cva(
  [
    'inline-flex items-center justify-center',
    'border ring-offset-background transition-colors',
    'disabled:text-color-400 disabled:cursor-not-allowed',
    'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
  ],
  {
    variants: {
      variant: {
        default: 'bg-primary-500 text-white hover:bg-primary-600',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80 hover:opacity-80',
        outline:
          'border border-input bg-white hover:bg-accent hover:text-accent-foreground hover:opacity-80',
        field: inputVariants({ variant: 'default' }),
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90 hover:opacity-80',
        ghost: 'border-0 hover:bg-accent hover:text-accent-foreground hover:opacity-80',
        link: 'underline-offset-4 hover:underline text-primary-500 border-0 hover:text-primary-600',
        icon: 'bg-white border hover:opacity-80',
        action: 'bg-white text-gray-600 hover:bg-gray-200/80 hover:opacity-80',
      },
      size: {
        xs: 'px-2 py-1 text-xs shadow-sm font-base',
        sm: 'px-3 py-1 text-sm shadow-sm font-base',
        md: 'px-4 py-1.5 text-sm shadow-sm font-base',
        lg: 'px-4 py-2 text-sm shadow-sm font-semibold',
        xl: 'px-5 py-2.5 text-sm shadow-md font-semibold',
      },
    },
    compoundVariants: [
      {
        variant: ['default', 'secondary', 'outline', 'destructive'],
        size: ['xs', 'sm', 'md', 'lg', 'xl'],
        class: 'rounded-sm',
      },
      {
        variant: 'action',
        size: ['xs', 'sm', 'md', 'lg', 'xl'],
        class: 'rounded-sm px-1 py-1',
      },
      { variant: 'icon', size: ['xs', 'sm', 'md', 'lg', 'xl'], class: 'rounded-full p-0' },
      { variant: 'link', size: ['xs', 'sm', 'md', 'lg', 'xl'], class: 'p-0 m-0 shadow-none' },
    ],
    defaultVariants: {
      variant: 'default',
      size: 'md',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp className={cn(buttonVariants({ variant, size }), className)} ref={ref} {...props} />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
