import { ChangeLogNode } from '@/types';
import { cn, formatUTCDate } from '@/utils/format';
import { Fragment } from 'react';

interface ChangeLogProps {
  changes: ChangeLogNode[];
  className?: string;
}

export const ChangeLog = ({ changes, className }: ChangeLogProps) => {
  return (
    <div className={cn('', className)}>
      <table className="border-collapse border border-gray-300 table-auto w-full divide-y divide-gray-200">
        <tbody>
          {changes?.map((node) => (
            <Fragment key={`cl-date-${node.date}`}>
              <tr className="bg-gray-700 text-white text-sm">
                <td className="px-2 py-3" colSpan={5}>
                  {node.date}
                </td>
              </tr>

              <tr className="bg-gray-200 text-gray-700 text-sm font-medium">
                <td className="px-2 py-1">Actor</td>
                <td className="px-2 py-1">Time</td>
                <td className="px-2 py-1">Field</td>
                <td className="px-2 py-1">Old Value</td>
                <td className="px-2 py-1">New Value</td>
              </tr>

              {node.changes.map((change) => {
                return (
                  <tr
                    key={`cl-node-${change.id}`}
                    className="text-sm font-baseline even:bg-gray-50 odd:bg-white"
                  >
                    <td className="px-2 py-1">{change.updated_by?.full_name ?? 'System'}</td>
                    <td className="px-2 py-1">{formatUTCDate(change.created_at, 'h:mm a')}</td>
                    <td className="px-2 py-1">{change.field_name}</td>
                    <td className="px-2 py-1">{change.original_value ?? <i>empty</i>}</td>
                    <td className="px-2 py-1">{change.new_value ?? <i>empty</i>}</td>
                  </tr>
                );
              })}
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};
