import { useAssets } from '@/api/equipment/assets/getAssets';
import { Placeholder } from '@/components/Elements';
import { FormCheckbox, FormDate, FormInput, FormSelect } from '@/components/Form';
import { Badge, BadgeProps } from '@/components/ui/elements/badge';
import { Form, FormAutosave, FormControl, FormField, FormItem } from '@/components/ui/form/form';
import { ALL_START_TIMES, SERVICE_LENGTH_OPTIONS, SETUP_LENGTH_OPTIONS } from '@/config';
import { useCalendarStore } from '@/stores/calendar';
import { Asset, EventActivity, LeadActivity, ModelID } from '@/types';
import { post } from '@/utils/api';
import { formatDate, formatMoney } from '@/utils/format';
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { RadioGroup } from '@radix-ui/react-radio-group';
import { useQuery } from '@tanstack/react-query';
import { addMinutes, isAfter, subMinutes } from 'date-fns';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useShallow } from 'zustand/react/shallow';
import { Button } from '../ui/elements/button';
import { RadioGroupItem } from '../ui/elements/radio-group';

interface ActivityDateTime extends LeadActivity, EventActivity {
  date: string;
  time: string;
}

interface AssetAvailability extends Asset {
  availability: AvailabilityResponse | null;
}

export interface AvailabilityResponse {
  asset_id: number;
  activity_id: number;
  available: boolean;
  gap_next: number | null;
  gap_prev: number | null;
  reason: string | null;
  warning: string | null;
  travel_next: number | null;
  travel_next_is_depot: boolean;
  travel_next_status: string;
  travel_prev: number | null;
  travel_prev_is_depot: boolean;
  travel_prev_status: string;
}

interface ActivityRowProps {
  activity: LeadActivity | EventActivity;
  location_id: ModelID;
  readonly?: boolean;
  olbAdvancedDays?: number;
  onSave: (data: any) => void;
  onDelete: () => void;
}

const schema = z.object({
  base_price: z.coerce.number().gte(0, 'Required'),
  addl_hour_price: z.coerce.number().gte(0, 'Required'),
  taxable: z.coerce.boolean(),
  price_locked: z.coerce.boolean(),
  duration: z.coerce.number(),
  date: z.coerce.string().min(1, 'Required'),
  time: z.coerce.string().min(1, 'Required'),
  setup: z.coerce.number(),
  teardown: z.coerce.number(),
  asset_id: z.coerce
    .number({
      invalid_type_error: 'You must assign equipment to this activity!',
      required_error: 'You must assign equipment to this activity!',
    })
    .gt(0, 'You must assign equipment to this activity!'),
});

const timeOptions = ALL_START_TIMES;

const durationOptions = SERVICE_LENGTH_OPTIONS;

const setupOptions = [{ label: 'Setup', value: 0 }, ...SETUP_LENGTH_OPTIONS.slice(1)];

const teardownOptions = [{ label: 'Teardown', value: 0 }, ...SETUP_LENGTH_OPTIONS.slice(1)];

const getAvailability = (
  location_id: ModelID,
  activity: LeadActivity | EventActivity,
  force_past_travel: boolean,
) => {
  let config = {};

  if ('event_id' in activity) {
    config = {
      event_id: activity.event_id,
      mode: 'event',
      force_past_travel,
    };
  } else {
    config = {
      lead_id: activity.lead_id,
      mode: 'lead',
      force_past_travel,
    };
  }

  return post<AvailabilityResponse[]>(
    `/v4/franchises/franchise/${location_id}/get-availability`,
    config,
  );
};

export const ActivityRow = ({
  activity,
  location_id,
  readonly = false,
  olbAdvancedDays = 6,
  onSave,
  onDelete,
}: ActivityRowProps) => {
  const [pastTravelActive, setPastTravelActive] = useState(false);
  const isFutureEventDate = isAfter(new Date(activity.start_at.replace(' ', 'T')), new Date());

  const daysUntilDateWanted =
    !('event_id' in activity) && activity.start_at
      ? Math.ceil(
          (new Date(activity.start_at.replace(' ', 'T')).getTime() - new Date().getTime()) /
            86400000,
        )
      : 1;

  const { data: assets } = useAssets({
    location: location_id,
    search: { active: true },
    config: {
      staleTime: 1000 * 60 * 60 * 24,
    },
  });

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      ...activity,
      duration: activity.duration || 60,
      setup: activity.setup || 0,
      teardown: activity.teardown || 0,
      date: formatDate(activity.start_at, 'yyyy-MM-dd'),
      time: formatDate(activity.start_at, 'HH:mm'),
    },
  });

  const {
    data: availability,
    isFetching,
    isPending,
    isFetched,
    isSuccess,
  } = useQuery({
    queryKey: [
      'availability',
      { id: 'event_id' in activity ? activity.event_id : activity.lead_id },
    ],
    queryFn: () => getAvailability(location_id, activity, pastTravelActive || isFutureEventDate),
    enabled: pastTravelActive || isFutureEventDate,
  });

  const { addTemporaryEvent, removeTemporaryEvent } = useCalendarStore(
    useShallow((state) => ({
      addTemporaryEvent: state.addTemporaryEvent,
      removeTemporaryEvent: state.removeTemporaryEvent,
    })),
  );

  useEffect(() => {
    if (!('event_id' in activity) && availability) {
      const travelPlan = availability.find((record) => record.asset_id === activity.asset_id);

      const preTravelStart =
        !activity.arrive_at || activity.arrive_at === activity.start_at
          ? addMinutes(new Date(activity.start_at.replace(' ', 'T')), activity.setup || 0)
          : new Date(activity.arrive_at.replace(' ', 'T'));

      if (travelPlan?.travel_prev) {
        let travelPrevDuration = 30;
        travelPrevDuration = travelPlan?.travel_prev > 10 ? travelPlan?.travel_prev : 10;

        // Add Travel Time Temp Chip
        addTemporaryEvent({
          ...activity,
          id: `temp-pre-travel-${activity.id}`,
          cal_event_type: 'travel',
          resourceId: activity.asset_id,
          title: 'Travel',
          start: subMinutes(new Date(preTravelStart), travelPrevDuration ?? 30),
          end: preTravelStart,

          backgroundColor: '#ebebeb',
          borderColor: '#676767',
          textColor: 'black',
        });
      }

      const postTravelStart =
        !activity.depart_at || activity.depart_at === activity.end_at
          ? addMinutes(new Date(activity.end_at.replace(' ', 'T')), activity.teardown || 0)
          : new Date(activity.depart_at.replace(' ', 'T'));

      if (travelPlan?.travel_next) {
        let travelNextDuration = 30;
        travelNextDuration = travelPlan?.travel_next > 10 ? travelPlan?.travel_next : 10;

        // Add Travel Time Temp Chip
        addTemporaryEvent({
          ...activity,
          id: `temp-post-travel-${activity.id}`,
          cal_event_type: 'travel',
          resourceId: activity.asset_id,
          title: 'Travel',
          start: postTravelStart,
          end: addMinutes(new Date(postTravelStart), travelNextDuration ?? 30),

          backgroundColor: '#ebebeb',
          borderColor: '#676767',
          textColor: 'black',
        });
      }
    }

    return () => {
      if (!('event_id' in activity)) removeTemporaryEvent(`temp-post-travel-${activity.id}`);
    };
  }, [activity, availability]);

  const assetList = (
    assets?.filter((asset) => asset.asset_type_id === activity.service.asset_type_id) || []
  ).map((asset): AssetAvailability => {
    return {
      ...asset,
      availability:
        availability?.find(
          (record) =>
            record.asset_id === asset.asset_id && record.activity_id === activity.activity_id,
        ) || null,
    };
  });

  const handleSave = (data: Partial<ActivityDateTime>) => {
    if (!data.date || data.date === 'undefined') return;
    data.start_at = `${data.date} ${data.time}:00`;
    onSave(data);
  };

  // Save changes when leaving this page!
  useEffect(() => {
    return () => {
      if (form.formState.isDirty) {
        form.handleSubmit(handleSave)();
      }
    };
  }, []);

  return (
    <div className="py-2 px-3 border-b bg-white shadow rounded-lg">
      <div className="flex flex-row pb-4">
        {!readonly && (
          <TrashIcon
            className="h-6 w-6 p-1 mr-3 hover:bg-red-300 hover:cursor-pointer rounded"
            onClick={onDelete}
          />
        )}
        <div className="grow font-semibold">{activity.service.name}</div>
        <div className="text-right font-bold">{formatMoney(activity.total)}</div>
      </div>

      <Form {...form}>
        <FormAutosave interval={1000} onAutosave={form.handleSubmit(handleSave)}>
          <form onSubmit={form.handleSubmit(handleSave)}>
            <fieldset disabled={readonly} className="space-y-3">
              <div className="@container flex flex-col">
                <div className="grid gap-1 gap-y-3 grid-cols-1 @sm:grid-cols-2 @md:grid-cols-5 pt-1.5">
                  <FormSelect
                    control={form.control}
                    name="duration"
                    options={durationOptions}
                    label="Duration"
                  />
                  <FormInput control={form.control} name="base_price" label="Base" />
                  <FormInput control={form.control} name="addl_hour_price" label="Add'l Hr" />
                  <FormCheckbox control={form.control} name="taxable" label="Tax" />
                  <FormCheckbox control={form.control} name="price_locked" label="$Locked" />
                </div>
                <div className="grid gap-1 gap-y-3 grid-cols-1 @sm:grid-cols-2 @md:grid-cols-4 pt-1.5">
                  <span className="">
                    <FormDate
                      disabled={readonly}
                      control={form.control}
                      name="date"
                      label="Date"
                      rules={{ required: true }}
                    />
                  </span>
                  <span className="">
                    <FormSelect
                      control={form.control}
                      options={timeOptions}
                      name="time"
                      label="Time"
                    />
                  </span>
                  <span className="">
                    <FormSelect
                      control={form.control}
                      name="setup"
                      options={setupOptions}
                      label="Setup"
                    />
                  </span>
                  <span className="">
                    <FormSelect
                      control={form.control}
                      name="teardown"
                      options={teardownOptions}
                      label="Teardown"
                    />
                  </span>
                </div>
              </div>

              {(!!form.formState.errors.asset_id || !activity.asset_id) && (
                <div className="text-xs pt-1 pl-1 font-semibold text-red-500">
                  You must assign equipment to this activity!
                </div>
              )}

              {!('event_id' in activity) && daysUntilDateWanted < olbAdvancedDays && (
                <div
                  className="flex flex-row items-center p-1.5 text-sm bg-orange-100 text-orange-500 rounded-md"
                  role="alert"
                >
                  <ExclamationCircleIcon className="w-6 h-6 mr-1.5" />
                  Warning: Date too soon to book online. {daysUntilDateWanted} days away, but
                  configured limit is {olbAdvancedDays} days.
                </div>
              )}

              <div className="flex flex-col">
                <FormField
                  control={form.control}
                  name="asset_id"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <RadioGroup
                          onValueChange={field.onChange}
                          defaultValue={field.value?.toString()}
                          className="flex flex-col space-y-1"
                        >
                          {assetList?.map((asset, i) => (
                            <div
                              key={`asset-${asset.asset_id}-${activity.activity_id}`}
                              className="flex flex-col text-xs"
                            >
                              <div className="space-x-1 py-1 align-middle">
                                <span>
                                  <RadioGroupItem
                                    value={asset.id.toString()}
                                    id={`asset${asset.id}`}
                                  />
                                </span>
                                <label
                                  className="cursor-pointer font-semibold"
                                  htmlFor={`asset${asset.id}`}
                                >
                                  {asset.name} ({asset.abbr})
                                </label>
                                {isFetching ? (
                                  <>
                                    <Placeholder size="sm" className="w-36" />
                                    <Placeholder size="sm" className="w-36" />
                                  </>
                                ) : (
                                  <>
                                    {!pastTravelActive && !isFutureEventDate && (
                                      <Button
                                        variant="link"
                                        className="text-xs"
                                        onClick={() => setPastTravelActive(true)}
                                      >
                                        Past date - load travel anyway?
                                      </Button>
                                    )}
                                    {asset.availability && (
                                      <>
                                        <TravelBadge
                                          status={asset.availability.travel_prev_status}
                                          travel_time={asset.availability.travel_prev}
                                          is_depot={asset.availability.travel_prev_is_depot}
                                          gap={asset.availability.gap_prev}
                                          prep="from"
                                        />
                                        <TravelBadge
                                          status={asset.availability.travel_next_status}
                                          travel_time={asset.availability.travel_next}
                                          is_depot={asset.availability.travel_next_is_depot}
                                          gap={asset.availability.gap_next}
                                          prep="to"
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                              <div>
                                {asset.availability && (
                                  <>
                                    <NotAvailableWarning availability={asset.availability} />
                                    <AvailabilityWarning availability={asset.availability} />
                                  </>
                                )}
                              </div>
                            </div>
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            </fieldset>
          </form>
        </FormAutosave>
      </Form>
    </div>
  );
};

interface TravelBadgeProps {
  status: string;
  travel_time: number | null;
  is_depot: boolean;
  gap: number | null;
  prep: 'to' | 'from';
}

export const TravelBadge = ({ status, travel_time, is_depot, gap, prep }: TravelBadgeProps) => {
  let variant = '' as BadgeProps['variant'];
  switch (status) {
    case 'warn':
      variant = 'yellow';
      break;
    case 'bad':
    case 'error':
      variant = 'red';
      break;
    default:
      variant = 'green';
      break;
  }

  if (travel_time === null || travel_time === undefined) return <></>;

  return (
    <Badge variant={variant} size="sm" role="status">
      <span className="px-0.5 font-bold">{travel_time} mins</span>
      {is_depot ? (
        <>{prep} depot</>
      ) : (
        <>
          {prep} event ({gap})
        </>
      )}
    </Badge>
  );
};

interface AvailabilityWarningProps {
  availability: AvailabilityResponse;
}

export const NotAvailableWarning = ({ availability }: AvailabilityWarningProps) => {
  if (availability.available) return <></>;

  return (
    <div className="flex flex-row items-center">
      <div className="min-w-24 ml-5 mr-3">
        <ExclamationTriangleIcon className="w-6 h-6 text-red-500" />
      </div>
      <div role="alert" className="text-red-500">
        {availability.reason}
      </div>
    </div>
  );
};

export const AvailabilityWarning = ({ availability }: AvailabilityWarningProps) => {
  if (!availability.warning) return <></>;

  return (
    <div className="flex flex-row items-center">
      <div className="min-w-24 ml-5 mr-3">
        <ExclamationCircleIcon className="w-6 h-6 text-orange-500" />
      </div>
      <div role="alert" className="grow text-orange-500">
        {availability.warning}
      </div>
    </div>
  );
};
