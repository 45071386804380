'use client';

import { cn } from '@/utils/format';
import { cva, VariantProps } from 'cva';
import * as React from 'react';

const badgeVariants = cva(
  'inline-flex items-center border rounded-full font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'bg-primary hover:bg-primary/80 border-transparent text-primary-foreground',
        secondary:
          'bg-secondary hover:bg-secondary/80 border-transparent text-secondary-foreground',
        destructive:
          'bg-destructive hover:bg-destructive/80 border-transparent text-destructive-foreground',
        destructiveLight:
          'bg-destructive-foreground hover:bg-destructive/20 border-transparent text-destructive',
        outline: 'text-foreground',
        gray: 'bg-gray-100 text-gray-800',
        red: 'bg-red-100 text-red-800 border-red-200',
        green: 'bg-green-100 text-green-800 border-green-200',
        blue: 'bg-blue-100 text-blue-800 border-blue-200',
        indigo: 'bg-indigo-100 text-indigo-800 border-indigo-200',
        purple: 'bg-purple-100 text-purple-800 border-purple-200',
        pink: 'bg-pink-100 text-pink-800 border-pink-200',
        yellow: 'bg-yellow-200 text-yellow-800 border-yellow-300',
        orange: 'bg-orange-100 text-orange-800 border-orange-200',
      },
      size: {
        xs: 'px-1.5 py-0 text-[10px]',
        sm: 'px-2.5 py-0.5 text-xs',
        lg: 'px-3 py-0.5 text-sm',
      },
    },
    defaultVariants: {
      variant: 'gray',
      size: 'lg',
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  onRemove?: (event: React.SyntheticEvent) => void;
}

function Badge({ className, size, color, variant, onRemove, children, ...props }: BadgeProps) {
  const removable = !!onRemove;
  return (
    <span className={cn(badgeVariants({ size, variant }), className)} {...props}>
      {children}
      {removable && (
        <button
          type="button"
          className="group relative ml-1 -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20"
          onClick={onRemove}
        >
          <span className="sr-only">Remove</span>
          <svg
            viewBox="0 0 14 14"
            className="h-3.5 w-3.5 stroke-gray-600/50 group-hover:stroke-gray-600/75"
          >
            <title>Remove</title>
            <path d="M4 4l6 6m0-6l-6 6" />
          </svg>
          <span className="absolute -inset-1" />
        </button>
      )}
    </span>
  );
}

export { Badge, badgeVariants };
