import { Form, FormAutosave } from '@/components/ui/form/form';
import { EventCouponLineItem, LeadCouponLineItem } from '@/types';
import { formatMoney } from '@/utils/format';
import { TrashIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormCheckbox } from '../Form/FormCheckbox';

interface CouponRowProps {
  couponLineItem: LeadCouponLineItem | EventCouponLineItem;
  readonly?: boolean;
  onSave: (data: any) => void;
  onDelete: () => void;
}

const schema = z.object({
  taxable: z.coerce.boolean(),
});

export const CouponRow = ({
  couponLineItem,
  readonly = false,
  onSave,
  onDelete,
}: CouponRowProps) => {
  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: couponLineItem,
  });

  const handleSave = (data: Partial<typeof couponLineItem>) => {
    onSave(data);
  };

  // Save changes when leaving this page!
  useEffect(() => {
    return () => {
      if (form.formState.isDirty) {
        form.handleSubmit(handleSave)();
      }
    };
  }, []);

  return (
    <div>
      <Form {...form}>
        <FormAutosave interval={1000} onAutosave={form.handleSubmit(handleSave)}>
          <form onSubmit={form.handleSubmit(handleSave)}>
            <fieldset
              disabled={readonly}
              className="flex flex-row items-center space-x-2 space-y-3"
            >
              {!readonly && (
                <span className="pl-3 mt-2">
                  <TrashIcon
                    className="h-6 w-6 p-1 mr-3 hover:bg-red-300 hover:cursor-pointer rounded"
                    onClick={onDelete}
                  />
                </span>
              )}
              <div className="grow flex-col">
                <span className="text-sm leading-tight">Coupon: {couponLineItem.coupon.code}</span>
              </div>
              <span className="w-24">
                <FormCheckbox control={form.control} name="taxable" label="Tax" />
              </span>
              <div className="w-12 pr-2 text-sm font-semibold text-right pt-1.5">
                -{formatMoney(couponLineItem.coupon.value)}
              </div>
            </fieldset>
          </form>
        </FormAutosave>
      </Form>
    </div>
  );
};
