import { Avatar } from '@/components/Elements';
import { Customer } from '@/types';

interface CustomerAvatarProps {
  customer: Customer | null | undefined;
}

export const CustomerAvatar = ({ customer }: CustomerAvatarProps) => {
  const customerInitials =
    (customer?.first_name ? customer.first_name[0] : '?') +
    (customer?.last_name ? customer.last_name[0] : '?');

  return (
    <Avatar
      alt={customer?.full_name ?? ''}
      fallback={customerInitials}
      size="xl"
      className="bg-purple-700 text-white mr-2"
    />
  );
};
