import { useQrCode } from '@/hooks/useQrCode';
import { cn } from '@/utils/format';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { FileExtension, Options } from 'qr-code-styling';
import { Button } from '../ui/elements/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/elements/dropdown-menu';
import fireball from './../../assets/fireball.svg';

type QrCodeProps = {
  url: string;
  useFireball?: boolean;
  options?: Options;
  className?: string;
  size?: number;
};

type FileExtensionOptions = {
  value: FileExtension;
  label: string;
};

const FileExtOptions = [
  {
    value: 'png',
    label: 'PNG - Recommended',
  },
  {
    value: 'jpeg',
    label: 'JPEG',
  },
  {
    value: 'svg',
    label: 'SVG',
  },
  {
    value: 'webp',
    label: 'WEBP',
  },
] as FileExtensionOptions[];

export const QrCode = ({
  url,
  options = {},
  useFireball = true,
  size = 400,
  className = '',
}: QrCodeProps) => {
  const _options = {
    ...options,
    data: url,
    width: size,
    height: size,
    margin: size * 0.025,
    image: useFireball ? fireball : undefined,
  };

  const _printOptions = {
    ..._options,
    width: 1200,
    height: 1200,
    margin: 50,
  };

  const { ref: displayRef } = useQrCode(_options);
  const { qrCode: printableQrCode, ref: printableRef } = useQrCode(_printOptions);

  const onDownloadClick = (extension: FileExtension) => {
    if (!printableQrCode) return;
    printableQrCode.download({
      extension,
    });
  };

  return (
    <>
      <div
        className={cn(
          'flex flex-col mx-auto text-center border border-gray-600 rounded p-1',
          'max-w-[400px] max-h-[400px] overflow-hidden',
          className,
        )}
      >
        <div className="relative">
          <div className={cn('absolute z-1 top-2/3 left-1/2', `left-[calc(50%-61px)]`)}>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline">
                  <ArrowDownTrayIcon className="mr-2 h-4 w-4" />
                  Download
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {FileExtOptions.map((option) => (
                  <DropdownMenuItem
                    key={`select-${option.value}`}
                    onClick={() => onDownloadClick(option.value)}
                  >
                    {option.label}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <div ref={displayRef} />
        </div>
      </div>
      <div className="hidden" ref={printableRef} />
    </>
  );
};
