import { cn } from '@/utils/format';
import { Slot } from '@radix-ui/react-slot';
import { forwardRef } from 'react';

export const inlineButtonDefaultStyles =
  'relative items-center bg-white -ml-px px-2 py-2 text-xs font-semibold text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-primary-600 hover:text-white focus:z-10 transition-all';

interface ButtonGroupProps {
  children: React.ReactNode;
  className?: string;
}

const ButtonGroup = ({ children, className }: ButtonGroupProps) => {
  return <span className={cn('isolate flex rounded-md shadow-sm', className)}>{children}</span>;
};
ButtonGroup.displayName = 'ButtonGroup';

interface ButtonGroupButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isFirst?: boolean;
  isLast?: boolean;
  isActive?: boolean;
  asChild?: boolean;
}

const ButtonGroupButton = forwardRef<HTMLButtonElement, ButtonGroupButtonProps>(
  (
    { className, asChild = false, isFirst = false, isLast = false, isActive = false, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(
          inlineButtonDefaultStyles,
          'inline-flex px-3',
          isFirst ? 'rounded-l-md' : '',
          isLast ? 'rounded-r-md' : '',
          isActive ? 'bg-primary text-white' : '',
          props.disabled ? 'text-gray-200 cursor-not-allowed' : '',
          className,
        )}
        type="button"
        ref={ref}
        {...props}
      />
    );
  },
);
ButtonGroup.Button = ButtonGroupButton;

export default ButtonGroup;
