import { Textarea } from '@/components/ui/elements/textarea';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  useFormAutosave,
} from '@/components/ui/form/form';
import { cn } from '@/utils/format';
import { VariantProps } from 'cva';
import { ClassProp } from 'cva/dist/types';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { formFieldVariants } from './FormInput';

type FormTextareaProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  ClassProp &
  VariantProps<typeof formFieldVariants> & {
    label?: string;
    description?: string;
    placeholder?: string;
    onFieldFocus?: (value?: string) => void;
    onFieldBlur?: (value: string) => void;
  };

export const FormTextarea = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  placeholder,
  label,
  description,
  variant,
  className,
  onFieldFocus,
  onFieldBlur,
  ...props
}: FormTextareaProps<TFieldValues, TName>) => {
  const autosave = useFormAutosave();

  return (
    <FormField
      control={control}
      name={name}
      render={({ field: { onBlur, ...field } }) => (
        <FormItem className="relative">
          {label && <FormLabel className={cn(formFieldVariants({ variant }))}>{label}</FormLabel>}
          <FormControl>
            <Textarea
              placeholder={placeholder}
              className={cn('resize-none', className)}
              onFocus={(event) => {
                if (onFieldFocus) onFieldFocus(event.target.value);
                if (autosave.enabled) autosave.setPaused(true);
              }}
              onBlur={(event) => {
                if (onFieldBlur) onFieldBlur(event.target.value);
                if (autosave.enabled) autosave.setPaused(false);
                onBlur();
              }}
              {...field}
            />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
      {...props}
    />
  );
};
