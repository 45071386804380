import { EventType, ModelID, SearchParams } from '@/types';

export type Filter = 'all';
export type Sort = 'created_at' | 'updated_at' | 'event_type_id' | 'name';
export interface EventTypeSearchParams extends SearchParams<Filter, Sort>, Partial<EventType> {}

export const featurePath = '/v4/support/event-type';

export const featureKeys = {
  all: [{ scope: 'event-types' }] as const,

  lists: (location: ModelID) => [{ ...featureKeys.all[0], location, entity: 'list' }] as const,

  list: (location: ModelID, search: EventTypeSearchParams = {}) =>
    [{ ...featureKeys.lists(location)[0], search }] as const,

  details: () => [{ ...featureKeys.all[0], entity: 'detail' }] as const,

  detail: (id: ModelID) => [{ ...featureKeys.details()[0], id }] as const,
};
