import { featureKeys as eventFeatureKeys } from '@/api/events/events';
import { queryClient } from '@/lib/react-query';
import {
  CreateDTO,
  useCreateMutationFactory,
  UseCreateMutationProps,
} from '@/lib/react-query/create';
import { Event, EventNote } from '@/types';
import { create } from '@/utils/api';
import { EventNoteSearchParams, featureKeys, featurePath } from '.';

export type CreateNoteDTO = CreateDTO<EventNote, EventNoteSearchParams>;

const createEventNote = ({ data, params = {} }: CreateNoteDTO) =>
  create<EventNote>(`${featurePath}`, data, params);

export const useCreateEventNote = (options?: UseCreateMutationProps<EventNote>) => {
  return useCreateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: createEventNote,
    ...options,
    onSuccess: (newData) => {
      queryClient.setQueriesData({ queryKey: featureKeys.lists() }, (previous: any) => [
        newData,
        ...previous,
      ]);
      queryClient.setQueriesData({ queryKey: eventFeatureKeys.all }, (previous: any) => {
        if (Array.isArray(previous)) {
          const remapped = previous.map((item) => {
            if (item.id === newData.event_id) {
              item.notes = [newData, ...item.notes];
              return item;
            }
            return item;
          });

          return [...remapped];
        } else if (previous.paginator && previous.results) {
          const results = previous.results.map((item: Event) => {
            if (item.id === newData.event_id) {
              item.notes = [newData, ...item.notes];
              return { ...item };
            }
            return { ...item };
          });

          return {
            ...previous,
            results: [...results],
          };
        } else {
          previous.notes = [newData, ...previous.notes];

          return { ...previous };
        }
      });
    },
  });
};
