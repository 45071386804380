import { PolicyCheck } from '@/features/auth/authorization';

export const InspectionsPolicies: Record<string, PolicyCheck> = {
  'equipment.inspections.view': ({ acl, user, meta }) => {
    return acl.equipment.inspections.view;
  },
  'equipment.inspections.create': ({ acl, user, meta }) => {
    return acl.equipment.inspections.create;
  },
  'equipment.inspections.update': ({ acl, user, meta }) => {
    return acl.equipment.inspections.edit;
  },
  'equipment.inspections.delete': ({ acl, user, meta }) => {
    return acl.equipment.inspections.delete;
  },
};
