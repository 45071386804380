import { useBookingValidation } from '@/api/leads/leads/getBookingValidation';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/elements/tooltip';
import { cn } from '@/utils/format';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { useContext } from 'react';
import { LeadContext } from '../../contexts/LeadContext';

export const OnlineBookingValidation = () => {
  const { lead } = useContext(LeadContext);

  if (!lead) throw new Error('No lead has been loaded...');

  const { data, isPending, isError, error } = useBookingValidation({ lead });

  if (!data || data.length === 0) return <></>;

  const isCritial = data.some((problem) => problem.type === 'critical');

  return (
    <div
      className={cn(
        'w-full text-sm p-4 rounded-lg mb-4',
        isCritial ? 'bg-red-100 text-red-800' : 'bg-yellow-100 text-yellow-800',
      )}
    >
      <div className="flex justify-between">
        <div className="font-bold">Online Booking Notices</div>
        <TooltipProvider delayDuration={50}>
          <Tooltip>
            <TooltipTrigger>
              <QuestionMarkCircleIcon className="h-5 w-5" />
            </TooltipTrigger>
            <TooltipContent side="bottom" align="end" className="w-64">
              Note: These alerts are generated by the online booking system and may prevent the lead
              from booking online. Red alerts are critical and must be resolved before the lead can
              book online. Yellow alerts are less critical but may prevent the lead from booking if
              they attempt to change anything with their quote via the online booking process.
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      {data.map((problem, index) => (
        <span key={index}>
          <p>
            <span className="capitalize font-semibold">{problem.type}</span> - {problem.message}
          </p>
        </span>
      ))}
    </div>
  );
};
