import { useCreateCustomerList } from '@/api/customers/customer-lists/createCustomerList';
import { useDeleteCustomerList } from '@/api/customers/customer-lists/deleteCustomerList';
import { useUpdateCustomerList } from '@/api/customers/customer-lists/updateCustomerList';
import { ConfirmationDialog } from '@/components/Elements';
import { FormInput, FormTextarea } from '@/components/Form';
import { Button } from '@/components/ui/elements/button';
import { Form } from '@/components/ui/form/form';
import { PolicyGate } from '@/features/auth/authorization';
import { LocationContext } from '@/providers/location';
import { CustomerList } from '@/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

type CustomerListFormProps = {
  customerlist?: CustomerList;
  onCreate?: (data: CustomerList) => void;
  onUpdate?: (data: CustomerList) => void;
  onDelete?: () => void;
};

const schema = z.object({
  name: z.string().min(1, 'Required'),
  description: z.string().nullish(),
});

export const CustomerListForm = ({
  customerlist,
  onCreate,
  onUpdate,
  onDelete,
}: CustomerListFormProps) => {
  const { activeLocationId } = useContext(LocationContext);

  const { mutate: createCustomerList } = useCreateCustomerList({
    onSuccess: onCreate,
  });
  const { mutate: updateCustomerList } = useUpdateCustomerList({
    onSuccess: onUpdate,
  });
  const { mutate: deleteCustomerList } = useDeleteCustomerList({
    onSuccess: onDelete,
  });

  const handleSubmit = (values: z.infer<typeof schema>) => {
    if (customerlist) {
      handleUpdate(values);
    } else {
      handleCreate(values);
    }
  };

  const handleCreate = (values: z.infer<typeof schema>) => {
    createCustomerList({
      data: { ...values, franchise_id: activeLocationId },
      params: {},
    });
  };

  const handleUpdate = (values: z.infer<typeof schema>) => {
    if (customerlist)
      updateCustomerList({
        data: values,
        id: customerlist.id,
        params: {},
      });
  };

  const handleDelete = () => {
    if (customerlist) deleteCustomerList({ id: customerlist.id });
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: customerlist?.name ?? '',
      description: customerlist?.description ?? '',
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-3">
        <FormInput
          control={form.control}
          name="name"
          label="Name"
          placeholder="Name your list..."
        />

        <FormTextarea control={form.control} name="description" label="Description" />
        <div className="flex justify-between">
          <PolicyGate policy="customers.lists.delete">
            {customerlist && (
              <ConfirmationDialog
                triggerButton={<Button variant="destructive">Delete</Button>}
                confirmButtonText="Delete"
                confirmAction={handleDelete}
                title={`Delete customer list called ${customerlist.name}?`}
                body="Are you sure you want to delete this customer list? This action cannot be undone."
              />
            )}
          </PolicyGate>
          <Button type="submit">Submit</Button>
        </div>
      </form>
    </Form>
  );
};
