import { Scheduler as SchedulerTool } from '@/components/CalendarViewer/Scheduler';
import { Head } from '@/components/Head';
import Page from '@/components/Layout/Page';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/scheduler')({
  component: Scheduler,
});

function Scheduler() {
  return (
    <Page className="py-0">
      <Head title="Calendar" />
      <Page.Body
        policy="calendar.view"
        className="h-[calc(100vh-102px)] md:h-[calc(100vh-26px)] py-0"
      >
        <SchedulerTool />
      </Page.Body>
    </Page>
  );
}
