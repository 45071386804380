import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ChangeLogNode, ChangeLogResponse, Event, ModelID } from '@/types';
import { get, getRaw } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { EventSearchParams, featureKeys, featurePath } from '.';

type QueryFnType = typeof findEvent;
type QueryGetInvoiceFnType = typeof getInvoice;
type QueryChangelogFnType = typeof getChangelog;

type UseEventOptions = {
  id: ModelID;
  search?: EventSearchParams;
  config?: QueryConfig<QueryFnType>;
};

type UseInvoiceOptions = {
  event: Event;
  search?: EventSearchParams;
  config?: QueryConfig<QueryGetInvoiceFnType>;
};

type UseEventChangelogOptions = {
  event: Event;
  search?: EventSearchParams;
  config?: QueryConfig<QueryChangelogFnType>;
};

const findEvent = (id: ModelID, search: any = {}) => get<Event>(`${featurePath}/${id}`, search);

const getInvoice = (id: ModelID) => getRaw<Blob>(`${featurePath}/${id}/invoice`);

const getChangelog = async (event: Event, search?: EventSearchParams): Promise<ChangeLogNode[]> => {
  const response = await get<ChangeLogResponse>(`${featurePath}/${event.id}/changelog`, search);
  return response.changelog;
};

export const useEvent = ({ id, search = {}, config = {} }: UseEventOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: featureKeys.detail(+id),
    queryFn: () => findEvent(id, search),
  });
};

export const useInvoice = ({ event, config = {} }: UseInvoiceOptions) => {
  return useQuery<Blob>({
    ...config,
    queryKey: [{ scope: 'events', entity: 'invoice', id: event.id }],
    queryFn: () => getInvoice(event.id),
  });
};

export const useEventChangelog = ({ event, search, config = {} }: UseEventChangelogOptions) => {
  return useQuery<ChangeLogNode[]>({
    ...config,
    queryKey: [{ scope: 'events', entity: 'changelog', id: event.id, search }],
    queryFn: () => getChangelog(event, search),
  });
};
