import { useHeartbeat } from '@/api/locations/useHeartbeat';
import { formatNumber } from '@/utils/numbers-utils';
import { formatDistanceToNow, fromUnixTime } from 'date-fns';

export const LeadStatsBar = () => {
  const { waiting, waitTime, ttfc, bookingRatio } = useHeartbeat();
  const displayWaitTime = waitTime ? formatDistanceToNow(fromUnixTime(waitTime)) : '--';

  const stats = [
    {
      name: 'Longest Wait',
      value: displayWaitTime,
    },
    {
      name: 'Waiting',
      value: waiting,
    },
    {
      name: 'Booking Ratio',
      value: formatNumber(bookingRatio, 'percent'),
    },
    {
      name: 'TTFC',
      value: formatNumber(ttfc, 'percent'),
    },
  ];

  return (
    <dl className="mx-auto grid gap-px divide-y md:divide-x md:divide-y-0 grid-cols-2 md:grid-cols-4">
      {stats.map((stat) => (
        <div
          key={stat.name}
          className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2  px-4 py-5 sm:px-6 xl:px-8"
        >
          <dt className="text-sm font-medium leading-6 text-gray-500">{stat.name}</dt>
          <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
            {stat.value}
          </dd>
        </div>
      ))}
    </dl>
  );
};
