import { useHeartbeat } from '@/api/locations/useHeartbeat';
import { Badge, BadgeProps } from '@/components/ui/elements/badge';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/elements/dropdown-menu';
import { ClockIcon } from '@heroicons/react/24/outline';
import { useNavigate } from '@tanstack/react-router';
import { formatDistanceToNow, fromUnixTime, intervalToDuration } from 'date-fns';

export const WaitTime = () => {
  const navigate = useNavigate();
  const { waiting, waitTime, nextLeadId, longestWaiting, oldestLead, newestLead } = useHeartbeat();

  if (!waitTime) return <></>;

  const displayWaitTime = formatDistanceToNow(fromUnixTime(waitTime));
  const waitDuration = intervalToDuration({
    start: fromUnixTime(waitTime),
    end: new Date(),
  });

  let condition = 'green' as BadgeProps['variant'];
  // More than 1 hour
  if (waitDuration.hours && waitDuration.hours > 0) {
    condition = 'orange';
  }
  // More than 8 hours
  if (
    (waitDuration.years && waitDuration.years > 0) ||
    (waitDuration.months && waitDuration.months > 0) ||
    (waitDuration.days && waitDuration.days > 0) ||
    (waitDuration.hours && waitDuration.hours > 8)
  ) {
    condition = 'destructiveLight';
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="w-full md:w-48">
        <Badge
          variant={condition}
          size="lg"
          className="hover:cursor-pointer rounded-none w-full md:w-fit md:rounded-full"
        >
          <div className="flex flex-row py-1 items-center">
            <span className="-ml-1">
              <ClockIcon className="h-6 w-6" />
            </span>
            <span className="px-2 text-center leading-tight tracking-tight">
              <span className="inline-block text-xs font-normal">Wait: </span>
              <span className="text-xs font-semibold">
                <span className="inline-block">{displayWaitTime}</span> ({waiting})
              </span>
            </span>
          </div>
        </Badge>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="center">
        <DropdownMenuLabel>Go to...</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem
            onClick={() =>
              nextLeadId ? navigate({ to: `/leads/lead/${nextLeadId}/quote` }) : () => null
            }
          >
            <span>Next Lead</span>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          {longestWaiting && (
            <DropdownMenuItem
              onClick={() =>
                nextLeadId ? navigate({ to: `/leads/lead/${longestWaiting}/quote` }) : () => null
              }
            >
              <span>Longest Wait</span>
            </DropdownMenuItem>
          )}
          {newestLead && (
            <DropdownMenuItem
              onClick={() =>
                nextLeadId ? navigate({ to: `/leads/lead/${newestLead}/quote` }) : () => null
              }
            >
              <span>Newest Lead</span>
            </DropdownMenuItem>
          )}
          {oldestLead && (
            <DropdownMenuItem
              onClick={() =>
                nextLeadId ? navigate({ to: `/leads/lead/${oldestLead}/quote` }) : () => null
              }
            >
              <span>Oldest Lead</span>
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
