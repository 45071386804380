import {
  DeleteDTO,
  useDeleteMutationFactory,
  UseDeleteMutationProps,
} from '@/lib/react-query/delete';
import { PaymentMethod } from '@/types';
import { remove } from '@/utils/api';
import { featureKeys, featurePath, PaymentMethodSearchParams } from '.';

export type DeletePaymentMethodDTO = DeleteDTO<PaymentMethod, PaymentMethodSearchParams>;

const deletePaymentMethod = ({ id, data, params }: DeletePaymentMethodDTO) =>
  remove(`${featurePath}/${id}`);

export const useDeletePaymentMethod = (options?: UseDeleteMutationProps<PaymentMethod>) => {
  return useDeleteMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: deletePaymentMethod,
    ...options,
  });
};
