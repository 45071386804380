import { useValidateEmail, VerdictOption } from '@/api/system/validation/validateEmail';
import { Input } from '@/components/ui/elements/input';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  useFormAutosave,
} from '@/components/ui/form/form';
import { cn } from '@/utils/format';
import { VariantProps } from 'cva';
import { ClassProp } from 'cva/dist/types';
import { useState } from 'react';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { formFieldVariants } from '../Form/FormInput';

type FormInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  VariantProps<typeof formFieldVariants> &
  ClassProp & {
    label?: string;
    description?: string;
    placeholder?: string;
    prefix?: string;
    suffix?: string;
    step?: string | number;
    autoComplete?: string;
    onFieldFocus?: (value?: string) => void;
    onFieldBlur?: (value: string) => void;
  };

export const FormEmailInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  placeholder,
  label,
  description,
  variant,
  className,
  prefix,
  suffix,
  step,
  autoComplete = 'false',
  onFieldFocus,
  onFieldBlur,
  ...props
}: FormInputProps<TFieldValues, TName>) => {
  const autosave = useFormAutosave();
  const { mutate: validator } = useValidateEmail({});
  const [verdict, setVerdict] = useState<VerdictOption | null>(null);
  const [suggestion, setSuggestion] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const validateEmail = (value: string) => {
    if (!value) return true;
    const basicTest = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    if (!basicTest) return false;
    validator(
      { email: value },
      {
        onSuccess: (data) => {
          setVerdict(data.result.verdict);

          if (data.result.suggestion) {
            setSuggestion(data.result.suggestion);
          } else {
            setSuggestion(null);
          }

          const errors = [];
          if (!data.result.checks.domain.has_mx_or_a_record) {
            errors.push('Invalid destination');
          }
          if (data.result.checks.domain.is_suspected_disposable_address) {
            errors.push('Suspected disposable email');
          }
          if (data.result.checks.additional.has_known_bounces) {
            errors.push('Known Bounces');
          }
          if (data.result.checks.additional.has_suspected_bounces) {
            errors.push('May Bounce');
          }

          setValidationErrors(errors);
        },
      },
    );
  };

  return (
    <FormField
      control={control}
      name={name}
      render={({ field: { onBlur, ...field } }) => (
        <FormItem className="relative">
          {label && <FormLabel className={cn(formFieldVariants({ variant }))}>{label}</FormLabel>}
          <FormControl>
            <div>
              {prefix && (
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">{prefix}</span>
                </div>
              )}
              <Input
                id={name}
                placeholder={placeholder}
                type="email"
                step={step}
                autoComplete={autoComplete}
                aria-invalid={control?._formState.errors[name] ? 'true' : 'false'}
                className={cn(className, prefix ? 'pl-7' : '', suffix ? 'pr-12' : '')}
                onFocus={(event) => {
                  if (onFieldFocus) onFieldFocus(event.target.value);
                  if (autosave.enabled) autosave.setPaused(true);
                }}
                onBlur={(event) => {
                  if (onFieldBlur) onFieldBlur(event.target.value);
                  if (autosave.enabled) autosave.setPaused(false);
                  onBlur();
                  validateEmail(event.target.value);
                }}
                {...field}
              />
              {suffix && (
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <span className="text-gray-500 sm:text-sm" id="price-currency">
                    {suffix}
                  </span>
                </div>
              )}
            </div>
          </FormControl>
          <div className="mt-1 flex flex-col space-y-1">
            {verdict && (
              <span
                className={cn(
                  'items-center px-1.5 py-0.5 rounded-sm text-xs',
                  verdict === 'Valid'
                    ? 'bg-green-800 text-green-100'
                    : verdict === 'Risky'
                    ? 'bg-orange-800 text-orange-100'
                    : 'bg-red-800 text-red-100',
                )}
              >
                {verdict}
              </span>
            )}
            {suggestion && (
              <span
                className={cn(
                  'items-center px-1.5 py-0.5 rounded-sm text-xs',
                  'bg-blue-100 text-blue-900',
                )}
              >
                Did you mean <b>{suggestion}</b>?
              </span>
            )}
            {validationErrors.map((error, i) => (
              <span
                key={`email-validation-error-${i}`}
                className={cn(
                  'items-center px-1.5 py-0.5 rounded-sm text-xs',
                  'bg-red-100 text-red-900',
                )}
              >
                {error}
              </span>
            ))}
          </div>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
      {...props}
    />
  );
};
