'use client';

import { cn, getTimezone, todayAsCalendarDate } from '@/utils/format';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { CalendarDate, CalendarDateTime, ZonedDateTime } from '@internationalized/date';
import * as React from 'react';
import { useContext, useState } from 'react';
import {
  Button as AriaButton,
  Calendar as CalendarPrimitive,
  CalendarCell,
  CalendarGrid,
  CalendarStateContext,
  DatePickerStateContext,
} from 'react-aria-components';
import { Button } from './button';

export type CalendarProps = React.ComponentProps<typeof CalendarPrimitive> & {
  calendarControls?: React.ReactNode;
};

type CalendarHeadingProps = {
  mode?: 'date' | 'month' | 'year';
};

type CalendarControlsProps = {
  children: React.ReactNode;
};

type CalendarPickerProps = {
  onChange?: (value: number) => void;
};

type CalendarMonthPickerProps = CalendarPickerProps;
type CalendarYearPickerProps = CalendarPickerProps;

const Calendar = (props: CalendarProps) => {
  const [selectorMode, setSelectorMode] = useState<'date' | 'month' | 'year'>('date');
  const [selectedDate, setSelectedDate] = useState<
    CalendarDate | CalendarDateTime | ZonedDateTime | undefined | null
  >(props.defaultValue);

  const handleModeToggle = () => {
    setSelectorMode((mode) => {
      switch (mode) {
        case 'date':
          return 'month';
        case 'month':
          return 'year';
        case 'year':
          return 'date';
      }
    });
  };

  const handleDateSelect = (value: CalendarDate | CalendarDateTime | ZonedDateTime) => {
    setSelectedDate(value);
    if (props.onChange) props.onChange(value);
  };

  let visibleDuration = { months: 1 };
  switch (selectorMode) {
    case 'date':
      visibleDuration = { months: 1 };
      break;
    case 'month':
      visibleDuration = { months: 12 };
      break;
    case 'year':
      visibleDuration = { months: 12 * 12 };
      break;
  }

  return (
    <CalendarPrimitive
      className="w-full"
      {...props}
      value={selectedDate}
      visibleDuration={visibleDuration}
      onChange={handleDateSelect}
    >
      <header className="flex flex-row text-center mb-4">
        <AriaButton slot="previous" className="text-gray-400 hover:text-gray-700">
          <ChevronRightIcon className="h-5 w-5 rotate-180" />
        </AriaButton>
        <button
          type="button"
          onClick={handleModeToggle}
          className="grow text-sm hover:bg-primary-100 hover:cursor-pointer"
        >
          <CalendarHeading mode={selectorMode} />
        </button>
        <AriaButton slot="next" className="text-gray-400 hover:text-gray-700">
          <ChevronRightIcon className="h-5 w-5" />
        </AriaButton>
      </header>
      {selectorMode === 'date' && (
        <CalendarGrid className="w-full text-center text-sm">
          {(date) => (
            <CalendarCell
              date={date}
              className={cn(
                'transition-all',
                'data-[outside-month]:text-gray-300 text-sm px-0 py-1 data-[hovered]:bg-primary-300 data-[hovered]:rounded-full',
                'data-[selected]:bg-primary-200 data-[selected]:text-black data-[selected]:rounded-full',
              )}
            />
          )}
        </CalendarGrid>
      )}
      {selectorMode === 'month' && (
        <CalendarMonthPicker
          onChange={(value) => {
            setSelectorMode('date');
          }}
        />
      )}
      {selectorMode === 'year' && (
        <CalendarYearPicker
          onChange={(value) => {
            setSelectorMode('month');
          }}
        />
      )}
      {props.calendarControls && <CalendarControls>{props.calendarControls}</CalendarControls>}
    </CalendarPrimitive>
  );
};
Calendar.displayName = 'Calendar';

const CalendarMonthPicker = ({ onChange }: CalendarMonthPickerProps) => {
  const state = useContext(CalendarStateContext);
  const selectedMonth = state.focusedDate?.month;

  const MONTHS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  return (
    <div className="grid grid-cols-3 gap-y-3 gap-x-6 text-base text-center">
      {MONTHS.map((month, i) => (
        <button
          key={`month-${i}`}
          type="button"
          className={cn(
            'py-2 hover:bg-primary-200 rounded-full',
            selectedMonth && selectedMonth === i + 1 ? 'bg-primary-300' : '',
          )}
          onClick={() => {
            state.setFocusedDate(state.focusedDate?.set({ month: i + 1 }));
            if (onChange) onChange(i + 1);
          }}
        >
          {month}
        </button>
      ))}
    </div>
  );
};

const CalendarYearPicker = ({ onChange }: CalendarYearPickerProps) => {
  const state = useContext(CalendarStateContext);
  const selectedYear = state.focusedDate?.year;

  const startYear = selectedYear - 7;
  const endYear = selectedYear + 4;

  const YEARS = [];
  for (let i = startYear; i <= endYear; i++) {
    YEARS.push(i);
  }

  return (
    <div className="grid grid-cols-3 gap-y-3 gap-x-6 text-base text-center">
      {YEARS.map((year) => (
        <button
          key={`year-${year}`}
          type="button"
          className={cn(
            'py-2 hover:bg-primary-200 rounded-full',
            selectedYear && selectedYear === year ? 'bg-primary-300' : '',
          )}
          onClick={() => {
            state.setFocusedDate(state.focusedDate?.set({ year }));
            if (onChange && year) onChange(year);
          }}
        >
          {year}
        </button>
      ))}
    </div>
  );
};

const CalendarHeading = ({ mode }: CalendarHeadingProps) => {
  const state = useContext(CalendarStateContext);

  if (mode === 'year') {
    return <div className="flex-grow text-sm">Year Select</div>;
  }

  if (mode === 'month') {
    return (
      <div className="flex-grow text-sm">
        {state.focusedDate?.toDate(getTimezone()).toLocaleDateString('en-US', {
          year: 'numeric',
        })}
      </div>
    );
  }

  return (
    <div className="flex-grow text-sm">
      {state.focusedDate?.toDate(getTimezone()).toLocaleDateString('en-US', {
        month: 'long',
        year: 'numeric',
      })}
    </div>
  );
};

const CalendarControls = ({ children }: CalendarControlsProps) => {
  return <div className="flex mt-2 justify-between">{children}</div>;
};

const DatePickerClearButton = () => {
  const state = useContext(DatePickerStateContext);
  return (
    <Button
      variant="outline"
      size="xs"
      aria-label="Clear"
      onClick={() => {
        state.setValue(null);
        state.close();
      }}
    >
      Clear
    </Button>
  );
};

const DatePickerTodayButton = () => {
  const state = useContext(DatePickerStateContext);
  const today = todayAsCalendarDate();
  return (
    <Button
      variant="outline"
      size="xs"
      aria-label="Today"
      onClick={() => {
        state.setValue(today);
        state.close();
      }}
    >
      Today
    </Button>
  );
};

export {
  Calendar,
  CalendarHeading,
  CalendarControls,
  CalendarMonthPicker,
  CalendarYearPicker,
  DatePickerClearButton,
  DatePickerTodayButton,
};
