import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/elements/accordion';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/elements/dialog';
import { PolicyGate } from '@/features/auth/authorization';
import { IdBadge } from '@/features/events/components/Badges/IdBadge';
import { StatusBadge } from '@/features/events/components/Badges/StatusBadge';
import { SurveyBadge } from '@/features/events/components/Badges/SurveyBadge';
import { EventNotes } from '@/features/events/components/Detail/Tabs/components/EventNotes';
import { EventOverviewInvoice } from '@/features/events/components/Detail/Tabs/components/EventOverviewInvoice';
import { LeadContext } from '@/features/leads/contexts/LeadContext';
import { cn, formatDate, formatMoney } from '@/utils/format';
import { useContext } from 'react';
import { AccordionItemButtonStyle, AccordionItemPanelStyle } from '../../styles';

export const OtherBookingsAccordionItem = () => {
  const { lead } = useContext(LeadContext);

  if (!lead) return null;

  const events =
    (lead.customer?.events ?? []).sort(
      (a, b) => new Date(b.start_at).getTime() - new Date(a.start_at).getTime(),
    ) ?? [];

  return (
    <PolicyGate policy="events.view">
      {events.length > 0 && (
        <AccordionItem value="other-bookings">
          <AccordionTrigger className={cn(AccordionItemButtonStyle, 'mt-3')}>
            Other Bookings Found ({events.length ?? 0})
          </AccordionTrigger>
          <AccordionContent className={AccordionItemPanelStyle}>
            <div className="max-h-96 my-2 p-1 border overflow-y-scroll">
              {events.map((event) => (
                <>
                  <Dialog>
                    <DialogTrigger className="flex flex-col w-full space-y-2 py-2 border-b pb-2 px-2 text-left hover:bg-slate-100">
                      <div className="flex flex-row w-full">
                        <span>
                          <IdBadge event={event} /> <StatusBadge event={event} />{' '}
                          <SurveyBadge event={event} />
                        </span>
                        <span className="grow text-right">{formatMoney(event.total)}</span>
                      </div>
                      <div className="flex flex-row justify-between space-x-4 w-full text-xs font-semibold">
                        <span>{event.name}</span>
                        <span className="text-right">{formatDate(event.start_at)}</span>
                      </div>
                      <div className="text-xs">{event.special_instructions}</div>
                    </DialogTrigger>
                    <DialogContent className="w-full max-w-[80vw]">
                      <DialogHeader>
                        <DialogTitle>Event</DialogTitle>
                      </DialogHeader>
                      <div className="overflow-y-scroll max-h-[80vh]">
                        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mr-4">
                          <div className="md:basis-5/6">
                            <EventOverviewInvoice event={event} />
                          </div>
                          <EventNotes event={event} />
                        </div>
                      </div>
                      <DialogFooter></DialogFooter>
                    </DialogContent>
                  </Dialog>
                </>
              ))}
            </div>
          </AccordionContent>
        </AccordionItem>
      )}
    </PolicyGate>
  );
};
