import { useUpdateMutationFactory, UseUpdateMutationProps } from '@/lib/react-query/update';
import { Lead, ModelID } from '@/types';
import { post } from '@/utils/api';
import { featureKeys, featurePath } from '.';

export type RestoreQuoteDTO = {
  data: {
    lead_id: ModelID;
    quote_id: ModelID;
  };
};

export const restoreQuote = ({ data }: RestoreQuoteDTO) =>
  post<Lead>(`${featurePath}/${data.lead_id}/restore-quote`, data, {});

export const useRestoreQuote = (options?: UseUpdateMutationProps<Lead>) => {
  return useUpdateMutationFactory({
    invalidateKeys: featureKeys,
    mutationFn: restoreQuote,
    ...options,
  });
};
