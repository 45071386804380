import _ from 'lodash';
import { ACL } from '.';
import { HeadGameCoachACL } from './head_gamecoach';
export const ACL_ROLE_MANAGER = 'Manager';

export const ManagerACL: ACL = _.merge({}, HeadGameCoachACL, {
  role: ACL_ROLE_MANAGER,
  dashboard: {
    news: true,
    motd: true,
  },
  calendar: {
    view: true,
    notes: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
  },
  scheduler: {
    view: true,
    edit: true,
  },
  map: {
    view: true,
  },
  equipment: {
    view: true,
    assets: {
      view: true,
      edit: true,
    },
    vehicles: {
      view: false,
      edit: false,
    },
    gamescans: {
      view: true,
      create: true,
      edit: true,
      delete: false,
    },
    blackouts: {
      view: true,
      create: true,
      edit: true,
      delete: true,
    },
  },
  customers: {
    history: true,
    lists: {
      view: true,
      create: true,
      edit: true,
      delete: true,
      export: true,
    },
    rewards: {
      view: true,
      approve: true,
    },
  },
  messages: {
    view: true,
    send: true,
  },
  leads: {
    history: true,
    export: true,
    transfer: true,
  },
  events: {
    cancel: true,
    confirm: true,
    history: true,
    export: true,
    transfer: true,
  },
  payments: {
    refund: true,
    edit: true,
  },
  payment_methods: {
    delete: true,
  },
  settings: {
    view: true,
    edit: true,
  },
  ordering: {
    view: false,
  },
  resources: {
    view: true,
    directory: {
      view: true,
      internal: {
        view: true,
      },
    },
    store: {
      view: true,
    },
  },
});
