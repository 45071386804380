import { CalendarEventChipRenderProps } from '../types';
import { CalendarChip } from './CalendarChip';

export const TravelChip = ({ eventInfo }: CalendarEventChipRenderProps) => {
  const { timeText } = eventInfo.event.extendedProps;

  return (
    <CalendarChip
      eventInfo={eventInfo}
      className="bg-gray-200 border-dotted border-gray-400 text-gray-900"
    >
      <span>{timeText}</span>
      <span className="truncate">{eventInfo.event.title}</span>
    </CalendarChip>
  );
};
