'use client';

import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/elements/popover';
import { useDebounce } from '@/hooks/useDebounce';
import { DateMode } from '@/types';
import { cn, formatDate } from '@/utils/format';
import { convertDateModeToRange } from '@/utils/logic';
import { CalendarIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { getLocalTimeZone } from '@internationalized/date';
import { VariantProps } from 'cva';
import { useContext, useState } from 'react';
import {
  Button as AriaButton,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  DateInput,
  DateRange,
  DateRangePicker as DateRangePickerPrimitive,
  DateRangePickerProps,
  DateRangePickerStateContext,
  DateSegment,
  DateValue,
  FieldError,
  Group,
  Heading,
  Label,
  RangeCalendar,
  RangeCalendarStateContext,
} from 'react-aria-components';
import List from '../Elements/List/List';
import { Button } from '../ui/elements/button';
import { CalendarControls } from '../ui/elements/calendar';
import { inputVariants } from '../ui/elements/input';

type PickerProps<T extends DateValue> = DateRangePickerProps<T> &
  React.RefAttributes<HTMLDivElement> &
  VariantProps<typeof inputVariants> & {
    label?: string;
    onSetDateRange?: (range: DateRange | undefined) => void;
    onSetDateMode?: (mode: DateMode) => void;
  };

export const DateRangePicker = ({
  variant,
  size,
  label,
  defaultValue,
  onSetDateRange,
  onSetDateMode,
  ...props
}: PickerProps<DateValue>) => {
  const [range, setRange] = useState<DateRange | null | undefined>(defaultValue);
  const [open, setOpen] = useState(false);

  const handleDateMode = (mode: DateMode) => {
    const calculatedRange = convertDateModeToRange(mode);
    setRange(calculatedRange);
    if (onSetDateRange) onSetDateRange(calculatedRange);
    if (onSetDateMode) onSetDateMode(mode);
  };

  const handleChange = (range: DateRange | undefined) => {
    setRange(range);
    debouncedChange();
  };

  const debouncedChange = useDebounce(() => {
    if (onSetDateRange && range) onSetDateRange(range);
    if (onSetDateMode) onSetDateMode(null);
  }, 500);

  return (
    <DateRangePickerPrimitive
      isOpen={open}
      onOpenChange={setOpen}
      defaultValue={defaultValue}
      value={range}
      onChange={handleChange}
      {...props}
    >
      <Label aria-hidden={!label} className={!label ? 'hidden' : ''}>
        {label || 'Date Range'}
      </Label>
      <Popover open={open} onOpenChange={setOpen}>
        <Group className={cn(inputVariants({ variant, size }), 'flex flex-row items-center')}>
          <DateRangePickerDateInput slot="start" />
          <span aria-hidden="true" className="mx-2 opacity-60">
            -
          </span>
          <DateRangePickerDateInput slot="end" />
          <PopoverTrigger className="ml-auto">
            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
          </PopoverTrigger>
        </Group>
        <PopoverContent className="w-full">
          <div className="flex flex-row space-x-2 whitespace-nowrap">
            <List>
              <List.ItemButton className="py-2 pl-2 w-full" onClick={() => handleDateMode('7day')}>
                Last 7 Days
              </List.ItemButton>
              <List.ItemButton className="py-2 pl-2 w-full" onClick={() => handleDateMode('30day')}>
                Last 30 Days
              </List.ItemButton>
              <List.ItemButton
                className="py-2 pl-2 w-full"
                onClick={() => handleDateMode('last-month')}
              >
                Last Month
              </List.ItemButton>
              <List.ItemButton
                className="py-2 pl-2 w-full"
                onClick={() => handleDateMode('last-year')}
              >
                Last Year
              </List.ItemButton>
              <List.ItemButton className="py-2 pl-2 w-full" onClick={() => handleDateMode('wtd')}>
                Week to Date
              </List.ItemButton>
              <List.ItemButton className="py-2 pl-2 w-full" onClick={() => handleDateMode('mtd')}>
                Month to Date
              </List.ItemButton>
              <List.ItemButton className="py-2 pl-2 w-full" onClick={() => handleDateMode('ytd')}>
                Year to Date
              </List.ItemButton>
            </List>
            <div className="w-full">
              <RangeCalendar className="w-full" visibleDuration={{ months: 3 }}>
                <header className="w-full flex flex-row text-center mb-4">
                  <AriaButton slot="previous" className="text-gray-400 hover:text-gray-700">
                    <ChevronRightIcon className="h-5 w-5 rotate-180" />
                  </AriaButton>
                  <Heading className="grow" />
                  <AriaButton slot="next" className="text-gray-400 hover:text-gray-700">
                    <ChevronRightIcon className="h-5 w-5" />
                  </AriaButton>
                </header>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-3">
                  <DateRangePickerCalendarGrid />
                  <DateRangePickerCalendarGrid offset={{ months: 1 }} />
                  <DateRangePickerCalendarGrid offset={{ months: 2 }} />
                </div>
                <CalendarControls>
                  <DatePickerClearButton />
                  <Button
                    variant="outline"
                    size="xs"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Close
                  </Button>
                </CalendarControls>
              </RangeCalendar>
            </div>
          </div>
        </PopoverContent>
      </Popover>
      <FieldError>
        {(props) => (
          <span className="text-xs text-red-500 ml-2">{props.validationErrors.join(',')}</span>
        )}
      </FieldError>
    </DateRangePickerPrimitive>
  );
};

const DatePickerClearButton = () => {
  const state = useContext(DateRangePickerStateContext);
  return (
    <Button
      variant="outline"
      size="xs"
      aria-label="Clear"
      onClick={() => {
        state.setValue(null);
      }}
    >
      Clear
    </Button>
  );
};

const DateRangePickerDateInput = ({ slot }: { slot: 'start' | 'end' }) => {
  return (
    <DateInput slot={slot} className="flex flex-row text-sm">
      {(segment) => (
        <DateSegment
          segment={segment}
          className={cn(
            'data-[invalid]:text-red data-[placeholder]:opacity-60 data-[readonly]:opacity-50',
            'data-[focused]:bg-primary-100 outline-none rounded-sm',
            'p-0.5',
          )}
        />
      )}
    </DateInput>
  );
};

export const DateRangePickerCalendarGrid = ({ offset }: { offset?: { months: number } }) => {
  const state = useContext(RangeCalendarStateContext);
  const start = state.visibleRange?.start.add(offset || { months: 0 }).toDate(getLocalTimeZone());
  const month = formatDate(start, 'MMMM');

  return (
    <div>
      <h2 className="py-2 text-xs text-center font-semibold uppercase text-gray-600">{month}</h2>
      <CalendarGrid className="w-full text-center text-sm" offset={offset}>
        <CalendarGridHeader>
          {(day) => (
            <CalendarHeaderCell className="text-gray-500 text-xs">{day}</CalendarHeaderCell>
          )}
        </CalendarGridHeader>
        <CalendarGridBody>
          {(date) => (
            <CalendarCell
              date={date}
              className={cn(
                'transition-all',
                'data-[outside-month]:hidden text-sm px-1 py-1 data-[hovered]:bg-primary-300 data-[hovered]:rounded-full',
                'data-[selected]:bg-primary-200 data-[selected]:text-black',
                'data-[selection-start]:bg-primary-700 data-[selection-start]:text-white data-[selection-start]:rounded-l-full',
                'data-[selection-end]:bg-primary-700 data-[selection-end]:text-white data-[selection-end]:rounded-r-full',
              )}
            />
          )}
        </CalendarGridBody>
      </CalendarGrid>
    </div>
  );
};
