import { useEvents } from '@/api/events/events/getEvents';
import List from '@/components/Elements/List/List';
import NoResults from '@/components/Elements/NoResults/NoResults';
import { useAuthorization } from '@/features/auth/authorization';
import { AuthContext } from '@/providers/auth';
import { LocationContext } from '@/providers/location';
import { formatDate } from '@/utils/format';
import { useContext } from 'react';

export const EventsToday = () => {
  const { activeLocationId } = useContext(LocationContext);
  const { checkRole } = useAuthorization();
  const { user } = useContext(AuthContext);

  const isGameCoach = checkRole({
    allowedRoles: ['Game Coach', 'Head Game Coach'],
  });

  const { data: events } = useEvents({
    location: activeLocationId,
    search: {
      filter: 'today',
      staff_id: isGameCoach ? user?.user_id : undefined,
    },
    config: {
      staleTime: 1000 * 60 * 60,
    },
  });

  return (
    <div className="rounded border px-4 bg-white">
      <h2 className="text-sm font-medium pt-2 pb-1">{isGameCoach ? 'Your' : "Today's"} Events</h2>
      {!events?.length && <NoResults label="events" />}
      <List className="h-full max-h-[470px] overflow-y-auto -mx-4">
        {events?.map((event, index) => (
          <List.ItemLink
            key={`inbox-${event.id}`}
            to={`/events/event/${event.id}/overview`}
            className="flex flex-col"
          >
            <div className="space-x-2">
              <span>#{event.id}</span>
              <span>{event.name || 'No Name'}</span>
            </div>
            <span className="text-xs font-thin">{formatDate(event.start_at)}</span>
          </List.ItemLink>
        ))}
      </List>
    </div>
  );
};
