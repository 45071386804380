import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';

type PaginatorProps = {
  page: number;
  lastPage: number;
  onPageChange: CallableFunction;
  className?: string;
};

export const Paginator = ({ page = 1, lastPage, onPageChange, className }: PaginatorProps) => {
  const isFirstPage = page === 1;
  const isLastPage = page === lastPage;
  const useExpandedPageOptions = lastPage > 6;

  const pageOptions = [];

  if (!useExpandedPageOptions) {
    let i = 1;
    while (i <= lastPage) {
      pageOptions.push(i++);
    }
  } else {
    const minPage = page > 3 ? page - 3 : 1;
    const maxPage = page > 3 ? page + 3 : lastPage > 7 ? 7 : lastPage;

    let i = page > 3 ? page - 3 : 1;
    while (i <= maxPage && i <= lastPage) {
      pageOptions.push(i++);
    }
  }

  const gotoPrevPage = () => {
    if (!isFirstPage) {
      onPageChange(page - 1);
    }
  };
  const gotoNextPage = () => {
    if (!isLastPage) {
      onPageChange(page + 1);
    }
  };
  const gotoFirstPage = () => {
    if (!isFirstPage) {
      onPageChange(1);
    }
  };
  const gotoLastPage = () => {
    if (!isLastPage) {
      onPageChange(lastPage);
    }
  };
  const gotoPage = (num: number) => onPageChange(num);

  return (
    <nav
      className={clsx(
        'flex items-center justify-between border-t border-gray-200 px-4 sm:px-0',
        className,
      )}
    >
      <div className="-mt-px flex w-0 flex-1">
        <button
          onClick={gotoFirstPage}
          type="button"
          className={clsx(
            'inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium  ',
            isFirstPage
              ? 'text-gray-300'
              : 'text-gray-500 hover:text-gt-green-700 hover:border-gt-green-300 cursor-pointer',
          )}
        >
          <ChevronDoubleLeftIcon className={clsx('mx-1.5 h-5 w-5')} aria-hidden="true" />
        </button>
        <button
          onClick={gotoPrevPage}
          type="button"
          className={clsx(
            'inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium  ',
            isFirstPage
              ? 'text-gray-300'
              : 'text-gray-500 hover:text-gt-green-700 hover:border-gt-green-300 cursor-pointer',
          )}
        >
          <ChevronLeftIcon className={clsx('mx-1.5 h-5 w-5')} aria-hidden="true" />
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {pageOptions?.map((cursor) => (
          <button
            key={`paginator${cursor}`}
            type="button"
            onClick={() => gotoPage(cursor)}
            className={clsx(
              'inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium  ',
              cursor === page
                ? 'border-gt-green-500 text-gt-green-600'
                : 'border-transparent text-gray-500 hover:text-gt-green-700 hover:border-gt-green-300 cursor-pointer',
            )}
          >
            {cursor}
          </button>
        ))}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <button
          type="button"
          onClick={gotoNextPage}
          className={clsx(
            'inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium  ',
            isLastPage
              ? 'text-gray-300'
              : 'text-gray-500 hover:text-gt-green-700 hover:border-gt-green-300 cursor-pointer',
          )}
        >
          <ChevronRightIcon className={clsx('mx-1.5 h-5 w-5')} aria-hidden="true" />
        </button>
        <button
          type="button"
          onClick={gotoLastPage}
          className={clsx(
            'inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium  ',
            isLastPage
              ? 'text-gray-300'
              : 'text-gray-500 hover:text-gt-green-700 hover:border-gt-green-300 cursor-pointer',
          )}
        >
          <ChevronDoubleRightIcon className={clsx('mx-1.5 h-5 w-5')} aria-hidden="true" />
        </button>
      </div>
    </nav>
  );
};

export default Paginator;
