import { LeadSearchParams } from '@/api/leads/leads';
import { SidebarLayout, SidebarLinks } from '@/components/Layout';
import { PolicyGate } from '@/features/auth/authorization';
import { InsufficientPermissions } from '@/features/auth/components/InsufficientPermissions';
import { LeadsModeToggle } from '@/features/leads/components/LeadsModeToggle';
import { GamificationSummary } from '@/features/leads/components/Results/GamificationSummary';
import { WaitingBadge } from '@/features/leads/components/WaitingBadge';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/leads/$filter')({
  component: LeadFeature,
});

const LeadsSubMenu: SidebarLinks<LeadSearchParams> = [
  [
    {
      label: 'Inbox',
      path: '/leads/inbox',
      access_rights: ['leads.view'],
      extra: <WaitingBadge />,
    },
    {
      label: 'Conversations',
      path: '/leads/conversations',
      access_rights: ['messages.view'],
    },
    {
      label: 'New',
      path: '/leads/new',
      access_rights: ['leads.view'],
    },
    {
      label: 'Priority',
      path: '/leads/priority',
      access_rights: ['leads.view'],
    },
  ],
  [
    {
      label: 'Daily Call List',
      path: '/leads/daily-call-list',
      nk: 'dcl',
      search_params: {
        event_type_ids: undefined,
      },
      access_rights: ['leads.view'],
    },
    {
      label: 'Daily Call List - NBE',
      path: '/leads/daily-call-list',
      nk: 'nbe',
      search_params: {
        event_type_ids: ['3', '4', '5', '6', '7', '8', '9'],
      },
      access_rights: ['leads.view'],
    },
    {
      label: 'Needs Followup',
      path: '/leads/needs-followup',
      access_rights: ['leads.view'],
    },
    {
      label: 'Open',
      path: '/leads/open',
      access_rights: ['leads.view'],
    },
    {
      label: 'Pending Transfers',
      path: '/leads/pending-transfer',
      access_rights: ['leads.view'],
    },
    {
      label: 'Archived',
      path: '/leads/archived',
      access_rights: ['leads.view'],
    },
    {
      label: 'Booked',
      path: '/leads/booked',
      access_rights: ['leads.view'],
    },
    {
      label: 'All',
      path: '/leads/all',
      access_rights: ['leads.view'],
    },
  ],
];

function LeadFeature() {
  return (
    <PolicyGate policy="leads.view" forbiddenFallback={<InsufficientPermissions />}>
      <SidebarLayout
        title="Leads"
        links={LeadsSubMenu}
        perserveSearch
        sidebarChildren={
          <>
            <LeadsModeToggle />
            <GamificationSummary />
          </>
        }
      >
        <Outlet />
      </SidebarLayout>
    </PolicyGate>
  );
}
