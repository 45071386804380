import { Lead } from '@/types';
import { useNavigate } from '@tanstack/react-router';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { LockedBadge } from '../../../Badges/LockedBadge';

interface WaitingIndicatorProps {
  lead: Lead;
}

export const WaitingIndicator = ({ lead }: WaitingIndicatorProps) => {
  const navigate = useNavigate();

  return (
    <>
      {lead.waiting_since ? (
        <button
          className="flex w-full justify-between rounded-lg bg-red-100 text-red-700 px-4 py-2 mb-3 text-left text-sm font-normal hover:bg-red-200 hover:cursor-pointer"
          type="button"
          onClick={() => navigate({ to: `/leads/lead/${lead.id}/messages` })}
        >
          Waiting Since:{' '}
          {formatDistanceToNow(new Date(lead.waiting_since.replace(' ', 'T')), {
            includeSeconds: true,
            addSuffix: true,
          })}
          <LockedBadge lead={lead} />
        </button>
      ) : (
        <div className="flex justify-between rounded-lg border border-gray-100 bg-white px-4 py-2 mb-3 text-left text-sm font-light text-gray-900">
          Last Update:{' '}
          {formatDistanceToNow(new Date(lead.updated_at.replace(' ', 'T')), {
            includeSeconds: true,
            addSuffix: true,
          })}
          <LockedBadge lead={lead} />
        </div>
      )}
    </>
  );
};
