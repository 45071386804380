import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ChangeLogNode, ChangeLogResponse, Lead, ModelID } from '@/types';
import { get } from '@/utils/api';
import { useQuery } from '@tanstack/react-query';
import { featureKeys, featurePath, LeadSearchParams } from '.';

type QueryFnType = typeof findLead;
type QueryChangelogFnType = typeof getChangelog;

type UseLeadOptions = {
  id: ModelID;
  search?: LeadSearchParams;
  config?: QueryConfig<QueryFnType>;
};

type UseLeadChangelogOptions = {
  lead: Lead;
  search?: LeadSearchParams;
  config?: QueryConfig<QueryChangelogFnType>;
};

const findLead = (id: ModelID, search?: LeadSearchParams) =>
  get<Lead>(`${featurePath}/${id}`, search);

export const findNextLead = async (id?: ModelID, franchise_id?: ModelID) =>
  await get<Lead>(`${featurePath}/next-lead`, {
    id_not: id,
    franchise_id,
  });

const getChangelog = async (lead: Lead, search?: LeadSearchParams): Promise<ChangeLogNode[]> => {
  const response = await get<ChangeLogResponse>(`${featurePath}/${lead.id}/changelog`, search);
  return response.changelog;
};

export const useLead = ({ id, search, config = {} }: UseLeadOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: featureKeys.detail(+id, search),
    queryFn: () => findLead(id, search),
  });
};

export const useLeadChangelog = ({ lead, search, config = {} }: UseLeadChangelogOptions) => {
  return useQuery<ChangeLogNode[]>({
    ...config,
    queryKey: [{ scope: 'leads', entity: 'changelog', id: lead.id, search }],
    queryFn: () => getChangelog(lead, search),
  });
};
