import { useLogContactAttempt } from '@/api/customers/customers/logContactAttempt';
import { useUpdateCustomer } from '@/api/customers/customers/updateCustomer';
import { CustomerListDropdownMenuGroup } from '@/components/Dropdown/CustomerListDropdownMenuGroup';
import { Button } from '@/components/ui/elements/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@/components/ui/elements/dropdown-menu';
import { Customer } from '@/types';
import { todayAsCalendarDate } from '@/utils/format';
import {
  CalendarDaysIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
import { useCreateBlankLead } from '../hooks/useCreateBlankLead';

interface CustomerActionDropdownMenu {
  customer: Customer;
  children?: React.ReactNode;
}

const calculateFollowUp = (days = 1) => {
  return {
    followup_at: todayAsCalendarDate().add({ days }).toString(),
  };
};

export const CustomerActionDropdownMenu = ({ customer, children }: CustomerActionDropdownMenu) => {
  const { createBlankLead } = useCreateBlankLead({ customer });

  const { mutate: updateCustomer } = useUpdateCustomer();

  const { mutate: logContact } = useLogContactAttempt();

  const FollowUpActions = [
    {
      label: 'Follow Up - Tomorrow',
      onClick: () => updateCustomer({ id: customer.id, data: calculateFollowUp(1) }),
    },
    {
      label: 'Follow Up - Two days',
      onClick: () => updateCustomer({ id: customer.id, data: calculateFollowUp(2) }),
    },
    {
      label: 'Follow Up - Next Week',
      onClick: () => updateCustomer({ id: customer.id, data: calculateFollowUp(7) }),
    },
    {
      label: 'Follow Up - Two Weeks',
      onClick: () => updateCustomer({ id: customer.id, data: calculateFollowUp(14) }),
    },
    {
      label: 'Follow Up - Next Month',
      onClick: () => updateCustomer({ id: customer.id, data: calculateFollowUp(28) }),
    },
    {
      label: 'Follow Up - Next Quarter',
      onClick: () => updateCustomer({ id: customer.id, data: calculateFollowUp(90) }),
    },
    {
      label: 'Follow Up - Next Year',
      onClick: () => updateCustomer({ id: customer.id, data: calculateFollowUp(365) }),
    },
    {
      label: 'Never',
      onClick: () =>
        updateCustomer({
          id: customer.id,
          data: {
            followup_at: '2500-12-31 23:59:59',
          },
        }),
    },
  ];

  const LogContactActions = [
    {
      label: 'Log Call - Spoke to Customer',
      onClick: () =>
        logContact({ id: customer.id, data: { contactType: 'call', result: 'Spoke to Customer' } }),
    },
    {
      label: 'Log Call - No Answer',
      onClick: () =>
        logContact({ id: customer.id, data: { contactType: 'call', result: 'No Answer' } }),
    },
    {
      label: 'Log Call - Left Voicemail',
      onClick: () =>
        logContact({ id: customer.id, data: { contactType: 'call', result: 'Left Voicemail' } }),
    },
    {
      label: 'Log TXT',
      onClick: () =>
        logContact({ id: customer.id, data: { contactType: 'call', result: 'Sent Text' } }),
    },
    {
      label: 'Log Email',
      onClick: () =>
        logContact({ id: customer.id, data: { contactType: 'call', result: 'Sent Email' } }),
    },
  ];

  if (!customer) {
    return <></>;
  }

  return (
    <DropdownMenu>
      {children ? (
        children
      ) : (
        <DropdownMenuTrigger asChild>
          <Button variant="action">
            <span className="sr-only">More Actions</span>
            <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
          </Button>
        </DropdownMenuTrigger>
      )}
      <DropdownMenuContent side="bottom" align="end" className="w-56">
        <DropdownMenuItem onClick={createBlankLead} className="cursor-pointer">
          <PlusCircleIcon className="mr-2 h-4 w-4" />
          <span>Start new blank lead...</span>
        </DropdownMenuItem>

        <DropdownMenuSeparator />

        <CustomerListDropdownMenuGroup actionText="Add Customer to List" customer={customer} />

        <DropdownMenuSeparator />

        <DropdownMenuGroup>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <CalendarDaysIcon className="mr-2 h-4 w-4" />
              <span>Follow Up</span>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                {FollowUpActions.map((action, i) => {
                  return (
                    <DropdownMenuItem
                      key={`ddm-logcontact-${i}`}
                      onClick={action.onClick}
                      className="cursor-pointer"
                    >
                      {action.label}
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
        </DropdownMenuGroup>

        <DropdownMenuSeparator />

        <DropdownMenuGroup>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <PencilIcon className="mr-2 h-4 w-4" />
              <span>Log Contact</span>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                {LogContactActions.map((action, i) => {
                  return (
                    <DropdownMenuItem
                      key={`ddm-fullowup-${i}`}
                      onClick={action.onClick}
                      className="cursor-pointer"
                    >
                      {action.label}
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
