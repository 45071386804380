import { CalendarEventChipRenderProps } from '../types';
import { CalendarChip } from './CalendarChip';

export const NoteChip = ({ eventInfo }: CalendarEventChipRenderProps) => {
  const { timeText, type, reason } = eventInfo.event.extendedProps;

  return (
    <CalendarChip
      eventInfo={eventInfo}
      className="bg-blue-200 border border-blue-400 text-gray-900 font-semibold"
    >
      <span>{eventInfo.event.title}</span>
    </CalendarChip>
  );
};
