import { MAPBOX_KEY } from '@/config';
import { LocationContext } from '@/providers/location';
import { router } from '@/router';
import { TravelCharge } from '@/types';
import { cn } from '@/utils/format';
import { MapPinIcon } from '@heroicons/react/24/solid';
import { useNavigate } from '@tanstack/react-router';
import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  default as MapboxGLMap,
  FullscreenControl,
  GeolocateControl,
  Layer,
  Marker,
  Source,
} from 'react-map-gl';
import { useShallow } from 'zustand/react/shallow';
import NoResults from '../Elements/NoResults/NoResults';
import { LayerFilterControl } from './LayerFilterControl';
import { heatmapLayer } from './layers/heatmapLayer';
import { MapContext } from './MapContext';
import { Pin, useMapStore } from './MapStateStore';
import { MapStyleControl } from './MapStyleControl';

interface MapViewerProps {
  size: number;
  controls?: React.ReactNode;
  className?: string;
}

const MapViewer = ({ size, controls, className = '' }: MapViewerProps) => {
  const mapRef = useRef<any>();
  const { activeLocationId: fallbackLocationId } = useContext(LocationContext);
  const { heatmap, travelChargeLayers } = useContext(MapContext);
  const navigate = useNavigate();
  const [initialized, setInitialized] = useState(false);
  const [hoverZone, setHoverZone] = useState<TravelCharge | undefined>(undefined);

  const { center, zoom, pins, setCenter, setZoom, mode, hiddenLayers, activeMapLocationId } =
    useMapStore(
      useShallow((state) => ({
        center: state.center,
        zoom: state.zoom,
        pins: state.pins,
        mode: state.mode,
        setCenter: state.setCenter,
        setZoom: state.setZoom,
        hiddenLayers: state.hiddenLayers,
        toggleHiddenLayer: state.toggleHiddenLayer,
        activeMapLocationId: state.activeMapLocationId,
      })),
    );

  const location = (activeMapLocationId as number) ?? fallbackLocationId;

  let layerTarget = undefined as string | undefined;
  switch (mode) {
    case 'mapbox://styles/mapbox/streets-v12':
    case 'mapbox://styles/mapbox/satellite-streets-v12':
      layerTarget = 'road-label';
      break;
    default:
      layerTarget = 'road-rail';
      break;
  }

  const mapIsReady = mapRef.current
    ?.getMap()
    .getStyle()
    .layers.find((layer: any) => layer.id === layerTarget);

  const markers = useMemo(
    () =>
      pins.map((pin) => (
        <Marker
          key={`pin-${pin.type}-${pin.id}`}
          longitude={pin.lng}
          latitude={pin.lat}
          anchor="bottom"
          onClick={(evt) => handleMarkerClick(evt, pin)}
        >
          <div className="absolute left-7 top-1 px-1 whitespace-nowrap text-center text-xs font-medium bg-gray-900 text-white rounded">
            {pin.message}
          </div>
          <MapPinIcon className={cn('h-7 w-7', pin.color ?? 'text-black')} title={pin.message} />
        </Marker>
      )),
    [pins],
  );

  const handleMapMove = useCallback((evt: any) => {
    setCenter(evt.viewState.longitude, evt.viewState.latitude);
    setZoom(evt.viewState.zoom);
  }, []);

  const handleMarkerClick = (evt: any, pin: Pin) => {
    switch (pin.type) {
      case 'lead': {
        const newLocation = `leads/lead/${pin.itemId}/quote`;
        if (router.state.location.pathname !== newLocation) navigate({ to: `/${newLocation}` });
        break;
      }
      case 'event': {
        const newLocation = `events/event/${pin.itemId}/overview`;
        if (router.state.location.pathname !== newLocation) navigate({ to: `/${newLocation}` });
        break;
      }
    }
  };

  useEffect(() => {
    mapRef.current?.resize();
  }, [size]);

  // Wait for initialization, then set the map mode.
  // This is a workaround for a bug in the underlying mapbox-gl-js library
  // which throws an error if the style is provided before the map is initialized.
  useEffect(() => {
    if (mode && mapRef.current) {
      mapRef.current?.getMap().setStyle(mode);
      setInitialized(true);
    }

    return () => {
      setInitialized(false);
    };
  }, [mapRef.current, mode, initialized]);

  let layers;
  if (travelChargeLayers) {
    layers = {
      ...travelChargeLayers,
      features: travelChargeLayers.features.filter((feature) =>
        hiddenLayers.length ? hiddenLayers.indexOf(feature.properties?.id) === -1 : true,
      ),
    };
  }

  if (location === 0) {
    return <NoResults label="location" />;
  }

  return (
    <div className={cn('w-full h-[100vh]', className)}>
      <div className="relative">
        {hoverZone && (
          <button
            type="button"
            onClick={() => setHoverZone(undefined)}
            className="absolute left-2 top-12 z-20 p-2 bg-white border border-gray-300 rounded shadow"
          >
            <div className="text-sm font-medium">{hoverZone?.name}</div>
            <div className="text-xs">${hoverZone?.charge}</div>
          </button>
        )}
      </div>
      <MapboxGLMap
        reuseMaps
        ref={mapRef}
        mapboxAccessToken={MAPBOX_KEY}
        {...center}
        zoom={zoom}
        onMove={handleMapMove}
        onLoad={() => {
          mapRef.current?.getMap().setStyle(mode);
          setInitialized(true);
        }}
        onClick={(evt) => {
          if (evt.features && evt.features?.length > 0) {
            setHoverZone(evt.features[0]?.properties as TravelCharge);
          }
        }}
        style={{ width: '100%', height: '100%' }}
        interactiveLayerIds={layers?.features.map((feature) => `layer-${feature.properties?.id}`)}
      >
        {markers}

        {heatmap && (
          <Source type="geojson" data={heatmap}>
            <Layer {...heatmapLayer} beforeId={layerTarget} />
          </Source>
        )}

        {initialized && mapIsReady && layers && (
          <Source key={`json-${layers.features.length}`} id="zones" type="geojson" data={layers}>
            {layers.features.map((feature, i) => (
              <Layer
                key={`layer-${feature.properties?.id}-${i}`}
                id={`layer-${feature.properties?.id}`}
                source="zones"
                type="fill"
                filter={['==', 'id', feature.properties?.id]}
                paint={{
                  'fill-opacity': 0.35,
                  'fill-color': feature.properties?.color || '#000',
                  'fill-antialias': true,
                  'fill-outline-color': '#000',
                }}
                beforeId={layerTarget}
              />
            ))}
          </Source>
        )}

        <FullscreenControl />
        <GeolocateControl />
        <LayerFilterControl />
        <MapStyleControl />
        {controls}
      </MapboxGLMap>
    </div>
  );
};

export default memo(MapViewer);
