import { cn } from '@/utils/format';
import { CalendarEventChipRenderProps } from '../types';
import { CalendarChip } from './CalendarChip';

export const BackgroundChip = ({ eventInfo }: CalendarEventChipRenderProps) => {
  const { last_column, final_column } = eventInfo.event.extendedProps;

  return (
    <CalendarChip
      eventInfo={eventInfo}
      style={{ backgroundColor: eventInfo.backgroundColor }}
      className={cn(
        'border-0',
        last_column ? 'border-r border-black' : '',
        final_column ? 'border-r-[3px] border-black' : '',
      )}
    />
  );
};
